import React, { useState } from 'react';
import { Wrapper } from '@components/grid';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import PaltaBreadcrumbs from '../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import PaltaModalSpinner from '../../components/PaltaModalSpinner';
import styled from 'styled-components';
import * as ROUTES from '../../constants/routes'
// import './ServicePayments.css';
import './servicePaymentWallets.css';
import { Col, Row, Container } from 'react-bootstrap';
import { useHistory } from 'react-router';
import * as API from '../../constants/api-staging';
import { useLocation } from 'react-router-dom';

const ScrollHorizontalContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 16px;
`;

const ScrollHorizontalItem = styled.div`
  color: #fff;
  background-color: #3A4D9F;
  padding: 32px;
  margin-right: 10px;
  border-radius: 16px;
  flex: 0 0 auto;
  cursor: pointer;
`;

const ServicePaymentWallets = () => {
  const [loading, setLoading] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState(undefined);
  const location = useLocation();
  const prevData = location.state.datosTransaccion;
  const businessName = location.state.businessName;
  const businessNameNoPrefix = businessName.split(' ').slice(1).join(' ');
  const history = useHistory();
  const activaFee = Number(API.CAPTAR_FEE_ACTIVA)
  const payBody = location.state.datosTransaccion;
  const paymentAmount = Number(payBody.factura[0].importe);
  const paymentAmountWithFee = ((paymentAmount / 100) * activaFee) + paymentAmount
  const userData = JSON.parse(localStorage.getItem('user'));
  const userWallets = walletFeesValidation(userData.wallets);
  const handleSelectedWallet = (currency) => {
    setSelectedWallet(currency);
  }
  function walletFeesValidation(wallets) {

    return wallets.filter(wallet => {
      if (wallet.currency === 'peso' && wallet.amount >= paymentAmount) {
        return wallet;
      }
      if (wallet.currency === 'peso_activa' && wallet.amount >= paymentAmountWithFee) {
        return wallet;
      }
    });
  }
  const sanitizeAmount = amount => {
    return amount.toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  }

  return (
    <>
      <PaltaNavBar className="light" />
      <PaltaBreadcrumbs
        name=""
        link={ROUTES.PAYMENTS_SELECT}
        variant="dark"
      />
      <section style={{ padding: '0.5rem 0px' }}></section>
      <Container>
        <Wrapper>
          <Container>
            <Row>
              <Col>
                <div className="data-label">Empresa:</div>
                <div 
                  className="data colorFont"
                  style={{textTransform: 'capitalize'}}
                  data-cy='business-name'
                  >{businessNameNoPrefix}</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="data-label">Importe:</div>
                <div 
                  className="data colorFont"
                  data-cy='final-amount'
                  >$ {sanitizeAmount(paymentAmount)}</div>
              </Col>
            </Row>
          </Container>
          <ScrollHorizontalContainer>
            {
              userWallets.length ?
              userWallets.map(wallet => {
                return (

                  <ScrollHorizontalItem
                    key={wallet.currency}
                    style={{fontFamily: "'ProductSans', sans-serif", fontWeight: 'bold'}}
                    onClick={() => {
                      history.push({
                        pathname: ROUTES.SERVICE_PAYMENTS_CASHOUT,
                        state: { wallet, payBody, businessName }
                      });
                    }}
                    data-cy='wallet'
                  >
                    {`${wallet.currencyLabel}`}
                  </ScrollHorizontalItem>
                )
              }) : 'No existen monederos con saldo'
            }
          </ScrollHorizontalContainer>
        </Wrapper>
      </Container >
      <PaltaFooter />
      <PaltaModalSpinner show={loading} size="sm" />
    </>
  )
};

export default ServicePaymentWallets;
