import React from 'react'
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes'

const BusinessList = ({ list }) => {
    return (
        <div 
            className='list-result'
            data-cy="result-list"
            >
            
            {
                list.map(business => {
                    return (
                        <Link
                            key={business.descripcion}
                            to={{
                                pathname: ROUTES.SERVICE_PAYMENTS_GENERIC_1,
                                state: business
                            }}
                            className='business-card'
                            data-cy={business.descripcion}
                        >
                            <div className='mb-1'>
                                Nombre:
                                {business.descripcion}
                            </div>

                            <div>
                                Rubro: {business.rubro}
                            </div>

                        </Link>
                    )
                })
            }
        </div>

    )
}

export default BusinessList;
