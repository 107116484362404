import React from 'react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { CreatePdfCashin } from '../create-pdf-cashin/CreatePdfCashin';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
export const SeeCashin = ({ cashinSee,escapeRegExp }) => {
  const year = new Date().getFullYear();
  const generatePdfDocument = async (cashinSee) => {
    const blob = await pdf((
      <CreatePdfCashin
        title='My PDF'
        data={cashinSee}
        escapeRegExp={escapeRegExp}
      />
    )).toBlob();
    saveAs(blob, "Ingreso.pdf");
  };
  return (
    <>
      <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
        <p className="mt-2 ml-2">Monto del Ingreso: </p>
        <p className="mt-2 mr-2">${cashinSee.amount.toFixed(2)}</p>
      </div>
      <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
        <p className="mt-2 ml-2">Fecha del ingreso: </p>
        <p className="mt-2 mr-2">
          {format(new Date(cashinSee.createdAt), 'yyyy') ===
            year.toString()
            ? format(
              new Date(cashinSee.createdAt),
              'dd-MMMM-yyy, HH:mm',
              { awareOfUnicodeTokens: true, locale: es },
            )
            : format(
              new Date(cashinSee.createdAt),
              'dd-MMMM-yyy',
              { awareOfUnicodeTokens: true, locale: es },
            )}

        </p>
      </div>
      {cashinSee._walletToId.currency === "fiscal_credit" ? (
        <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
          <p className="mt-2 ml-2">Monedero: </p>
          <p className="mt-2 mr-2">Crédito Fiscal</p>
        </div>
      ) : cashinSee._walletToId.currency === "peso" ? (
        <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
          <p className="mt-2 ml-2">Monedero: </p>
          <p className="mt-2 mr-2">Pesos</p>
        </div>

      ) : cashinSee._walletToId.currency === "peso_tourism" ? (
        <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
          <p className="mt-2 ml-2">Monedero: </p>
          <p className="mt-2 mr-2">Crédito Turismo</p>
        </div>
      ) : cashinSee._walletToId.currency === "	peso_activa" ? (
        <div className="modal-item-detail m-3" style={{ background: "#EDEDED", borderRadius: "30px" }}>
          <p className="mt-2 ml-2">Monedero: </p>
          <p className="mt-2 mr-2">Crédito Activa</p>
        </div>
      ) : null}
      {cashinSee.type === "cashin" && cashinSee.counterparty?.name !== undefined ? (
        <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
          <p className="mt-2 ml-2">Nombre y Apellido: </p>
          <p className="mt-2 mr-2"> {cashinSee.counterparty?.name}</p>
        </div>

      ) : cashinSee.type === "cashin" && cashinSee._userToId.role === "user" ? (
        <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
          <p className="mt-2 ml-2">Nombre y Apellido: </p>
          <p className="mt-2 mr-2">{cashinSee._userToId.name} {cashinSee._userToId.lastname}</p>
        </div>
      ) : cashinSee.type === "cashin" && (cashinSee._userToId.role === "commerce" || cashinSee._userToId.role === "branch" || cashinSee._userToId.role === "cash") ? (
        <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
          <p className="mt-2 ml-2">Nombre de Fantasía: </p>
          <p className="mt-2 mr-2">{escapeRegExp(`${cashinSee._userToId.fantasyName}`)}</p>
        </div>
      ) : null}
      {cashinSee.type === "cashin" && cashinSee.counterparty !== undefined && cashinSee.counterparty.account_routing?.scheme !== undefined && cashinSee.counterparty.account_routing?.address !== undefined ? (
        <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
          <p className="mt-2 ml-2">{cashinSee.counterparty.account_routing?.scheme}: </p>
          <p className="mt-2 mr-2">{cashinSee.counterparty.account_routing?.address} </p>
        </div>
      ) : null}
      {cashinSee.type === "cashin" && cashinSee.counterparty?.bank !== undefined ? (
        <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
          <p className="mt-2 ml-2">Banco Emisor: </p>
          <p className="mt-2 mr-2">{cashinSee.counterparty?.bank}</p>
        </div>
      ) : null}
      {cashinSee._currencyId.feePercent !== undefined ? (

        <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
          <p className="mt-2 ml-2">Porcentaje de Tarifa: </p>
          <p className="mt-2 mr-2">{cashinSee._currencyId.feePercent}%</p>
        </div>
      ) : null}
      {cashinSee.transactionCode !== undefined ? (
        <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
          <p className="mt-2 ml-2">N° operación: </p>
          <p className="mt-2 mr-2">{cashinSee.transactionCode}</p>
        </div>
      ) : null}
      <button className="btn float-right text-white rounded-pill mb-3 btnColor" onClick={() => generatePdfDocument(cashinSee)}>
        <span className="icon-download ">
          <i className="material-icons">file_download</i>
        </span>
        Descargar PDF
      </button>
    </>
  )
}