import {
  startRegistration,
  startAuthentication,
} from '@simplewebauthn/browser';
import axios from 'axios';
import Swal from 'sweetalert2';
import * as ROUTES from '../../constants/api-staging';

const getRegisterOptions = async () => {
  const response = await axios.get(`${ROUTES.BASE_URL}/webAuthn/registration-options`, {
    headers: {
      Authorization: window.localStorage.getItem('token'),
    },
  });
  return response?.data;
};
const verifyRegisterResponse = async (body) => {
  const response = await axios.post(
    `${ROUTES.BASE_URL}/webAuthn/verify-registration`,
    body,
    {
      headers: {
        Authorization: window.localStorage.getItem('token'),
      },
    },
  );
  return response?.data;
};
const getAuthenticationOptions = async (id) => {
  const response = await axios.get(
    `${ROUTES.BASE_URL}/webAuthn/authentication-options/${id}`,
  );
  return response?.data;
};
const verifyAuthenticationResponse = async (body, id) => {
  const response = await axios.post(
    `${ROUTES.BASE_URL}/webAuthn/verify-authentication/${id}`,
    body,
  );
  return response?.data;
};

export const webAuthn = {
  registerCredential: async (id) => {
    try {
      const options = await getRegisterOptions();
      if(!options?.data) return false;
      const authenticatorResponse = await startRegistration(options.data);
      const verified = await verifyRegisterResponse(authenticatorResponse);
      if (verified) {
        window.localStorage.setItem(
          'authn',
          JSON.stringify(id),
        );
         Swal.fire(
          'Credencial Registrada con Exito',
          'Ya puede utilizar este metodo para autenticarse',
          'success',
        );
      }else{
        Swal.fire('Ha ocurrido un error', 'Por favor, intente nuevamente.', 'error');
      }
    } catch (error) {
      if (error?.name === 'InvalidStateError') {
        Swal.fire(
          'Probablemente este autenticador ya ha sido registrado',
          'Por favor, intente con otro autenticador',
          'error',
        );
      } else {
        Swal.fire(
          'Error al generar la credencial',
          'Por favor, intente nuevamente',
          'error',
        );
      }
    }
  },
  authenticate: async (id) => {
    try {
      const options = await getAuthenticationOptions(id);
      if(!options?.data) return false;
      const authenticatorResponse = await startAuthentication(options.data);
      const data = await verifyAuthenticationResponse(
        authenticatorResponse,
        id,
      );
      return data;
    } catch (error) {
       alert(JSON.stringify(error));
       return false;
    }
  },
  isAvailable: async () => {
    if (window.PublicKeyCredential) {
      const available =
        await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
      return available;
    }
    return false;
  },
};
