import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PaltaBreadcrumbs from '../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import CoinbaseCommerceButton from 'react-coinbase-commerce';
import 'react-coinbase-commerce/dist/coinbase-commerce-button.css';

import * as ROUTES from '../../constants/routes';

class index extends Component {
  state = {
    amount: '',
    isLoading: false,
  };

  hanldeAmount = (e) => {
    this.setState({ amount: e.target.value });
  };
  handleChargeSuccess = () => {
    toast.success(
      toast.info(`Cargo exitoso con CoinBase`, {
        hideProgressBar: false,
        autoClose: 3500,
      }),
    );
    setTimeout(() => {
      this.props.history.push(ROUTES.DASHBOARD);
    }, 3500);
  };

  render() {
    let { id } = this.context.user;
    let toId = `${id}`;
    return (
      <div style={{ backgroundColor: '#fff' }}>
        <PaltaBreadcrumbs
          name="Cargar mi Palta"
          link={ROUTES.ACCOUNT}
          linkName="Mi Cuenta"
          variant="dark"
        />
        <section style={{ marginBottom: '4em' }}>
          <Container>
            <Row>
              <Col sm={12}>
                <h2 className="title" style={{ color: '#3628DD' }}>
                  Cargar mi Palta
                </h2>
              </Col>
            </Row>
          </Container>
          <Container>
            <div
              className="justify-content-md-center align-items-center mt-5"
              style={{ maxWidth: '28rem', margin: '0 auto' }}
            >
              <img
                style={{ margin: 'auto auto', display: 'block', width: '50%' }}
                src="https://upload.wikimedia.org/wikipedia/commons/1/1a/Coinbase.svg"
                alt="MercadoPago"
              />
              <hr />
              <CoinbaseCommerceButton
                customMetadata={toId}
                onChargeSuccess={this.handleChargeSuccess}
                className="btn-block btn-fullwidthe btn btn-primary btn-lg"
                checkoutId={'61c90914-aa89-49b0-b4b9-b013a207a4a7'}
              >
                Cargar con Crypto{' '}
              </CoinbaseCommerceButton>
            </div>
          </Container>
        </section>
      </div>
    );
  }
}

export default withRouter(index);
