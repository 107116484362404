export const URL = process.env.REACT_APP_API_LAMBDA;
export const BALANCE = '/v3/user/me/balance';
export const ME = '/v3/user/me';
export const USERS = '/v3/user';
export const PROVIDER = '/v2/provider';
export const TRANSACTIONS = '/v3/transaction';
export const TRANSACTIONS_ME = '/v3/user/me/transactions';
export const SIGNINEMAIL = '/v3/signin';
export const UPDATE_USER = '/v3/user/me';
export const CASHIN = '/v3/user/cashinmp';
export const REFRESH_TOKEN = '/v2/auth/validate_token';
export const VERIFY_TOKEN = '/user/verifyToken';
export const MARK_LOAN_AS_PAYD = '/v3/transaction/markAsPaidLoan';
export const CAPTAR_FEE_ACTIVA = process.env.REACT_APP_PERCENT_FEE_PAYSERVICE_CAPTAR_PAGOS;
export const BASE_URL = process.env.REACT_APP_API;