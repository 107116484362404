import React, { Fragment, useState } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

const ContainerImg = styled.div`
  border-radius: 50%;
  border: 2px solid gray;
  overflow: hidden;
`;
const AffinityContainer = styled.div`
  margin: 5px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid lightgray;
  box-shadow: ${(props) => (props.selected ? '0px 0px 3px gray' : 'none')};
  &:hover {
    cursor: pointer;
  }
`;

export const SelectAffinityModal = ({
  dataAfinity,
  setSelectAfinity,
  selectAfinity,
  setShow,
}) => {
  const [idAfinity, setIdAfinity] = useState(undefined);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title className="text-center colorFont font-weight-bold">
          Seleccioná una afinidad
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pl-0 pr-0" style={{ textAlign: 'justify' }}>
        <>
          <div
            className="m-0 vh-50 row justify-content-center align-items-center d-flex "
            style={{ paddingBottom: '45px !important' }}
          >
            {dataAfinity &&
              dataAfinity.length &&
              <>
              {dataAfinity.map((data, i) => {
                return data?.modifiesAppUi === true ? (
                  <Fragment key={i}>
                    {data.logo?.map((img, i) => {
                      return img.imageType === 'logo' ? (
                        <AffinityContainer
                          selected={data?._id === selectAfinity?._id}
                          onClick={() => {
                            setIdAfinity(data._id);
                            setSelectAfinity(data);
                          }}
                        >
                          <h5>{data.name}</h5>
                          <ContainerImg id={img._id} key={i}>
                            <img
                              className="img-fluid"
                              src={dataAfinity !== undefined && img.image}
                              alt=""
                              height={100}
                              width={100}
                            />
                          </ContainerImg>
                        </AffinityContainer>
                      ) : null;
                    })}
                  </Fragment>
                ) : null;
              })}
              <button
              className="btn btn-success mt-3"
              disabled={!selectAfinity?._id?.length}
              onClick={() => {
                localStorage.setItem('AfinityID', JSON.stringify(idAfinity));
                setShow(false);
              }}
            >
              Aceptar
            </button>
            </>
              }
          </div>
        </>
      </Modal.Body>
    </>
  );
};
