import React, { useState, useCallback, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import QrReader from 'react-qr-reader';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { useHistory, useLocation } from 'react-router-dom';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import { shopId, token } from '@store';
import * as ROUTES from '@constants/routes';
import { useGetShop } from '@core/api-hooks/use-shop/get';
import Swal from 'sweetalert2';
import { motion } from "framer-motion";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const QRReader = () => {
  const [error, setError] = useState(undefined);
  const [result, setResult] = useState(undefined);
  const delay = 500;
  const selectedShopId = useSetRecoilState(shopId);
  const history = useHistory();
  const query = useQuery();
  const [{ data, error: errorGetShop }, getShop] = useGetShop();
  const tokenData = useRecoilValue(token);

  useEffect(() => {
    if (errorGetShop) {
      setError('Comercio invalido');
      Swal.fire({
        icon: 'error',
        title: 'Problemas para obtener el comercio, intentelo de nuevo más tarde',
        padding: '2px',
        showConfirmButton: false,
        width: '300px',
        heightAuto: '200px',
        timer: 1500,
        customClass: {
          title: 'm-0 text-danger',
          header: 'p-0',
        },
      })

    }
  }, [errorGetShop]);

  useEffect(() => {
    if (data) {
      setError(undefined);
      selectedShopId(result.id);
      history.push(ROUTES.PAYMENTS_SELECT_WALLET, {shopId : result.id});
    }
  }, [data, result]);

  const handleScan = useCallback(
    (data) => {
      console.log('data es', data)
      if (data) {
        setError(undefined);
        const url = new URL(data);
        if (url.searchParams.get('name') && url.searchParams.get('id')) {
          setResult({
            name: url.searchParams.get('name'),
            id: url.searchParams.get('id'),
          });
          selectedShopId(undefined);
          getShop(url.searchParams.get('id'), tokenData);
        } else {
          setError('QR invalido, por favor vuelva a scannear');
        }
      }
    },
    [getShop],
  );

  const handleScanError = useCallback((err) => {
    console.log('ERR', err);
  }, []);

  const handleSelect = useCallback(() => {
    selectedShopId(result?.id);
    history.push(ROUTES.PAYMENTS_SELECT_WALLET, {shopId : result.id});
  }, [selectedShopId, result, history]);

  useEffect(() => {
    if (query.get('name')) {
      if (query.get('name') && query.get('id')) {
        setResult({
          name: query.get('name'),
          id: query.get('id'),
        });
      } else {
        setError('QR invalido, por favor vuelva a scannear');
      }
    }
  }, []);

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <PaltaNavBar />
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ type: 'spring', duration: .5, bounce: 0.3 }}
      >
        <section>
          <Container>
            <Row>
              <Col sm={12}>
                <h3 className="title colorFont">
                  QR Scanner
                </h3>
              </Col>
            </Row>
          </Container>
        </section>
        <section>
          <Container style={{ marginBottom: 16 }}>
            {error && (
              <Row>
                <Col sm={12}>
                  <p>{error}</p>
                </Col>
              </Row>
            )}
            <Row>
              <Col sm={12}>
                <p>{result?.name}</p>
              </Col>
            </Row>
            {!result ? (
              <QrReader
                delay={delay}
                onError={handleScanError}
                onScan={handleScan}
                style={{ width: '100%' }}
              />
            ) : (
              <>
                <div className="text-center" style={{ marginTop: '1rem' }}>
                  <Button
                    style={{ marginLeft: '1em' }}
                    variant="primary"
                    size="lg"
                    onClick={() => setResult(undefined)}
                  >
                    Volver a scannear
                  </Button>
                </div>
                <div className="text-center" style={{ marginTop: '1rem' }}>
                  <Button
                    style={{ marginLeft: '1em' }}
                    variant="primary"
                    size="lg"
                    onClick={handleSelect}
                  >
                    Continuar
                  </Button>
                </div>
              </>
            )}
          </Container>
        </section>
      </motion.div>
      <PaltaFooter />
    </div>
  );
};

export default QRReader;
