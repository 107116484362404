import React from 'react';
import styled from 'styled-components';
import * as ROUTES from '@constants/routes';
import logoUser from '../../../assets/images/user.svg';
import logoCommerce from '../../../assets/images/commerce.svg';
import logoBeneMzaAct from '../../../assets/images/Mendoza.svg';
import logoPalta from '../../../assets/images/palta-logo-500.svg';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useUserValidateId } from '../../../core/api-hooks/use-validation-id-user/get';

const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

`;

const BoxContainer = styled.div`
  width: 340px;
  min-height: 624px;
  display: flex;
  flex-direction: column;
  border-radius: 50px;

  box-shadow: 0px 0px 4.7px rgba(15, 15, 15, 0.28);
  position: relative;
  overflow: hidden;
  padding: 0px 10px 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;


const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RegisterUser = () => {
  const history = useHistory()
  const { id } = useParams()

  const [{ data: dataValid, error: errorData }, getUserValidateId] = useUserValidateId();

  useEffect(()=> {
    getUserValidateId(id).then(({data})=> {
        if(!data) {
        localStorage.removeItem("userID");
        history.push('/register')
      } else {
        localStorage.setItem("userID", id);
      }
    })
  },[])

  return (
    <>
      <AppContainer>
        <BoxContainer>
          <InnerContainer>
            <img
              src={logoPalta}
              width="120"
              height="50"
              className="d-inline-block align-top position-logo-palta "
              alt="Palta logo"
            />
            {!localStorage.getItem("errordata") ? (
              <div className="card carduser" style={{ width: "18rem" }} onClick={() => history.push(`${ROUTES.MENU_BENE_MZA_ACT}/${id}`)}>
                <img className="card-img-top" src={logoBeneMzaAct} alt="image-commerce" height={70} width={70} />
                <div className="card-body">
                  <p className="card-text text-center bold">
                    Soy beneficiario Mendoza activa
                  </p>
                </div>
              </div>
            ) : (
              <div className="card carduser-invalid" style={{ width: "18rem", background: "#DFE9DB" }}>
                <img className="card-img-top" src={logoBeneMzaAct} alt="image-commerce" height={70} width={70} />
                <div className="card-body">
                  <p className="card-text text-center bold">
                    Soy beneficiario Mendoza activa
                  </p>
                </div>
              </div>
            )}
            {!localStorage.getItem("errordata") ? (
              <div className="card carduser" style={{ width: "18rem" }} onClick={() => history.push(`${ROUTES.USER_REGISTER}/${id}`)}>
                <img className="card-img-top" src={logoUser} alt="image-user" height={70} width={70} />
                <div className="card-body">
                  <p className="card-text text-center bold">Deseo crear mi billetera palta</p>
                </div>
              </div>

            ) : (
              <div className="card carduser-invalid" style={{ width: "18rem", background: "#DFE9DB" }} >
                <img className="card-img-top" src={logoUser} alt="image-user" height={70} width={70} />
                <div className="card-body">
                  <p className="card-text text-center bold">Deseo crear mi billetera palta</p>
                </div>
              </div>
            )}

            {!localStorage.getItem("errordata") ? (
              <div className="card carduser" style={{ width: "18rem" }} onClick={() => history.push(`${ROUTES.COMMERCE_PAGE_1}/${id}`)}>
                <img className="card-img-top" src={logoCommerce} alt="image-commerce" height={70} width={70} />
                <div className="card-body">
                  <p className="card-text text-center bold">Deseo recibir palta en mi comercio</p>
                </div>
              </div>
            ) : (
              <div className="card carduser-invalid" style={{ width: "18rem", background: "#DFE9DB" }} >
                <img className="card-img-top" src={logoCommerce} alt="image-commerce" height={70} width={70} />
                <div className="card-body">
                  <p className="card-text text-center bold">Deseo recibir palta en mi comercio</p>
                </div>
              </div>
            )}

            
          </InnerContainer>
        </BoxContainer>
      </AppContainer>

    </>
  );
};
