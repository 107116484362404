import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
// import { Container } from 'react-bootstrap';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { user, cashOutWallet } from '../../store';
import PaltaBreadcrumbs from '../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import { Wrapper as WrapperComponent } from '../../components/wrapper';
import * as ROUTES from '../../constants/routes';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import { Container, Wrapper, Grid, Col } from '@components/grid';
import logo from '../../assets/images/advertencia.png';
const WalletItemWrapper = styled.div`
  background-color:#3A4D9F;
  margin-right: ${({ theme }) => theme.spacers.xs};
  padding: 14px 48px;
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.spacers.s};
  border: none;
  cursor: pointer;
  padding-left:30px;
  margin-bottom:20px
`;

const WalletItemWrapperDisabled = styled.div`
  background-color: ${({ theme }) => theme.colors.disabled};
  margin-right: ${({ theme }) => theme.spacers.xs};
  padding: 14px 48px;
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.spacers.s};
  border: none;
  padding-left:30px;
  margin-bottom:20px
`;

const AmountWrapper = styled.div`
  font-size: ${({ theme }) => theme.text.fontSize.xl};
`;

export const CashOut = () => {
  const userData = useRecoilValue(user);
  const setCashOutWallet = useSetRecoilState(cashOutWallet);
  const history = useHistory();

  const selectWallet = useCallback(
    (e, wallet) => {
      e.preventDefault();
      setCashOutWallet(wallet);
      history.push(ROUTES.CASH_OUT_CBU_CVU);
    },
    [history],
  );
  let userWallets = userData?.wallets?.slice().sort((a, b) => {

    if (a.currencyLabel > b.currencyLabel) {
      return -1;
    }
    if (a.currencyLabel < b.currencyLabel) {
      return 1;
    }
    return 0;
  })

  return (
    <>

      <PaltaNavBar />
      <PaltaBreadcrumbs className="p-0" name="Seleccionar monedero" link={ROUTES.ACCOUNT} variant="dark"/>
      <Container style={{ paddingLeft: 6 }}>
        <Wrapper>
          {isMobile ? (
            <h3 className="ml-3">Monederos</h3>
          ) : (
            <h2 className="ml-3">Monederos</h2>
          )}

          <div className="row d-flex justify-content-center align-items-center">

            <div className="shop-list-wrapper-cashout">
              {userData?.cvu !== undefined ?
                userWallets?.filter(wallet => !wallet.currency.includes('dolar')).map((wallet) => {
                  return (
                    userData.role === "user" ? (
                      wallet.currency !== 'peso' ? (
                        <WalletItemWrapperDisabled key={wallet.currency}>

                          <AmountWrapper><h5 className="text-white">{wallet.currencyLabel}{` $ ${wallet.amount.toFixed(2)}`}</h5></AmountWrapper>
                        </WalletItemWrapperDisabled>
                      ) : (
                        <WalletItemWrapper
                          key={wallet.currency}
                          onClick={(e) => selectWallet(e, wallet)}
                        >
                          <AmountWrapper><h5 className="text-white">{wallet.currencyLabel}{` $ ${wallet.amount.toFixed(2)}`}</h5></AmountWrapper>
                        </WalletItemWrapper>
                      )
                    ) : userData.role === "commerce" ? (
                      wallet.currency === 'fiscal_credit' ? (
                        <WalletItemWrapperDisabled key={wallet.currency}>
                          <AmountWrapper><h5 className="text-white">{wallet.currencyLabel}{` $ ${wallet.amount.toFixed(2)}`}</h5></AmountWrapper>
                        </WalletItemWrapperDisabled>
                      ) : (
                        <WalletItemWrapper
                          key={wallet.currency}
                          onClick={(e) => selectWallet(e, wallet)}
                        >
                          <AmountWrapper><h5 className="text-white">{wallet.currencyLabel}{` $ ${wallet.amount.toFixed(2)}`}</h5></AmountWrapper>
                        </WalletItemWrapper>
                      )
                    ) : null
                  )
                })
                :
                <div className="row mx-0 justify-content-center align-items-center mt-3">
                  <div className="col-12 col-sm-12  p-0">
                    <p className="alert alert-danger m-0 h4">
                      <img
                        src={logo}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                        alt="advertencia"
                      />
                      Para retirar dinero debe ingresar primero a la pantalla "Mi Cvu" y generar su cvu y alias.
                    </p>
                  </div>
                </div>

              }
            </div>
          </div>

          {userData?.cvu !== undefined &&
            <div className="row justify-content-center pr-1 mb-2">
              <div className="col-12 col-sm-12 p-0">
                <p className="alert alert-info m-0">
                  <WrapperComponent className="text-center p-0" style={{ color: '#3A4D9F', fontWeight: "bold" }}><p>Seleccione desde qué monedero desea extraer su dinero.</p> </WrapperComponent>
                </p>
              </div>
            </div>
          }
        </Wrapper>
      </Container>

      <PaltaFooter />
    </>
  );
};
