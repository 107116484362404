import React, { useState, useEffect } from 'react';
import { Container, Wrapper } from '@components/grid';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import PaltaBreadcrumbs from '../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import { useLocation } from 'react-router-dom';
import './ServicePayments.css';
import * as ROUTES from '../../constants/routes'
import './ServicePayments.css';
import PaltaModalSpinner from '../../components/PaltaModalSpinner';
import { useGetOpcion } from '../../core/api-hooks/captar-pagos/useGetOpcion';
import { useHistory } from 'react-router';
import { Col, Row } from 'react-bootstrap';
import { sanitizeAmount } from './utils';

const GetOpcion = () => {
    const location = useLocation();
    const prevData = location.state;
    const history = useHistory();
    const userData = JSON.parse(localStorage.getItem('user'));

    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [requiereDatosAdicionales, setRequiereDatosAdicionales] = useState(undefined);
    const [valueMontoVariable, setValueMontoVariable] = useState('')
    const [responseEmpresa, setResponseEmpresa] = useState(prevData.response.data.listaOpcion[0]);
    const [selectValue, setSelectValue] = useState(undefined);
    const [datosTransaccion, setDatosTransaccion] = useState(undefined);

    const [postBody, setPostBody] = useState(prevData.postBody)

    const handleInputMontoVariable = (e) => {
        const value = e.target.value;
        setValueMontoVariable(value);
    }
    const handleSelect = (e) => {
        setSelectValue(e.target.value);
    }

    const sanitizarMontoVariable = monto => {
        if (!monto.includes('.') || !monto.includes(',')) {
            return `${monto}.00`;
        }
        return monto.replace(/,/g, '.');
    }


    const handleButtonAvanzar = async () => {
        setLoading(true);
        const newPost = {
            ...postBody,
            ordinal: postBody.ordinal + 1,
            parametros: [...postBody.parametros, selectValue || sanitizarMontoVariable(valueMontoVariable)]
        }
        try {
            const response = await useGetOpcion(newPost);
            setLoading(false);
            if (response?.data?.data?.code === 200) {
                history.push({
                    pathname: ROUTES.SERVICE_PAYMENTS_GET_OPCION_4,
                    state: { response: response.data, businessName: prevData.businessName, newPost }
                })
            } else {
                setLoading(false)
                fireSwal('Hubo un error en la petición');
                history.push(ROUTES.DASHBOARD);
            }
        } catch (error) {
            setLoading(false)
            fireSwal('Hubo un error en la petición');
            history.push(ROUTES.DASHBOARD);
        }
    }
    const handlePaymentButton = () => {
        if (datosTransaccion) {
            history.push({
                pathname: ROUTES.SERVICE_PAYMENTS_WALLETS,
                state: { datosTransaccion, businessName: prevData.businessName }
            })
        }
    }

    useEffect(() => {

        if (responseEmpresa.opcion[0]?.idTransaccion?.[0]) {
            const postPayBody = {
                factura: [{
                    tipo: "sinFactura",
                    idTransaccion: responseEmpresa.opcion[0]?.idTransaccion?.[0],
                    importeVariable: responseEmpresa.opcion[0]?.importeVariable?.[0],
                    descripcion: responseEmpresa.opcion[0]?.descripcion?.[0],
                    importe: responseEmpresa.opcion[0]?.importe?.[0],
                    codeProducto: responseEmpresa.opcion[0]?.idProducto?.[0],
                    idTrxComercio: userData.cuit,
                    tipoPago: "S",
                    idPago: userData.cuit
                }],
                currency: undefined
            }

            setDatosTransaccion(postPayBody);
        }
        if (responseEmpresa?.opcion?.[0]?.opciones?.[0]?.opcion.length >= 1) {
            setSelectValue(responseEmpresa?.opcion?.[0]?.opciones?.[0]?.opcion[0]);
        }
        if (responseEmpresa.opcion[0].requiereDatosAdicionales[0] === 'false') {
        } else {
            setRequiereDatosAdicionales(true);
        }
    }, []);


    return (
        <>
            <PaltaNavBar className="light" />
            <PaltaBreadcrumbs
                name=""
                link={ROUTES.PAYMENTS_SELECT}
                variant="dark"
            />
            <Container>
                <Wrapper>
                    <Row>
                        <Col>
                            <h4>Datos requeridos para el servicio</h4>
                        </Col>
                    </Row>
                    <div>
                        <div>

                        </div>
                        {
                            !requiereDatosAdicionales ?
                                <>
                                    {
                                        datosTransaccion && (
                                            <>
                                                <Row>
                                                    <Col>
                                                        <div className="data-label">
                                                            Empresa: {prevData.businessName}
                                                        </div>
                                                        <div
                                                            className="data colorFont"
                                                            data-cy='amount'
                                                        >
                                                            Importe $: {sanitizeAmount(+datosTransaccion.factura[0].importe)}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                        )
                                    }
                                    <button
                                        className='button'
                                        onClick={handlePaymentButton}
                                        data-cy='payment-button'
                                    >
                                        Continuar al pago
                                    </button>
                                </>
                                :
                                <>
                                    {
                                        responseEmpresa?.opcion?.[0]?.opciones?.[0]?.opcion.length
                                            ?
                                            <>
                                                <Row>
                                                    <Col>
                                                        <div className="data-label">

                                                            <OpcionTable
                                                                opciones={responseEmpresa?.opcion?.[0]?.opciones?.[0]?.opcion}
                                                                handleSelect={handleSelect}
                                                                selectValue={selectValue}
                                                            />
                                                        </div>
                                                        <div className="data colorFont">
                                                            <button
                                                                className='button'
                                                                onClick={handleButtonAvanzar}
                                                                data-cy='more-options-button'
                                                            >Continuar
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                            :
                                            <>
                                                {
                                                    responseEmpresa?.opcion?.[0]?.importeVariable?.[0] === 'true' && !datosTransaccion
                                                        ?
                                                        <>
                                                            <Row>
                                                                <Col>
                                                                    <div className="data-label">
                                                                        Ingrese un monto entre ${responseEmpresa?.opcion?.[0]?.importeMinimo} y ${responseEmpresa?.opcion?.[0]?.importeMaximo}
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            className='input-empresa form-control'
                                                                            value={valueMontoVariable}
                                                                            onChange={handleInputMontoVariable}
                                                                            type='number'
                                                                            min={Number(responseEmpresa?.opcion?.[0]?.importeMinimo)}
                                                                            max={Number(responseEmpresa?.opcion?.[0]?.importeMaximo)}
                                                                            data-cy='variable-amount'
                                                                        ></input>
                                                                    </div>
                                                                    <div>
                                                                        <button
                                                                            className='button'
                                                                            onClick={handleButtonAvanzar}
                                                                            data-cy='more-options-button'
                                                                        >Continuar</button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>el importe no es variable</> // aparentemente nunca llega acá
                                                }
                                            </>
                                    }
                                </>
                        }
                    </div>
                </Wrapper>
            </Container >
            <PaltaFooter />
            <PaltaModalSpinner show={loading} size="sm" />
        </>
    );
};

export default GetOpcion;