import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Container, Row, Col } from 'react-bootstrap';

import * as API from '../../constants/api-staging';
import fetchApiMig from '../../core/FetchApiMig';
import PaltaBreadcrumbs from '../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import PaltaRowListItem from '../../components/PaltaRowListItem/PaltaRowListItem';

import * as ROUTES from '../../constants/routes';

class index extends Component {
  state = {
    amount: '',
    isLoading: false,
  };

  hanldeAmount = (e) => {
    this.setState({ amount: e.target.value });
  };
  hanldeSubmitCashIn = (e) => {
    e.preventDefault();
    this.setState({ isLoading: 'Procesando Carga' });
    let data = {
      amount: Number(this.state.amount),
      description: 'Palta Cashin',
      source: 'web',
    };
    fetchApiMig('POST', `${API.CASHIN}`, data)
      .then((mpResponse) => {
        toast.info(` 🤙 Serás redirigido a Mercado Pago`, {
          hideProgressBar: false,
          autoClose: 3500,
        });
        setTimeout(() => {
          window.open(mpResponse.url);
          this.setState({ amount: '', isLoading: false });
        }, 3500);
      })
      .catch((statusError) => {
        toast.error(`Error al cargar Palta`);
        // if(statusError === 401) {
        //     toast.error(`Debe verificar el email`);

        //     user.sendEmailVerification().then(() => {});
        // } else {
        //     toast.error(`Error al comunicarse con MercadoPago: ${statusError}`);
        // }
      });
  };

  render() {
    return (
      <div style={{ backgroundColor: '#fff' }}>
        <PaltaBreadcrumbs name="Cargar mi Palta" linkName="Mi Cuenta" variant="dark"/>
        <section style={{ marginBottom: '4em' }}>
          <Container>
            <Row>
              <Col sm={12}>
                <h2 className="title" style={{ color: '#3628DD' }}>
                  Eliga metodo para cargar
                </h2>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <div className="ml-auto mr-auto">
                <PaltaRowListItem
                  variant="card"
                  className="shadow"
                  img={
                    'https://vignette.wikia.nocookie.net/logopedia/images/5/5c/MercadoPago.svg'
                  }
                  name="Mercado Pago"
                  link={ROUTES.CASH_IN_MP}
                />
                <PaltaRowListItem
                  variant="card"
                  className="shadow"
                  img={
                    'https://upload.wikimedia.org/wikipedia/commons/1/1a/Coinbase.svg'
                  }
                  name="CoinBase"
                  link={ROUTES.CASH_IN_CB}
                />
              </div>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

export default index;
