import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { user } from '../../store';
import { useRecoilValue } from 'recoil';
import { H2 } from '../typography';
import { isMobile } from 'react-device-detect';
import twitter from '../../assets/images/twitter.svg';
import instagram from '../../assets/images/instagram.svg';
const FooterWrapper = styled.div`
padding: 32px 0px;
background-color: ${({ theme }) => theme.colors.backgroundFooter};
`;
const PaltaFooter = () => {
  const userData = useRecoilValue(user);
  return (
    <>
      {
        !isMobile ? (
          userData && userData !== undefined ? (
            <FooterWrapper>
              <Container className="footer-lg">
                <Row>
                  <Col>
                    <H2>Soporte</H2>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://palta.app/terminos-y-condiciones.html"
                    >
                      <p> Términos y condiciones</p>
                    </a>
                  </Col>
                  <Col>
                    <H2>¡Somos Palta!</H2>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://activa.palta.app"
                    >
                      <p>Landing</p>
                    </a>
                  </Col>
                  <Col>
                    <H2>Social</H2>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/palta_app/"
                    >
                      <p>
                        <img
                          src={instagram}
                          height={20}
                          width={20}
                          alt="instagram"
                          style={{ marginTop: -2, fontFamily: 'ProductSans' }}
                        />
                        Instagram
                      </p>
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/tarjetapalta"
                    >
                      <p>
                        <img
                          src={twitter}
                          height={20}
                          width={20}
                          alt="twitter"
                          style={{ marginTop: -2, fontFamily: 'ProductSans' }}
                        />
                        Twitter
                      </p>
                    </a>
                  </Col>
                </Row>
              </Container>
            </FooterWrapper >
          ) : null

        ) : null
      }
    </>
  );
};

export default PaltaFooter;
