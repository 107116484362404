import React from 'react';
import { ThemeProvider as Provider } from 'styled-components';
import { isMobile } from 'react-device-detect';

import { colors } from './colors';
import { emphasis } from './emphasis';
import { spacers } from './spacers';
import { fontSize, fontWeight } from './text';
import { breakPoint } from './breakPoint'
const ThemeProvider = ({ children }) => (
  <Provider
    theme={{
      colors,
      emphasis,
      spacers,
      text: {
        fontSize,
        fontWeight,
      },
      breakPoint,
      isMobile,
    }}
  >
    <>{children}</>
  </Provider>
);

export default ThemeProvider;
