import React, { useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { user, token } from '../../store';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import * as API from '../../constants/api-staging';
import { useRecoilValue } from 'recoil';
import { auth } from '../../core/Firebase';
import { fetcher } from '../../core/Fetch/Fetch';
export const ChangePassword = () => {
  const userData = useRecoilValue(user);
  const tokenData = useRecoilValue(token);
  const { register, handleSubmit, formState: { errors }, watch } = useForm({
    mode: 'onChange',
  });
  const password = useRef({});
  password.current = watch("password", "");
  const onSubmit = useCallback(async (data) => {
    let password = data.password;
    auth.signInWithEmailAndPassword(userData.email,data.oldPassword)
      .then((firebaseResults) => {
        firebaseResults.user.getIdToken().then(function (idToken) {
          fetcher(`${API.BASE_URL}/user/${userData._id}/password`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: tokenData,
            },
            body: JSON.stringify({
              idToken,
              password
            }),
          })
            .then((res) => {
              if (!res.ok) {
                if (res.status !== 200) {
                  console.log('transactions error');
                }
                if (res.status === 401) {
                  Swal.fire({
                    icon: 'error',
                    title: "Sesion Expirada",
                    padding: "2px",
                    showConfirmButton: false,
                    width: "300px",
                    heightAuto: "200px",
                    timer: 1500,
                    customClass: {
                      title: "m-0 text-danger",
                      header: "p-0"
                    }
                  }).then(function () {
                    window.location.href = "/";
                  });
                }

              } else {
                return res.json();
              }
            })
            .then(({ data }) => {
              Swal.fire({
                icon: 'success',
                title: "Contraseña guardada",
                padding: "2px",
                showConfirmButton: false,
                width: "300px",
                heightAuto: "200px",
                timer: 1500,
                customClass: {
                  title: "m-0 text-success",
                  header: "p-0"
                }
              }).then(function () {
                window.location.href = "/signout";
              });
            })
            .catch((error) => {
            })
        });
      }) 
      .catch((err) => {
        const errors = ['auth/invalid-email', 'auth/wrong-password'];
        if (errors.indexOf(err.code) >= 0) {
          Swal.fire({
            icon: 'error',
            title: "Contraseña incorrecta",
            padding: "2px",
            showConfirmButton: false,
            width: "300px",
            heightAuto: "200px",
            timer: 1500,
            customClass: {
              title: "m-0 text-danger",
              header: "p-0"
            }
          })
        }else{
          Swal.fire({
            icon: 'error',
            title: "Contraseña Anterior incorrecta",
            padding: "2px",
            showConfirmButton: false,
            width: "300px",
            heightAuto: "200px",
            timer: 1500,
            customClass: {
              title: "m-0 text-danger",
              header: "p-0"
            }
          }) 
        }
      });
  },
    [],
  );
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title className="text-center colorFont font-weight-bold modal-title h4">Cambio de Contraseña</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container mt-6">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row d-flex justify-content-center mt-2">
              <div className="col-12 mr-4 ml-4 mb-3 col-sm-12">
                <div className="form-group">
                  <label className="colorFont">Contraseña Anterior</label>
                  <input
                    type="password"
                    name="oldPassword"
                    className="form-control"
                    autoComplete="new-password"
                    ref={register({
                      required: true,
                    })}
                  />
                  {(errors.oldPassword && errors.oldPassword.type === 'required') && (
                    <>
                      <p className="input-error">* El campo es requerido</p>
                    </>
                  )}
                </div>
                <div className="form-group">
                  <label className="colorFont">Contraseña Nueva</label>
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    autoComplete="new-password"
                    ref={register({
                      required: true,
                      pattern: {
                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.$@$!%*?&])[A-Za-z\d.$@$!%*?&]{8,15}$/,
                      },
                      maxLength: 15,
                      minLength: 8
                    })}
                  />
                  {(errors.password && errors.password.type === 'required') && (
                    <>
                      <p className="input-error">* El campo es requerido</p>
                    </>
                  )}
                  {(errors.password && errors.password.type === 'pattern') && (
                    <>
                      <p className="input-error">
                        * Al menos una letra mayúscula
                      </p>
                      <p className="input-error">
                        * Al menos una letra minucula
                      </p>
                      <p className="input-error">
                        * Al menos 1 caracter especial
                      </p>
                    </>
                  )}
                  {(errors.password && errors.password.type === 'minLength') && (
                    <>
                      <p className="input-error">
                        * Contraseña debe tener como minimo 8 caracteres
                      </p>
                    </>
                  )}
                  {(errors.password && errors.password.type === 'maxLength') && (
                    <>
                      <p className="input-error">
                        * Contraseña debe tener como maximo 15 caracteres
                      </p>

                    </>
                  )}
                </div>
                <div className="form-group">
                  <label className="colorFont">Repetir Nueva Contraseña</label>
                  <input
                    type="password"
                    name="password_confirmation"
                    className="form-control"
                    ref={register({
                      required: true,
                      validate: value =>
                        value === password.current || "* Las contraseñas no coinciden"
                    })}
                  />
                  {(errors.password_confirmation && errors.password_confirmation.type === 'required') && (
                    <>
                      <p className="input-error">* El campo es requerido</p>
                    </>
                  )}
                  {errors.password_confirmation &&
                    <p className="input-error">
                      {errors.password_confirmation.message}
                    </p>}
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <button
                type="submit"
                className="btn button btnColor text-white"
              >
                Confirmar
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </>
  )
}