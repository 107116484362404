import axios from '../../Axios/Axios';
import * as API from '../../../constants/api-staging';

export const getEmpresa = async (idProducto) => {
  const token = localStorage.getItem('token').replace(/"/g, '');
  try {
    const response = await axios.get(
      `${API.BASE_URL}/captarpagos/getEmpresa/${idProducto}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
