import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import logo from '../../assets/images/palta-animation.gif';
import * as ROUTES from '@constants/routes';
import './logo.css';
import { useHistory } from 'react-router-dom';
const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

`;

const BoxContainer = styled.div`
  width: 340px;
  min-height: 624px;
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  background-color: white;
  box-shadow: 0px 0px 4.7px rgba(15, 15, 15, 0.28);
  position: relative;
  overflow: hidden;
  padding: 0px 10px 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;


const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Palta = () => {
  const [show, setShow] = useState(undefined);
  const history = useHistory();
  useEffect(() => {
    setShow(logo);
  }, [show])
  return (
    <>
      <AppContainer>
        <BoxContainer>
          <InnerContainer>
            <div className='container'>
            <h2 className=" text-center text-colors">Bienvenido a Palta</h2>
            <p className="text-center text-colors">Para formar parte de palta completa el siguiente formulario</p>
            </div>
           
           <div className='container-img'>
            {show && (
              <img
                id="img-logo"
                src={show}
                width="120"
                height="50"
                className="d-inline-block align-top img-logo"
                alt="Palta logo"
              />

            )}

           </div>
            <a
              type="submit"
              size="md"
              className=" bg-transparent border-0 position-boton-right"
              onClick={() => history.push(ROUTES.REGISTER)}
              style={{ fontSize: 18 }}

            >

              <i style={{ fontSize: 50, width: "20%" }} className="material-icons button-color-next">arrow_circle_right_icon</i>
            </a>
          </InnerContainer>
        </BoxContainer>
      </AppContainer>

    </>
  );
};

