import React, { useState, useEffect } from 'react';
import { Container, Row, Spinner } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import anuncioExito from '../../../assets/images/anuncio-cash-exito.svg';
import { isMobile } from 'react-device-detect';
import { sendSuccessData, selectedShop, transactionCode, date } from '../../../store';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import PaltaModalSpinner from '../../../components/PaltaModalSpinner';
import { sanitizeAmount } from '../../service-payments/utils';
import { motion } from 'framer-motion';
import { vibrate } from '../../../helpers/app';

export const Success = () => {
  const year = new Date().getFullYear();
  const [loading, setLoading] = useState(true);
  const successData = useRecoilValue(sendSuccessData);
  const shop = useRecoilValue(selectedShop);
  const transactionCodetData = useRecoilValue(transactionCode);
  const datePay = useRecoilValue(date);
  useEffect(() => {
    if (successData && transactionCodetData && datePay) {
      vibrate([20, 40, 10, 10])
      setLoading(false);
    }
  }, [successData, transactionCodetData, datePay]);
  function escapeRegExp(string) {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(`<!doctype html><body>${string}`, 'text/html').body.textContent;
    return decodedString
  }
  return (
    <div>
      <PaltaNavBar />
      <section>
        <motion.div
          initial={{ y: '80%', opacity: 0, scale: 0.2 }}
          animate={{ y: 0, opacity: 1, scale: 1 }}
          transition={{ type: 'spring', duration: .4, bounce: 0.2 }}
        >
          <Container className="contenedor">
            {isMobile ? (
              <h3 className="font-white-bold mt-3 mb-0" style={{ color: "#79B279" }}>
                {`${successData?.currency.includes('dolar') ? '' : '$'} ${successData && sanitizeAmount(successData?.transaction?.amount)} `}
                {successData?.currency === "peso" ? (
                  "Pesos"
                ) : successData?.currency === "fiscal_credit" ? (
                  "Crédito Fiscal"
                ) : successData?.currency === "peso_activa" ? (
                  "Crédito Activa"
                ) : successData?.currency === "peso_tourism" ? (
                  "Crédito Turismo"
                ) : successData?.currency === "dolar" ? (
                  "USDP"
                ) : successData?.currency === "dolar_tourism" ? (
                  "USDP Turismo"
                ) : successData?.currency === "dolar_activa" ? (
                  "USDP Activa"
                ) : null
                }
              </h3>
            ) : (
              <h2 className=" font-white-bold mt-3 mb-0" style={{ color: "#79B279" }}>
                {`$ ${sanitizeAmount(successData?.transaction?.amount)} `}
                {successData?.currency === "peso" ? (
                  "Pesos"
                ) : successData?.currency === "fiscal_credit" ? (
                  "Crédito Fiscal"
                ) : successData?.currency === "peso_activa" ? (
                  "Crédito Activa"
                ) : successData?.currency === "peso_tourism" ? (
                  "Crédito Turismo"
                ) : successData?.currency === "dolar" ? (
                  "USDP"
                ) : successData?.currency === "dolar_tourism" ? (
                  "USDP Turismo"
                ) : successData?.currency === "dolar_activa" ? (
                  "USDP Activa"
                ) : null
                }
              </h2>
            )}
            <Row className="d-flex justify-content-center align-items-center h1">
              <img src={anuncioExito} alt="imagen" height={300} />
            </Row>

          </Container>
          <Container>
            {isMobile ? (
              <div className="row alert alert-success">
                <div className="col-12 col-sm-12 p-0">
                  <h5 className="m-0 text-center" style={{ fontSize: 20, color: "#155724" }}>
                    Solicitud de pago realizada
                  </h5>
                  <hr style={{ border: 0, borderTop: "3px solid rgba(0,0,0,.1)", borderColor: "#155724" }} />
                </div>
                <hr style={{ border: 0, borderTop: "3px solid rgba(0,0,0,.1)", borderColor: "#155724" }} />
                <div className="col-12 col-sm-12 p-0">
                  <p className="m-0" style={{ fontSize: 18, textAlign: 'center' }}>
                    Pagado a: {shop && shop.role === "user" ? (

                      <span style={{ textTransform: 'capitalize' }}>{`${shop && shop.name} ${shop && shop.lastname}`}</span>
                    ) :
                      <span style={{ textTransform: 'capitalize' }}>{`${shop && escapeRegExp(`${shop.fantasyName}`)}`}</span>
                    }
                  </p>
                  <p className="m-0" style={{ fontSize: 18, textAlign: 'center' }}>{`N° operación: ${transactionCodetData}`}</p>
                  {datePay !== undefined && (

                    <p className="m-0" style={{ fontSize: 18, textAlign: 'center' }}>
                      {format(
                        new Date(datePay),
                        'yyyy',
                      ) === year.toString()
                        ? `Creada el ${format(
                          new Date(datePay),
                          'dd-MMMM , HH:mm:ss',
                          {
                            awareOfUnicodeTokens: true,
                            locale: es,
                          },
                        )}`
                        : `Creada el ${format(
                          new Date(datePay),
                          'dd-MMMM , HH:mm:ss',
                          {
                            awareOfUnicodeTokens: true,
                            locale: es,
                          },
                        )}`
                      }
                    </p>
                  )}
                  <p className="m-0" style={{ fontSize: 15, textAlign: 'center' }}>
                    Tené en cuenta que esta operación podría demorar hasta 24hs hábiles.
                  </p>
                </div>
              </div>

            ) : (
              <div className="row d-flex justify-content-center align-items-center mt-3 alert alert-success">
                <div className="col-12 col-sm-12 p-0 mt-2">
                  <h5 className="m-0 text-center" style={{ fontSize: 20, color: "#155724" }}>
                    Solicitud de pago realizada
                  </h5>
                  <hr style={{ border: 0, borderTop: "3px solid rgba(0,0,0,.1)", borderColor: "#155724" }} />
                </div>
                <div className="col-4 col-sm-4 p-0">
                  <p className=" m-0 text-center" style={{ fontSize: 18 }}>
                    Pagado a: {shop && shop.role === "user" ? (

                      <span style={{ textTransform: 'capitalize' }}>{`${shop && shop.name} ${shop && shop.lastname}`}</span>
                    ) :
                      <span style={{ textTransform: 'capitalize' }}>{`${shop && escapeRegExp(`${shop.fantasyName}`)}`}</span>
                    }
                  </p>
                </div>
                <div className="col-8 col-sm-6 p-0 ">
                  <p className="m-0 text-center" style={{ fontSize: 18 }}>{`N° operación: ${transactionCodetData}`}</p>
                  {datePay !== undefined && (

                    <p className="m-0 text-center" style={{ fontSize: 18 }}>
                      {format(
                        new Date(datePay),
                        'yyyy',
                      ) === year.toString()
                        ? `Creada el ${format(
                          new Date(datePay),
                          'dd-MMMM , HH:mm:ss',
                          {
                            awareOfUnicodeTokens: true,
                            locale: es,
                          },
                        )}`
                        : `Creada el ${format(
                          new Date(datePay),
                          'dd-MMMM , HH:mm:ss',
                          {
                            awareOfUnicodeTokens: true,
                            locale: es,
                          },
                        )}`
                      }
                    </p>
                  )}
                </div>
                <div className="col-12 col-sm-12 p-0 mt-2">
                  <p className="m-0 text-center" style={{ fontSize: 18 }}>
                    Tené en cuenta que esta operación podría demorar hasta 24hs hábiles.
                  </p>
                </div>
              </div>

            )}



          </Container>
          {loading && (
            <PaltaModalSpinner show={loading} size="sm" />
          )}
        </motion.div>
      </section>

      <PaltaFooter />
    </div>
  );
};