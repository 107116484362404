import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { CashinButton } from '../cashin-button/CashinButton';
const PaltaRowListItem = ({ name, link, userData, isMobile }) => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleOpenModal = () => {
    setShow(true);
  }
  return (
    <>
      {isMobile ? (
        <>
          {name === "Enviar Dinero" ? (
            <Link to={link ? link : '#'}>
              <img src="assets/images/button-enviar.svg"
                height={70}
                width={260}
                alt="botonEnviar"
                style={{ fontFamily: "ProductSans" }} />
            </Link>
          ) : (
            <img src="assets/images/button-ingresar1.svg"
              alt="botoningresar"
              style={{ fontFamily: "ProductSans",cursor:"pointer" }}
              height={70}
              width={260}
              onClick={() => {
                handleOpenModal();
              }} />
          )}
        </>
      ) : (
        <>
          {name === "Enviar Dinero" ? (
            <Link to={link ? link : '#'}>
              <img src="assets/images/button-enviar.svg"
                height={100}
                width={250}
                alt="botonEnviar"
                style={{ fontFamily: "ProductSans" }} />
            </Link>

          ) : (
            <img
              src="assets/images/button-ingresar.svg"
              alt="botoningresar"
              style={{ marginLeft: 15, fontFamily: "ProductSans",cursor:"pointer" }}
              height={100}
              width={250}
              onClick={() => {
                handleOpenModal();
              }} />
          )}
        </>
      )}

      <Modal show={show} onHide={handleClose} centered>
        <CashinButton
          userData={userData}
        />
      </Modal>
    </>
  );

}
export default PaltaRowListItem;