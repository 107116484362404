const device = {
  mobileSmall: 340,
  mobile: 640,
  tablet: 1024,
  desktop: 1280,
  large: 1281,
};
export const breakPoint = {
  mobileSmall: `(max-width: ${device.mobileSmall}px)`,
  mobile: `(max-width: ${device.mobile}px)`,
  tablet: `(max-width: ${device.tablet}px)`,
  desktop: `(max-width: ${device.desktop}px)`,
  large: `(min-width: ${device.large}px)`,
};
