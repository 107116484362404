import React from 'react';
import { sanitizeAmount } from '../../service-payments/utils';

export const BoxRight = ({ userData, transaction, P }) => {
  return (
    <div className="transaction-item-right-mobile d-flex justify-content-center align-items-center">
      <P>
        <span style={{ color: '#3A4D9F' }}>
          {transaction.type === 'transaction' ||
            transaction.type === 'promo' ||
            transaction.type === 'REVERTIR TRANSACCION' ? (
            `${userData._id !==
              transaction._userFromId._id
              ? '+'
              : '-'
            }
            $${sanitizeAmount(transaction.amount)}`
          ) : transaction.type === 'cashin' ? (
            <span className="text-success">
              {' '}
              + ${sanitizeAmount(transaction.amount)}
            </span>
          ) : transaction.type === 'PAGO SERVICIO' ? (
            <span style={{ color: '#f728ce'}}>
              {`${userData._id !==
              transaction._userFromId._id
              ? '+'
              : '-'
            }
            $${sanitizeAmount(transaction.amount)}`}
            </span>
          ) :
            transaction.type === 'cashout' ||
            transaction.type === 'xnet' ? (
            <span className="text-danger">
              {' '}
              - ${sanitizeAmount(transaction.amount)}
            </span>
          ) : transaction.type === 'tax' && userData._id ===
            transaction._userToId._id ? (
            <span>
              {' '}
              + ${sanitizeAmount(transaction.amount)}
            </span>
          ) : transaction.type === 'tax' && userData._id !==
            transaction._userToId._id ? (
            <span>
              {' '}
              - ${sanitizeAmount(transaction.amount)}
            </span>
          ) : transaction._userToId?.role ===
            'transaction' ? (
            <span>
              {' '}
              - ${sanitizeAmount(transaction.amount)}
            </span>
          ) : transaction._userToId?.role ===
            'user' ? (
            <span>
              {' '}
              - ${sanitizeAmount(transaction.amount)}
            </span>
          ) : null}
        </span>
      </P>
    </div>
  )


}

