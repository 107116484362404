import { useState, useCallback } from 'react';
import Axios from '../../Axios/Axios';
import * as API from '../../../constants/api-staging';
import Swal from 'sweetalert2';
export const useUserValidateId = () => {

  const [data, setData] = useState(undefined);
  const [error, setError] = useState(false);

  const getUserValidateId = useCallback((id) => {
    return new Promise((resolve) => {
      Axios.get(`${API.BASE_URL}/register/checkRegister/${id}`, {
        headers: {
          "Content-type": "application/json",
        }
      }).then(response => {
          setData(response.data.message);
          localStorage.setItem("dataValid", response.data.message);
          localStorage.setItem("errordata", "");
          resolve({data: response.data.message,error})
        })
        .catch(error => {
          console.log(error.response.data.message)
          localStorage.setItem("dataValid", "");
          localStorage.setItem("errordata", error.response.data.message);
          setError(error.response.data.message);
          Swal.fire({
            icon: 'error',
            title: `${error.response.data.message}`,
            padding: "2px",
            showConfirmButton: false,
            width: "300px",
            heightAuto: "200px",
            timer: 1500,
            customClass: {
              title: "m-0 text-danger",
              header: "p-0"
            }
          })
          resolve({data, error: error.response.data.message})
        });
    })
   

  }, []);

  return [
    {
      data,
      error,
    },
    getUserValidateId,
  ];
};
