import React from "react";
import { useEffect, useState } from "react";
import getAllUnreadNotification from "../../services/notification/getAllUnread";
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import Toast from "react-bootstrap/Toast";
import NotificationDetail from "../NotificationDetail";
import updatePartialNotification from "../../services/notification/updatePartialNotification";
import parseDateToArgTime from "../../helpers/date";
import styled from "styled-components";




const MainCss = styled.main`

    .toast {
        font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        width: 350px;
        max-width: 100%;
        font-size: .875rem;
        pointer-events: auto;
        background-color: rgba(255, 255, 255, .85);
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, .1);
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
        border-radius: .25rem
    }

  
    .toast.showing {
        opacity: 0
    }



    .toast-container {
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        max-width: 100%;
        pointer-events: none
    }

    .toast-container>:not(:last-child) {
        margin-bottom: .75rem
    }

    .toast-header {
        display: flex;
        align-items: center;
        padding: .5rem .75rem;
        color: #6c757d;
        background-color: rgba(255, 255, 255, .85);
        background-clip: padding-box;
        border-bottom: 1px solid rgba(0, 0, 0, .05) ;
        border-top-left-radius: calc(.25rem - 1px) ;
        border-top-right-radius: calc(.25rem - 1px) ;
    }

    .btn-close {
        box-sizing: content-box;
        width: 1em;
        height: 1em;
        padding: .25em .25em;
        color: #000;
        background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
        border: 0;
        border-radius: .25rem;
        opacity: .5;
    }

    .toast-header .btn-close {
        margin-right: -.375rem;
        margin-left: .75rem;
    }

    .toast-body {
        padding: .75rem;
        word-wrap: break-word;
    }
    
    b, strong {
        font-weight: bolder;
    }

    .me-auto {
        margin-right: auto !important;
    }



    .small, small {
        font-size: .875em;
        font-size: 80%;
        font-weight: 400;
    }
`;







export default function NotificationPanel() {


    const loggedUser = JSON.parse(localStorage.getItem("user"));


    const { _id: userId } = loggedUser;


    const [notifications, setNotifications] = useState([]);

    const [selectedNotification, setSelectedNotification] = useState(undefined);

    useEffect(() => {
        getAllUnreadNotification({ userId })
            .then(response => response.data.data)
            .then(notifications => {
                setNotifications(notifications);
            })
            .catch(error => console.error(error));

    }, []);

    const createClickHandler = (notification) => {
        return e => setSelectedNotification(notification)
    };

    const createCloseHandler = (notification, index) => (e => {
        const updatedNotification = Object.assign(notification, { read: true });
        const updatedNotificationArray = [...notifications];

        updatedNotificationArray[index] = updatedNotification;

        setNotifications(updatedNotificationArray);

        updatePartialNotification(notification._id, { read: true })
            .catch(error => console.error(error));

    });

    return (
        <>


            <PaltaNavBar className="light" />

            <NotificationDetail notification={selectedNotification} handleNotificationClose={e => setSelectedNotification(undefined)} />

            <MainCss className="d-flex flex-column align-items-center w-100 p-3 pb-5 mb-5 h-100 overflow-auto">


                {(notifications.filter(notification => notification.read === false).length === 0) ? <p className="w-100 text-center p-3">No hay notificaciones!</p> : undefined}

                {
                    notifications.filter(notification => notification.read === false)
                        .map((notification, index) => (



                            
                                <Toast className="flex-shrink-0" key={`${notification._id}-${index}`} show={true} onClose={createCloseHandler(notification, index)} >

                                    <Toast.Header>

                                        <strong className="me-auto">{notification.notification.title}</strong>
                                        <small>{parseDateToArgTime(notification.createdAt)}</small>

                                    </Toast.Header>
                                    <Toast.Body className="text-nowrap overflow-hidden text-truncate" onClick={createClickHandler(notification)}> {notification.notification.body}</Toast.Body>

                                </Toast>
                          


                        ))
                }

                <span className="my-5"></span>
            </MainCss>

            <PaltaFooter />
        </>
    );
}