import React, { useState, useEffect } from 'react';
import { Container, Form, Row, Col, Spinner } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import { token } from '../../../store';
import PaltaList from '../../../components/PaltaList/PaltaList';
import PaltaBreadcrumbs from '../../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import * as ROUTES from '../../../constants/routes';
import { useGetShops } from '../../../core/api-hooks/use-shops/get';
import { ShopItem } from './shop-item';
import Swal from 'sweetalert2';
import PaltaModalSpinner from '@components/PaltaModalSpinner';
export const Select = () => {
  const tokenData = useRecoilValue(token);
  const [loading, setLoading] = useState(false);
  const [{ data, error }, getShop] = useGetShops();
  const [search, setSearch] = useState(undefined);
  const [searchList, setSearchList] = useState(undefined);

  useEffect(() => {
    if (data) {
      const filterHidden = data.users.filter(user => user.hiddenFromSearch !== true);
      setSearchList(filterHidden);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Problemas para cargar los datos',
        padding: '2px',
        showConfirmButton: false,
        width: '300px',
        heightAuto: '200px',
        timer: 1500,
        customClass: {
          title: 'm-0 text-danger',
          header: 'p-0',
        },
      })
      setLoading(false);
    }
  }, [error]);

  useEffect(() => {
    if (search === '') {
      setSearch(undefined);
    }
    if (search && search.length >= 4) {
      setTimeout(() => {
        getShop(tokenData, search);
      }, 100);
     
    } else {
      setSearchList(undefined);
    }
  }, [search, tokenData]);
  function escapeRegExp(string) {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(`<!doctype html><body>${string}`, 'text/html').body.textContent;
    return decodedString
  }
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <PaltaNavBar/>
      <PaltaBreadcrumbs name="Seleccionar destinatario" link={ROUTES.PAYMENTS} variant="dark"/>
      <section style={{ padding: '3rem 0px' }}>
        <Container>
          <Row>
            <Col sm={12}>
              <h5>Buscar</h5>
            </Col>
            <Col sm={12}>
              <Form.Group className="form-group-icon" controlId="name">
                <i className="material-icons icon colorFont">search</i>
                <Form.Control
                  type="text"
                  name="name"
                  autoComplete="off"
                  placeholder="Nombre del destinatario"
                  onChange={(e) => setSearch(escapeRegExp(`${e.target.value}`))}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>Destinatario</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <PaltaList
                style={{
                  padding: '1rem 0rem 4rem',
                }}
              >
                {searchList?.length !== 0
                  ? searchList?.map((user, index) => {
                    return <ShopItem key={index} shop={user} escapeRegExp={escapeRegExp} />;
                  })
                  :
                  <div className="row mx-0 justify-content-center mt-3">
                    <div className="col-12 col-sm-12  p-0">
                      <p className="alert alert-danger m-0">
                        No encontramos un destinatario con ese nombre!
                      </p>
                    </div>
                  </div>
                }
              </PaltaList>
            </Col>
          </Row>
        </Container>
      </section>
      {loading && (
        <PaltaModalSpinner show={loading} size="sm"/>
      )}
      <PaltaFooter/>
    </div>
  );
};
