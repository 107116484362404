import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import ModalFailure from '../../../components/ModalFailure/ModalFailure';
import { SeeFailure } from '../../../components/SeeFailure/SeeFailure';
import {
  user,
  selectedShop,
  sendSuccessData,
  selectedAmount,
  selectedFees,
  selectedWalletPay,
  token,

} from '../../../store';
import { useGetUser } from '../../../core/api-hooks/use-user/get';
import userHelper from '../../../helpers/user';
import PaltaModalSpinner from '@components/PaltaModalSpinner';
import { usePostTransaction } from '../../../core/api-hooks/use-transaction/post';
import PaltaBreadcrumbs from '../../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import { Spacer } from '../../../components/spacer';
import * as ROUTES from '../../../constants/routes';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import Swal from 'sweetalert2';
import { getCurrencyPair, sanitizeAmount } from '../../service-payments/utils';
import { motion } from 'framer-motion';

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 16px;
  opacity: ${({ theme }) => theme.emphasis.low};
`;

export const Confirm = () => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useRecoilState(user);
  const shop = useRecoilValue(selectedShop);
  const setSuccessData = useSetRecoilState(sendSuccessData);
  const [{ data }, posTransaction] = usePostTransaction();
  const selectedAmountValue = useRecoilValue(selectedAmount);
  const selectedFeesValue = useRecoilValue(selectedFees);
  const walletPay = useRecoilValue(selectedWalletPay);
  const [wallet, setWallet] = useState();
  const history = useHistory();
  const [{ data: dataGetUser, error: errorGetUser }, getUser] = useGetUser();
  const [viewErrorModal, setViewErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const tokenData = useRecoilValue(token);
  

  const destinationHasDollarWallet = shop?.wallets?.some(wal => wal.currency.includes('dolar'))


  function escapeRegExp(string) {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(`<!doctype html><body>${string}`, 'text/html').body.textContent;
    return decodedString
  }

  const location = useLocation();
  const fiscalCreditFeeAmount = location.state.fiscalCreditFeeAmount;
  const rate = location.state.bid;

  useEffect(() => {
    if (userData && walletPay) {
      const selectedWallet = userData.wallets.find(
        (wallet) => wallet.currency === walletPay,
      );
      setWallet(selectedWallet);
    }
  }, [userData, walletPay]);

  useEffect(() => {
    if (shop) {
      setLoading(false);
    }
  }, [shop]);

  const onsubmit = useCallback(() => {
    setLoading(true);
    const shopWalletId = shop.wallets.find((wal) => wal.currency === walletPay);
    const pairCurrency = getCurrencyPair(walletPay);
    const pairWallet = shop.wallets.find((wal) => wal.currency === pairCurrency);

    let sendData = {
      _walletFromId: wallet._id,
      _walletToId: shopWalletId?._id ? shopWalletId._id : pairWallet._id,
      amount: Number(selectedAmountValue),
      automaticDollarConversion: !shopWalletId && true,
      rate
    };

    posTransaction(sendData, tokenData, setLoading, setViewErrorModal, setErrorMessage);
    sendData = {
      _walletFromId: "",
      _walletToId: "",
      amount: 0,

    }
  }, [shop, wallet, selectedAmountValue, selectedFeesValue, tokenData]);

  useEffect(() => {
    if (dataGetUser) {
      userHelper.setUser(
        JSON.stringify({
          ...dataGetUser.user,
          wallets: dataGetUser.wallets,
        }),
      );
      setUserData({
        ...dataGetUser.user,
        wallets: dataGetUser.wallets,
      });
      setLoading(false);
      setSuccessData(data);
      history.push(ROUTES.PAYMENTS_SELECT_SUCCESS);
    } else {
      setLoading(false);
      history.goForward(`${ROUTES.PAYMENTS_SELECT_SUCCESS}`);

    }
  }, [dataGetUser, history]);

  useEffect(() => {
    if (data) {
      getUser(tokenData);
    }
  }, [data, getUser, tokenData]);

  useEffect(() => {
    if (errorGetUser) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'La actualización de los datos no pudo efectuarse',
        padding: '2px',
        showConfirmButton: false,
        width: '300px',
        heightAuto: '200px',
        timer: 1500,
        customClass: {
          title: 'm-0 text-danger',
          header: 'p-0',
        },
      })
    }
  }, [errorGetUser]);

  const isDollarWallet = wallet?.currency?.includes('dolar');

  return (
    // <>asd</>
    <div style={{ backgroundColor: '#fff' }}>
      <PaltaNavBar />
      <PaltaBreadcrumbs
        name="Confirmar envío de dinero"
        link={ROUTES.PAYMENTS_SELECT_AMOUNT}
        variant="dark"
      />
      <motion.div
        initial={{ x: '100%', opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ type: 'spring', duration: .4, bounce: 0.2 }}
      >
        <section style={{ padding: '3rem 0px' }}>
          <Container>
            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <RowWrapper>
                  <h5>Destinatario</h5>
                  <p style={{ textTransform: 'capitalize' }}>
                    {shop?.role === "user" ? (
                      `${shop && shop.fantasyName ? shop.fantasyName + " -" : "" } ${shop && shop.name} ${shop && shop.lastname} `
                    ) :
                    `${shop && shop.fantasyName ? shop.fantasyName + " -" : "" } ${shop && shop.businessName || '' }`
                    }
                  </p>
                </RowWrapper>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <Separator />
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <RowWrapper>
                  <h5>Monedero</h5>
                  <p
                    style={{ color: wallet && wallet?.currency?.includes('dolar') ? '#85bb65' : '#3a4d9f' }}
                  >{
                      destinationHasDollarWallet ? //Pregunto si el destinatario tiene monederos Dolar
                        `${shop?.wallets.find((wall) => wall.currency === walletPay).currencyLabel}` //Si tiene devuelvo el currencyLabel del mismo
                        :  walletPay.includes('dolar') ? //Pregunto si el envio de dinero es desde un monedero Dolar
                        `De USDP => ${getCurrencyPair(walletPay)}` // Si es asi devuelvo la transformacion ya que no tendria el destinatario un monedero de dolar habilitado
                        :  `${shop?.wallets.find((wall) => wall.currency === walletPay).currencyLabel}` //Si el pago no es en dolar devuelvo directamente el currencyLabel ya que no va a haber transformacion
                    }</p>
                </RowWrapper>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <Separator />
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <RowWrapper>
                  <h5>Monto a enviar</h5>
                  <p>{isDollarWallet ? 'USDP' : '$'} {sanitizeAmount(selectedAmountValue)}</p>
                </RowWrapper>
              </Col>
            </Row>

            {wallet?.currency !== "fiscal_credit" ? (

              <Row>
                <Col md={{ span: 6, offset: 3 }}>
                  <RowWrapper>
                    <h5>Impuesto</h5>
                    <p>{isDollarWallet ? 'USDP' : '$'} {selectedFeesValue && sanitizeAmount(selectedFeesValue)}</p>
                  </RowWrapper>
                </Col>
              </Row>
            ) :
              <Row>
                <Col md={{ span: 6, offset: 3 }}>
                  <RowWrapper>
                    <h5>Comisión</h5>
                    <p> {isDollarWallet ? 'USDP' : '$'} {fiscalCreditFeeAmount && sanitizeAmount(Number(fiscalCreditFeeAmount))}</p>
                  </RowWrapper>
                </Col>
              </Row>
            }


            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <RowWrapper>
                  <h5>Total</h5>
                  {wallet?.currency !== "fiscal_credit" ? (
                    <h5>{isDollarWallet ? 'USDP' : '$'} {selectedAmountValue && sanitizeAmount((selectedAmountValue + selectedFeesValue))}</h5>
                  ) : <h5>$ {selectedAmountValue && sanitizeAmount(Number(selectedAmountValue) + Number(fiscalCreditFeeAmount))}</h5>
                  }
                </RowWrapper>
              </Col>
            </Row>
            <Spacer />
            <Row>
              <Col>
                <div className="text-center" style={{ marginTop: '1rem' }}>
                  <Button
                    style={{ marginLeft: '1em', background: "#3A4D9F" }}
                    size="lg"
                    onClick={onsubmit}
                  >
                    Confirmar
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {loading && (
          <PaltaModalSpinner show={loading} size="sm" />
        )}
      </motion.div>
      {viewErrorModal && (
          <ModalFailure
            show={true}
            onHide={() => setViewErrorModal(false)}>
            <div>
              <SeeFailure errorMessage={errorMessage} 
              />
            </div>
          </ModalFailure>
        )}
      <PaltaFooter />
    </div>
  );
};
