import React, { useState, useEffect } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { NavLink } from 'react-router-dom';
import { user } from '../../store';
import logo from '../../assets/images/palta-logo-500.svg';
import enviarDinero from '../../assets/images/logo-enviar-nav.svg';
import * as ROUTES from '../../constants/routes';
import PaltaModalCvu from '../PaltaModalCvu';
import './nav-bar.css';
import { Cvu } from '../Cvu/Cvu';
import { vibrate } from '../../helpers/app';

const PaltaNavBar = ({ bg, variant, className }) => {

  const [scrollY, setScrollY] = useState({
    scrollY: window.scrollY
  })
  const [cvu, setCvu] = useState({});
  const userData = useRecoilValue(user);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleScroll = (e) => {
    setScrollY({
      scrollY: window.scrollY,
    });
  };

  return (
    <>
      {userData !== undefined ? (
        <>
          <Navbar
            bg={bg}
            variant={variant}
            expand="lg"
            className={`navbar-light bg-light d-none d-md-block ${className}`}
            sticky="top"
          >
            <Container
              className={scrollY.scrollY > 10 ? 'navbar-toggler-shadow' : ''}
              style={{ borderRadius: '16px' }}
            >
              <Navbar.Brand style={{ paddingLeft: '1em' }}>
                <img
                  src={logo}
                  width="120"
                  height="50"
                  className="d-inline-block align-top"
                  alt="Palta logo"
                />
              </Navbar.Brand>
              <Navbar.Toggle
                className="my-2 my-lg-0 "
                aria-controls="navbar-nav"
                target="#navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="material-icons ">menu</i>
              </Navbar.Toggle>
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="navbar-collapse navbar-palta-collapse"
              >
                <Nav className="mr-auto">
                  <Nav.Item>
                    <NavLink
                      onClick={() => vibrate(15)}
                      to={ROUTES.DASHBOARD}
                      activeclassname="active"
                      className="nav-link"
                      exact
                    >
                      <span className="nav-icon">
                        <i className="material-icons">home</i>
                      </span>
                      Home
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink
                      onClick={() => vibrate(15)}
                      to={ROUTES.RESUME}
                      activeclassname="active"
                      className="nav-link"
                      exact
                    >
                      <span className="nav-icon">
                        <i className="material-icons">leaderboard</i>
                      </span>
                      Actividad
                    </NavLink>
                  </Nav.Item>
                  {userData && userData.role !== "branch" && userData.role !== "cash" && userData.role !== "viewer" ? (
                    <>
                      <Nav.Item>
                        <NavLink
                          onClick={() => vibrate(15)}
                          to={ROUTES.QR_READER}
                          activeclassname="active"
                          className="nav-link"

                        >
                          <span className="nav-icon">
                            <i className="material-icons">qr_code_scanner</i>
                          </span>
                          QR Escáner
                        </NavLink>
                      </Nav.Item>
                      <Nav.Item>
                        <NavLink
                          onClick={() => vibrate(15)}
                          to={ROUTES.PAYMENTS}
                          activeclassname="active"
                          className="nav-link "

                        >
                          <span className="nav-icon">
                            <img
                              src={enviarDinero}
                              width="20"
                              height="20"
                              className="d-inline-block align-top"
                              alt="Enviar dinero"
                            />
                          </span>
                          Envíar Dinero
                        </NavLink>
                      </Nav.Item>
                    </>
                  ) : null}
                  {userData && userData?.role !== "branch" && userData?.role !== "cash" && userData?.role !== "viewer" ? (
                    <Nav.Item>
                      <button
                        activeclassname="active"
                        className="nav-link border-0 bg-transparent"
                        onClick={() => {
                          vibrate(15)
                          setCvu(userData);
                        }}>

                        <span className="nav-icon ">
                          <i className="material-icons ">payment</i>
                        </span>
                        Mi Cvu

                      </button>
                    </Nav.Item>
                  ) : null}
                  <Nav.Item>
                    <NavLink
                      onClick={() => vibrate(15)}
                      to={`/me/notification`}
                      activeclassname="active"
                      className="nav-link"
                    >

                      <span className="nav-icon mr-2">

                            <i className="material-icons" style={{ position: "relative" }}>

                              notifications
                              {
                                (userData?.hasUnreadNotifications === true) ?


                                  (
                                    <span
                                      style={{ position: "absolute", left: "80%", top: 0, }}
                                      className="badge p-1 bg-danger">
                                      <span className="d-none">New alerts</span>
                                    </span>
                                  )
                                  : undefined
                              }

                            </i>

                      </span>
                      Notificaciones

                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink
                      onClick={() => vibrate(15)}
                      to={ROUTES.ACCOUNT}
                      activeclassname="active"
                      className="nav-link"
                    >
                      <span className="nav-icon">
                        <i className="material-icons">account_circle</i>
                      </span>
                      Mi Cuenta
                    </NavLink>
                  </Nav.Item>

                </Nav>
              </Navbar.Collapse>
            </Container>

          </Navbar>
          <Navbar className="d-md-none">
            <div
              id="bottom-navbar-nav"
              className="bottom-navbar-palta-collapse shadow-reversed fixed-bottom"
              style={{ paddingBottom: 65 }}
            >
              <Nav className="mr-auto">
                <Nav.Item>
                  <NavLink
                    onClick={() => vibrate(15)}
                    to={ROUTES.DASHBOARD}
                    activeclassname="active"
                    className="nav-link"
                    exact
                  >
                    <div className="nav-link-container">
                      <span className="nav-icon">
                        <i className="material-icons ">home</i>
                      </span>
                      <div>Home</div>
                    </div>
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink
                    onClick={() => vibrate(15)}
                    to={ROUTES.RESUME}
                    activeclassname="active"
                    className="nav-link"

                  >
                    <div className="nav-link-container">
                      <span className="nav-icon">
                        <i className="material-icons">leaderboard</i>
                      </span>
                      <div>Actividad</div>
                    </div>
                  </NavLink>
                </Nav.Item>
                {userData && userData.role !== "branch" && userData.role !== "cash" && userData.role !== "viewer" ? (
                  <>
                    <Nav.Item>
                      <NavLink
                        onClick={() => vibrate(15)}
                        to={ROUTES.QR_READER}
                        activeclassname="active"
                        className="nav-link"
                      >
                        <div className="nav-link-container">
                          <span className="nav-icon">
                            <i className="material-icons">qr_code_scanner</i>
                          </span>
                          <div>QR Escáner</div>
                        </div>
                      </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                      <NavLink
                        onClick={() => vibrate(15)}
                        to={ROUTES.PAYMENTS}
                        activeclassname="active"
                        className="nav-link"
                      >
                        <div className="nav-link-container">
                          <span className="nav-icon">
                            <img
                              src={enviarDinero}
                              width="28"
                              height="28"
                              className="d-inline-block align-top"
                              alt="Enviar dinero"
                            />
                          </span>
                          <div>Enviar Dinero</div>
                        </div>
                      </NavLink>
                    </Nav.Item>
                  </>
                ) : null}
                {userData && userData?.role !== "branch" && userData?.role !== "cash" && userData?.role !== "viewer" ? (
                  <Nav.Item>
                    <button activeclassname="active"
                      className="nav-link border-0 bg-transparent" onClick={() => {
                        vibrate(15)
                        setCvu(userData);
                      }}>
                      <div className="nav-link-container">
                        <span className="nav-icon">
                          <i className="material-icons">payment</i>
                        </span>
                        <div>Mi Cvu</div>
                      </div>
                    </button>
                  </Nav.Item>
                ) : null}

                <Nav.Item>
                  <NavLink
                    onClick={() => vibrate(15)}
                    to={ROUTES.ACCOUNT}
                    activeclassname="active"
                    className="nav-link"
                  >
                    <div className="nav-link-container">
                      <span className="nav-icon">
                        <i className="material-icons">account_circle</i>
                      </span>
                      <div>Mi Cuenta</div>
                    </div>
                  </NavLink>
                </Nav.Item>
              </Nav>
              {cvu._id && (
                <PaltaModalCvu
                  show={true}
                  onHide={() => setCvu({})}

                  title="Mi CVU y Alias"

                >
                  <Cvu
                    cvu={cvu}
                    setCvu={setCvu}
                  />
                </PaltaModalCvu>
              )}
            </div>
          </Navbar>
        </>
      ) : null}
    </>
  );
}


export default PaltaNavBar;
