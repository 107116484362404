import { useState, useCallback } from 'react';
import {  useSetRecoilState } from 'recoil';
import * as API from '../../../constants/api-staging';
import Swal from 'sweetalert2';
import { fetcher } from '../../Fetch/Fetch';
import {
  transactionCode,
  date
} from '../../../store';
export const usePostTransaction = () => {

  const [data, setData] = useState(undefined);
  const [error, setError] = useState(false);
  const setTransactionCode = useSetRecoilState(transactionCode);
  const setDatePay = useSetRecoilState(date);
  const posTransaction = useCallback((body, token, setLoading, setViewErrorModal, setErrorMessage) => {
    fetcher(`${API.BASE_URL}/transaction`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
      .then(async (res) => {

        if (!res.ok) {
          if (res.status !== 200) {
            console.log('transactions error');
          }
          if (res.status === 401) {
            Swal.fire({
              icon: 'error',
              title: "Sesion Expirada",
              padding: "2px",
              showConfirmButton: false,
              width: "300px",
              heightAuto: "200px",
              timer: 1500,
              customClass: {
                title: "m-0 text-danger",
                header: "p-0"
              }
              
            }).then(function () {
              window.location.href = "/";
            });
          }
          if(res.status === 400){
            const response = await res.json();
            if(response.code.includes('MONTO MAXIMO PARA MENORES DE EDAD SUPERADO')){
              const errorMsg = response.code.split('(');
              setErrorMessage(errorMsg[1].slice(0,errorMsg[1].length-1));
            }else{
              return res.json();
            }
          }
        } else {

          return res.json();
        }
      })
      .then(({ data }) => {
        setData(data);
        setTransactionCode(data.transactionCode);
        setDatePay(data.createdAt)
      })
      .catch((error) => {
        setLoading(false)
        setViewErrorModal(true);
        setError(true);
      });
  }, []);

  return [
    {
      data,
      error,
    },
    posTransaction,
  ];
};
