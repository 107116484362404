import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import retiro from '../../assets/images/detalle-del-retiro.svg';
import './modalCash.css';
class ModalCash extends Component {
  render() {
    let { children, footer } = this.props;
    return (
      <Modal className="m-0" {...this.props} aria-labelledby="contained-modal-title-vcenter text-black" centered >
        <Modal.Header className="p-0" closeButton>
          <img className="img-fluid"
            style={{ borderRadius: "14px" }}
            height={400} src={retiro}
            alt="detalle-del-retiro"
          />
        </Modal.Header>
        {children &&
          <Modal.Body className="p-0">
            {children}
          </Modal.Body>
        }
        {footer &&
          <Modal.Footer>
            {footer}
          </Modal.Footer>
        }

      </Modal>
    );
  }
}

export default ModalCash;