import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import './PaltaBreadcrumbs.css';

class PaltaBreadcrumbs extends Component {
  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let { name, variant } = this.props;
    return (
      <section className={`box-breadcrumbs box-breadcrumbs-${variant}`}>
        <Container>
          <Row>
            <Col>
              <div onClick={this.goBack}>
                <div
                  className={`breadcrumbs ${
                    variant ? `breadcrumbs-${variant}` : ''
                  }`}
                >
                  <i className="material-icons icon">keyboard_arrow_left</i>
                </div>
              </div>
              <h3
                className={`breadcrumbs-title ${
                  variant ? `breadcrumbs-title-${variant}` : ''
                }`}
              >
                {name}
              </h3>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default withRouter(PaltaBreadcrumbs);
