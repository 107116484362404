import { useState, useCallback } from 'react';
import { fetcher } from '../../Fetch/Fetch';
import * as API from '../../../constants/api-staging';
import Swal from 'sweetalert2';
export const useGetShop = () => {
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(false);

  const getShop = useCallback((id, token) => {
    fetcher(`${API.BASE_URL}/user/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then((res) => {
        if (!res.ok) {
          if (res.status !== 200) {
            setError(true);
          }
          if (res.status === 401) {
            Swal.fire({
              icon: 'error',
              title: "Sesion Expirada",
              padding: "2px",
              showConfirmButton: false,
              width: "300px",
              heightAuto: "200px",
              timer: 1500,
              customClass: {
                title: "m-0 text-danger",
                header: "p-0"
              }
            }).then(function () {
              window.location.href = "/";
            });
          }
        } else {
          return res.json();
        }
        return res.json();
      })
      .then(({ data }) => {
        setData(data);
      })
      .catch((error) => {
        setError(true);
      });
  }, []);

  const getShops = useCallback((ids, token) => {
    Promise.all(
      ids.map((id) =>
        fetcher(`${API.BASE_URL}/user/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
        }).then((res) => {
          if (!res.ok) {
            if (res.status !== 200) {
              Swal.fire({
                icon: 'error',
                title: "Sesion Expirada",
                padding: "2px",
                showConfirmButton: false,
                width: "300px",
                heightAuto: "200px",
                timer: 1500,
                customClass: {
                  title: "m-0 text-danger",
                  header: "p-0"
                }
              }).then(function () {
                // Redirect the user
                // window.localStorage.clear();
                window.location.href = "/";
              });
              setError(true);
            }
          } else {
            return res.json();
          }
          return res.json();
        }),
      ),
    )
      .then((shops) => {
        const data = shops.map((shop) => shop.data);
        setData(data);
      })
  }, []);

  return [
    {
      data,
      error,
    },
    getShop,
    getShops,
  ];
};
