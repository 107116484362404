import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { user, token } from '../../store';
import userHelper from '../../helpers/user';
import * as ROUTES from '../../constants/routes';

const PrivateRoute = ({ component: Component, wording, ...rest }) => {
  const setUserData = useSetRecoilState(user);
  const setTokenData = useSetRecoilState(token);

  return (
    <Route
      {...rest}
      render={(props) => {
        const user = userHelper.getUser();
        setUserData(JSON.parse(user));
        if (userHelper.getToken()) {
          setTokenData(userHelper.getToken());
          return <Component {...props} wording={wording} />;
        } else {
          return <Redirect to={ROUTES.WELCOME} />;
        }
      }}
    />
  );
};

export default PrivateRoute;
