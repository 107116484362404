const userHelper = {
  setToken: (token) => {
    console.log('!token ', !token);
    console.log('typeof ', typeof token !== 'string');
    console.log('trim ', !token.trim());
    if (!token || typeof token !== 'string' || !token.trim()) return false;
    try {
      localStorage.setItem('token', token);
      return true;
    } catch (error) {
      return false;
    }
  },
  getToken: () => localStorage.getItem('token'),
  setName: (name) => {
    try {
      localStorage.setItem('name', name);
      return true;
    } catch (error) {
      return false;
    }
  },
  setID: (_id) => {
    if (!_id || typeof _id !== 'string' || !_id.trim()) return false;
    try {
      localStorage.setItem('_id', _id);
      return true;
    } catch (error) {
      return false;
    }
  },
  getID: () => localStorage.getItem('_id'),
  setUser: (data) => {
    try {
      localStorage.setItem('user', data);
      return true;
    } catch (error) {
      return false;
    }
  },
  getUser: () => localStorage.getItem('user'),
};

export default userHelper;
