import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import '../ModalTransaccion/modalTransaccion.css'
import retiroFallido from '../../assets/images/retiroFallido.jpg';
class ModalFailure extends Component {
  render() {
    let { children } = this.props;
    return (
      <Modal {...this.props} aria-labelledby="contained-modal-title-vcenter" centered

      >
        <Modal.Header className="p-0" closeButton>
          <img className="img-fluid"
            style={{ borderRadius: "14px" }}
            height={400} src={retiroFallido} alt="imagenRetiroFallido" />
        </Modal.Header>
        {children &&
          <Modal.Body className="p-0">
            {children}
          </Modal.Body>
        }
      </Modal>
    );
  }
}

export default ModalFailure;