import styled from 'styled-components';

export const Body1 = styled.div`
  font-size: ${({ theme }) => theme.text.fontSize.s};
  font-weight: ${({ theme }) => theme.text.fontWeight.regular};
  color: ${({ theme }) => theme.colors.primary};
  opacity: ${({ theme }) => theme.emphasis.high};
  letter-spacing: 0.43px;
  line-height: 18px;
  font-size:20px;
  font-family: 'ProductSansBold';
`;
