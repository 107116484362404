import React from 'react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import { CreatePdfTax } from '../pdf-tax/CreatePdfTax';
export const SeeTax = ({ tax }) => {
  const generatePdfDocument = async (tax) => {
    const blob = await pdf((
      <CreatePdfTax
        title='My PDF'
        data={tax}
      />
    )).toBlob();
    saveAs(blob, "Impuesto.pdf");
  };
  const year = new Date().getFullYear();
  return (
    <>

      <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
        <p className="mt-2 ml-2">Monto del impuesto: </p>
        <p className="mt-2 mr-2">${tax.amount.toFixed(2)}</p>
      </div>
      <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
        <p className="mt-2 ml-2">Monedero: </p>
        <p className="mt-2  mr-2">{tax.currencyLabel}</p>
      </div>
      <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
        <p className="mt-2 ml-2">Fecha del impuesto: </p>
        <p className="mt-2  mr-2">
          {format(new Date(tax.createdAt), 'yyyy') ===
            year.toString()
            ? format(
              new Date(tax.createdAt),
              'dd-MMMM-yyy, HH:mm',
              { awareOfUnicodeTokens: true, locale: es },
            )
            : format(
              new Date(tax.createdAt),
              'dd-MMMM-yyy, HH:mm',
              { awareOfUnicodeTokens: true, locale: es },
            )}

        </p>
      </div>
      {tax._transactionOriginId !== undefined ? (
        <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
          <p className="mt-2 ml-2">Monto Inicial: </p>
          <p className="mt-2  mr-2">${tax._transactionOriginId.amount.toFixed(2)}</p>
        </div>

      ) : tax._cashoutOriginId !== undefined ? (
        <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
          <p className="mt-2 ml-2">Monto Inicial: </p>
          <p className="mt-2  mr-2">${tax._cashoutOriginId.amount.toFixed(2)}</p>
        </div>
      ) : tax._cashinOriginId !== undefined ? (
        <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
          <p className="mt-2 ml-2">Monto Inicial: </p>
          <p className="mt-2  mr-2">${tax._cashinOriginId.amount.toFixed(2)}</p>
        </div>
      ) : null
      }
      {tax.originTransactionCode !== undefined && tax.type === "tax" ? (
        <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
          <p className="mt-2 ml-2">N° transacción origen: </p>
          <p className="mt-2 mr-2">{tax.originTransactionCode}</p>
        </div>
      ) : null}
      <button className="btn float-right text-white rounded-pill mb-3 btnColor" onClick={() => generatePdfDocument(tax)}>
        <span className="icon-download ">
          <i className="material-icons">file_download</i>
        </span>
        Descargar PDF
      </button>
    </>

  )
}
