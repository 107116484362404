import React, { useState, useCallback, useEffect, useDebugValue } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form, Button, Container, Row } from 'react-bootstrap';
import { useSetRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { auth } from '@core/Firebase';
import { user, token } from '@store';
import './login.css';
import logo from '../../assets/images/palta-512.svg';
import * as API from '@constants/api-staging';
import * as ROUTES from '@constants/routes';
import userHelper from '@helpers/user';
import { ForgotPassword } from './forgot-password';
import PaltaModalSpinner from '../../components/PaltaModalSpinner';
import Swal from 'sweetalert2';
import { fetcher } from '../../core/Fetch/Fetch';
import { webAuthn } from '../../core/WebAuthn/WebAuthn';
const { REACT_APP_RECAPTCHA_GOOGLE_KEY } = process.env;

const LinkButton = styled.a`
  display: flex;
  justify-content: center;
  text-decoration: none;
  margin-top: ${({ theme }) => theme.spacers.m};
  cursor: pointer;
`;
const LogInContainer = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
`;
const FingerPrint = styled.div`
  color: #3a4d9f;
  border: 2px solid #3a4d9f;
  border-radius: 0.25rem;
  margin-left: 10px;
  height: 100%;
`;
// const BoxContainer = styled.div`
//   width: 340px;
//   min-height: 620px;
//   display: flex;
//   flex-direction: column;
//   border-radius: 50px;
//   background-color: #fff;
//   box-shadow: 0px 0px 4.7px rgba(15, 15, 15, 0.28);
//   position: relative;
//   overflow: hidden;

// `;
// const InnerContainer = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin-bottom: 10px;
// `;
// const AppContainer = styled.div`
//   width: 100vw;
//   height: 100vh;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

const LogIn = () => {
  const { register, handleSubmit, errors, formState } = useForm({
    mode: 'onChange',
  });
  const [loading, setLoading] = useState(false);
  const setUserData = useSetRecoilState(user);
  const setTokenData = useSetRecoilState(token);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${REACT_APP_RECAPTCHA_GOOGLE_KEY}`,
      function () {
        console.log('Script loaded!');
      },
    );
  }, []);

  const handleWebAuthn = async () => {
    if (
      window.localStorage.getItem('authn') &&
      JSON.parse(window.localStorage.getItem('authn'))
    ) {
      const data = await webAuthn.authenticate(
        JSON.parse(window.localStorage.getItem('authn')),
      );
      if (!data) {
        return Swal.fire(
          'Los datos biometricos no coinciden',
          'Por favor, intente nuevamente',
          'error',
        );
      }
      if (data) {
        userHelper.setUser(JSON.stringify(data?.data));
        userHelper.setToken(data?.data?.token);
        setUserData(data?.data);
        setTokenData(data?.data?.token);
        history.push(ROUTES.DASHBOARD);
      }
    }
  };

  const onSubmit = useCallback(async (data) => {
    setLoading(true);
    await auth
      .signInWithEmailAndPassword(data.email, data.password)
      .then((firebaseResults) => {
        firebaseResults.user.getIdToken().then(function (idToken) {
          window.grecaptcha.ready(async () => {
            const recaptchaToken = await window.grecaptcha.execute(
              REACT_APP_RECAPTCHA_GOOGLE_KEY,
              { action: 'submit' },
            );
            fetcher(`${API.BASE_URL}${API.VERIFY_TOKEN}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                idToken,
                recaptchaToken,
              }),
            })
              .then((res) => {
                if (!res.ok) {
                  if (res.status === 401) {
                    Swal.fire({
                      icon: 'error',
                      title: 'Usuario o Contraseña incorrectos',
                      padding: '2px',
                      showConfirmButton: false,
                      width: '300px',
                      heightAuto: '200px',
                      timer: 1500,
                      customClass: {
                        title: 'm-0 text-danger',
                        header: 'p-0',
                      },
                    });
                  }
                  if (res.status === 400) {
                    Swal.fire({
                      icon: 'error',
                      title: 'Usuario inexistente',
                      padding: '2px',
                      showConfirmButton: false,
                      width: '300px',
                      heightAuto: '200px',
                      timer: 1500,
                      customClass: {
                        title: 'm-0 text-danger',
                        header: 'p-0',
                      },
                    });
                  }
                } else {
                  return res.json();
                }
              })
              .then(({ data }) => {
                userHelper.setUser(JSON.stringify(data));
                userHelper.setToken(data.token);
                setUserData(data);
                setTokenData(data.token);
                history.push(ROUTES.DASHBOARD);
                setLoading(false);
              })
              .catch((err) => {
                Swal.fire({
                  icon: 'error',
                  title: 'Error al intentar loguearse',
                  padding: '2px',
                  showConfirmButton: false,
                  width: '300px',
                  heightAuto: '200px',
                  timer: 1500,
                  customClass: {
                    title: 'm-0 text-danger',
                    header: 'p-0',
                  },
                });
                setLoading(false);
              });
          });
        });
      })
      .catch((err) => {
        const errors = ['auth/invalid-email', 'auth/wrong-password'];
        if (errors.indexOf(err.code) >= 0) {
          Swal.fire({
            icon: 'error',
            title: 'Credenciales incorrectas',
            padding: '2px',
            showConfirmButton: false,
            width: '300px',
            heightAuto: '200px',
            timer: 1500,
            customClass: {
              title: 'm-0 text-danger',
              header: 'p-0',
            },
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error al intentar loguearse',
            padding: '2px',
            showConfirmButton: false,
            width: '300px',
            heightAuto: '200px',
            timer: 1500,
            customClass: {
              title: 'm-0 text-danger',
              header: 'p-0',
            },
          });
        }
        setLoading(false);
      });
  }, []);
  return (
    <>
      <Container className=" p-0">
        <Row
          className="d-flex justify-content-center alig-item-center"
          style={{
            boxShadow: 'rgb(236 220 220) 10px 10px 8px',
            background: 'rgb(242, 242, 242)',
          }}
        >
          {isMobile ? (
            <>
              <div className="col-6 col-sm-6 col-md-6">
                <img
                  className="float-right  ml-5 mt-1"
                  src="assets/images/Mendoza.svg"
                  alt=""
                  height={80}
                  width={140}
                />
              </div>
              <div className="col-6 col-sm-6 col-md-6 p-0">
                <img
                  className="float-right mr-5"
                  src="assets/images/palta-logo-500.svg"
                  alt=""
                  height={80}
                  width={110}
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-6 col-sm-6 col-md-6 p-0">
                <img
                  className="float-right  mt-1"
                  src="assets/images/Mendoza.svg"
                  alt=""
                  height={80}
                  width={150}
                />
              </div>
              <div className="col-6 col-sm-6 col-md-6 p-0">
                <img
                  className="float-right mr-5"
                  src="assets/images/palta-logo-500.svg"
                  alt=""
                  height={80}
                  width={110}
                />
              </div>
            </>
          )}
        </Row>
      </Container>

      <h2 style={{ marginTop: '60px' }}>¡Hola, Palter!</h2>
      <p style={{ marginTop: '18px', fontSize: 20, color: '#3A4D9F' }}>
        Ingresá con email
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="form-group-login">
          <input
            type="email"
            style={{ background: '#C0C4E2' }}
            name="email"
            placeholder="Tu e-mail"
            ref={register({
              required: 'Required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              },
            })}
            onChange={(e) => {
              const value = e.target.value.trim();
            }}
            className="form-control"
            data-cy="input-email"
          />
        </Form.Group>
        {errors.email && errors.email.type === 'required' && (
          <p className="input-error">El campo email es requerido</p>
        )}
        {errors.email && errors.email.type === 'pattern' && (
          <p className="input-error">El email es invalido</p>
        )}
        {errors.email && errors.email.type === 'validate' && (
          <p className="input-error">no debe posseer espacios</p>
        )}
        <Form.Group className="form-group-login">
          <input
            name="password"
            style={{ background: '#C0C4E2' }}
            placeholder="Tu contraseña"
            type="password"
            ref={register({ required: 'Required', minLength: 3 })}
            className="form-control mt-3 mb-3"
            data-cy="input-password"
          />
        </Form.Group>
        {errors.password && errors.password.type === 'required' && (
          <p className="input-error">El campo contraseña es requerido</p>
        )}
        {errors.password && errors.password.type === 'minLength' && (
          <p className="input-error">Debe tener mínimo 3 letras</p>
        )}
        <LogInContainer>
          <Button
            type="submit"
            size="md"
            className=" active btn-block btn-fullwidthe btnColor"
            disabled={!formState.isValid}
            style={
              window.localStorage.getItem('authn')
                ? { fontSize: 18, height: '100%' }
                : { fontSize: 18 }
            }
            data-cy="button-ingresar"
          >
            Ingresar
          </Button>
          {window.localStorage.getItem('authn') &&
            JSON.parse(window.localStorage.getItem('authn')) && (
              <FingerPrint
                className="box-icon d-flex align-items-center"
                onClick={() => handleWebAuthn()}
              >
                <i className="material-icons" style={{ fontSize: 40 }}>
                  fingerprint
                </i>
              </FingerPrint>
            )}
        </LogInContainer>
        <LinkButton
          onClick={() => setShowModal(true)}
          style={{ color: '#3A4D9F', fontSize: 16 }}
        >
          ¿Se te olvidó tu contraseña?
        </LinkButton>
      </form>
      <div className="row d-flex justify-content-center align-items-center mt-2">
        <Button
          onClick={() => {
            window.open(`${ROUTES.PALTA}`);
            window.close();
          }}
          alt=""
          style={{
            color: '#3A4D9F',
            fontFamily: 'ProductSans',
            marginTop: '15px',
            textTransform: 'none',
            fontSize: 15,
            background: 'transparent',
            border: '0px',
          }}
        >
          ¿No tenés Cuenta? ¡Registrate!
        </Button>
      </div>
      {loading && (
        <PaltaModalSpinner show={loading} size="sm">
          <h3 className="subtitle">
            <img
              src={logo}
              width="120"
              height="50"
              className="d-inline-block align-top img-rotate float-left"
              alt="Palta logo"
            />
            <p
              className="float-right mt--1 mb-0"
              style={{ fontSize: 30, marginRight: 40 }}
            >
              {' '}
              Cargando...
            </p>
          </h3>
        </PaltaModalSpinner>
      )}

      {showModal && (
        <ForgotPassword setShowModal={setShowModal} setLoading={setLoading} />
      )}
    </>
  );
};

export default LogIn;
