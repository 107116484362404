import React, { Component } from 'react';
import { Button, Container, Form, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';

import fetchApiMig from '../../core/FetchApiMig';
import PaltaBreadcrumbs from '../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';

import * as ROUTES from '../../constants/routes';
import * as API from '../../constants/api-staging';

class index extends Component {
  state = {
    amount: '',
    isLoading: false,
  };

  hanldeAmount = (e) => {
    this.setState({ amount: e.target.value });
  };

  hanldeSubmitCashIn = (e) => {
    e.preventDefault();
    this.setState({ isLoading: 'Procesando Carga' });
    let data = {
      amount: Number(this.state.amount),
      description: 'Palta Cashin',
      source: 'web',
    };
    fetchApiMig('POST', `${API.CASHIN}`, data)
      .then((mpResponse) => {
        toast.info(` 🤙 Serás redirigido a Mercado Pago`, {
          hideProgressBar: false,
          autoClose: 3500,
        });
        setTimeout(() => {
          window.open(mpResponse.url);
          this.setState({ amount: '', isLoading: false });
        }, 3500);
      })
      .catch((statusError) => {
        toast.error(`Error al cargar Palta`);
      });
  };

  render() {
    let { amount, isLoading } = this.state;
    let isInvalid = Boolean(!amount);
    return (
      <div style={{ backgroundColor: '#fff' }}>
        <PaltaBreadcrumbs
          name="Cargar mi Palta"
          link={ROUTES.ACCOUNT}
          linkName="Mi Cuenta"
          variant="dark"
        />
        <section style={{ marginBottom: '4em' }}>
          <Container>
            <Row>
              <Col sm={12}>
                <h2 className="title" style={{ color: '#3628DD' }}>
                  Cargar mi Palta
                </h2>
              </Col>
            </Row>
          </Container>
          <Container>
            <div className="justify-content-md-center align-items-center mt-5">
              <Form style={{ maxWidth: '28rem', margin: '0 auto' }}>
                <img
                  style={{
                    margin: 'auto auto',
                    display: 'block',
                    width: '50%',
                  }}
                  src="https://vignette.wikia.nocookie.net/logopedia/images/5/5c/MercadoPago.svg"
                  alt="MercadoPago"
                />
                <hr />
                <Form.Group className="form-group-icon" controlId="amount">
                  <i className="material-icons icon">monetization_on</i>
                  <Form.Control
                    type="number"
                    name="amount"
                    placeholder="Monto a Cargar"
                    value={amount}
                    onChange={this.hanldeAmount}
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  size="lg"
                  className="btn-block btn-fullwidthe"
                  onClick={this.hanldeSubmitCashIn}
                  disabled={isInvalid || isLoading}
                  type="submit"
                >
                  {' '}
                  {isLoading || 'Cargar'}{' '}
                </Button>
              </Form>
            </div>
          </Container>
        </section>
      </div>
    );
  }
}

export default index;
