import React, { useState, useEffect } from 'react';
import { Container, Wrapper } from '@components/grid';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import { useLocation } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { fireSwal } from '../../components/utils/swalUtils';
import PaltaModalSpinner from '../../components/PaltaModalSpinner';
import PaltaBreadcrumbs from '../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import { useGetOpcion } from '../../core/api-hooks/captar-pagos/useGetOpcion';
import { useHistory } from 'react-router';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import {
    getCurrencyLabel,
    getCurrencyPair,
    availableWithTaxApplied,
    sanitizeAmount,
    trunc
} from '../service-payments/utils';
import { useTimer } from 'react-timer-hook';
import {
    GeneralHeading,
    SimpleButton,
    SimpleContainer,
    SmallHeading,
    SmallParagraph
} from '../../components/UI/UIComponents';
import { getRates, getPaltaDollarWallets } from '../../core/api-hooks/dollar-utils/paltaDollars';
import { motion } from 'framer-motion';

const DollarConversion = () => {
    const location = useLocation();
    const history = useHistory();
    const { currency, currencyLabel } = location.state.currency;
    const [loading, setLoading] = useState(false);
    const [originWallet, setOriginWallet] = useState(null);
    const [amount, setAmount] = useState('');
    const [transactionType, setTransactionType] = useState();
    const [rates, setRates] = useState({ ask: undefined, bid: undefined })
    const [paltaDollarWallets, setPaltaDollarWallets] = useState([]);
    const [expirationDate, setExpirationDate] = useState(null);
    const [timeLeft, setTimeLeft] = useState();
    const [total, setTotal] = useState();
    const [inputColor, setInputColor] = useState('#3a4d9f')
    const userData = JSON.parse(localStorage.getItem('user'));
    const taxedUser = userData?.taxPercentage

    const handleExpiration = () => {
        setRates({ ask: undefined, bid: undefined });
        setTimeLeft(undefined);
        getInfo();
    }

    function MyTimer({ expiryTimestamp }) {
        const { seconds } =
            useTimer({
                expiryTimestamp,
                onExpire: () => {
                    handleExpiration();
                }
            });
        return (
            <div style={{ textAlign: 'center', marginTop: '1em' }}>
                La cotización se actualizará en {seconds} segundos
            </div>
        );
    }

    useEffect(() => {
        getInfo();
    }, []);

    useEffect(() => {
        if (amount) {
            if (transactionType === 'ask') {
                const rate = rates[transactionType];
                const numAmount = Number(amount);
                setTotal(numAmount / rate);
                setInputColor('#3a4d9f');
                if (amount > originWallet.amount) {
                    setInputColor('red');
                }
            } else {
                const rate = rates[transactionType];
                const numAmount = Number(amount);
                setTotal(numAmount * rate);
                setInputColor('#85BB65');
                if (amount > originWallet.amount) {
                    setInputColor('red');
                }
            }
        }
    }, [amount])

    async function getInfo() {
        try {
            const rates = await getRates();
            setRates(rates.data.data);
            setExpirationDate(rates.data.data.rateDate + 30000);
            const paltaWallets = await getPaltaDollarWallets();
            setPaltaDollarWallets(paltaWallets.data.data)
        } catch (error) {
            history.push(ROUTES.DASHBOARD);
        }
    }

    const handleWalletSelection = (operationType, originCurrency) => {
        setTotal();
        setAmount('');
        setTransactionType(operationType);
        const [userOriginWallet] = userData.wallets.filter(wal => wal.currency === originCurrency);
        setOriginWallet(userOriginWallet);
    }

    const handleInput = (e) => {
        setAmount(e.target.value.replace(',', '.'));
    }

    const handleContinuar = () => {
        const destinationWallet = paltaDollarWallets.find(wal => wal.currency === originWallet.currency);

        const operation = {
            originWallet,
            destinationWallet,
            amount,
            total,
            rate: rates[transactionType],
            transactionType
        };

        history.push({
            pathname: ROUTES.DOLLAR_TRANSACTION_DETAIL,
            state: operation
        })
    }

    const handleMaxButton = () => {
        if (originWallet) {
            if (taxedUser) {
                setAmount(trunc(Number(availableWithTaxApplied(originWallet.amount, taxedUser))))
            } else {
                setAmount(trunc(Number(originWallet.amount, taxedUser)))
            }
        }
    }

    return (
        <>
            <motion.div
                initial={{ scale: .3 }}
                animate={{ scale: 1 }}
                transition={{ type: 'spring', duration: .4 }}
            >
                <PaltaNavBar className="light" />
                <PaltaBreadcrumbs
                    name="Inicio"
                    link={ROUTES.DASHBOARD}
                    variant="dark"
                />
                <Container>
                    <Wrapper>
                        <Row>
                            <Col>
                                <GeneralHeading>
                                    Intercambio de monedas
                                </GeneralHeading>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <SmallHeading style={{ textAlign: 'center' }}>
                                    {currencyLabel}
                                </SmallHeading>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <SimpleContainer>
                                    <Row style={{ margin: '0 auto' }}>

                                        <div
                                            style={{
                                                display: 'grid',
                                                gridTemplateColumns: '1fr 1fr',
                                                textAlign: 'center',
                                                width: '100%'
                                            }}
                                        >
                                            <div>
                                                <SimpleButton
                                                    onClick={() => {
                                                        const pairWallet = getCurrencyPair(currency);
                                                        handleWalletSelection('ask', pairWallet);
                                                    }}
                                                    backgroundColor='#85BB65'
                                                    style={{ width: '90%' }}
                                                >Comprar</SimpleButton>
                                                <SmallParagraph>
                                                    Cotización: ${rates.ask ? sanitizeAmount(rates.ask) : <Spinner className='ml-2' animation="grow" size="sm"></Spinner>}
                                                </SmallParagraph>
                                            </div>
                                            <div>
                                                <SimpleButton
                                                    onClick={() => {
                                                        handleWalletSelection('bid', currency);
                                                    }}
                                                    style={{ width: '90%' }}
                                                    backgroundColor='#85BB65'
                                                >Vender</SimpleButton>
                                                <SmallParagraph>
                                                    Cotización: ${rates.bid ? sanitizeAmount(rates.bid) : <Spinner className='ml-2' animation="grow" size="sm"></Spinner>}
                                                </SmallParagraph>
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {!expirationDate ? '' : <MyTimer expiryTimestamp={expirationDate} />}
                                        </Col>
                                    </Row>
                                    <Row className='mt-4' style={{ textAlign: 'center' }}>

                                        <Col>
                                            Disponible
                                            <p
                                                style={{
                                                    fontSize: '1.4em',
                                                    marginLeft: '3px'
                                                }}
                                            >
                                                {
                                                    !originWallet ? <span
                                                        style={{ color: 'red' }}
                                                    >Seleccionar tipo de operación</span>
                                                        :
                                                        <span>{!originWallet.currency.match(/usd/gi) ?
                                                            taxedUser ?
                                                                `$ ${sanitizeAmount(trunc(availableWithTaxApplied(originWallet.amount, taxedUser)))}`
                                                                : `$ ${sanitizeAmount(trunc(originWallet.amount))}`


                                                            :

                                                            taxedUser ? `USDP$  ${sanitizeAmount(trunc(availableWithTaxApplied(originWallet.amount, taxedUser)))}`
                                                                : `USDP$  ${sanitizeAmount(trunc(originWallet.amount))}`


                                                        }</span>
                                                }</p>
                                        </Col>
                                    </Row>
                                    {
                                        transactionType &&
                                        <>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}>
                                                <div
                                                >
                                                    <button
                                                        className='btn btn-outline-primary'
                                                        style={{
                                                            fontSize: '30px',
                                                            marginBottom: '2px',
                                                            color: '#3a4d9f'
                                                        }}
                                                        onClick={handleMaxButton}
                                                    >MAX</button>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    fontSize: '3rem',
                                                    alignItems: 'center',
                                                    color: inputColor
                                                }}
                                            >
                                                <div
                                                    style={{ flexBasis: '10%px' }}
                                                >
                                                    {
                                                        transactionType === 'ask' ?
                                                            <div style={{ display: 'flex' }}><div
                                                                style={{ color: 'white' }}
                                                            >USDP</div><div>$</div></div>
                                                            :
                                                            transactionType === 'bid' ? 'USDP'
                                                                : ''
                                                    }
                                                </div>
                                                <div
                                                    style={{ flexBasis: '90%px' }}
                                                >
                                                    <Form.Control
                                                        type="number"
                                                        inputMode="decimal"
                                                        value={amount}
                                                        disabled={!originWallet}
                                                        onChange={handleInput}
                                                        onWheel={(e) => e.target.blur()}
                                                        step="any"
                                                        max={2000}
                                                        className="payment-amount"
                                                        style={{
                                                            color: inputColor,
                                                            fontSize: '3rem'
                                                        }}
                                                        autoComplete="off"
                                                        placeholder="0"
                                                    />
                                                </div>

                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    fontSize: '1.2em'
                                                }}
                                            >
                                                <div style={{ flexBasis: '60%', fontSize: '1.2em' }} className="data-label">
                                                    Comisión
                                                </div>
                                                <div
                                                    className="data colorFont"
                                                    style={{ textTransform: 'capitalize', fontSize: '1.2em' }}
                                                >0 %</div>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <div style={{ flexBasis: '40%', fontSize: '2.2em' }} className="data-label">
                                                    Recibís
                                                </div>
                                                <div
                                                    className="data colorFont dolar-color"
                                                    style={{ textTransform: 'capitalize', fontSize: '2.2em', color: inputColor }}
                                                    data-cy='business-name'
                                                > {
                                                        !total
                                                            ?
                                                            '-----' :
                                                            transactionType === 'ask'
                                                                ?
                                                                <span
                                                                    style={{ color: '#85BB65' }}
                                                                >USDP {sanitizeAmount(total)}</span>
                                                                :
                                                                <span
                                                                    style={{ color: '#3a4d9f' }}
                                                                >$ {sanitizeAmount(total)}</span>
                                                    }</div>
                                            </div>
                                        </>
                                    }
                                    <div
                                        style={{ fontSize: '1em', textAlign: 'center' }}
                                    >
                                        <SimpleButton
                                            className='button'
                                            style={{ fontSize: '1.2em', textAlign: 'center', marginBottom: '4em' }}
                                            onClick={handleContinuar}
                                            disabled={!originWallet
                                                || !amount
                                                || timeLeft
                                                || amount > originWallet.amount
                                                || (transactionType === 'ask' && amount < 10)
                                            }
                                        >
                                            CONTINUAR
                                        </SimpleButton>
                                    </div>
                                </SimpleContainer>
                            </Col>
                        </Row>
                    </Wrapper>
                </Container >
                <PaltaFooter />
                <PaltaModalSpinner show={loading} size="sm" />
            </motion.div>
        </>
    );
};

export default DollarConversion;
