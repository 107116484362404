import React, { useEffect, useState } from 'react';
import { Container, Wrapper } from '@components/grid';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import './ServicePayments.css';
import { buscarEmpresaCP } from '../../core/api-hooks/captar-pagos/useBuscarEmpresasCP';
import PaltaBreadcrumbs from '../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import BusinessList from './BusinessList';
import PaltaModalSpinner from '../../components/PaltaModalSpinner';
import * as ROUTES from '../../constants/routes';
import imgAysam from '../../assets/images/aysam-new.png';
import imgEcogas from '../../assets/images/ecogas-new.png';
import imgDirectv from '../../assets/images/directv.png';
import imgEdemsa from '../../assets/images/edemsa.png';
import imgAtm from '../../assets/images/atm.jpeg';
import imgSuper from '../../assets/images/super-new.png';
import { useHistory } from 'react-router-dom';

import { Col, Row } from 'react-bootstrap';

const ServicePayments = () => {
  const history = useHistory();
  const [triggered, setTriggered] = useState(false)
  const [businessName, setBusinessName] = useState('');
  const [businessList, setBusinessList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearchButton = async () => {
    setBusinessList(undefined);
    const response = await buscarEmpresaCP(businessName);
    setLoading(false);
    setBusinessList(response?.data?.empresas);
    if (response?.data?.empresas.length === 0) {
      setTriggered(true);
    } else {
      setTriggered(false);
    }
  }

  const redirectPayment = (idProducto, descripcion) =>{
    const business = {
      descripcion,
      idProducto
    }
    history.push({
       pathname: ROUTES.SERVICE_PAYMENTS_GENERIC_1,
        state: business
    })
    
  }

  const redirectPaymentBarcode = () =>{
    history.push(ROUTES.SERVICE_PAYMENTS_BARCODE);
  }

  const handleSearchInput = (e) => {
    setBusinessName(e.target.value);
  }

  useEffect(() => {
    if (businessName.length > 2) {
      const timer = setTimeout(() => {
        setLoading(true);
        handleSearchButton();
      }, 450);

      return () => {
        clearTimeout(timer)
      }
    }
  }, [businessName]);



  return (
    <>
      <PaltaNavBar className="light" />
      <PaltaBreadcrumbs
        name="Seleccionar forma de pago"
        link={ROUTES.PAYMENTS_SELECT}
        variant="dark"
      />
      <Container>
        <Wrapper>
        <div className='company-topup__container'>
                                <div
                                    className='phone__container'
                                    
                                    onClick={(e) => redirectPayment(58150001, 'EDEMSA')}
                                >
                                    <div className='phone-image__container' >
                                        <img className='phone-image__img' src={imgEdemsa} style={{borderRadius : '0'}}/>
                                    </div>
                                    <div>
                                        <h3></h3>
                                    </div>
                                </div>
                                <div
                                    className='phone__container  '
                                    
                                    onClick={(e) => redirectPaymentBarcode()}
                                >
                                    <div className='phone-image__container'>
                                        <img className='phone-image__img' src={imgAysam} style={{borderRadius : '0'}}/>
                                    </div>
                                    <div>
                                        <h3></h3>
                                    </div>
                                </div>
                                <div
                                   className='phone__container  '
                                   
                                   onClick={(e) => redirectPayment(41750001, 'ATM')}
                                >
                                    <div className='phone-image__container'>
                                        <img className='phone-image__img' src={imgAtm} style={{borderRadius : '0'}}/>
                                    </div>
                                    <div>
                                        <h3></h3>
                                    </div>
                                </div>
                                <div
                                   className='phone__container  '
                                   
                                   onClick={(e) => redirectPayment(5750003, "DirecTV")}
                                >
                                    <div className='phone-image__container'>
                                        <img className='phone-image__img' src={imgDirectv} style={{borderRadius : '0'}}/>
                                    </div>
                                    <div>
                                        <h3></h3>
                                    </div>
                                </div>
                                <div
                                   className='phone__container  '
                                   
                                   onClick={(e) => redirectPayment(72050001, "Ecogas Cuyo")}
                                >
                                    <div className='phone-image__container' >
                                        <img className='phone-image__img' src={imgEcogas} style={{borderRadius : '0'}}/>
                                    </div>
                                    <div>
                                        <h3></h3>
                                    </div>
                                </div>
                                <div
                                   className='phone__container  '
                                   
                                   onClick={(e) => redirectPayment(59050002, "Supercanal")}
                                >
                                    <div className='phone-image__container'>
                                        <img className='phone-image__img' src={imgSuper}  style={{borderRadius : '0'}}/>
                                    </div>
                                    <div>
                                        <h3></h3>
                                    </div>
                                </div>
                            </div>
          <Row>
            <Col>
              <h4 data-cy="heading">Buscar Empresa</h4>
            </Col>
          </Row>

          <div className='service-container' >
            <input
              type="text"
              value={businessName}
              className="form-control input-empresa"
              id="empresa"
              aria-describedby="empresa"
              placeholder="Ingresá el nombre de la empresa..."
              onChange={handleSearchInput}
              data-cy="business-search"
            ></input>
          </div>
          {businessList?.length > 0 && <BusinessList list={businessList} />}
          {triggered &&
            <div
              className="data colorFont"
              data-cy="no-results">
              No se encontraron resultados
            </div>}
        </Wrapper>
      </Container>
      <PaltaFooter />
      <PaltaModalSpinner show={loading} size="sm" />

    </>
  );
};

export default ServicePayments;
