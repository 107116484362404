import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import App from './views/App/App';
import ThemeProvider from './theme/context';
import * as serviceWorker from './serviceWorker';



ReactDOM.render(
  <ThemeProvider>
    <RecoilRoot>
      <Router>
        <App />
      </Router>
    </RecoilRoot>
  </ThemeProvider>,
  document.getElementById('root'),
  
);

if (module.hot) {
  module.hot.accept();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
