import React, { useState, useEffect } from 'react';
import { Container, Row, Spinner } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { isMobile } from 'react-device-detect';
import { cashOutCbuCvu, cashOutAmount, transactionCode, date, cashName } from '../../../store';
import anuncioExito from '../../../assets/images/anuncio-cash-exito.svg';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import './success.css';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import PaltaModalSpinner from '../../../components/PaltaModalSpinner';

export const CashOutSuccess = () => {
  const year = new Date().getFullYear();
  const [loading, setLoading] = useState(true);
  const cashOutCbuCvuData = useRecoilValue(cashOutCbuCvu);
  const cashOutAmountData = useRecoilValue(cashOutAmount);
  const transactionCodetData = useRecoilValue(transactionCode);
  const cashNameData = useRecoilValue(cashName);
  const dateCashOut = useRecoilValue(date);
  useEffect(() => {
    if (cashOutCbuCvuData && cashOutAmountData && cashNameData && transactionCodetData && dateCashOut) {
      setLoading(false);
    }
  }, [cashOutCbuCvuData, cashOutAmountData, transactionCodetData, dateCashOut && cashNameData]);
  // eslint-disable-next-line no-extend-native
  String.prototype.capitalize = function () {
    return this.replace(/(^|\s)([a-z])/g, function (m, p1, p2) { return p1 + p2.toUpperCase(); });
  };
  return (
    <div >
      <PaltaNavBar/>
      <section>
        <Container className="contenedor">
          {isMobile ? (
            <h3 className="font-white-bold mt-3 mb-0" style={{ color: "#79B279" }}>
              {`$ ${cashOutAmountData?.toFixed(2)} `}
              {cashOutAmountData?._currencyId === "5fe2514dc2f07419dcf9d9dc" ? (
                "pesos"
              ) : cashOutAmountData?._currencyId === "5fe2514dc2f07419dcf9d9dd" ? (
                "crédito activa"
              ) : cashOutAmountData?._currencyId === "5fe2514dc2f07419dcf9d9de" ? (
                "crédito turismo"
              ) : cashOutAmountData?._currencyId === "5fe2514dc2f07419dcf9d9df" ? (
                "crédito fiscal"
              ) : null}
            </h3>
          ) : (
            <h2 className=" font-white-bold mt-3 mb-0" style={{ color: "#79B279" }}>
              {`$ ${cashOutAmountData?.toFixed(2)} `}
              {cashOutAmountData?._currencyId === "5fe2514dc2f07419dcf9d9dc" ? (
                "pesos"
              ) : cashOutAmountData?._currencyId === "5fe2514dc2f07419dcf9d9dd" ? (
                "crédito activa"
              ) : cashOutAmountData?._currencyId === "5fe2514dc2f07419dcf9d9de" ? (
                "crédito turismo"
              ) : cashOutAmountData?._currencyId === "5fe2514dc2f07419dcf9d9df" ? (
                "crédito fiscal"
              ) : null}
            </h2>
          )}
          <Row className="d-flex justify-content-center align-items-center h1">
            <img src={anuncioExito} alt="imagen" height={300} />
          </Row>

        </Container>
        <Container >
          {isMobile ? (
            <div className="row alert alert-success">
              <div className="col-12 col-sm-12 p-0">
                <h5 className="m-0 text-center" style={{ fontSize: 20, color: "#155724" }}>
                  Solicitud de transferencia realizada
                </h5>
                <hr style={{ border: 0, borderTop: "3px solid rgba(0,0,0,.1)", borderColor: "#155724" }} />
              </div>
              <hr style={{ border: 0, borderTop: "3px solid rgba(0,0,0,.1)", borderColor: "#155724" }} />
              <div className="col-12 col-sm-12 p-0">
                <p className="m-0" style={{ fontSize: 18 }}>
                  {`Transferido a : ${cashNameData?.capitalize()}`}
                </p>

                <p className="m-0" style={{ fontSize: 18 }}>{`N° operación: ${transactionCodetData}`}</p>
                {dateCashOut !== undefined && (

                  <p className="m-0" style={{ fontSize: 18 }}>
                    {format(
                      new Date(dateCashOut),
                      'yyyy',
                    ) === year.toString()
                      ? `Creada el ${format(
                        new Date(dateCashOut),
                        'dd-MMMM , HH:mm:ss',
                        {
                          awareOfUnicodeTokens: true,
                          locale: es,
                        },
                      )}`
                      : `Creada el ${format(
                        new Date(dateCashOut),
                        'dd-MMMM , HH:mm:ss',
                        {
                          awareOfUnicodeTokens: true,
                          locale: es,
                        },
                      )}`
                    }
                  </p>
                )}
                <p className="m-0" style={{ fontSize: 15 }}>
                  Tené en cuenta que esta operación podría demorar hasta 24hs hábiles.
                </p>
              </div>

            </div>
          ) : (
            <>
              <div className="row d-flex justify-content-center align-items-center mt-3 alert alert-success">
                <div className="col-12 col-sm-12 p-0 mt-2">
                  <h5 className="m-0 text-center" style={{ fontSize: 20, color: "#155724" }}>
                    Solicitud de transferencia realizada
                  </h5>
                  <hr style={{ border: 0, borderTop: "3px solid rgba(0,0,0,.1)", borderColor: "#155724" }} />
                </div>
                <div className="col-4 col-sm-4 p-0">
                  <p className="m-0 text-center" style={{ fontSize: 18 }}>
                    {`Transferido a : ${cashNameData?.capitalize()}`}
                  </p>
                </div>
                <div className="col-8 col-sm-6 p-0 ">
                  <p className="m-0 text-center" style={{ fontSize: 18 }}>{`N° operación: ${transactionCodetData}`}</p>
                  {dateCashOut !== undefined && (

                    <p className="m-0 text-center" style={{ fontSize: 18 }}>
                      {format(
                        new Date(dateCashOut),
                        'yyyy',
                      ) === year.toString()
                        ? `Creada el ${format(
                          new Date(dateCashOut),
                          'dd-MMMM , HH:mm:ss',
                          {
                            awareOfUnicodeTokens: true,
                            locale: es,
                          },
                        )}`
                        : `Creada el ${format(
                          new Date(dateCashOut),
                          'dd-MMMM , HH:mm:ss',
                          {
                            awareOfUnicodeTokens: true,
                            locale: es,
                          },
                        )}`
                      }
                    </p>
                  )}
                </div>
                <div className="col-12 col-sm-12 p-0 mt-2">

                  <p className="m-0 text-center" style={{ fontSize: 18 }}>
                    Tené en cuenta que esta operación podría demorar hasta 24hs hábiles.
                  </p>
                </div>
              </div>
            </>
          )}

        </Container>
      </section>
        {loading && (
          <PaltaModalSpinner show={loading} size="sm"/>
        )}
    
      <PaltaFooter/>
    </div>
  );
};