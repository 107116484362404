import anuncioExito from '../../assets/images/anuncio-cash-exito.svg'
import { Container, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import './ServicePayments.css';
import { sanitizeAmount } from './PaymentCashout.jsx'
import * as API from '../../constants/api-staging';

const Success = () => {
    const location = useLocation();
    const { payBody, responseBody } = location.state;
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const today = new Date();
    const formatedDate = today.toLocaleDateString("es-AR", options)
    const time = today.toString().split(' ');
    const amount = Number(payBody.factura[0].importe)
    const activaFee = Number(API.CAPTAR_FEE_ACTIVA);
    const paymentAmountWithFee = ((responseBody.data.monto / 100) * activaFee) + responseBody.data.monto;

    return (
        <div>
            <PaltaNavBar />
            <section>
                <Container className="contenedor">

                    <h2 style={{ color: "#79B279", marginTop: '0.5em' }}>

                        {payBody?.currency === "peso" ? (
                            `$ ${sanitizeAmount(Number(amount))} pesos`
                        ) : payBody?.currency === "fiscal_credit" ? (
                            `${sanitizeAmount(Number(amount))} crédito fiscal`
                        ) : payBody?.currency === "peso_activa" ? (
                            `$ ${sanitizeAmount(Number(paymentAmountWithFee))} crédito activa`
                        ) : payBody?.currency === "peso_tourism" ? (
                            `${sanitizeAmount(Number(amount))} crédito turismo`
                        ) : null
                        }
                    </h2>

                    <div className="d-flex justify-content-center align-items-center h1">
                        <img src={anuncioExito} alt="imagen" height={300} />
                    </div>
                </Container>
                <Container style={{ backgroundColor: '#D4EDDA', textAlign: 'center' }}>
                    <div>
                        <h5 className="success-subheading">
                            Solicitud de pago de servicios realizada
                        </h5>
                    </div>
                    <div>
                        <hr style={{ border: 0, borderTop: "3px solid rgba(0,0,0,.1)", borderColor: "#155724" }} />
                        <div className='pb-3'>
                            <p className='success-body'>
                                {`N° operación: ${responseBody.data.transaction}`}
                            </p>
                            {true !== undefined && (
                                <p className='success-body'>
                                    {`Creada el: ${formatedDate} a las ${time[4]}`}
                                </p>
                            )}
                        </div>
                    </div>
                </Container>
            </section>
            <PaltaFooter />
        </div>
    )
}

export default Success;