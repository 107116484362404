import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { motion } from 'framer-motion';
import {
  user,
  selectedWalletPay,
  selectedAmount,
  selectedFees,
} from '../../../store';
import { fiscalCreditFee } from '../../../store/fiscal-credit-fee';
import { getRates } from '../../../core/api-hooks/dollar-utils/paltaDollars';
import PaltaModalSpinner from '@components/PaltaModalSpinner';
import PaltaBreadcrumbs from '../../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import * as ROUTES from '../../../constants/routes';
import './amount.css';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import { sanitizeAmount } from '../../service-payments/utils';
import { getFiscalCreditFees } from '../../../core/api-hooks/fiscal-credit-fees/get';
import { ModalDesiredCurrency } from '../../../components/ModalDesiredCurrency/ModalDesiredCurrency';
import { makeSwalError } from '../../../helpers/swalError';

export const Amount = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [balance, setBalance] = useState(0);
  const [wallet, setWallet] = useState();
  const userData = useRecoilValue(user);
  const walletPay = useRecoilValue(selectedWalletPay);
  const [amount, setAmount] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [total, setTotal] = useState(state?.amount || 0);
  const history = useHistory();
  const setSelectedAmount = useSetRecoilState(selectedAmount);
  const setSelectedFees = useSetRecoilState(selectedFees);
  const [fiscalCreditFeeAmount, setFiscalCreditFeeAmount] = useState(0);
  const [maxFiscalCreditAvailableAmount, setMaxFiscalCreditAvailableAmount] =
    useState(0);
  const [desiredInputCurrency, setDesiredInputCurrency] = useState();
  const [dollarRates, setDollarRates] = useState();
	const [fiscalCreditFeeValue, setFiscalCreditFee] = useRecoilState(fiscalCreditFee);

  const [modalOpen, setModalOpen] = useState();
  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);
	
  useEffect(() => {
    if (userData && walletPay) {
      setModalOpen(walletPay.includes('dolar'));
      getRates().then((rates) =>  
        setDollarRates({
          bid: rates?.data?.data.bid,
          date: rates?.data?.data.rateDate,
        })
      );
      const selectedWallet = userData.wallets.find(
        (wallet) => wallet.currency === walletPay,
      );
      setWallet(selectedWallet);
      setBalance(selectedWallet.amount);

      setMaxFiscalCreditAvailableAmount(selectedWallet.amount / 1); //(fiscalCreditFee / 100) + 1);
    }
  }, [userData, walletPay]);

	useEffect(async () => {
		if (wallet && walletPay === 'fiscal_credit' && fiscalCreditFeeValue === undefined) {
			try {				
				const fee = (await getFiscalCreditFees(wallet._id)).data;
				setFiscalCreditFee(fee)
			} catch (error) {
				makeSwalError('Hubo un error')
			}
		}
	}, [wallet, userData])

  useEffect(() => {
    if (amount !== 0) {
      setTaxAmount(
        (Number(amount) * (userData?.taxPercentage / 100)).toFixed(2),
      );
    } else {
      setTaxAmount(0);
    }
  }, [amount, wallet]);

  useEffect(() => {
    if (amount) {
      const fcFeeAmount = (Number(amount) * Number(fiscalCreditFeeValue)) / 100;
      setFiscalCreditFeeAmount(fcFeeAmount);
      if (taxAmount && wallet?.currency !== 'fiscal_credit') {
        if (desiredInputCurrency === 'Peso') {
          setTotal(
            Number(Number(amount) / dollarRates.bid) + Number(taxAmount),
          );
        } else {
          setTotal(Number(amount) + Number(taxAmount));
        }
      } else if (wallet?.currency === 'fiscal_credit') {
        setTotal(Number(amount) + Number(fcFeeAmount));
      } else {
        setTotal(Number(amount));
      }
    }
  }, [amount, taxAmount]);

  useEffect(() => {
    if (wallet?.currency === 'fiscal_credit') {
      const totalWithFiscalFee = (
        Number(amount) + Number(fiscalCreditFeeAmount)
      ).toFixed(2);
      setIsValid(
        Number(total) !== 0 && Number(totalWithFiscalFee) <= Number(balance),
      );
    } else {
      if (
        (Number(total) !== 0 && Number(total) <= Number(balance)) ||
        (desiredInputCurrency === 'Peso' && Number(total) <= Number(balance))
      ) {
        setIsValid(true);
      } else if (desiredInputCurrency === 'Peso' && total > balance) {
        setIsValid(false);
      } else {
        setIsValid(false);
      }
    }
  }, [total, balance]);

  const onsubmit = useCallback(() => {
    setLoading(true);
    if (desiredInputCurrency === 'Peso') {
      setSelectedAmount(Number(amount) / dollarRates.bid);
    } else {
      setSelectedAmount(Number(amount));
    }

    setSelectedFees(taxAmount ? Number(taxAmount) : 0);
    setTimeout(() => {
      setLoading(false);
      history.push({
        pathname: ROUTES.PAYMENTS_SELECT_CONFIRM,
        state: { fiscalCreditFeeAmount, bid: dollarRates?.bid, dataQR: state?.dataQR },
      });
    }, 2000);
  }, [amount, history, taxAmount, fiscalCreditFeeAmount]);

  const isDollarWallet = (wallet) => {
    const response = !wallet?.currency.includes('dolar')
      ? '#3A4D9F'
      : desiredInputCurrency === 'Peso'
      ? '#3A4D9F'
      : '#85BB65';
    return response;
  };
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <PaltaNavBar />
      <PaltaBreadcrumbs
        name="Monto a enviar"
        link={ROUTES.PAYMENTS_SELECT_WALLET}
        variant="dark"
      />
      <motion.div
        initial={{ x: '100%', opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ type: 'spring', duration: 0.4, bounce: 0.2 }}
      >
        <ModalDesiredCurrency
					close={close}
					modalOpen={modalOpen}
					setDesiredInputCurrency={setDesiredInputCurrency}
				/>
        <section style={{ padding: '1rem 0px' }}>
          <Container>
            <Row className="d-flex justify-content-center text-center">
              <Col>
                <h5 className="m-0">Monedero</h5>
              </Col>
              <Col>
                <h5 className="m-0">Saldo</h5>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center text-center">
              <Col>
                <p className="m-0" style={{ textTransform: 'capitalize' }}>
                  {wallet && wallet.currencyLabel.replace('dolar', 'dólar')}
                </p>
              </Col>
              <Col>
                <p className="m-0">{`$ ${
                  balance && sanitizeAmount(balance)
                }`}</p>
              </Col>
            </Row>
            {wallet?.currency === 'fiscal_credit' && (
              <>
                <Row className="d-flex justify-content-center text-center mt-3">
                  <Col>
                    <h5 className="m-0"></h5>
                  </Col>
                  <Col>
                    <h5 className="m-0">Saldo Disponible</h5>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center text-center">
                  <Col>
                    <h5 className="m-0"></h5>
                  </Col>
                  <Col>
                    <p className="m-0">
                      $ {sanitizeAmount(maxFiscalCreditAvailableAmount)}
                    </p>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <div
                style={{
                  display: 'flex',
                  fontSize: '3rem',
                  alignItems: 'center',
                }}
              >
                <div style={{ flexBasis: '10%px' }}>
                  {!wallet?.currency.includes('dolar') ||
                  desiredInputCurrency === 'Peso' ? (
                    <div
                      style={{
                        display: 'flex',
                        fontSize: '4rem',
                        fontWeight: 'bold',
                        color: isDollarWallet(wallet),
                      }}
                    >
                      <div>$</div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        fontSize: '4rem',
                        fontWeight: 'bold',
                        color: isDollarWallet(wallet),
                      }}
                    >
                      <div>USDP</div>
                    </div>
                  )}
                </div>
                <div style={{ flexBasis: '90%px' }}>
                  <Form.Control
                    type="text"
                    inputMode="decimal"
                    step="any"
                    max={balance}
                    className="payment-amount"
                    style={{
                      color: isDollarWallet(wallet),
                    }}
                    disabled={state?.dataQR?.amount ? true : false}
                    autoComplete="off"
                    placeholder={ amount || "0"}
                    onChange={(e) => {
                      let amount = e.target.value.replace(/,/g, '.');
                      setAmount(e.target.value === '' ? '0' : amount);
                    }}
                  />
                </div>
              </div>
              <Col md={{ span: 6, offset: 3 }}>
                <div className="data-wapper">
                  {wallet?.currency !== 'fiscal_credit' ? (
                    <>
                      <div className="data-amount-label">{`Impuesto ${sanitizeAmount(
                        userData?.taxPercentage,
                      )}%`}</div>
                      <div className="data-amount">{`$ ${taxAmount}`}</div>
                    </>
                  ) : (
                    <>
                      <div className="data-amount-label">Comisión {fiscalCreditFeeValue} %</div>
                      <div className="data-amount">
                        $ {sanitizeAmount(fiscalCreditFeeAmount)}
                      </div>
                    </>
                  )}
                </div>
                <div className="data-wapper">
                  <div className="data-amount-label">Total</div>
                  {(desiredInputCurrency !== 'Peso' && total > balance) ||
                  (desiredInputCurrency === 'Peso' && total > balance) ? (
                    wallet?.currency?.includes('dolar') ? (
                      <div className="data-amount text-danger">{`USDP ${sanitizeAmount(
                        total,
                      )}`}</div>
                    ) : (
                      <div className="data-amount text-danger">{`$ ${sanitizeAmount(
                        total,
                      )}`}</div>
                    )
                  ) : desiredInputCurrency === 'Peso' && dollarRates.bid ? (
                    <div className="data-amount">{`USDP ${sanitizeAmount(
                      total,
                    )}`}</div>
                  ) : wallet?.currency?.includes('dolar') ? (
                    <div className="data-amount">{`USDP ${sanitizeAmount(
                      total,
                    )}`}</div>
                  ) : (
                    <div className="data-amount">{`$ ${sanitizeAmount(
                      total,
                    )}`}</div>
                  )}
                </div>
                <div className="text-center">
                  <Button
                    style={{ marginLeft: '1em', background: '#3A4D9F' }}
                    size="lg"
                    disabled={!isValid}
                    onClick={onsubmit}
                  >
                    Enviar
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {loading && <PaltaModalSpinner show={loading} size="sm" />}
      </motion.div>
      <PaltaFooter />
    </div>
  );
};
