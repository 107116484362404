import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function NotificationDetail({ notification, handleNotificationClose }) {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        handleNotificationClose();
    };

    useEffect(() => {
        if (notification) {
            setShow(true);
        }

    }, [notification]);

    return (
        <>
            {
                (notification) ? (<>
                    <Modal show={show} onHide={handleClose} centered scrollable={true} >
                        <Modal.Header>
                            <Modal.Title>{notification.notification.title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body  className='text-wrap text-break'>{notification.notification.body}</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>) : null
            }
        </>
    );
}