import React from 'react';
import paltaError from '../../assets/images/palta-error.png';
import './SeeFailure.css'

export const SeeFailure = ({errorMessage}) => {

  return (
    <>
      
      <div className="modal-item-detail mb-2 center-modal" style={{ background: "#EDEDED", borderRadius: "30px" }}>
        <p className="mt-2 ml-2 center-modal-content">
            <img src={paltaError} alt='palta-error' className='img-fluid'/>
            <h3>Ups! Algo salió mal.</h3>
            {errorMessage && <h6>{errorMessage === 'ENVÍA' ? 'Has superado el monto máximo de menores de edad para transferir' : 'El usuario al que intentas enviarle el dinero ha superado el monto máximo de menores de edad'}</h6>}
            {!errorMessage && <h6>Por favor intente nuevamente</h6>}
        </p>
        
      </div>
    </>
  )
}