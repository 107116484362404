import React, { Component } from 'react';
import { auth } from '@core/Firebase';
import { Container } from '@components/grid';
class signOut extends Component {
  componentDidMount() {
    auth
      .signOut()
      .then(() => {
        const webAuthn = window.localStorage.getItem('authn')
          ? JSON.parse(window.localStorage.getItem('authn'))
          : null;
        window.localStorage.clear();
        webAuthn &&
          window.localStorage.setItem('authn', JSON.stringify(webAuthn));
        window.location.href = '/';
      })
      .catch((error) => {
        console.log('Error', error);
      });
  }

  render() {
    return (
      <Container>
        <h2 className="title" hidden>
          Cerrar Sesión
        </h2>
      </Container>
    );
  }
}

export default signOut;
