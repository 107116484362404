import React, { useState, useCallback, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import logoPalta from '../../../../assets/images/palta-logo-500.svg';
import * as API from '@constants/api-staging';
import * as ROUTES from '@constants/routes';
import userHelper from '@helpers/user';
import Swal from 'sweetalert2';
import '../../../register-form/register.css';
import { useRecoilState, useRecoilValue } from 'recoil';
import { dataCommerceFormBene, dataFormCommerce, dataFormCommerceFinish, dataFormCommerceFinishBene } from '../../../../store';
import Axios from '../../../../core/Axios/Axios';
import PaltaModalSpinner from '../../../../components/PaltaModalSpinner';
const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

`;

const BoxContainer = styled.div`
  width: 340px;
  min-height: 624px;
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 0px 0px 4.7px rgba(15, 15, 15, 0.28);
  position: relative;
  overflow: hidden;
  padding: 0px 10px 0px 10px;
`;


const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

export const CommerceBeneMzaActPage2 = () => {
  const { id } = useParams();
  const datacommerceForm = useRecoilValue(dataCommerceFormBene);
  const [datacommerceFormFinish, setDatacommerceFormFinish] = useRecoilState(dataFormCommerceFinishBene);
  const datacommerce = useRecoilValue(dataFormCommerceFinishBene);
  const { register, handleSubmit, errors, formState, watch } = useForm({
    mode: 'onChange',
  });
  const commerceData = useRef({});
  commerceData.current = watch("commerceData", "");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [upLoadFile, setUpLoadFile] = useState({
    selectedFile: null
  }
  );
  const [fileSelect, setFileSelect] = useState(false);
  const [fileTooBig, setFileTooBig] = useState(false);
  let dataDefaultPep = datacommerce?.pep !== undefined ? datacommerce?.pep : false;
  let dataDefaultHolderOrProxy = datacommerce?.commerceData !== undefined ? datacommerce?.commerceData : true;

  const [statePep] = useState({ data: { checked: dataDefaultPep } });
  const [stateHolderOrProxy] = useState({ data: { checked: dataDefaultHolderOrProxy } });
  const onSubmit = useCallback(async (data) => {
    const sendData = {
      commerceData: data.commerceData === "true" ? true : false,
      impositiveCondition: data.impositiveCondition,
      pep: data.pep === "true" ? true : false,
      rubro: data.rubro,
    }
    setDatacommerceFormFinish(sendData);
    history.push(`${ROUTES.MENU_BENE_MZA_ACT_COMMERCE_PAGE_3}/${id}`);
  }, [datacommerceFormFinish]);
  const onFileChange = event => {
    if (event.target.files[0].size > 64000000) {
      setFileTooBig(true);
    } else {
      setFileTooBig(false);
    }
    setUpLoadFile({ selectedFile: event.target.files[0] });
  };
  const onFileUpload = () => {
    setLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.append("file", upLoadFile.selectedFile);
    bodyFormData.append("id", id);
    Axios.post(`${API.BASE_URL}/user/uploadfilegoogle`,
      bodyFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        setFileSelect(true);
        setLoading(true);
        Swal.fire({
          icon: 'success',
          title: `Archivo subido con exito `,
          padding: '2px',
          showConfirmButton: false,
          width: '300px',
          heightAuto: '200px',
          timer: 1500,
          customClass: {
            title: 'm-0 text-success',
            header: 'p-0',
          },
        });
        setLoading(false);
      })
      .catch(error => {
      });
  };
  if (datacommerceForm === undefined) {
    history.push(`${ROUTES.MENU_BENE_MZA_ACT_COMMERCE_PAGE_1}/${id}`)
  }
  return (
    <>
      <AppContainer>
        <BoxContainer>
          <InnerContainer>
            <img
              src={logoPalta}
              width="120"
              height="50"
              className="d-inline-block align-top position-logo-palta-form-user "
              alt="Palta logo"
            />
            <form
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="input-container">
                <div className="row d-flex justify-content-center mt-2">
                  <div className="col-12 mr-4 ml-4 mb-3 col-sm-12">
                    <div className="form-group  text-center mb-0">
                      <br />
                      <div className="input-container  pt-1 mb-3">
                        <select className="form-control  mt-4 mb-3" defaultValue={datacommerce?.rubro} name="rubro" ref={register({ required: 'Required' })}>
                          <option value="Aberturas\(Puertas Ventanas Portones\)">aberturas(puertas ventanas portones)</option>
                          <option value="accesorios y repuestos para automotores">accesorios y repuestos para automotores</option>
                          <option value="aceites y conservas">aceites y conservas</option>
                          <option value="agencias de viajes, turismo">agencias de viajes, turismo</option>
                          <option value="alarmas, seguridad">alarmas, seguridad</option>
                          <option value="alfombras, tapicerías, cortinas">alfombras, tapicerías, cortinas</option>
                          <option value="arte, galerías">arte, galerías</option>
                          <option value="artículos de limpieza">artículos de limpieza</option>
                          <option value="artículos del hogar">artículos del hogar</option>
                          <option value="autoservicios y minimarket">autoservicios y minimarket</option>
                          <option value="bazar">bazar</option>
                          <option value="bicicletas, rodados">bicicletas, rodados</option>
                          <option value="bijouterie, relojería">bijouterie, relojería</option>
                          <option value="blanco, mantelería">blanco, mantelería</option>
                          <option value="bodegas y restaurantes">bodegas y restaurantes</option>
                          <option value="bombonerías, confiterías y prod.afines">bombonerías, confiterías y prod.afines</option>
                          <option value="calefacción, plomería, aire acondicionado">calefacción, plomería, aire acondicionado
                          </option>
                          <option value="camping, caza, pesca, armería">camping, caza, pesca, armería</option>
                          <option value="carpinterías">carpinterías</option>
                          <option value="cementerio parque">cementerio parque</option>
                          <option value="cerrajerías">cerrajerías</option>
                          <option value="cines, teatros, espectáculos">cines, teatros, espectáculos</option>
                          <option value="colchonerías">colchonerías</option>
                          <option value="colegios, institutos, academias">colegios, institutos, academias</option>
                          <option value="combustibles">combustibles</option>
                          <option value="comercios de catálogos y vtas\.p\/menor">comercios de catálogos y vtas.p/menor</option>
                          <option value="compra de artículos usados">compra de artículos usados</option>
                          <option value="computación, venta y reparación">computación, venta y reparación</option>
                          <option value="concesionarios de auto">concesionarios de auto</option>
                          <option value="deportes">deportes</option>
                          <option value="dietéticas y herboristerías">dietéticas y herboristerías</option>
                          <option value="electrónica, reparaciones">electrónica, reparaciones</option>
                          <option value="eléctricidad, iluminación">eléctricidad, iluminación</option>
                          <option value="empresa de transportes">empresa de transportes</option>
                          <option value="entretenimientos">entretenimientos</option>
                          <option value="especialidades médicas">especialidades médicas</option>
                          <option value="estudios arquitectura, ingeniería">estudios arquitectura, ingeniería</option>
                          <option value="estudios contables">estudios contables</option>
                          <option value="estudios jurídicos">estudios jurídicos</option>
                          <option value="farmacias">farmacias</option>
                          <option value="ferreterías">ferreterías</option>
                          <option value="fintech">fintech</option>
                          <option value="fotografías, filmaciones">fotografías, filmaciones</option>
                          <option value="frigoríficos">frigoríficos</option>
                          <option value="gimnasios, clubes">gimnasios, clubes</option>
                          <option value="gomerías, venta de cubiertas">gomerías, venta de cubiertas</option>
                          <option value="guardapolvos, uniformes, ropa de trabajo">guardapolvos, uniformes, ropa de trabajo
                          </option>
                          <option value="heladerías">heladerías</option>
                          <option value="hospedaje\(hoteles, alquileres temporarios, \{etc\.\)">
                            hospedaje(hoteles, alquileres temporarios, etc.)</option>
                          <option value="importadoras">importadoras</option>
                          <option value="impresos, serigrafía, cartelería">impresos, serigrafía, cartelería</option>
                          <option value="indumentaria femenina">indumentaria femenina</option>
                          <option value="indumentaria futura mamá">indumentaria futura mamá</option>
                          <option value="indumentaria infantil">indumentaria infantil</option>
                          <option value="indumentaria masculina">indumentaria masculina</option>
                          <option value="indumentaria unisex">indumentaria unisex</option>
                          <option value="institutos de belleza integral">institutos de belleza integral</option>
                          <option value="instrumentos musicales">instrumentos musicales</option>
                          <option value="joyerías">joyerías</option>
                          <option value="jugueterías">jugueterías</option>
                          <option value="lanas, tejidos">lanas, tejidos</option>
                          <option value="lavandería">lavandería</option>
                          <option value="lencerías">lencerías</option>
                          <option value="librerías, papelerías">librerías, papelerías</option>
                          <option value="lubricentros">lubricentros</option>
                          <option value="marroquinerías, cueros, talabarterías">marroquinerías, cueros, talabarterías</option>
                          <option value="mat\. de construcción">mat. de construcción</option>
                          <option value="mercería">mercería</option>
                          <option value="metalúrgicas, herrería">metalúrgicas, herrería</option>
                          <option value="motos\(venta y reparación\)">motos(venta y reparación)</option>
                          <option value="mueblerías">mueblerías</option>
                          <option value="municipalidad">municipalidad</option>
                          <option value="máquinas industriales">máquinas industriales</option>
                          <option value="odontólogos">odontólogos</option>
                          <option value="ópticas">ópticas</option>
                          <option value="ortopedias">ortopedias</option>
                          <option value="otros">otros</option>
                          <option value="panaderías">panaderías</option>
                          <option value="peluquerías">peluquerías</option>
                          <option value="perfumerías, pañaleras">perfumerías, pañaleras</option>
                          <option value="pets shops - venta de alimentos para mascotas">pets shops - venta de alimentos para
                            mascotas</option>
                          <option value="pinturerías">pinturerías</option>
                          <option value="pizzerías, rotiserías">pizzerías, rotiserías</option>
                          <option value="pool, billares, juegos de salón">pool, billares, juegos de salón</option>
                          <option value="prepagas de salud">prepagas de salud</option>
                          <option value="bazar">publicidad, agencias</option>
                          <option value="refrigeración">refrigeración</option>
                          <option value="regalerías">regalerías</option>
                          <option value="reposterías, cotillón">reposterías, cotillón</option>
                          <option value="restaurantes, parrillas, bares">restaurantes, parrillas, bares</option>
                          <option value="sanatorios, hospitales">sanatorios, hospitales</option>
                          <option value="sanitarios, cerámicos">sanitarios, cerámicos</option>
                          <option value="santerías">santerías</option>
                          <option value="sederías">sederías</option>
                          <option value="seguros">seguros</option>
                          <option value="servicios de procesamiento de datos">servicios de procesamiento de datos</option>
                          <option value="servicios de televisión por cable">servicios de televisión por cable</option>
                          <option value="servicios fúnebre">servicios fúnebre</option>
                          <option value="servicios profesionales-consultoría">servicios profesionales-consultoría</option>
                          <option value="servicios y reparaciones para el hogar">servicios y reparaciones para el hogar</option>
                          <option value="servicios\(luz, gas, agua\)">servicios(luz, gas, agua)</option>
                          <option value="supermercados">supermercados</option>
                          <option value="taller mecánico">taller mecánico</option>
                          <option value="telefonía celular">telefonía celular</option>
                          <option value="transportes">transportes</option>
                          <option value="venta de diarios y revistas">venta de diarios y revistas</option>
                          <option value="venta de equipos g\.n\.c">venta de equipos g.n.c</option>
                          <option value="venta de madera">venta de madera</option>
                          <option value="verdulerías">verdulerías</option>
                          <option value="veterinarias y acuarios">veterinarias y acuarios</option>
                          <option value="vidrios, espejo, cristales">vidrios, espejo, cristales</option>
                          <option value="vinoteca, licorerías">vinoteca, licorerías</option>
                          <option value="viveros">viveros</option>
                          <option value="viviendas industrializadas">viviendas industrializadas</option>
                          <option value="zapaterías y calzados">zapaterías y calzados</option>

                        </select>
                        <label className="label-text-select pl-3 pr-3" htmlFor="name" >Rubro</label>

                        {errors.rubro && errors.rubro.type === 'required' && (
                          <p className="input-error">El campo es requerido</p>
                        )}
                      </div>
                      <div className="input-container  pt-1 mb-3">
                        <select className="form-control mt-4 mb-3" defaultValue={datacommerce?.impositiveCondition} name="impositiveCondition" ref={register({ required: 'Required' })}>
                          <option value="Monotributista">Monotributista</option>
                          <option value="Responsable Inscripto">Responsable Inscripto</option>
                          <option value="Exento Iva">Exento Iva</option>
                          <option value="Consumidor final">Consumidor Final</option>
                        </select>
                        <label className="label-text-select pl-3 pr-3" htmlFor="name" >Condicion impositiva</label>
                        {errors.impositiveCondition && errors.impositiveCondition.type === 'required' && (
                          <p className="input-error">El campo es requerido</p>
                        )}
                      </div>
                      <br />
                      <label className="text-center h2 mb-0 bold" style={{ fontSize: 18, width: "auto", height: "2rem" }} htmlFor="name" >
                        <a
                          target="_blank"
                          style={{ textDecoration: "underline" }}
                          rel="noopener noreferrer"
                          href="http://servicios.infoleg.gob.ar/infolegInternet/anexos/195000-199999/195785/norma.htm"
                        >
                          <p> ¿Sos persona expuesta políticamente?</p>
                        </a>
                      </label>
                      <br />
                      <label style={{ width: "auto", height: "1rem" }}>
                        <input
                          name="pep"
                          className="mr-2"
                          ref={register({ required: 'Required' })}
                          value={true}
                          defaultChecked={statePep.data.checked === true}
                          type="radio"
                        />
                        Si
                      </label>
                      <label style={{ width: "auto", height: "1rem" }} className="ml-2">
                        <input
                          className="mr-2"
                          name="pep"
                          ref={register({ required: 'Required' })}
                          value={false}
                          defaultChecked={statePep.data.checked === false}
                          type="radio"
                        />
                        No
                      </label>
                      {(errors.pep && errors.pep.type === 'required') && (
                        <p className="input-error mb-0">* El campo es requerido</p>
                      )}
                      <br />
                      <label className="text-center h2 mb-0 pt-3 bold mr-2" style={{ fontSize: 18, width: "auto", height: "2rem" }} htmlFor="name" >
                        ¿Soy?
                      </label>
                      <label style={{ width: "auto", height: "1rem" }} className="mt-2">
                        <input
                          name="commerceData"
                          className="mr-2"
                          ref={register({ required: 'Required' })}
                          value={true}
                          defaultChecked={stateHolderOrProxy.data.checked === true}
                          type="radio"
                        />
                        Titular
                      </label>
                      <label style={{ width: "auto", height: "1rem" }} className="ml-2">
                        <input
                          className="mr-2"
                          name="commerceData"
                          ref={register({ required: 'Required' })}
                          value={false}
                          defaultChecked={stateHolderOrProxy.data.checked === false}
                          type="radio"
                        />
                        Apoderado
                      </label>
                      {(errors.pep && errors.pep.type === 'required') && (
                        <p className="input-error mb-0">* El campo es requerido</p>
                      )}

                      <form encType="multipart/form-data" method="post">
                        {commerceData.current === "false" ? (

                          <div className="d-flex justify-content-center align-items-center mt-2">

                            <div className="form-group  text-center mb-0">

                              <input
                                type="file"
                                onChange={onFileChange}
                                id="inp"
                                className="form-control-file p-1"
                                style={{ fontSize: 15 }}
                                multiple
                              />

                              <button
                                type="button"
                                className="btn active btn-md btn-fullwidthe btnColor text-white mt-3"
                                style={{ fontSize: 18, borderRadius: 5, fontFamily: "inherit", textTransform: "none" }}
                                onClick={onFileUpload}
                                disabled={fileTooBig || !upLoadFile.selectedFile}
                              >
                                Subir Archivo
                              </button>
                              {
                                fileTooBig &&
                                <div
                                  className="alert alert-danger"
                                  role="alert"
                                  style={{ position: 'absolute', left: '70px', top: '417px' }}
                                >
                                  El archivo es muy grande
                                </div>
                              }

                            </div>

                          </div>
                        ) : null}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {commerceData.current === "false" ? (
                <div className="position-button-align-bene-mza-act-commerce-apod d-flex justify-content-center align-items-center mt-2">

                  <Button
                    type="submit"
                    size="md"
                    className=" active btn-block btn-fullwidthe bg-danger mt-0"
                    onClick={() => history.push(`${ROUTES.MENU_BENE_MZA_ACT_COMMERCE_PAGE_1}/${id}`)}
                    style={{ fontSize: 18, borderRadius: 5, fontFamily: "inherit", textTransform: "none" }}
                    data-cy="button-ingresar"
                  >
                    Volver
                  </Button>
                  <Button
                    type="submit"
                    size="md"
                    className="active btn-block btn-fullwidthe btnColor mt-0 ml-2"

                    disabled={!formState.isValid || datacommerceForm === undefined || fileSelect === false}
                    style={{ fontSize: 18, borderRadius: 5, fontFamily: "inherit", textTransform: "none" }}
                    data-cy="button-ingresar"
                  >
                    Siguiente
                  </Button>
                </div>
              ) : (
                <div className="position-button-align-bene-mza-act-commerce d-flex justify-content-center align-items-center mt-2">

                  <Button
                    type="submit"
                    size="md"
                    className=" active btn-block btn-fullwidthe bg-danger mt-0"
                    onClick={() => history.push(`${ROUTES.MENU_BENE_MZA_ACT_COMMERCE_PAGE_1}/${id}`)}
                    style={{ fontSize: 18, borderRadius: 5, fontFamily: "inherit", textTransform: "none" }}
                    data-cy="button-ingresar"
                  >
                    Volver
                  </Button>
                  <Button
                    type="submit"
                    size="md"
                    className="active btn-block btn-fullwidthe btnColor mt-0 ml-2"
                    disabled={!formState.isValid || datacommerceForm === undefined}
                    style={{ fontSize: 18, borderRadius: 5, fontFamily: "inherit", textTransform: "none" }}
                    data-cy="button-ingresar"
                  >
                    Siguiente
                  </Button>
                </div>
              )}
              {loading && (
                <PaltaModalSpinner show={loading} size="sm" />
              )}
            </form>
          </InnerContainer>

        </BoxContainer>
      </AppContainer>

    </>
  );
};

