import React, { useState } from 'react';
import { Container, Wrapper } from '@components/grid';
import PaltaBreadcrumbs from '../../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import '../ServicePayments.css'
import imgMovistar from '../../../assets/images/movistar.svg';
import imgClaro from '../../../assets/images/claro.svg';
import imgPersonal from '../../../assets/images/personal.png';
import { useGetOpcion as getOpcion } from '../../../core/api-hooks/captar-pagos/useGetOpcion'
import { useEffect } from 'react';
import PaltaModalSpinner from '../../../components/PaltaModalSpinner';
import { fireSwal } from '../../../components/utils/swalUtils';
import { useHistory } from 'react-router';
import * as ROUTES from '../../../constants/routes';

const PhoneTopUp = () => {
    const [loading, setLoading] = useState(false);
    const [company, setCompany] = useState('');
    const [selectedAmount, setSelectedAmount] = useState(undefined);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isValidParameters, setIsValidParameters] = useState(false);
    const [opcionBody, setopcionBody] = useState({
        tipo: "sinFactura",
        descripcion: undefined,
        codeProducto: undefined,
        ordinal: 2,
        parametros: []
    })
    const [payPostBody, setPayPostBody] = useState({
        factura: [
            {
                tipo: "recarga",
                idTransaccion: undefined,
                importeVariable: "false",
                descripcion: undefined,
                importe: undefined,
                codeProducto: undefined,
                idTrxComercio: undefined,
                tipoPago: "E",
                idPago: undefined,
            }
        ],
        currency: undefined
    });

    const history = useHistory();
    const codeClaro = '94950010';
    const codeMovistar = '94950004';
    const codePersonal = '94950009';
    const userData = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        setIsValidParameters(selectedAmount && phoneNumber && company ? true : false)
    }, [selectedAmount, phoneNumber, company])

    const handleCompany = (company) => {
        setCompany(company);
    }

    const handleAmount = (e) => {
        const value = e.target.innerText.split('$')[1];
        setSelectedAmount(value);
    }

    const handlePhoneInput = (e) => {
        const value = e.target.value;
        setPhoneNumber(value);
    }

    const handleNextButton = async () => {

        const body = {
            ...opcionBody,
            descripcion: company === 'movistar' ? '111 Movistar Recarga' : company === 'claro' ? '111 Claro Recarga' : '111 Personal Recarga',
            codeProducto: company === 'movistar' ? codeMovistar : company === 'claro' ? codeClaro : codePersonal,
            parametros: [phoneNumber, selectedAmount + '.00']
        };
        setLoading(true);
        const opcionResponse = await getOpcion(body);
        setLoading(false)
        const response = opcionResponse.data.data.listaOpcion[0].opcion[0];
        if (opcionResponse.data.data.code === 200) {
            const payBody = {
                ...payPostBody,
                factura: [{
                    tipo: "recarga",
                    importeVariable: "false",
                    tipoPago: "E",
                    idTransaccion: response.idTransaccion[0],
                    descripcion: response.descripcion[0],
                    importe: response.importe[0],
                    codeProducto: response.idProducto[0],
                    idTrxComercio: userData.cuit,
                    idPago: userData.cuit
                }]
            }
            history.push({
                pathname: ROUTES.SERVICE_PAYMENTS_WALLETS,
                state: { datosTransaccion: payBody, businessName: body.descripcion }
            })
        } else {
            fireSwal('hubo un error')
        }
    }

    const possibleAmounts = ['10', '20', '50', '100', '150', '200', '300', '400', '500', '600', '700', '1000']
    return (
        <>
            <>
                <PaltaNavBar className="light" />
                <PaltaBreadcrumbs
                name="Inicio"
                link={ROUTES.SERVICE_PAYMENTS_PHONE}
                variant="dark"
                />
                <Container>
                    <Wrapper>
                        <h4>Ingrese número de celular</h4>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <input
                                className='form-control'
                                type='text'
                                inputMode="decimal"
                                style={{ border: '2px solid ' }}
                                onChange={handlePhoneInput}
                                value={phoneNumber}
                            />
                            <p
                                style={{ fontSize: '0.9em' }}
                            >
                                Ingrese su celular sin 0 y sin 15</p>
                        </div>
                        <h4>Seleccione Compañía</h4>
                        <div className='topup-container'>


                            <div className='company-topup__container'>
                                <div
                                    className={`phone__container ${company === 'claro' && 'company-shadow'}`}
                                    onClick={() => {
                                        handleCompany('claro')
                                    }}
                                >
                                    <div className='phone-image__container'>
                                        <img className='phone-image__img' src={imgClaro} />
                                    </div>
                                    <div>
                                        <h3></h3>
                                    </div>
                                </div>
                                <div
                                    className={`phone__container ${company === 'movistar' && 'company-shadow'}`}
                                    onClick={() => {
                                        handleCompany('movistar')
                                    }}

                                >
                                    <div className='phone-image__container'>
                                        <img className='phone-image__img' src={imgMovistar} />
                                    </div>
                                    <div>
                                        <h3></h3>
                                    </div>
                                </div>
                                <div
                                    className={`phone__container ${company === 'personal' && 'company-shadow'}`}
                                    onClick={() => {
                                        handleCompany('personal')
                                    }}
                                >
                                    <div className='phone-image__container phone-personal'>
                                        <img style={{ marginTop: '28px', maxWidth: '90%', padding: '7px 7px', borderRadius: '7px', backgroundColor: 'black', filter: 'invert(0%) sepia(4%) saturate(7500%) hue-rotate(169deg) brightness(100%) contrast(103%)' }} className='phone-image__img' src={imgPersonal} />
                                    </div>
                                    <div>
                                        <h3></h3>
                                    </div>
                                </div>
                            </div>
                            <h4>Seleccione Monto</h4>
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                marginTop: '0.2em',
                                fontSize: '0.85rem',
                                fontFamily: "'ProductSans', sans-serif",
                                fontWeight: 'bold',
                            }}>
                                {
                                    possibleAmounts.map(amount => {
                                        return (
                                            <div
                                                className='phone-amount__container'
                                                style={{
                                                    backgroundColor: selectedAmount === amount && '#5e9f72',
                                                    padding: '20px 0'
                                                }}
                                                onClick={handleAmount}
                                            >
                                                ${amount}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div style={{ textAlign: 'center', fontSize: '1.2em' }}>
                                {
                                    <button
                                        className='button phone-button'
                                        disabled={!isValidParameters}
                                        onClick={handleNextButton}
                                        style={{ fontFamily: "'ProductSans', sans-serif", fontWeight: 'bold', fontSize: '0.85rem' }}
                                    >
                                        Continuar
                                    </button>
                                }
                            </div>
                        </div>
                    </Wrapper>
                </Container>
                <PaltaFooter />
                <PaltaModalSpinner show={loading} size="sm" />


            </>
        </>
    );
};

export default PhoneTopUp;
