import { motion } from "framer-motion";

const Backdrop = ({ children, onClick }) => {
 
  return (
    <motion.div
      onClick={onClick}
      className="backdrop"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
          backgroundColor: 'hsla(187, 52%, 0%, 0.84)',
          position: 'absolute',
          top: '0',
          left: '0',
          height: '100%',
          width: '100%',
          zIndex: '99',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
      }}
    >
      {children}
    </motion.div>
  );
};

export default Backdrop;