import React from 'react';
import PaltaBreadcrumbs from '../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import { Container, Row, Col } from 'react-bootstrap';
import * as ROUTES from '../../constants/routes';
import { BASE_URL } from '../../constants/api-staging';
import { useRecoilValue } from 'recoil';
import { useState } from 'react';
import PaltaModalSpinner from '../../components/PaltaModalSpinner';
import { motion } from 'framer-motion';
import axios from '../../core/Axios/Axios';
import Swal from 'sweetalert2';
import { vibrate } from '../../helpers/app';
import { useEffect } from 'react';
import styled from 'styled-components';
import { user } from '../../store';
import { useHistory } from 'react-router-dom';

const List = styled.div`
  padding: 0;
  max-height: 300px;
  margin-bottom: 10px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
`;

export const MassiveQr = () => {
  const [file, setFile] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [previousDocuments, setPreviousDocuments] = useState(undefined);
  const [documentSelected, setDocumentSelected] = useState(undefined);
  const userData = useRecoilValue(user);
  const history = useHistory();

  const getPreviousDocuments = async () => {
    const response = await axios.get(
      `${BASE_URL}/qrtres/getAllQRCsv/${userData._id}`,
      {
        headers: {
          Authorization: window.localStorage.getItem('token'),
        },
      },
    );
    setPreviousDocuments(response.data.data);
  };

  useEffect(async () => {
    try {
      setLoading(true);
      await getPreviousDocuments();
    } catch (error) {
      Swal.fire(
        'Error al obtener los documentos previos',
        'Por favor, intente nuevamente',
        'error',
      ).then(() => {
        history.push(ROUTES.ACCOUNT);
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const createNewCsv = async (formData) => {
    const response = await axios({
      method: 'post',
      url: `${BASE_URL}/qrtres/QRMassiveMunicipalities`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: window.localStorage.getItem('token'),
      },
    });
    return response;
  };

  const downloadDocument = (data, name) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
  };

  const createDocumentError = async (errorData) => {
    const response = await axios.post(
      `${BASE_URL}/qrtres/QRMassiveMunicipalitiesIncorrect`,
      {
        incorrectData: errorData,
      },
      {
        headers: {
          Authorization:window.localStorage.getItem('token'),
        },
      },
    );
    return response.data;
  };

  const getDocumentSelected = async () => {
    const response = await axios.get(
      `${BASE_URL}/qrtres/getOneQRCsv/${documentSelected._id}`,
      {
        headers: {
          Authorization: window.localStorage.getItem('token'),
        },
      },
    );
    return response;
  };

  const getCsvDocumentSelected = async (e) => {
    try {
      setLoading(true);
      const response = await getDocumentSelected();
      downloadDocument(response.data, 'QrMasivo.csv');
      Swal.fire(`${documentSelected.name} descargado con exito`, '', 'success');
    } catch (e) {
      Swal.fire(
        'Error al descargar el documento',
        'Por favor, intente nuevamente',
        'error',
      );
    } finally {
      setLoading(false);
    }
  };

  const formater = (string, maxLength) => {
    let formated =
      string.length > maxLength
        ? `${string.slice(0, maxLength - 3)}...`
        : string;
    return formated;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      vibrate(15);
      setLoading(true);
      const formData = new FormData();
      formData.append('file', file);
      const response = await createNewCsv(formData);
      downloadDocument(response.data, 'QrMasivo.csv');
      Swal.fire('QRs generados con éxito!', '', 'success');
      await getPreviousDocuments();
    } catch (error) {
      if (error.response) {
        if (error.response.data?.code === 'EMPTY-FILE') {
          Swal.fire(
            'Error al generar los QRs',
            'El archivo se encuentra vacío.',
            'error',
          );
        } else if (error.response.data?.code === 'INCORRECT') {
          Swal.fire(
            'Error, algunos campos contienen errores',
            'A continuación se descargará un archivo detallando los mismos',
            'warning',
          );
          try {
            const errorData = await createDocumentError(
              error.response.data?.data,
            );
            downloadDocument(errorData, 'CamposConErrores.csv');
          } catch {
            Swal.fire(
              'Error, no hemos podido verificar los campos con errores',
              'Por favor, intente nuevamente.',
              'error',
            );
          }
        } else {
          Swal.fire(
            'Ha ocurrido un error',
            'Por favor, intente nuevamente.',
            'error',
          );
        }
      } else {
        Swal.fire(
          'Ha ocurrido un error',
          'Por favor, intente nuevamente.',
          'error',
        );
      }
    } finally {
      setLoading(false);
    }
  };
  const handleInputChange = ({ target }) => {
    if (target.files && target.files.length) {
      const file = target.files[0];
      setFile(file);
    } else {
      setFile(null);
    }
  };
  return (
    <div style={{ backgroundColor: '#fff' }}>
      {loading && <PaltaModalSpinner show={loading} size="sm" />}
      <PaltaNavBar />
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ type: 'spring', duration: 0.5, bounce: 0.3 }}
      >
        <PaltaBreadcrumbs name="Mi Cuenta" link={ROUTES.ACCOUNT} variant="dark"/>
        <section style={{ padding: '3rem 0px' }}>
          <Container>
            <Row className="justify-content-center align-items-center d-flex">
              <Col>
                <h5
                  className="text-center"
                  style={{ color: '#3A4D9F' }}
                >{`Alta QR Masivo`}</h5>
              </Col>
            </Row>
            <Row className="justify-content-center align-items-center d-flex">
              <Col>
                <div class="panel-body">
                  <List class="list-group">
                    {previousDocuments &&
                      previousDocuments.length &&
                      previousDocuments.map((document) => {
                        return (
                          <li
                            class="list-group-item list-group-item-action"
                            key={document._id}
                            onClick={(e) => setDocumentSelected(document)}
                          >
                            {formater(document.name, 20)}
                          </li>
                        );
                      })}
                  </List>
                  {documentSelected && (
                    <div className="justify-content-center align-items-center d-flex flex-column mt-3">
                      <button
                        className="btn text-white rounded-pill mb-3 btnColor"
                        onClick={(e) => getCsvDocumentSelected(e)}
                      >
                        Descargar "{formater(documentSelected.name, 16)}"
                      </button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col className="justify-content-center d-flex">
                <form
                  className="justify-content-center d-flex flex-column"
                  onSubmit={handleSubmit}
                >
                  <div class="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="inputGroupFile02"
                      accept="text/csv"
                      name="file"
                      onChange={handleInputChange}
                    />
                    <label className="custom-file-label" for="inputGroupFile02">
                      {file ? file.name : 'Elija un archivo'}
                    </label>
                  </div>
                  <div className="justify-content-center align-items-center d-flex flex-column mt-3">
                    <button
                      className="btn text-white rounded-pill mb-5 btnColor"
                      disabled={file ? false : true}
                    >
                      Cargar Documento
                    </button>
                  </div>
                </form>
              </Col>
            </Row>
          </Container>
        </section>
      </motion.div>
    </div>
  );
};
