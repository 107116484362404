import axios from '../../Axios/Axios';
import * as API from '../../../constants/api-staging';

export const getBarra = async (barcode) => {
  const token = localStorage.getItem('token').replace(/"/g, '');
  const response = await axios.get(
    `${API.BASE_URL}/captarpagos/getBarra/${barcode}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
    },
  );
  return response;
};
