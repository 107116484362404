import React, { useEffect } from 'react';
import { useState } from 'react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { CreatePdf } from '../create-pdf/CreatePdf';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import './seeTransaction.css';
import { sanitizeAmount } from '../../views/service-payments/utils';
export const SeeTransaction = ({ item, commission, escapeRegExp,userData}) => {

  
  const [ticketPf, setTicketPf] = useState(undefined);
  const [lote, setLote] = useState(undefined);
  const [ticket, setTicket] = useState(undefined);
  const [cupon, setCupon] = useState(undefined);
  

  useEffect(() =>{
    if(item.payService){
    setTicketPf(item.payService.ticketPF)
    setLote(item.payService.ticketPF?.substr(0,6))
    setTicket(item.payService.ticketPF?.substr(9,21).trim())
    setCupon(item.payService.ticketPF?.substr(item.payService.ticketPF.length-4,4))
    }
  },[item.payService])
  
  const year = new Date().getFullYear();

  const renderDestiny = (item) => {
    if(item.type !== 'PAGO SERVICIO'){
    switch (item._userToId.role) {
      case 'commerce':
      case 'branch':
      case 'cash':
        return <p className="mt-2 mr-2">{escapeRegExp(`${item._userToId.fantasyName}`)}</p>;
      default:
        return (
          <p className="mt-2 mr-2">
            {item._userToId.name} {item._userToId.lastname}
          </p>
        );
    }
  }else{
    return (<p className="mt-2 mr-2">
               {item.payService.description} 
          </p>)
  }
  };

  const renderOrigin = (item) => {
    switch (item._userFromId?.role) {
      case 'commerce':
      case 'branch':
      case 'cash':
        return <p className="mt-2 mr-2">{escapeRegExp(`${item._userFromId?.fantasyName}`)}</p>;
      default:
        return (
          <p className="mt-2 mr-2">
            {item._userFromId?.name} {item._userFromId?.lastname}
          </p>
        );
    }
  };
  const generatePdfDocument = async (item, commission) => {
    const blob = await pdf((
      <CreatePdf
        title='My PDF'
        data={item} commission={commission}
        escapeRegExp={escapeRegExp}
        userData={userData}
        lote={lote}
        ticket={ticket}
        cupon={cupon}

      />
    )).toBlob();
    saveAs(blob, "Transaccion.pdf");
  };
  return (
    <>

      <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
        <p className="mt-2 ml-2">Monto de la transacción: </p>
        <p className="mt-2 mr-2">${sanitizeAmount(item.amount)}</p>
      </div>
      <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
        <p className="mt-2 ml-2">Monedero: </p>
        <p className="mt-2  mr-2">{item.currencyLabel}</p>
      </div>
      <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
        <p className="mt-2 ml-2">Fecha: </p>
        <p className="mt-2  mr-2">
          {format(new Date(item.createdAt), 'yyyy') ===
            year.toString()
            ? format(
              new Date(item.createdAt),
              'dd-MMMM-yyy, HH:mm',
              { awareOfUnicodeTokens: true, locale: es },
            )
            : format(
              new Date(item.createdAt),
              'dd-MMMM-yyy, HH:mm',
              { awareOfUnicodeTokens: true, locale: es },
            )}

        </p>
      </div>

      <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
        <p className="mt-2 ml-2">Cuenta origen:</p>
        {renderOrigin(item)}
      </div>
      {/* <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
        <p className="mt-2 ml-2">Email: </p>
        <p className="mt-2  mr-2">{item._userFromId.email}</p>
      </div> */}
      <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
        <p className="mt-2 ml-2">{item.type === 'PAGO SERVICIO' ? 'Servicio:' : 'Destinatario:'}</p>
        {renderDestiny(item)}
      </div>
      {item.type === 'PAGO SERVICIO' && 
      (<div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
        <p className="mt-2 ml-2">N° de ticket: </p>
      <p className="mt-2  mr-2">{item.payService.ticketCP}</p>
    </div>)}
    {item.type === 'PAGO SERVICIO' && (<>
      
      <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
          <p className="mt-2 ml-2">Lote-cupon:</p>
          <p className="mt-2  mr-2">{`${lote}-${cupon}`}</p>
      </div>
    
      <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
          <p className="mt-2 ml-2">Ticket pago fácil:</p>
          <p className="mt-2  mr-2">{ticket}</p>
      </div>
      </>)}
      {userData._id !== item._userFromId._id && commission !== undefined ? (
        commission.commission === true ? (

          <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
            <p className="mt-2 ml-2">Comisión: {commission.commissionPercentage} % </p>
            <p className="mt-2  mr-2"> $ {commission.amount}</p>
          </div>
        ) : null
      ) : null}
      {item.transactionCode !== undefined ? (
        <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
          <p className="mt-2 ml-2">N° operación: </p>
          <p className="mt-2 mr-2">{item.transactionCode}</p>
        </div>
      ) : null}
      {item._transactionOriginId?.transactionCode !== undefined ? (
        <div className="modal-item-detail mb-2" style={{ background: "#EDEDED", borderRadius: "30px" }}>
          <p className="mt-2 ml-2">N° transacción origen: </p>
          <p className="mt-2 mr-2">{item._transactionOriginId?.transactionCode}</p>
        </div>
      ) : null}

      <button className="btn float-right text-white rounded-pill mb-3 btnColor" onClick={() => generatePdfDocument(item, commission)}>
        <span className="icon-download ">
          <i className="material-icons">file_download</i>
        </span>
        Descargar PDF
      </button>

    </>
  )
}