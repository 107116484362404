import axios from '../../Axios/Axios';
import { fireSwal } from '../../../components/utils/swalUtils';
import Swal from 'sweetalert2';
import * as API from '../../../constants/api-staging';

export const useGetOpcion = async (body) => {
  const token = localStorage.getItem('token').replace(/"/g, '');
  try {
    const response = await axios.post(
      `${API.BASE_URL}/captarpagos/postOpcion`,
      body,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    );
    return response;
  } catch (error) {
    const statusCode = error.message.slice(error.message.length - 4);
    if(Number(statusCode) === 400){
    Swal.fire({
      icon: 'info',
      title: 'Hubo un error al ingresar los datos o no posee deudas.',
      footer: 'Por favor, intente nuevamente.',
      padding: "2px",
      showConfirmButton: true,
      width: "400px",
      heightAuto: "200px",
      buttonsStyling : 'false',
    });
    }else{
      fireSwal('Hubo un error', 2000,'error','Por favor, intente nuevamente.');
    }
    // setTimeout(() => {
    //   window.location = '/dashboard';
    // }, 100);
    // return {error: true}
  }
};
