import * as API from '../../constants/api-staging';
//TODO verificar donde se esta llamando este componente y cambiarlo por el nuevo fetcher
const fetchApi = ( method, context, urlPath, body ) => {
    let headers = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Access-Token": context.accessToken,
        "Token-Type": "Bearer",
        "Client": context.client,
        "Expiry": context.expirySession,
        "Uid": context.user.uid,
        "Host": "",
        "Cookie": "",
    }
    if ( method === "GET" ) {
        return fetch(`${API.URL}${urlPath}`, {
                method: method,
                cache: "no-cache",
                headers: {
                    "Content-Type": " application/json",
                    "Access-Token": context.accessToken,
                    "Token-Type": " Bearer",
                    "Client": context.client,
                    "Expiry": context.expirySession,
                    "Uid": context.user.uid,
                    "Host": "",
                    "Cookie": "",
                },
            })
        .then(res => {
            refreshToken(context)
            // .then(data => console.log("refres-token: ", data.success));
            if (!res.ok) {
                if (res.status === 401) console.log(`Error de autenticación ${res.status}`);
                if (res.status > 401 ) console.log(`Error en el servidor: Error ${res.status}`);
                throw res.status;
            } else {
                return res.json();
            }
        })
    } else {
        return fetch(`${API.URL}${urlPath}`, {
                method: method,
                headers: headers,
                cache: "no-cache",
                body: JSON.stringify(body)
            })
        .then(res => {
            if (!res.ok) {
                throw res.status;
            } else {
                if (res.headers.has('access-token')) context.handleAccessToken(res.headers.get('access-token'));
                if (res.headers.has('client')) context.handleClient(res.headers.get('client'));
                if (res.headers.has('expiry')) context.handleExpirySession(res.headers.get('expiry'));
                return res.json();
            }
        })
    }
}

const refreshToken = context => {
    let headers = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Access-Token": context.accessToken,
        "Token-Type": "Bearer",
        "Client": context.client,
        "Expiry": context.expirySession,
        "Uid": context.user.uid,
        "Host": "",
        "Cookie": "",
    }
    // console.log(`Refresh Token headers:`, headers);
    return (fetch(`${API.URL}${API.REFRESH_TOKEN}`, {
            headers,
            cache: "no-cache",
        })
        .then(res => {
            // console.log("access-token refreshToken: ", res.headers.get('Access-Token'));
            if (!res.ok) {
                throw res.status;
            } else {
                if (res.headers.has('access-token')) context.handleAccessToken(res.headers.get('access-token'));
                if (res.headers.has('client')) context.handleClient(res.headers.get('client'));
                if (res.headers.has('expiry')) context.handleExpirySession(res.headers.get('expiry'));
                return res.json();
            }
        }))
}

export default fetchApi;