import Backdrop from "./Backdrop";

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 35,
      stiffness: 600,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};


const FramerModal = ({ handleClose, setState, children }) => {

  return (
    <Backdrop onClick={() => {}}>
      {children}
    </Backdrop>
  );
};


export default FramerModal;