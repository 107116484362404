import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { Body } from '@components/grid';
import { wording } from '../../store';
import PrivateRoute from '../../core/PrivateRoute';
import SignOut from '../SignOut';
import Dashboard from '../Dashboard';
import Payments from '../Payments';
import PaymentsPay from '../PaymentsPay';
import LoansPay from '../LoansPay';
import Account from '../Account';
import Profile from '../Profile';
import Resume from '../Resume';
import Exportcsv from '../Exportcsv';
import CashIn from '../CashIn';
import CashInMP from '../CashInMP';
import CashInCB from '../CashInCB';
import QRCode from '../qr-generator';
import QRReader from '../qr-reader';
import ItemView from '../ItemView';
import { Select } from '../Payments/select';
import { SelectWallet } from '../Payments/select-wallet';
import { Amount } from '../Payments/amount';
import { Confirm } from '../Payments/confirm';
import { Success } from '../Payments/success';
import { CashOut } from '../cash-out';
import { CbuCvu } from '../cash-out/cbu-cvu';
import { ValidateCbuCvu } from '../cash-out/validate-cbu-cvu';
import { CashOutAmount } from '../cash-out/amount';
import { CashOutSuccess } from '../cash-out/success';
import '../../core/Intercom/intercom';
import * as ROUTES from '../../constants/routes';

import '../../assets/ReactToastify.css';
import { auth } from '@core/Firebase';
import { AccountBox } from '../../components/acount-box';
import userHelper from '@helpers/user';
import jwt_decode from 'jwt-decode';
import { ConfirmCashout } from '../cash-out/confirm';
import { Register } from '../register-form';
import { Palta } from '../Palta';
import { SuccessRegister } from '../register-form/register-success/success';
import { RegisterUser } from '../register-form/register-user-commerce/registerUser';
import { UserRegister } from '../register-form/register-user/registerUser';
import { UserBeneMzaAct } from '../register-form/menu-bene-mza-act/user-bene-mza-act/userBeneMzaAct';
import { RegisterCommercePage1 } from '../register-form/register-commerce/commerce';
import { RegisterCommercePage2 } from '../register-form/register-commerce/commercepage2';
import { MenuBeneMzaAct } from '../register-form/menu-bene-mza-act/menuBeneMzaAct';
import { CommerceBeneMzaActPage1 } from '../register-form/menu-bene-mza-act/commerce-bene-mza-act/commerceBeneMzaActPage1';
import { CommerceBeneMzaActPage2 } from '../register-form/menu-bene-mza-act/commerce-bene-mza-act/commerceBeneMzaActPage2';
import { ValidPhone } from '../register-form/valid-phone/validPhone';
import { CommerceBeneMzaActPage3 } from '../register-form/menu-bene-mza-act/commerce-bene-mza-act/commerceBeneMzaActPage3';
import { UserRegisterValidIdentity } from '../register-form/register-user/registerUserValidIdentity';
import { CommerceRegisterValidIdentity } from '../register-form/register-commerce/registerCommerceValidIdentity';
import { CommerceBeneMzaActRegisterValidIdentity } from '../register-form/menu-bene-mza-act/commerce-bene-mza-act/registerCommerceBeneMzaActValidIdentity';
import { UserBeneMzaActRegisterValidIdentity } from '../register-form/menu-bene-mza-act/user-bene-mza-act/registerUserBeneMzaActValidIdentity';
import { useUserValidateId } from '../../core/api-hooks/use-validation-id-user/get';
import { RegisterFinish } from '../register-form/register-finish/finish';
import { SearchShops } from '../SearchShops/SearchShops';
import { MassiveQr } from '../MassiveQr/MassiveQr';
import ServicePayments from '../service-payments/ServicePayments';
import ServicePayments1 from '../service-payments/ServicePayments1';
import PhoneTopUp from '../service-payments/phone/PhoneTopUp';
import GetOpcion1 from '../service-payments/GetOpcion1';
import GetOpcion2 from '../service-payments/GetOpcion2';
import GetOpcion3 from '../service-payments/GetOpcion3';
import GetOpcion4 from '../service-payments/GetOpcion4';
import ServicePaymentWallets from '../service-payments/ServicePaymentWallets';
import PaymentCashout from '../service-payments/PaymentCashout';
import ServicePaymentSuccess from '../service-payments/ServicePaymentSuccess';
import ChooseInput from '../service-payments/ChooseInput';
import BarcodeScanner from '../service-payments/BarcodeScanner';
import DollarConversion from '../Dollar/DollarConversion';
import TransactionDetails from '../Dollar/TransactionDetails';
import DollarSuccess from '../Dollar/DollarSuccess';
import NotificationPanel from '../../components/NotificationPanel';
import useFirebaseMessagingHandler from '../../hooks/useFirebaseMessagingHandler';


const App = () => {
  const [{ data: dataValid, error: errorData }, getUserValidateId] =
    useUserValidateId();
  const { screens } = useRecoilValue(wording);
  const history = useHistory();

  //FirebaseMessaging
  useFirebaseMessagingHandler();
 

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        var decoded = jwt_decode(userHelper.getToken());
        if (decoded !== undefined) {
          if (userHelper.getToken() !== null) {
            if (decoded.exp * 1000 < Date.now()) {
              history.push(ROUTES.WELCOME);
              const webAuthn = window.localStorage.getItem('authn')
                ? JSON.parse(window.localStorage.getItem('authn'))
                : null;
              window.localStorage.clear();
              webAuthn &&
                window.localStorage.setItem('authn', JSON.stringify(webAuthn));
            } else {
              history.push(ROUTES.DASHBOARD);
            }
          } else {
            const webAuthn = window.localStorage.getItem('authn')
              ? JSON.parse(window.localStorage.getItem('authn'))
              : null;
            window.localStorage.clear();
            webAuthn &&
              window.localStorage.setItem('authn', JSON.stringify(webAuthn));

            history.push(ROUTES.WELCOME);
          }
        } else {
          const webAuthn = window.localStorage.getItem('authn')
            ? JSON.parse(window.localStorage.getItem('authn'))
            : null;
          window.localStorage.clear();
          webAuthn &&
            window.localStorage.setItem('authn', JSON.stringify(webAuthn));
          history.push(ROUTES.WELCOME);
        }
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Body>
      <Route exact path={ROUTES.SIGNOUT} component={SignOut} />
      <Switch>
        <Route exact path={ROUTES.WELCOME} component={AccountBox} />
        {/* <Route exact path={ROUTES.LOGIN} component={LogIn} /> */}
        <Route exact path={ROUTES.PALTA} component={Palta} />
        <Route exact path={ROUTES.REGISTER} component={Register} />
        <Route
          exact
          path={ROUTES.REGISTER_SUCCESS}
          component={SuccessRegister}
        />
        <Route
          exact
          path={`${ROUTES.REGISTER_USER}/:id`}
          component={RegisterUser}
        />
        <Route exact path={ROUTES.VALID_PHONE} component={ValidPhone} />
        <Route
          exact
          path={`${ROUTES.USER_REGISTER}/:id`}
          component={UserRegister}
        />

        <Route
          exact
          path={`${ROUTES.USER_REGISTER_VALID_IDENTITY}/:id`}
          component={UserRegisterValidIdentity}
        />
        <Route
          exact
          path={`${ROUTES.USER_REGISTER_VALID_IDENTITY_COMMERCE}/:id`}
          component={CommerceRegisterValidIdentity}
        />
        <Route
          exact
          path={`${ROUTES.USER_REGISTER_VALID_IDENTITY_COMMERCE_BENE_MZA_ACT}/:id`}
          component={CommerceBeneMzaActRegisterValidIdentity}
        />
        <Route
          exact
          path={`${ROUTES.USER_REGISTER_VALID_IDENTITY_USER_BENE_MZA_ACT}/:id`}
          component={UserBeneMzaActRegisterValidIdentity}
        />

        <Route
          exact
          path={`${ROUTES.MENU_BENE_MZA_ACT_USER}/:id`}
          component={UserBeneMzaAct}
        />
        <Route
          exact
          path={`${ROUTES.MENU_BENE_MZA_ACT_COMMERCE_PAGE_1}/:id`}
          component={CommerceBeneMzaActPage1}
        />
        <Route
          exact
          path={`${ROUTES.MENU_BENE_MZA_ACT_COMMERCE_PAGE_2}/:id`}
          component={CommerceBeneMzaActPage2}
        />
        <Route
          exact
          path={`${ROUTES.MENU_BENE_MZA_ACT_COMMERCE_PAGE_3}/:id`}
          component={CommerceBeneMzaActPage3}
        />
        <Route
          exact
          path={`${ROUTES.MENU_BENE_MZA_ACT}/:id`}
          component={MenuBeneMzaAct}
        />
        <Route
          exact
          path={`${ROUTES.COMMERCE_PAGE_1}/:id`}
          component={RegisterCommercePage1}
        />
        <Route
          exact
          path={`${ROUTES.COMMERCE_PAGE_2}/:id`}
          component={RegisterCommercePage2}
        />
        <Route exact path={ROUTES.REGISTER_FINISH} component={RegisterFinish} />
        <PrivateRoute exact path={ROUTES.DASHBOARD} component={Dashboard} />
        <PrivateRoute
          exact
          path={ROUTES.SEARCH_SHOPS}
          component={SearchShops}
        />
        <PrivateRoute exact path={ROUTES.QR_READER} component={QRReader} />
        <PrivateRoute exact path={ROUTES.PAYMENTS} component={Payments} />
        <PrivateRoute
          exact
          path={ROUTES.PAYMENTS_PAY}
          component={PaymentsPay}
        />
        <PrivateRoute exact path={ROUTES.PAYMENTS_SELECT} component={Select} />
        <PrivateRoute
          exact
          path={ROUTES.PAYMENTS_SELECT_WALLET}
          component={SelectWallet}
        />
        <PrivateRoute
          exact
          path={ROUTES.PAYMENTS_SELECT_AMOUNT}
          component={Amount}
        />
        <PrivateRoute
          exact
          path={ROUTES.PAYMENTS_SELECT_CONFIRM}
          component={Confirm}
        />
        <PrivateRoute
          exact
          path={ROUTES.PAYMENTS_SELECT_SUCCESS}
          component={Success}
        />
        <PrivateRoute exact path={ROUTES.LOANS_PAY} component={LoansPay} />
        <PrivateRoute
          exact
          path={`${ROUTES.PAYMENTS_PAY}/:id`}
          component={PaymentsPay}
        />
        <PrivateRoute exact path={ROUTES.ACCOUNT} component={Account} />
        <PrivateRoute exact path={ROUTES.PROFILE} component={Profile} />
        <PrivateRoute exact path={ROUTES.MASSIVE_QR} component={MassiveQr} />
        <PrivateRoute exact path={ROUTES.CASH_OUT} component={CashOut} />
        <PrivateRoute exact path={ROUTES.CASH_OUT_CBU_CVU} component={CbuCvu} />
        <PrivateRoute
          exact
          path={ROUTES.CASH_OUT_VALIDATE_CBU_CVU}
          component={ValidateCbuCvu}
        />
        <PrivateRoute
          exact
          path={ROUTES.CASH_OUT_AMOUNT}
          component={CashOutAmount}
        />
        <PrivateRoute
          exact
          path={ROUTES.CASH_OUT_CONFIRM}
          component={ConfirmCashout}
        />
        <PrivateRoute
          exact
          path={ROUTES.CASH_OUT_SUCCESS}
          component={CashOutSuccess}
        />

        <PrivateRoute exact path={ROUTES.EXPORT_CSV} component={Exportcsv} />
        <PrivateRoute exact path={ROUTES.CASH_IN} component={CashIn} />
        <PrivateRoute exact path={ROUTES.CASH_IN_MP} component={CashInMP} />
        <PrivateRoute exact path={ROUTES.CASH_IN_CB} component={CashInCB} />
        <PrivateRoute exact path={ROUTES.QR_GENERATOR} component={QRCode} />
        <PrivateRoute
          exact
          path={ROUTES.RESUME}
          component={Resume}
          wording={screens.activity}
        />
        <PrivateRoute
          exact
          path={`${ROUTES.RESUME}/:year/:month/`}
          component={Resume}
        />
        <PrivateRoute
          exact
          path={`${ROUTES.ITEM_VIEW}/:_txsId`}
          component={ItemView}
        />
        <PrivateRoute
          exact
          path={ROUTES.SERVICE_PAYMENTS_GENERIC}
          component={ServicePayments}
        />
        <PrivateRoute
          exact
          path={ROUTES.SERVICE_PAYMENTS_GENERIC_1}
          component={ServicePayments1}
        />
        <PrivateRoute
          exact
          path={ROUTES.SERVICE_PAYMENTS_GET_OPCION_1}
          component={GetOpcion1}
        />
        <PrivateRoute
          exact
          path={ROUTES.SERVICE_PAYMENTS_GET_OPCION_2}
          component={GetOpcion2}
        />
        <PrivateRoute
          exact
          path={ROUTES.SERVICE_PAYMENTS_GET_OPCION_3}
          component={GetOpcion3}
        />
        <PrivateRoute
          exact
          path={ROUTES.SERVICE_PAYMENTS_GET_OPCION_4}
          component={GetOpcion4}
        />
        <PrivateRoute
          exact
          path={ROUTES.SERVICE_PAYMENTS_PHONE}
          component={PhoneTopUp}
        />
        <PrivateRoute
          exact
          path={ROUTES.SERVICE_PAYMENTS_WALLETS}
          component={ServicePaymentWallets}
        />
        <PrivateRoute
          exact
          path={ROUTES.SERVICE_PAYMENTS_CASHOUT}
          component={PaymentCashout}
        />

        <PrivateRoute
          exact
          path={ROUTES.SERVICE_PAYMENTS_SUCCESS}
          component={ServicePaymentSuccess}
        />

        <PrivateRoute
          exact
          path={ROUTES.SERVICE_PAYMENTS_CHOOSE_MODE}
          component={ChooseInput}
        />

        <PrivateRoute
          exact
          path={ROUTES.SERVICE_PAYMENTS_BARCODE}
          component={BarcodeScanner}
        />

        <PrivateRoute
          exact
          path={ROUTES.DOLLAR_CONVERSION}
          component={DollarConversion}
        />

        <PrivateRoute
          exact
          path={ROUTES.DOLLAR_TRANSACTION_DETAIL}
          component={TransactionDetails}
        />

        <PrivateRoute
          exact
          path={ROUTES.DOLLAR_TRANSACTION_SUCCESS}
          component={DollarSuccess}
        />

        <PrivateRoute
          exact
          path={'/me/notification'}
          component={NotificationPanel}
        />

        <Route path="*" component={AccountBox} />
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
      />
    </Body>
  );
};

export default App;
