import React, { Component } from 'react';
import { Modal, ModalDialog } from 'react-bootstrap';
import logo from '../../assets/images/palta-512.svg';
import './../../components/PaltaModalSpinner/paltaModal.css';
class PaltaModalSpinner extends Component {
  render() {
    return (
      <Modal {...this.props} className="modal-content-spinner" aria-labelledby="contained-modal-title-vcenter bd-example-modal-lg "
        centered  >
        <div className="style-container">
          <h3 className="subtitle">
            <img
              src={logo}
              width="120"
              height="50"
              className="d-inline-block align-top img-rotate"
              alt="Palta logo"
            />
          </h3>
        </div>
      </Modal>
    );
  }
}

export default PaltaModalSpinner;