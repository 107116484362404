import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import {
  user,
  shopId,
  selectedShop,
  selectedWalletPay,
  token,
} from '../../../store';
import PaltaModalSpinner from '@components/PaltaModalSpinner';
import PaltaBreadcrumbs from '../../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import * as ROUTES from '../../../constants/routes';
import { useGetShop } from '../../../core/api-hooks/use-shop/get';
import './select-wallet.css';
import Swal from 'sweetalert2';
import { motion } from 'framer-motion';
import { getCurrencyPair } from '../../service-payments/utils'


const WalletGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 90%;
  margin: 0 auto;

  @media (max-width: 992px) {

  }
`;

const Wallet = styled.div`
  background-color: ${props => props.bc};
  padding: 1rem;
  border-radius: 12px;
  text-align: center;
  color: white;
  width: 100%;
  cursor: pointer;

  @media (max-width: 992px) {

  }
`;

const ScrollHorizontalItem = styled.div`
  color: #fff;
  background-color: #3A4D9F;
  padding: 32px;
  margin-right: 10px;
  border-radius: 16px;
  flex: 0 0 auto;
  cursor: pointer;
`;

export const SelectWallet = () => {
  const userData = useRecoilValue(user);
  const setSelectedWalletPay = useSetRecoilState(selectedWalletPay);
  const setSelectedShop = useSetRecoilState(selectedShop);
  const [loading, setLoading] = useState(true);
  const [{ data, error }, getShop] = useGetShop();
  const history = useHistory();
  const { state } = useLocation();
  const tokenData = useRecoilValue(token);

  useEffect(() => {
    
    const shopId = state.shopId;
    
    if (userData && shopId) {
      getShop(shopId, tokenData);
    }
  }, []);

  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Problemas para cargar los datos',
        padding: '2px',
        showConfirmButton: false,
        width: '300px',
        heightAuto: '200px',
        timer: 1500,
        customClass: {
          title: 'm-0 text-danger',
          header: 'p-0',
        },
      })
      setLoading(false);
    }
  }, [error]);
  let userWalletsSort = userData?.wallets.slice().sort((a, b) => {

    if (a.currencyLabel > b.currencyLabel) {
      return -1;
    }
    if (a.currencyLabel < b.currencyLabel) {
      return 1;
    }
    return 0;
  })
  let dataWalletsSort = data?.wallets.slice().sort((a, b) => {

    if (a.currencyLabel > b.currencyLabel) {
      return -1;
    }
    if (a.currencyLabel < b.currencyLabel) {
      return 1;
    }
    return 0;
  })
  const filterWallets = useCallback(
    (dataWallets, userWallets) => {

      const walletsUser = userWallets.map((wall) => wall.currency);
      const allWallets = [];
      const originDollarWallets = userWallets.filter(wal => wal.currency.includes('dolar'))
      const originPesoWallets = userWallets.filter(wal => !wal.currency.includes('dolar'))
      const destinationDollarWallets = dataWallets.filter(wal => wal.currency.includes('dolar'))
      const destinationPesoWallets = dataWallets.filter(wal => !wal.currency.includes('dolar'))

      dataWallets.forEach((wallet) => {
        walletsUser.forEach((currency) => {
          if (wallet.currency === currency) {
            allWallets.push(wallet);
          }
        });
      });

      const allPesoWallets = allWallets.filter(wal => !wal.currency.includes('dolar'))
      let allDollarWallets;
      if (originDollarWallets.length > 0 && destinationDollarWallets.length > 0) {
        allDollarWallets = allWallets.filter(wal => wal.currency.includes('dolar'));
      } else {
        allDollarWallets = originDollarWallets.filter(wal => {
          const pairWallet = getCurrencyPair(wal.currency)
          const isPair = destinationPesoWallets.some(wal => wal.currency === pairWallet)
          return isPair
        })
      }
      if (allWallets.length === 0) {
        return (
          <Col>
            <div className="data">No hay billeteras disponibles.</div>
          </Col>
        );
      }



      return (
        <div>
          <WalletGrid>

            {
              allPesoWallets.map((wallet) => (

                <Wallet
                  key={wallet.currency}
                  onClick={() => {
                    setSelectedWalletPay(wallet.currency);
                    setSelectedShop(data);
                    history.push(ROUTES.PAYMENTS_SELECT_AMOUNT);
                  }}
                  bc='#3A4D9F'
                >
                  {`${wallet.currencyLabel}`}
                </Wallet>


              ))
            }
          </WalletGrid>

          <WalletGrid style={{ marginTop: '20px' }}>
            {
              allDollarWallets.length > 0 && (
                allDollarWallets.map((wallet) => (

                  <Wallet
                    key={wallet.currency}
                    onClick={() => {
                      setSelectedWalletPay(wallet.currency);
                      setSelectedShop(data);
                      history.push(ROUTES.PAYMENTS_SELECT_AMOUNT);
                    }}
                    bc='#85bb65'
                  >
                    {`${wallet.currencyLabel}`}
                  </Wallet>


                ))
              )
            }

          </WalletGrid>
        </div>
      )
    },
    [data],
  );
  function escapeRegExp(string) {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(`<!doctype html><body>${string}`, 'text/html').body.textContent;
    return decodedString
  }
  return (

    <div style={{ backgroundColor: '#fff' }}>
      <PaltaNavBar />
      <PaltaBreadcrumbs
        name="Seleccionar forma de pago"
        link={ROUTES.PAYMENTS_SELECT}
        variant="dark"
      />
      <motion.div
        initial={{ x: '100%', opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ type: 'spring', duration: .4, bounce: 0.2 }}
      >
        <section style={{ padding: '3rem 0px' }}>
          <Container>
            <Row>
              <Col>
                <h5>Destinatario</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="data-label">Nombre:</div>
                <div className="data colorFont" style={{ textTransform: 'capitalize' }}>
                  {data && data.role === "user" ? (
                    `${data && data.fantasyName ? data.fantasyName + " -" : "" } ${data && data.name} ${data && data.lastname} `
                  ) :
                  `${data && data.fantasyName ? data.fantasyName + " -" : "" } ${data && data.businessName || '' }`
                  }
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="data-label">Cuit:</div>
                <div className="data colorFont">{`${data && data.cuit}`}</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5 style={{ textAlign: 'center' }}>Seleccionar monedero</h5>
              </Col>
            </Row>
            <div style={{ marginTop: '16px' }}>
              <div>
                {data && filterWallets(dataWalletsSort, userWalletsSort)}
              </div>
            </div>
          </Container>

        </section>
        {loading && (
          <PaltaModalSpinner show={loading} size="sm" />
        )}
      </motion.div>
      <PaltaFooter />
    </div >

  );
};
