import React, { useState } from 'react';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import PaltaBreadcrumbs from '../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import PaltaModalSpinner from '../../components/PaltaModalSpinner';
import * as ROUTES from '../../constants/routes';
import { Col, Container, Row } from 'react-bootstrap';
import { Wrapper } from '../../components/grid';
import './ServicePayments.css'
import { useHistory } from 'react-router';
import imgNumberPad from '../../assets/images/dial-pad.png';
import imgBarcode from '../../assets/images/barcode.png';

const ChooseInput = () => {
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    const handleBuscarManual = () => {
        history.push(ROUTES.SERVICE_PAYMENTS_GENERIC);
    }


    const handleBarcodeScanner = () => {
        history.push(ROUTES.SERVICE_PAYMENTS_BARCODE);
    }

    return (
        <>
            <PaltaNavBar className="light" />
            <PaltaBreadcrumbs
                name="Inicio"
                link={ROUTES.SERVICE_PAYMENTS_CHOOSE_MODE}
                variant="dark"
            />
            <Container>
                <Wrapper>
                    <Row>
                        <Col>
                            <h4
                                data-cy="heading"
                                className='mt-3'
                            >Pago de servicios</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <button
                                className='button-payment-input button-a'
                                onClick={handleBuscarManual}
                                style={{fontFamily: "'ProductSans', sans-serif", fontWeight: 'bold'}}
                            >
                                <div><img className='choose-img' src={imgNumberPad}></img></div>
                                <div style={{ fontSize: '0.85rem' }}>Buscar manualmente</div>

                            </button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <button
                                className='button-payment-input button-a'
                                onClick={handleBarcodeScanner}
                                style={{fontFamily: "'ProductSans', sans-serif", fontWeight: 'bold'}}
                            >
                                <div><img className='choose-img' src={imgBarcode}></img></div>
                                <div style={{ fontSize: '0.85rem' }}>Escanear código de barras</div>

                            </button>
                        </Col>
                    </Row>


                </Wrapper>
            </Container>
            <PaltaFooter />
            <PaltaModalSpinner show={loading} size="sm" />
        </>
    )
};

export default ChooseInput;
