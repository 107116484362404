import React from "react";
import { ModalDesiredCurrencyTitle } from "./ModalDesiredCurrencyTitle";
import { ModalDesiredCurrencyButtonContainer } from "./ModalDesiredCurrencyButtonContainer";

export const ModalDesiredCurrencyPickerContainer = ({ close, setDesiredInputCurrency }) => {
	return (
		<div
			style={{
				backgroundColor: 'white',
				padding: '2rem',
				borderRadius: '12px',
				margin: '0 20px',
			}}
		>
			<ModalDesiredCurrencyTitle />
			<ModalDesiredCurrencyButtonContainer close={close} setDesiredInputCurrency={setDesiredInputCurrency} />
		</div>
	)
}