export const geoloc = {
  argentina: {
    mendoza: [
      "capital",
      "general alvear",
      "godoy cruz",
      "guaymallén",
      "junín",
      "la paz",
      "las heras",
      "lavalle",
      "luján de cuyo",
      "maipú",
      "malargüe",
      "rivadavia",
      "san carlos",
      "san martín",
      "san rafael",
      "santa rosa",
      "tunuyán",
      "tupungato",
    ],
    "buenos aires": [
      "25 de mayo",
      "adolfo alsina",
      "adolfo gonzales chaves",
      "alberti",
      "almirante brown",
      "arrecifes",
      "avellaneda",
      "ayacucho",
      "azul",
      "bahía blanca",
      "balcarce",
      "baradero",
      "benito juárez",
      "berazategui",
      "berisso",
      "bolívar",
      "bragado",
      "brandsen",
      "campana",
      "cañuelas",
      "capitán sarmiento",
      "carlos casares",
      "carlos tejedor",
      "carmen de areco",
      "castelli",
      "chacabuco",
      "chascomús",
      "chivilcoy",
      "colón",
      "coronel de marina leonardo rosales",
      "coronel dorrego",
      "coronel pringles",
      "coronel suárez",
      "daireaux",
      "dolores",
      "ensenada",
      "escobar",
      "esteban echeverría",
      "exaltación de la cruz",
      "ezeiza",
      "florencio varela",
      "florentino ameghino",
      "general alvarado",
      "general alvear",
      "general arenales",
      "general belgrano",
      "general guido",
      "general juan madariaga",
      "general la madrid",
      "general las heras",
      "general lavalle",
      "general paz",
      "general pinto",
      "general pueyrredón",
      "general rodríguez",
      "general san martín",
      "general viamonte",
      "general villegas",
      "guaminí",
      "hipólito yrigoyen",
      "hurlingham",
      "ituzaingó",
      "josé c. paz",
      "junín",
      "la costa",
      "la matanza",
      "la plata",
      "lanús",
      "laprida",
      "las flores",
      "leandro n. alem",
      "lezama",
      "lincoln",
      "lobería",
      "lobos",
      "lomas de zamora",
      "luján",
      "magdalena",
      "maipú",
      "malvinas argentinas",
      "mar chiquita",
      "marcos paz",
      "mercedes",
      "merlo",
      "monte",
      "monte hermoso",
      "moreno",
      "morón",
      "navarro",
      "necochea",
      "nueve de julio",
      "olavarría",
      "patagones",
      "pehuajó",
      "pellegrini",
      "pergamino",
      "pila",
      "pilar",
      "pinamar",
      "presidente perón",
      "puan",
      "punta indio",
      "quilmes",
      "ramallo",
      "rauch",
      "rivadavia",
      "rojas",
      "roque pérez",
      "saavedra",
      "saladillo",
      "salliqueló",
      "salto",
      "san andrés de giles",
      "san antonio de areco",
      "san cayetano",
      "san fernando",
      "san isidro",
      "san miguel",
      "san nicolás",
      "san pedro",
      "san vicente",
      "suipacha",
      "tandil",
      "tapalqué",
      "tigre",
      "tordillo",
      "tornquist",
      "trenque lauquen",
      "tres arroyos",
      "tres de febrero",
      "tres lomas",
      "vicente lópez",
      "villa gesell",
      "villarino",
      "zárate",
    ],
    "capital federal": [
      "agronomía",
      "almagro",
      "balvanera",
      "barracas",
      "belgrano",
      "boca",
      "boedo",
      "caballito",
      "chacarita",
      "coghlan",
      "colegiales",
      "constitución",
      "flores",
      "floresta",
      "liniers",
      "mataderos",
      "monserrat",
      "monte castro",
      "nueva pompeya",
      "núñez",
      "palermo",
      "parque avellaneda",
      "parque chacabuco",
      "parque chas",
      "parque patricios",
      "paternal",
      "puerto madero",
      "recoleta",
      "retiro",
      "saavedra",
      "san cristóbal",
      "san nicolás",
      "san telmo",
      "vélez sársfield",
      "versalles",
      "villa crespo",
      "villa del parque",
      "villa devoto",
      "villa gral. mitre",
      "villa lugano",
      "villa luro",
      "villa ortúzar",
      "villa pueyrredón",
      "villa real",
      "villa riachuelo",
      "villa santa rita",
      "villa soldati",
      "villa urquiza",
    ],
    catamarca: [
      "ambato",
      "ancasti",
      "andalgalá",
      "antofagasta de la sierra",
      "belén",
      "capayán",
      "capital",
      "el alto",
      "fray mamerto esquiú",
      "la paz",
      "paclín",
      "pomán",
      "santa maría",
      "santa rosa",
      "tinogasta",
      "valle viejo",
    ],
    chaco: [
      "almirante brown",
      "bermejo",
      "chacabuco",
      "comandante fernández",
      "doce de octubre",
      "dos de abril",
      "fray justo santa maría de oro",
      "general belgrano",
      "general donovan",
      "general güemes",
      "independencia",
      "libertad",
      "libertador general san martín",
      "maipú",
      "mayor luis jorge fontana",
      "nueve de julio",
      "o'higgins",
      "presidencia de la plaza",
      "primero de mayo",
      "quitilipi",
      "san fernando",
      "san lorenzo",
      "sargento cabral",
      "tapenagá",
      "veinticinco de mayo",
    ],
    chubut: [
      "atlántico",
      "biedma",
      "cushamen",
      "escalante",
      "florentino ameghino",
      "futaleufú",
      "gaiman",
      "gastre",
      "languiñeo",
      "mártires",
      "paso de indios",
      "rawson",
      "río senguer",
      "sarmiento",
      "tehuelches",
      "telsen",
    ],
    córdoba: [
      "calamuchita",
      "capital",
      "colón",
      "cruz del eje",
      "general roca",
      "general san martín",
      "ischilín",
      "juárez celman",
      "marcos juárez",
      "minas",
      "pocho",
      "presidente roque sáenz peña",
      "punilla",
      "río cuarto",
      "río primero",
      "río seco",
      "río segundo",
      "san alberto",
      "san javier",
      "san justo",
      "santa maría",
      "sobremonte",
      "tercero arriba",
      "totoral",
      "tulumba",
      "unión",
    ],
    corrientes: [
      "bella vista",
      "berón de astrada",
      "capital",
      "concepción",
      "curuzú cuatiá",
      "empedrado",
      "esquina",
      "general alvear",
      "general paz",
      "goya",
      "itatí",
      "ituzaingó",
      "lavalle",
      "mburucuyá",
      "mercedes",
      "monte caseros",
      "paso de los libres",
      "saladas",
      "san cosme",
      "san luis del palmar",
      "san martín",
      "san miguel",
      "san roque",
      "santo tomé",
      "sauce",
    ],
    "entre ríos": [
      "colón",
      "concordia",
      "diamante",
      "federación",
      "federal",
      "feliciano (san josé de feliciano)",
      "gualeguay",
      "gualeguaychú",
      "islas del ibicuy",
      "la paz",
      "nogoyá",
      "paraná",
      "san salvador",
      "tala",
      "uruguay",
      "victoria",
      "villaguay",
    ],
    formosa: [
      "bermejo",
      "formosa",
      "laishí",
      "matacos",
      "patiño",
      "pilagás",
      "pilcomayo",
      "pirané",
      "ramón lista",
    ],
    jujuy: [
      "cochinoca",
      "dr. manuel belgrano",
      "el carmen",
      "humahuaca",
      "ledesma",
      "palpalá",
      "rinconada",
      "san antonio",
      "san pedro",
      "santa bárbara",
      "santa catalina",
      "susques",
      "tilcara",
      "tumbaya",
      "valle grande",
      "yavi",
    ],
    "la pampa": [
      "atreucó",
      "caleu caleu",
      "capital",
      "catriló",
      "chalileo",
      "chapaleufú",
      "chical co",
      "conhelo",
      "curacó",
      "guatraché",
      "hucal",
      "lihuel calel",
      "limay mahuida",
      "loventué",
      "maracó",
      "puelén",
      "quemú quemú",
      "rancul",
      "realicó",
      "toay",
      "trenel",
      "utracán",
    ],
    "la rioja": [
      "arauco",
      "castro barros",
      "chamical",
      "chilecito",
      "famatina",
      "general ángel vicente peñaloza",
      "general belgrano",
      "general felipe varela",
      "general juan facundo quiroga",
      "general lamadrid",
      "general ortiz de ocampo",
      "general san martín",
      "independencia",
      "la rioja (municipio)/ capital (departamento)",
      "rosario vera peñaloza",
      "san blas de los sauces",
      "sanagasta",
      "vinchina",
    ],
    misiones: [
      "25 de mayo",
      "apóstoles",
      "cainguás",
      "candelaria",
      "capital",
      "concepción",
      "eldorado",
      "general manuel belgrano",
      "guaraní",
      "iguazú",
      "leandro n. alem",
      "libertador gral. san martín",
      "montecarlo",
      "oberá",
      "san ignacio",
      "san javier",
      "san pedro",
    ],
    neuquén: [
      "aluminé",
      "añelo",
      "catán lil",
      "chos malal",
      "collón curá",
      "confluencia",
      "huiliches",
      "lácar",
      "loncopué",
      "los lagos",
      "minas",
      "ñorquín",
      "pehuenches",
      "picún leufú",
      "picunches",
      "zapala",
    ],
    "río negro": [
      "adolfo alsina",
      "avellaneda",
      "bariloche",
      "conesa",
      "el cuy",
      "general roca",
      "nueve de julio",
      "ñorquincó",
      "pichi mahuida",
      "pilcaniyeu",
      "san antonio",
      "valcheta",
      "veinticinco de mayo",
    ],
    salta: [
      "anta",
      "cachi",
      "cafayate",
      "cerrillos",
      "chicoana",
      "general güemes",
      "general josé de san martín",
      "guachipas",
      "iruya",
      "la caldera",
      "la candelaria",
      "la capital",
      "la poma",
      "la viña",
      "los andes",
      "metán",
      "molinos",
      "orán",
      "rivadavia",
      "rosario de la frontera",
      "rosario de lerma",
      "san carlos",
      "santa victoria",
    ],
    "san juan": [
      "albardón",
      "angaco",
      "calingasta",
      "caucete",
      "chimbas",
      "iglesia",
      "jáchal",
      "nueve de julio",
      "pocito",
      "rawson",
      "rivadavia",
      "capital",
      "san martín",
      "santa lucía",
      "sarmiento",
      "ullum",
      "valle fértil",
      "veinticinco de mayo",
      "zonda",
    ],
    "san luis": [
      "ayacucho",
      "belgrano",
      "chacabuco",
      "coronel pringles",
      "general pedernera",
      "gobernador dupuy",
      "juan martín de pueyrredón",
      "junín",
      "libertador general san martín",
    ],
    "santa cruz": [
      "corpen aike",
      "deseado",
      "güer aike",
      "lago argentino",
      "lago buenos aires",
      "magallanes",
      "río chico",
    ],
    "santa fe": [
      "belgrano",
      "caseros",
      "castellanos",
      "constitución",
      "garay",
      "general lópez",
      "general obligado",
      "iriondo",
      "la capital",
      "las colonias",
      "nueve de julio",
      "rosario",
      "san cristóbal",
      "san javier",
      "san jerónimo",
      "san justo",
      "san lorenzo",
      "san martín",
      "vera",
    ],
    "santiago del estero": [
      "aguirre",
      "alberdi",
      "atamisqui",
      "avellaneda",
      "banda",
      "belgrano",
      "choya",
      "copo",
      "figueroa",
      "general taboada",
      "guasayán",
      "jiménez",
      "juan felipe ibarra",
      "juan francisco borges",
      "loreto",
      "mitre",
      "moreno",
      "ojo de agua",
      "pellegrini",
      "quebrachos",
      "río hondo",
      "rivadavia",
      "robles",
      "salavina",
      "san martín",
      "sarmiento",
      "silípica",
    ],
    "tierra del fuego": [
      "antártida argentina",
      "islas del atlántico sur",
      "río grande",
      "tolhuin",
      "ushuaia",
    ],
    tucumán: [
      "burruyacú",
      "capital",
      "chicligasta",
      "cruz alta",
      "famaillá",
      "graneros",
      "juan bautista alberdi",
      "la cocha",
      "leales",
      "lules",
      "monteros",
      "río chico",
      "simoca",
      "tafí del valle",
      "tafí viejo",
      "trancas",
      "yerba buena",
    ],
  }
  // ,
  // brasil: { otro: ["otro"] },
  // bolivia: { otro: ["otro"] },
  // chile: { otro: ["otro"] },
  // paraguay: { otro: ["otro"] },
  // perú: { otro: ["otro"] },
  // uruguay: { otro: ["otro"] },
  // otro: { otro: ["otro"] },
};