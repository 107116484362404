
import React, { useState, useCallback, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import logoPalta from '../../../assets/images/palta-logo-500.svg';
import * as ROUTES from '@constants/routes';
import '../../register-form/register.css';
import { useSendDataRegister } from '../../../core/api-hooks/use-send-data-register/put';
import { usePostCreateIdentity } from '../../../core/api-hooks/use-create-identity/post';
import { useRecoilState, useRecoilValue } from 'recoil';
import { dataUserForm } from '../../../store';
const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

`;

const BoxContainer = styled.div`
  width: 340px;
  min-height: 624px;
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 0px 0px 4.7px rgba(15, 15, 15, 0.28);
  position: relative;
  overflow: hidden;
  padding: 0px 10px 0px 10px;
  align-items: center;
`;


const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

export const UserRegister = () => {
  const history = useHistory();
  const { id } = useParams();
  const [putSendDataRegister] = useSendDataRegister();
  const [postCreateIdentity] = usePostCreateIdentity();
  const [datauser, setDataUserForm] = useRecoilState(dataUserForm);
  const dataUserFormValue = useRecoilValue(dataUserForm);
  let dataDefaultObligatedSubject = dataUserFormValue?.obligatedSubject !== undefined ? dataUserFormValue?.obligatedSubject : false;
  let dataDefaultPep = dataUserFormValue?.pep !== undefined ? dataUserFormValue?.pep : false;
  const [state] = useState({ data: { checked: dataDefaultObligatedSubject } });
  const [statePep] = useState({ data: { checked: dataDefaultPep } });
  const { register, handleSubmit, errors, formState, watch } = useForm({
    mode: 'onChange',
  });
  const pep = useRef({});
  pep.current = watch("pep", "");
  const onSubmit = useCallback(async (data) => {
    let clientRiskPep = data.pep === "true" && "alto";
    let clientRiskData = data.origenIngreso === "Responsable inscripto" ? "medio"
      : data.origenIngreso === "Relación de Dependencia" ? "bajo"
        : data.origenIngreso === "Monotributista" ? "medio"
          : data.origenIngreso === "Jubilado - Pensionado" ? "bajo"
            : data.origenIngreso === "Independiente" ? "bajo"
              : null;
    const senData = {
      role: "user",
      pep: data.pep === "true" ? true : false,
      obligatedSubject: data.sujetoObligado === "true" ? true : false,
      originEarnings: data.origenIngreso,
      clientRisk: clientRiskPep !== false ? clientRiskPep : clientRiskData,
      isMzaActivaBeneficiary: false

    }
    setDataUserForm(senData)
    postCreateIdentity(id);
    putSendDataRegister(senData, id);

  }, []);
  return (
    <>
      <AppContainer>
        <BoxContainer>

          <img
            src={logoPalta}
            width="120"
            height="50"
            className="d-inline-block align-top position-logo-palta-form-user "
            alt="Palta logo"
          />
          <form
            style={{ marginTop: '27px' }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="input-container">
              <div className="row d-flex justify-content-center mt-2">
                <div className="col-12 mr-4 ml-4 mb-3 col-sm-12">
                  <h5 className="text-center">
                    A continuación se le pedirá una serie de datos necesarios para terminar su registro en Palta.
                  </h5>
                  <div className="form-group  text-center mb-0">
                    <label className="text-center h2 mt-3 mb-0 bold" style={{ fontSize: 18, width: "auto", height: "2rem" }} htmlFor="name" >
                      <a
                        target="_blank"
                        style={{ textDecoration: "underline" }}
                        rel="noopener noreferrer"
                        href="https://www.argentina.gob.ar/uif/sujetos-obligados/listado"
                      >
                        <p>¿Sos sujeto obligado?</p>
                      </a>
                    </label>
                    <br />
                    <label style={{ width: "auto", height: "1rem" }}>
                      <input
                        name="sujetoObligado"
                        className="mr-2"
                        ref={register({ required: 'Required' })}
                        value={true}
                        defaultChecked={state.data.checked === true}
                        type="radio"
                      />
                      Si
                    </label>
                    <label style={{ width: "auto", height: "1rem" }} className="ml-2">
                      <input
                        className="mr-2"
                        name="sujetoObligado"
                        ref={register({ required: 'Required' })}
                        value={false}
                        defaultChecked={state.data.checked === false}
                        type="radio"
                      />
                      No
                    </label>
                    {(errors.sujetoObligado && errors.sujetoObligado.type === 'required') && (
                      <p className="input-error mb-0">* El campo es requerido</p>
                    )}
                    <br />
                    <label className="text-center h2 mb-0 pt-3 bold" style={{ fontSize: 18, width: "auto", height: "2rem" }} htmlFor="name" >
                      <a
                        target="_blank"
                        style={{ textDecoration: "underline" }}
                        rel="noopener noreferrer"
                        href="http://servicios.infoleg.gob.ar/infolegInternet/anexos/195000-199999/195785/norma.htm"
                      >
                        <p> ¿Sos persona expuesta políticamente?</p>
                      </a>
                    </label>
                    <br />
                    <label style={{ width: "auto", height: "1rem" }}>
                      <input
                        name="pep"
                        className="mr-2"
                        ref={register({ required: 'Required' })}
                        value={true}
                        defaultChecked={statePep.data.checked === true}
                        type="radio"
                      />
                      Si
                    </label>
                    <label style={{ width: "auto", height: "1rem" }} className="ml-2">
                      <input
                        className="mr-2"
                        name="pep"
                        ref={register({ required: 'Required' })}
                        value={false}
                        defaultChecked={statePep.data.checked === false}
                        type="radio"
                      />
                      No
                    </label>
                    {(errors.pep && errors.pep.type === 'required') && (
                      <p className="input-error mb-0">* El campo es requerido</p>
                    )}
                    <div className="input-container pt-4 mb-3 ">
                      <select className="form-control mt-4 mb-3" defaultValue={dataUserFormValue?.originEarnings} name="origenIngreso" ref={register({ required: 'Required' })}>
                        <option value="Relación de Dependencia">Relación de  dependencia</option>
                        <option value="Monotributista">Monotributista</option>
                        <option value="Responsable inscripto">Responsable inscripto</option>
                        <option value="Jubilado - Pensionado">Jubilado - Pensionado</option>
                        <option value="Independiente">Independiente</option>
                      </select>
                      <label className="label-user-origenIng mt-3 pb-3" htmlFor="name" >¿Cuál es el origen de sus ingresos?</label>
                      {errors.origenIngreso && errors.origenIngreso.type === 'required' && (
                        <p className="input-error">El campo contraseña es requerido</p>
                      )}
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="position-button-align-user d-flex justify-content-center align-items-center mt-4">

              <Button
                type="submit"
                size="md"
                className=" active btn-block btn-fullwidthe bg-danger mt-0"
                onClick={() => history.push(`${ROUTES.REGISTER_USER}/${id}`)}
                style={{ fontSize: 18, borderRadius: 5, fontFamily: "inherit", textTransform: "none", marginRight: 4 }}
                data-cy="button-volver"
              >
                Volver
              </Button>

              <Button
                type="submit"
                size="md"
                className=" active btn-block btn-fullwidthe btnColor ml-2 mt-0"
                disabled={!formState.isValid}
                // onClick={() => history.push(ROUTES.REGISTER_USER)}
                style={{ fontSize: 18, borderRadius: 5, fontFamily: "inherit", textTransform: "none" }}
                data-cy="button-volver"
              >
                Siguiente
              </Button>



            </div>

          </form>

        </BoxContainer>
      </AppContainer>

    </>
  );
};

