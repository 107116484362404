import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import './PaltaList.css';

export default class PaltaList extends Component {

    render() {
        let { style, buttonText, children, className, onClick, hidden } = this.props;
        return(
            <Container style={style || {"paddingBottom": "4rem"}}>
                <ul className={ `list-palta w-100 ${className ? className : ''}` } >
                    { children }
                </ul>
                { hidden ? '' : buttonText && <Button variant="show-more" onClick={onClick} className="btn-lg btn-block btn-show-more" style={{marginTop: "2em"}}>{ buttonText }</Button> }
            </Container>
        )
    }

}