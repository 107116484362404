import styled from 'styled-components';

export const Body = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Container = styled.div`
  padding: 15px 0px;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.white};
`;
export const ContainerDasboard = styled.div`
  padding-top: 0px !important;
  padding-bottom: 66px !important;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Wrapper = styled.div`
  padding: ${({ backgroundLess }) => (backgroundLess ? '0px' : '0px 16px')};
  max-width: 1100px;
  margin: 0 auto;
`;

export const Grid = styled.div`
  display: grid;
  grid-gap: 8px;

  @media ${({ theme }) => theme.breakPoint.large} {
    font-size: 22px;
    grid-template-columns: repeat(12, 1fr);
  }

  @media ${({ theme }) => theme.breakPoint.desktop} {
    font-size: 22px;
    grid-template-columns: repeat(12, 1fr);
  }

  @media ${({ theme }) => theme.breakPoint.tablet} {
    font-size: 18px;
    grid-template-columns: repeat(8, 1fr);
  }

  @media ${({ theme }) => theme.breakPoint.mobile} {
    font-size: 18px;
    grid-template-columns: repeat(4, 1fr);
  }
  @media ${({ theme }) => theme.breakPoint.mobileSmall} {
    font-size: 18px;
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const GridSmall = styled.div`
  display: grid;
  grid-gap: 10px 10px;

  @media ${({ theme }) => theme.breakPoint.large} {
    font-size: 22px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${({ theme }) => theme.breakPoint.desktop} {
    font-size: 22px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${({ theme }) => theme.breakPoint.tablet} {
    font-size: 18px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${({ theme }) => theme.breakPoint.mobile} {
    font-size: 18px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${({ theme }) => theme.breakPoint.mobileSmall} {
    font-size: 18px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const GridWallet = styled.div`
  display: grid;
  grid-gap: 20px;
  background-color: red;
  @media ${({ theme }) => theme.breakPoint.large} {
    font-size: 22px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${({ theme }) => theme.breakPoint.desktop} {
    font-size: 22px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${({ theme }) => theme.breakPoint.tablet} {
    font-size: 18px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${({ theme }) => theme.breakPoint.mobile} {
    font-size: 18px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${({ theme }) => theme.breakPoint.mobileSmall} {
    font-size: 18px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Col = styled.div`
  grid-column: ${({ large }) => `auto/span ${large ? large : 1}`};

  @media ${({ theme }) => theme.breakPoint.large} {
    grid-column: ${({ large }) => `auto/span ${large ? large : 1}`};
  }

  @media ${({ theme }) => theme.breakPoint.desktop} {
    grid-column: ${({ desktop }) => `auto/span ${desktop ? desktop : 1}`};
  }

  @media ${({ theme }) => theme.breakPoint.tablet} {
    ${({ tablet }) => (tablet ? `grid-column: auto/span ${tablet};` : null)};
  }

  @media ${({ theme }) => theme.breakPoint.mobile} {
    ${({ mobile }) => (mobile ? `grid-column: auto/span ${mobile};` : null)};
  }
`;
