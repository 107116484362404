import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Form, Row, Col,Button } from 'react-bootstrap';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  token,
  cashOutWallet,
  cashOutCbuCvu,
  cashOutAmount,
  concept,
  description
} from '@store';
import PaltaModalSpinner from '@components/PaltaModalSpinner';
import PaltaBreadcrumbs from '../../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import * as ROUTES from '../../../constants/routes';
import { isMobile } from 'react-device-detect';
import { useGetCommissionCashout } from '../../../core/api-hooks/use-commission-cashout/get';
import '../cbu-cvu/cbu.css';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
export const CashOutAmount = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [conceptValue, setConcept] = useState("VAR");
  const [descriptionValue, setDescription] = useState("");
  const [amount, setAmount] = useState(0);
  const history = useHistory();
  const cashOutWalletData = useRecoilValue(cashOutWallet);
  const cashOutCbuCvuData = useRecoilValue(cashOutCbuCvu);
  const setCashOutAmount = useSetRecoilState(cashOutAmount);
  const setConceptState = useSetRecoilState(concept);
  const setDescriptionState = useSetRecoilState(description);

  const tokenData = useRecoilValue(token);
  const [{ data }, getCommissionCashout] = useGetCommissionCashout();
  useEffect(() => {
    if (!data) {

      getCommissionCashout(cashOutWalletData?._id, tokenData);
    }
  }, [!data, getCommissionCashout, tokenData]);
  const onsubmit = useCallback(() => {
    setLoading(true);
    setCashOutAmount(Number(amount));
    setConceptState(conceptValue)
    setDescriptionState(String(descriptionValue))
    setTimeout(() => {
      setLoading(false);
      history.push({
        pathname: `${ROUTES.CASH_OUT_CONFIRM}`,
        state: {
          data: location.state.data,
        }
      });

    }, 2000);
  }, [cashOutWalletData, cashOutCbuCvuData, amount, history, tokenData, conceptValue, descriptionValue]);
  // eslint-disable-next-line no-extend-native
  String.prototype.capitalize = function () {

    return this.replace(/(^|\s)([a-z])/g, function (m, p1, p2) { return p1 + p2.toUpperCase(); });
  };
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <PaltaNavBar/>
      <PaltaBreadcrumbs
        name="Monto a retirar"
        link={ROUTES.CASH_OUT_VALIDATE_CBU_CVU}
        variant="dark"
      />
      <section style={{ padding: '1rem 0px' }}>
        <Container>
          <Row className="mb-3">
            <Col>
              <h5>Ingrese el monto que desee retirar</h5>
            </Col>
          </Row>
          {isMobile ? (
            <Row>
              <Col>
                <h5>Saldo Disponible a retirar: {data && data.available?.toFixed(2)}</h5>
                {data && data.commission !== undefined && (
                  <p>Comisión + impuestos: {data.commission} % </p>
                )}
                {cashOutWalletData !== undefined ? (
                  <p>
                    {`Saldo disponible: $ ${cashOutWalletData && cashOutWalletData.amount.toFixed(2)}`}
                  </p>
                ) : <p>{`Saldo disponible:?`}</p>}
                <p>
                  {`Destinatario : ${location.state.data?.owners[0].display_name.capitalize()}`}
                </p>

              </Col>
            </Row>
          ) : (
            <Row className="d-flex justify-content-center align-items-center text-center">
              <Col lg="4">
                <h5>Saldo Disponible a retirar: $ {data && data.available?.toFixed(2)}</h5>
                {data && data.commission !== undefined && (
                  <p>Comisión + impuestos: {data.commission} % </p>
                )}
              </Col>
              <Col lg="4">
                {cashOutWalletData !== undefined ? (
                  <p className="mb-2">
                    {`Saldo disponible: $ ${cashOutWalletData && cashOutWalletData.amount.toFixed(2)}`}
                  </p>
                ) : <p>{`Saldo disponible:?`}</p>}
                <p>
                  {`Destinatario : ${location.state.data?.owners[0].display_name.capitalize()}`}
                </p>

              </Col>
            </Row>
          )}
          <Row className="d-flex justify-content-center align-items-center text-center">
            <Form.Group className=" justify-content-center align-items-center d-flex form-group flex-row">
              <Col lg="6" sm="4">
                <select
                  className="form-control"
                  onChange={e => setConcept(e.target.value === '' ? 'VAR' : e.target.value)}
                >
                  <option value="VAR">Varios</option>
                  <option value="ALQ">Alquiler</option>
                  <option value="CUO">Cuota</option>
                  <option value="EXP">Expensas</option>
                  <option value="FAC">Factura</option>
                  <option value="PRE">Préstamo</option>
                  <option value="SEG">Seguro</option>
                  <option value="HON">Honorarios</option>
                  <option value="HAB">Haberes</option>
                </select>
              </Col>
              <Col lg="6" sm="4">
                <Form.Control
                  type="text"
                  className="form-control"
                  autoComplete="off"
                  placeholder="Descripción"
                  maxLength={100}
                  name="descriptionValue"
                  onChange={e => {
                    setDescription(e.target.value === '' ? '' : e.target.value)
                  }}
                />
              </Col>
            </Form.Group>
          </Row>

          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <Form.Group className="align-items-center d-flex form-group flex-row">
                {amount > data?.available ? (
                  <h1
                    style={{
                      fontSize: '5rem',
                      color: 'red',
                      width: '5rem',
                      margin: '0',
                    }}
                  >
                    $
                  </h1>
                ) : (
                  <h1
                    style={{
                      fontSize: '5rem',
                      color: '#3A4D9F',
                      width: '5rem',
                      margin: '0',
                    }}
                  >
                    $
                  </h1>
                )}


                {amount > data?.available ? (
                  <Form.Control
                    type="number"
                    pattern="\d*"
                    name="amount"
                    className="payment-amount"
                    style={{
                      color: 'red',
                    }}
                    autoComplete="off"
                    placeholder="0"
                    max={cashOutWalletData?.amount.toFixed(2)}
                    onChange={(e) =>
                      setAmount(e.target.value === '' ? '0' : e.target.value)
                    }
                  />
                ) : (
                  <Form.Control
                    type="number"
                    pattern="\d*"
                    name="amount"
                    className="payment-amount colorFont"
                    autoComplete="off"
                    placeholder="0"
                    max={cashOutWalletData?.amount.toFixed(2)}
                    onChange={(e) =>
                      setAmount(e.target.value === '' ? '0' : e.target.value)
                    }
                  />
                )}
              </Form.Group>


            </Col>
          </Row>

        </Container>
        <div className="text-center" style={{ marginTop: '1rem' }}>
          <Button
            size="lg"
            disabled={amount === 0 || amount > data?.available}
            onClick={onsubmit}
            className="btnColor"
          >
            Retirar
          </Button>
        </div>
      </section>
      {loading && (
        <PaltaModalSpinner show={loading} size="sm"/>
      )}
      <PaltaFooter/>
    </div>
  );
};
