import React, { useCallback,useState } from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import styled from 'styled-components';
import * as API from '../../../constants/api-staging';
import PaltaModalSpinner from '@components/PaltaModalSpinner';
import PaltaBreadcrumbs from '../../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import * as ROUTES from '../../../constants/routes';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { cashOutAmount, cashOutCbuCvu, cashOutWallet, date, token, transactionCode, user, cashName } from '../../../store';
import Swal from 'sweetalert2';
import { useHistory, useLocation } from 'react-router';
import { concept } from '../../../store/use-concept';
import { description } from '../../../store/use-description';
import { Wrapper } from '../../../components/wrapper';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import ModalFailure from '../../../components/ModalFailure/ModalFailure';
import { SeeFailure } from '../../../components/SeeFailure/SeeFailure';
import { fetcher } from '../../../core/Fetch/Fetch';
const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 16px;
  opacity: ${({ theme }) => theme.emphasis.low};
`;

export const ConfirmCashout = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();
  const cashOutAmountValue = useRecoilValue(cashOutAmount);
  const conceptValue = useRecoilValue(concept);
  const descriptionValue = useRecoilValue(description);
  const userData = useRecoilValue(user);
  const cashOutWalletData = useRecoilValue(cashOutWallet);
  const cashOutCbuCvuData = useRecoilValue(cashOutCbuCvu);
  const setTransactionCode = useSetRecoilState(transactionCode);
  const setCashOutDate = useSetRecoilState(date);
  const setCashOutAmount = useSetRecoilState(cashOutAmount);
  const setCashName = useSetRecoilState(cashName);
  const tokenData = useRecoilValue(token);
  const onsubmit = useCallback(() => {
    setLoading(true);
    fetcher(`${API.BASE_URL}/wallet/${cashOutWalletData._id}/cashout`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: tokenData,
      },
      body: JSON.stringify({
        amount: Number(cashOutAmountValue.toFixed(2)),
        cvu: cashOutCbuCvuData.cbucvu !== "" ? cashOutCbuCvuData.cbucvu : null,
        alias: cashOutCbuCvuData.alias !== "" ? cashOutCbuCvuData.alias : null,
        description: descriptionValue,
        concept: conceptValue,
        email: [userData.email]
      }),

    })
      .then((res) => {
        if (!res.ok) {
          if (res.status !== 200) {
            setError(true);
          }
          if (res.status === 401) {
            Swal.fire({
              icon: 'error',
              title: "Sesion Expirada",
              padding: "2px",
              showConfirmButton: false,
              width: "300px",
              heightAuto: "200px",
              timer: 1500,
              customClass: {
                title: "m-0 text-danger",
                header: "p-0"
              }
            }).then(function () {
              window.location.href = "/";
            });
          }
        } else {
          return res.json();
        }
      })
      .then(({ data }) => {
        setLoading(false);
        setCashOutAmount(data.amount);
        setTransactionCode(data?.transactionCode);
        setCashOutDate(data?.createdAt)
        setCashName(data?.counterparty.name)
        history.push(ROUTES.CASH_OUT_SUCCESS);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });

  }, [cashOutWalletData, cashOutCbuCvuData, cashOutAmountValue, cashOutAmount, history, tokenData]);

  // eslint-disable-next-line no-extend-native
  String.prototype.capitalize = function () {
    return this.replace(/(^|\s)([a-z])/g, function (m, p1, p2) { return p1 + p2.toUpperCase(); });
  };
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <PaltaNavBar/>
      <PaltaBreadcrumbs
        name="Confirmar retiro de dinero"
        link={ROUTES.CASH_OUT_AMOUNT}
        variant="dark"
      />
      <section style={{ padding: '1rem 0px' }}>
        <Container>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <RowWrapper>
                <h5>Destinatario</h5>
                <p>{`${location.state.data?.owners[0].display_name.capitalize()}`} </p>

              </RowWrapper>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <Separator />
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <RowWrapper>
                <h5>{`${location.state.data?.owners[0].id_type}`} </h5>
                <p>{`${location.state.data?.owners[0].id}`} </p>

              </RowWrapper>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <Separator />
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <RowWrapper>
                <h5>Monto a retirar</h5>
                <p>{`$ ${cashOutAmountValue?.toFixed(2)}`}</p>
              </RowWrapper>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <Separator />
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <RowWrapper>
                <h5>CVU/CBU</h5>
                <p>{`${location.state.data?.account_routing.address}`}</p>
              </RowWrapper>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <Separator />
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <RowWrapper>
                <h5>ALIAS</h5>
                <p>{`${location.state.data?.label}`}</p>
              </RowWrapper>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <Separator />
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <RowWrapper>
                <h5>Entidad</h5>
                <p>{`${location.state.data?.bank_routing.address}`}</p>
              </RowWrapper>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <Separator />
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <RowWrapper>
                <h5>Concepto</h5>
                {conceptValue === "VAR" ? (
                  <p>Varios</p>
                ) : conceptValue === "ALQ" ? (
                  <p>Alquiler</p>
                ) : conceptValue === "CUO" ? (
                  <p>Cuota</p>
                ) : conceptValue === "EXP" ? (
                  <p>Expensas</p>
                ) : conceptValue === "FAC" ? (
                  <p>Factura</p>
                ) : conceptValue === "PRE" ? (
                  <p>Préstamo</p>
                ) : conceptValue === "SEG" ? (
                  <p>Seguro</p>
                ) : conceptValue === "HON" ? (
                  <p>Honorarios</p>
                ) : conceptValue === "HAB" ? (
                  <p>Haberes</p>
                ) : null}
              </RowWrapper>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <Separator />
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <RowWrapper>
                <h5>Descripción</h5>
                <p>{`${descriptionValue}`}</p>
              </RowWrapper>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center">
            <Col>
              <Wrapper className="d-flex justify-content-center align-items-center" style={{ padding: 10, }}>
                <button
                  type="submit"
                  className="btn  btn-lg button-cancel mr-2 "
                  onClick={() => {
                    history.push({
                      pathname: `${ROUTES.CASH_OUT_AMOUNT}`,
                      state: {
                        data: location.state.data,
                      }
                    });
              
                  }}
                >
                  Volver
                </button>
                <button
                  type="submit"
                  className=" btnColor btn btn-primary btn-lg"
                  onClick={onsubmit}
                >
                  Confirmar
                </button>



              </Wrapper>
            </Col>
          </Row>

        </Container>
      </section>
      {loading && (
        <PaltaModalSpinner show={loading} size="sm"/>
      )}
      {error && (
          <ModalFailure
            show={true}
            onHide={() => setError(false)}>
            <div>
              <SeeFailure
              />
            </div>
          </ModalFailure>
        )}
      <PaltaFooter/>
    </div>
  );
};
