const registerFormHelper = {
     maxLength : (str) => {
        return str.length > 15
          ? {
              error: true,
              message: '* Contraseña debe tener como máximo 15 caracteres',
              type: 'maxLength',
            }
          : { error: false, message: '', type: 'maxLength' };
      },
    minLength : (str) => {
        return str.length < 8
          ? {
              error: true,
              message: '* Contraseña debe tener como mínimo 8 caracteres',
              type: 'minLength',
            }
          : { error: false, message: '', type: 'minLength' };
      },
     inputRequired : (str) => {
        return str.length
          ? { error: false, message: '', type: 'required' }
          : { error: true, message: '* Campo obligatorio', type: 'required' };
      },
      lowerCaseLetters : (str) => {
        return str.match(/[a-z]/)
          ? { error: false, message: '', type: 'lowerCaseLetters' }
          : {
              error: true,
              message: '* Al menos una letra minúscula',
              type: 'lowerCaseLetters',
            };
      },
      upperCaseLetters : (str) => {
        return str.match(/[A-Z]/)
          ? { error: false, message: '', type: 'upperCaseLetters' }
          : {
              error: true,
              message: '* Al menos una letra mayúscula',
              type: 'upperCaseLetters',
            };
      },
      specialCharacters : (str) => {
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        return format.test(str)
          ? { error: false, message: '', type: 'SpecialCharacter' }
          : {
              error: true,
              message: '* Al menos un caracter especial',
              type: 'SpecialCharacter',
            };
      }
}

export default registerFormHelper;