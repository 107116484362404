import React ,{ useEffect } from 'react';
import styled from 'styled-components';
import logo from '../../../assets/images/success.svg';
import logoPalta from '../../../assets/images/palta-logo-500.svg';

const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BoxContainer = styled.div`
  width: 340px;
  min-height: 624px;
  display: flex;
  flex-direction: column;
  border-radius: 50px;

  box-shadow: 0px 0px 4.7px rgba(15, 15, 15, 0.28);
  position: relative;
  overflow: hidden;
  padding: 0px 10px 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RegisterFinish = () => {
  useEffect(() => {
    localStorage.clear();
  }, [])
  return (
    <>
      <AppContainer>
        <BoxContainer>
          <InnerContainer>
            <img
              src={logoPalta}
              width="120"
              height="50"
              className="d-inline-block align-top position-logo "
              alt="Palta logo"
            />
            <h2 className="text-center"> Completaste tu registro en palta</h2>
            <img
              src={logo}
              width="160"
              height="100"
              className="d-inline-block align-top mb-4 "
              alt="Palta logo"
            />
            <p className="text-center bold">
              En el transcurso de 20 minutos  recibiras un mail con la confirmación de alta de tu cuenta.
            </p>
          </InnerContainer>
        </BoxContainer>
      </AppContainer>
    </>
  );
};
