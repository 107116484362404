import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';

import PaltaList from '../../components/PaltaList/PaltaList';
import PaltaInfoContainer from '../../components/PaltaInfoContainer/PaltaInfoContainer';
import PaltaBreadcrumbs from '../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import PaltaListMenuItem from '../../components/PaltaListMenuItem/PaltaListMenuItem';
import PaltaListDetailsItem from '../../components/PaltaListDetailsItem/PaltaListDetailsItem';
import fetchApiMig from '../../core/FetchApiMig';
import PaltaModal from '../../components/PaltaModal';
import * as ROUTES from '../../constants/routes';
import * as API from '../../constants/api-staging';
import userHelper from '../../helpers/user';
import './itemPaid.css';

class App extends Component {
  _id = userHelper.getID();

  state = {
    amount: '',
    description: '',
    from_nickname: '',
    to_nickname: '',
    created_at: '',
    status: '',
    loan: {
      _id: '',
      isLoaded: false,
      paid: false,
      canSetAsPayed: false,
    },
    loading: true,
  };

  UNSAFE_componentWillMount() {
    const self = this;
    const { _txsId } = this.props.computedMatch.params;

    this.setState({ loading: true });
    fetchApiMig('GET', API.TRANSACTIONS + '/' + _txsId)
      .then((body) => {
        const {
          amount,
          description,
          from_nickname,
          to_nickname,
          created_at,
        } = body;
        const { isLoan, __loanData } = body;
        console.log('isLoan, __loanData', isLoan, __loanData);

        let status = this._id === String(body.to) ? 'Recibido' : 'Enviado';
        console.log('status', status);

        let newState = {
          amount,
          description,
          from_nickname,
          to_nickname,
          created_at,
        };

        if (!isLoan) {
          if (status === 'Enviado') {
            newState.backgroundColor = '#28dd8b';
            newState.status = status;
            newState.breadcrumbsColor = 'green';
            newState.textColor = '#FFFFFF';
            // this.setState(newState)
          } else {
            newState.backgroundColor = '#EAF8FF';
            newState.status = status;
            newState.breadcrumbsColor = 'cyan';
            newState.textColor = '#49BDF6';
            // this.setState(newState);
          }
        } else {
          // let newState = {};
          if (status === 'Enviado') {
            newState.backgroundColor = '#28dd8b';
            newState.status = status;
            newState.breadcrumbsColor = 'green';
            newState.textColor = '#FFFFFF';
          } else {
            newState.backgroundColor = '#e25b5b';
            newState.status = status;
            newState.breadcrumbsColor = 'red';
            newState.textColor = 'white';
          }
          let canSetAsPayed = __loanData.type === 'from';
          newState.loan = {
            isLoaded: true,
            canSetAsPayed,
            paid: __loanData ? __loanData.paid : null,
            _id: _txsId,
          };
          // self.setState(newState);
        }
        self.setState(newState);
        // self.setState({ amount, description, from_nickname, to_nickname, created_at });
      })
      .catch((error) => {
        toast.error(`Error al obtener la transferencia`);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  payLoan() {
    const self = this;
    fetchApiMig(
      'PUT',
      API.TRANSACTIONS + '/' + this.state.loan._id + '/markAsPaidLoan',
    )
      .then((body) => {
        if (body._id) {
          toast.success(` 🤙 Se ha pagado con éxito `, {
            hideProgressBar: false,
            autoClose: 2000,
          });
          self.setState({ loan: { _id: '', paid: true, isLoaded: true } });
        }
      })
      .catch((error) => toast.error(`Error al pagar el préstamo`));
  }

  render() {
    let {
      backgroundColor,
      status,
      breadcrumbsColor,
      textColor,
      loan,
      amount,
      description,
      from_nickname,
      to_nickname,
      from_image,
      to_image,
      created_at,
      loading,
    } = this.state;

    return (
      <div className="App" style={{ backgroundColor }}>
        <PaltaBreadcrumbs
          link={ROUTES.DASHBOARD}
          name="Detalles"
          variant={breadcrumbsColor}
        />
        <section style={{ paddingBottom: '5em', color: textColor }}>
          <PaltaInfoContainer
            subtitle={new Date(created_at).toLocaleDateString()}
            amount={status === 'Enviado' ? `$${amount}` : `+ $${amount}`}
            tagText={status}
            tagVariant={
              status === 'Enviado'
                ? 'paid'
                : loan._id
                ? 'received loan'
                : 'received'
            }
            loan={loan}
            payLoan={this.payLoan.bind(this)}
          />
        </section>
        <section className="light">
          <PaltaList className="list-item-details">
            <PaltaListMenuItem
              title={
                status === 'Enviado'
                  ? loan._id
                    ? 'Prestado a'
                    : 'Pagado a'
                  : loan._id
                  ? 'Préstamo de'
                  : 'Recibido de'
              }
              subtitle={status === 'Enviado' ? to_nickname : from_nickname}
              variant="main"
              imgsrc={status === 'Enviado' ? to_image : from_image}
            />
            <PaltaListDetailsItem title="Descripcion" message={description} />
          </PaltaList>
        </section>
        {loading && (
          <PaltaModal show={loading} size="sm">
            <h3 className="subtitle">
              <Spinner animation="grow" variant="primary" /> Cargando{' '}
            </h3>
          </PaltaModal>
        )}
      </div>
    );
  }
}

export default App;
