import React, { Component } from 'react';
import './PaltaListUser.css';
import emptyProfile from '../../assets/images/empty-profile.jpg';

export default class index extends Component {
  render() {
    let {
      className,
      name,
      username,
      amountDate,
      amountAmount,
      quotaTitle,
      quotaNumber,
      imgSrc,
      contentLeft,
      contentRight,
      received,
      onClick,
    } = this.props;
    return (
      <div
        onClick={onClick}
        style={{
          borderRadius: '16px',
          marginBottom: '8px',
          boxShadow: '2px 2px 6px rgba(50,50,50,0.2)',
        }}
      >
        <li className={`item-palta-user ${className ? className : ''}`}>
          {(name || username) && (
            <div className="box-transaction">
              {imgSrc ? (
                <figure className="box-img">
                  <img src={imgSrc} alt={name || 'placeholder'} />
                </figure>
              ) : (
                <figure className="box-img">
                  <img src={emptyProfile} alt="" />
                </figure>
              )}
              <div className="text">
                {name && <h5>{name}</h5>}
                {username && <span className="username">{username}</span>}
              </div>
              {(amountDate || amountAmount) && (
                <div className="infoAmount">
                  <span className="date">{amountDate}</span>
                  <span className={`amount ${received && 'received'}`}>
                    {amountAmount}
                  </span>
                </div>
              )}
              {(quotaTitle || quotaNumber) && (
                <div className="quota">
                  {quotaTitle && <span className="title">{quotaTitle}</span>}
                  {quotaNumber && <span className="number">{quotaNumber}</span>}
                </div>
              )}
            </div>
          )}
          {(contentLeft || contentRight) && (
            <div className="content">
              {contentLeft && <div className="left">{contentLeft}</div>}
              {contentRight && <div className="right">{contentRight}</div>}
            </div>
          )}
        </li>
      </div>
    );
  }
}
