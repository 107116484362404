export function getNumbers(billBody) {
  const segments = billBody.split('\n');
  const pureNumbers = segments.filter((segment) => {
    const dividedCharacters = segment
      .replace(/" "/gi, '')
      .split('')
      .map((char) => +char);

    const areAllNumbers = !dividedCharacters.some((char) => {
      return (
        typeof char === 'number' && isNaN(char) && !char >= 1 && !char <= 9
      );
    });

    return areAllNumbers;
  });
  return pureNumbers;
}

export const sanitizeAmount = (amount, decimals = 2) => {
  if (typeof amount !== 'undefined') {
    return amount
      .toFixed(decimals)
      .toString()
      .replace('.', ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
};

export const getCurrencyPair = (originCurrency) => {
  if (originCurrency === 'peso') return 'dolar';
  if (originCurrency === 'dolar') return 'peso';
  if (originCurrency === 'peso_activa') return 'dolar_activa';
  if (originCurrency === 'dolar_activa') return 'peso_activa';
  if (originCurrency === 'peso_tourism') return 'dolar_tourism';
  if (originCurrency === 'dolar_tourism') return 'peso_tourism';
};

export const getCurrencyLabel = (originCurrency) => {
  if (originCurrency === 'peso') return 'Pesos';
  if (originCurrency === 'dolar') return 'USDP';
  if (originCurrency === 'peso_activa') return 'Crédito Activa';
  if (originCurrency === 'dolar_activa') return 'USDP Activa';
  if (originCurrency === 'peso_tourism') return 'Crédito Turismo';
  if (originCurrency === 'dolar_tourism') return 'USDP Turismo';
};

export const trunc = (x, posiciones = 2) => {
  const s = x.toString();
  const l = s.length;
  const decimalLength = s.indexOf('.') + 1;
  if (l - decimalLength <= posiciones) {
    return x;
  }
  const isNeg = x < 0;
  const decimal = x % 1;
  const entera = isNeg ? Math.ceil(x) : Math.floor(x);
  const decimalFormated = Math.floor(
    Math.abs(decimal) * Math.pow(10, posiciones),
  );
  var finalNum =
    entera + (decimalFormated / Math.pow(10, posiciones)) * (isNeg ? -1 : 1);

  return finalNum;
};

export const availableWithTaxApplied = (amount, taxPercentage) => {
  const tax = (amount / 100) * taxPercentage
  return amount - tax;
}
