import firebase, { initializeApp } from 'firebase/app';
import 'firebase/auth';
import "firebase/messaging";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID
};

const app = firebase.initializeApp(config);

let messaging = {
   onMessage: () => {},
   getToken: async () => {}
}


const isMessagingSupported = firebase.messaging.isSupported();

if (isMessagingSupported) {
   messaging = firebase.messaging(app);
}




const auth = firebase.auth();

export { auth, firebase, app, messaging, isMessagingSupported };
