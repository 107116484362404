import * as API from '../../constants/api-staging';
import userHelper from '../../helpers/user';
//TODO verificar donde se esta llamando este componente y cambiarlo por el nuevo fetcher
const fetchApiMig = (method, urlPath, body, returnErrors) => {

    if (method === "GET") {
        return fetch(`${API.URL}${urlPath}`, {
            method: method,
            cache: "no-cache",
            headers: {
                "Content-Type": " application/json",
                "Authorization": userHelper.getToken()
                // "Access-Token": context.accessToken,
                // "Token-Type": " Bearer",
                // "Client": context.client,
                // "Expiry": context.expirySession,
                // "Uid": context.user.uid,
                // "Host": "",
                // "Cookie": "",
            },
        })
            .then(res => {
                // refreshToken(context)
                if (!res.ok) {
                    if (res.status === 401) console.log(`Error de autenticación ${res.status}`);
                    if (res.status > 401) console.log(`Error en el servidor: Error ${res.status}`);
                    throw res.status;
                } else {
                    return res.json();
                }
            })
    } else {
        return fetch(`${API.URL}${urlPath}`, {
            method: method,
            headers: {
                "Content-Type": " application/json",
                "Authorization": userHelper.getToken()
                // "Access-Token": context.accessToken,
                // "Token-Type": " Bearer",
                // "Client": context.client,
                // "Expiry": context.expirySession,
                // "Uid": context.user.uid,
                // "Host": "",
                // "Cookie": "",
            },
            cache: "no-cache",
            body: JSON.stringify(body)
        })
            .then(res => {
                if (!res.ok) {
                    console.log(`Error de autenticación ${res.status}`);
                    console.log(`Error en el servidor: Error ${res.status}`);
                    if(returnErrors) return res.json();
                    else throw res.status;
                } else {
                    return res.json();
                }
            })
    }
}

export default fetchApiMig;