import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import logo from '../../../assets/images/email.svg';
import logoPalta from '../../../assets/images/palta-logo-500.svg';
const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BoxContainer = styled.div`
  width: 340px;
  min-height: 624px;
  display: flex;
  flex-direction: column;
  border-radius: 50px;

  box-shadow: 0px 0px 4.7px rgba(15, 15, 15, 0.28);
  position: relative;
  overflow: hidden;
  padding: 0px 10px 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SuccessRegister = () => {
  function closeMe() {
    window.close();
  }
  return (
    <>
      <AppContainer>
        <BoxContainer>
          <InnerContainer>
            <img
              src={logoPalta}
              width="120"
              height="50"
              className="d-inline-block align-top position-logo "
              alt="Palta logo"
            />
            <h2 className="text-center">Bienvenido a Palta</h2>
            <img
              src={logo}
              width="160"
              height="100"
              className="d-inline-block align-top mb-4 "
              alt="Palta logo"
            />

            <p className="text-center bold mb-0">
              Enviamos un mail a tu correo
              electrónico para activar tu cuenta y disfrutar de nuestros beneficios .
            </p>
            <p className="text-center bold">
              Revisa la casilla principal y/o el correo no deseado(Spam).
            </p>
            <div className="position-button-success d-flex justify-content-center align-items-center mt-4">
              <Button
                type="submit"
                className=" active btn-block btn-fullwidthe btnColor mt-4"
                onClick={closeMe}
                style={{
                  fontSize: 18,
                  borderRadius: 5,
                  fontFamily: 'inherit',
                  textTransform: 'none',
                }}
              >
                Cerrar
              </Button>
            </div>
          </InnerContainer>
        </BoxContainer>
      </AppContainer>
    </>
  );
};
