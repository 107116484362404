
import React, {useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import logoPalta from '../../../../assets/images/palta-logo-500.svg';
import * as ROUTES from '@constants/routes';
import { useUserValidateId } from '../../../../core/api-hooks/use-validation-id-user/get';
import '../../../register-form/register.css';
import Swal from 'sweetalert2';
const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

`;

const BoxContainer = styled.div`
  width: 340px;
  min-height: 624px;
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 0px 0px 4.7px rgba(15, 15, 15, 0.28);
  position: relative;
  overflow: hidden;
  padding: 0px 10px 0px 10px;
  align-items: center;
`;


const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

export const CommerceBeneMzaActRegisterValidIdentity = () => {

  const history = useHistory();
  const { id } = useParams();
  const idVerification = localStorage.getItem('userID') || id;
  const [{ data: dataValid, error: errorData }, getUserValidateId] = useUserValidateId();

  useEffect(() => {
    getUserValidateId(idVerification)
    history.goForward();
   }, [])

  useEffect(() =>{
    if(errorData){
    Swal.fire({
      icon: 'error',
      title: 'El id ingresado no es válido!',
      text: 'Por favor intente nuevamente.',
      showConfirmButton : true,
    }).then(res =>{
        history.push(ROUTES.PALTA);
    });
  }
  },[errorData]) 

  function escapeRegExp(string) {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(`<!doctype html><body>${string}`, 'text/html').body.textContent;
    return decodedString
  }
  const myFunction = () => {
    document.getElementById("mati-button").click();
    const button = document.getElementById("mati-button");
    // setup callbacks
    // Message when a flow starts
    button.addEventListener('mati:loaded', ({ detail }) => {
      console.log('Started Flow', detail)
    })
    // Call to receive Identity after finishing the upload oin the SDK
    button.addEventListener('mati:userFinishedSdk', ({ detail }) => {
      history.push(ROUTES.REGISTER_FINISH);
      console.log('Finished Flow', detail);
      //window.location.href="https://www.getmati.com/";
    })
    // Call to receive message of flow not finished
    let sessionId = "1231"
    button.addEventListener('mati:exitedSdk', ({ detail }) => {
      console.log('Abandoned Flow')

    })
  }
  return (
    <>
      <AppContainer>
        <BoxContainer>
          <img
            src={logoPalta}
            width="120"
            height="50"
            className="d-inline-block align-top position-logo-palta-form-user "
            alt="Palta logo"
          />
          <div className="input-container">
            <div className="row d-flex justify-content-center mt-4">
              <div className="col-12 mr-4 ml-4 mb-3 col-sm-12">
                <h5 className="text-center">
                  A continuación comenzaremos con la validación de identidad , por favor tené a mano
                  tu dni que solicitaremos durante el proceso.
                </h5>
                <div className="form-group  text-center mb-0" style={{ paddingTop: 134 }}>
                  <h5 className="text-center">
                    Al finalizar la validación recibirás un correo electrónico indicandote cuando tu cuenta este activa,
                    en caso de no recibirlo comunicate a nuestro canal de atención haciendo click
                    <a
                      style={{ textDecoration: "underline" }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://api.whatsapp.com/send/?phone=%2B5492613665216&text=Hola+Palta+quiero+registrarme&app_absent=0"
                    >
                      <p> aqui</p>
                    </a>
                  </h5>

                </div>
              </div>
            </div>
          </div>

          <div className="position-validation-identity d-flex justify-content-center align-items-center mt-4">
            <>
              <Button
                type="submit"
                size="md"
                className=" active btn-block btn-fullwidthe bg-danger mt-0"
                onClick={() => history.push(`${ROUTES.MENU_BENE_MZA_ACT_COMMERCE_PAGE_3}/${id}`)}
                style={{ fontSize: 18, borderRadius: 5, fontFamily: "inherit", height: 43, textTransform: "none", marginRight: 4 }}
                data-cy="button-volver"
              >
                Volver
              </Button>
              <button id="personalized_button" onClick={() => myFunction()}>
                Siguiente
              </button>

              <mati-button
                id="mati-button"
                identityId={`${localStorage.getItem("identity")}`}
                clientid={`${process.env.REACT_APP_CLIENT_ID}`}
                metadata={localStorage.getItem("userID") ? escapeRegExp(`{"Client":"${localStorage.getItem("userID")}"}`) : escapeRegExp(`{"Client":"${id}"}`)}
                flowId={`${process.env.REACT_APP_FLOW_ID}`}
              />
            </>
          </div>
        </BoxContainer>
      </AppContainer>

    </>
  );
};

