import axios from '../../Axios/Axios';
import * as API from '../../../constants/api-staging';
import { makeSwalError } from '../../../helpers/swalError';

export const getFiscalCreditFees = async (walletId) => {
	const token = localStorage.getItem('token').replace(/"/g, '');
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `${token}`,
	}
	try {
		const response = await axios.get(`${API.BASE_URL}/wallet/${walletId}/get-fiscal-credit-fees`, { headers });
		return response.data;
	} catch (error) {
		makeSwalError('Hubo Un Error')
	}
}