import styled from 'styled-components';

export const GeneralHeading = styled.h1`
  font-family: 'ProductSansBold', sans-serif;
  color: #3a4d9f;
  margin-left: 15px;
  font-size: 29px;

  @media (max-width: 768px) {
  }
`;

export const MediumHeading = styled.h3`
  font-family: 'ProductSansBold', sans-serif;
  color: #3a4d9f;
  margin-left: 15px;
  font-size: 29px;

  @media (max-width: 768px) {
    margin-left: 0px;
  }
`;

export const SmallHeading = styled.h3`
  font-family: 'ProductSansBold', sans-serif;
  color: #3a4d9f;
  margin-left: 15px;
  margin-top: 20px;
  font-size: 22px;

  @media (max-width: 768px) {
    margin-left: 0px;
  }
`;

export const SimpleContainer = styled.div`
  font-family: 'ProductSansBold', sans-serif;
  color: #3a4d9f;
  margin: 0 200px;

  @media (max-width: 768px) {
    margin: 0 0px;
  }
`;

export const SimpleButton = styled.button`
  font-family: 'ProductSansBold', sans-serif;
  background-color: ${props => props.disabled ? '#cccccc': props.backgroundColor ? '#85BB65' : '#3a4d9f'};
  color: white;
  border: 0px;
  border-radius: 15px;
  padding: 1em 3em;
  margin: 0.8em auto;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const SmallParagraph = styled.p`
  font-family: 'ProductSansBold', sans-serif;
  color: black;
  // text-align: left;

  @media (max-width: 768px) {
    margin: 0;
  }
`;
