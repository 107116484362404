import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Wrapper } from '@components/grid';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import PaltaModalSpinner from '../../components/PaltaModalSpinner';
import PaltaBreadcrumbs from '../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import * as ROUTES from '../../constants/routes';
import { Col, Row } from 'react-bootstrap';
import { GeneralHeading, SimpleButton } from '../../components/UI/UIComponents';
import { makeTransaction } from '../../core/api-hooks/dollar-utils/paltaDollars';
import './Dollar.css';
import { getCurrencyLabel, getCurrencyPair, sanitizeAmount, trunc } from '../service-payments/utils';
import { useHistory } from 'react-router';
import { fireSwal } from '../../components/utils/swalUtils';
import { motion } from 'framer-motion';

const TransactionDetails = () => {
    const location = useLocation();
    const history = useHistory();
    const { originWallet, destinationWallet, amount, total, rate, transactionType } = location.state;
    const originCurrencyIsDolar = originWallet.currency.includes('dolar');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        history.goForward(ROUTES.DOLLAR_TRANSACTION_SUCCESS);
    }, [])

    const handlePayment = async () => {
        try {
            let body = {
                _walletFromId: originWallet._id,
                _walletToId: destinationWallet._id,
                rate,
                dollarTransaction: true
            }
            let paymentInfo = {
                destinationCurrency: getCurrencyPair(originWallet.currency),
                originCurrency: originWallet.currency
            }

            if (transactionType === 'ask') {
                body = {
                    ...body,
                    amount: trunc((amount / rate) * rate),
                    transactionType
                }
                paymentInfo = {
                    ...paymentInfo,
                    amountPayed: (amount / rate) * rate,
                    amountReceived: amount / rate
                }
            } else {
                body = {
                    ...body,
                    amount: trunc((amount / rate) * rate),
                    transactionType
                }
                paymentInfo = {
                    ...paymentInfo,
                    amountPayed: (amount / rate) * rate,
                    amountReceived: (amount * rate)
                }

            }

            setLoading(true);
            const response = await makeTransaction(body);
            setLoading(false);

            if (response.status === 200) {
                history.push({
                    pathname: ROUTES.DOLLAR_TRANSACTION_SUCCESS,
                    state: { ...paymentInfo, transactionCode: response.data.data.transactionCode }
                })
            }

        } catch (error) {
            fireSwal('Hubo un error en la operación. Si persiste, por favor contactate con Palta WhatsApp', 3000)
            history.push(ROUTES.DASHBOARD)
        }

    }
    return (
        <>
            <PaltaNavBar className="light" />
            <PaltaBreadcrumbs
                name="Elegir otro monto"
                link={ROUTES.DASHBOARD}
                variant="dark"
            />
            <Container>
                <motion.div
                    initial={{ x: '100%', opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ type: 'spring', duration: .4, bounce: 0.2 }}
                >
                    <Wrapper>
                        <Row>
                            <Col>
                                <GeneralHeading>
                                    Detalle de la transacción
                                </GeneralHeading>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ margin: '0 .7em' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        marginTop: '1em',
                                    }}
                                >
                                    <div
                                        style={{
                                            flexBasis: '60%',
                                            fontSize: '25px'
                                        }}
                                        className="data-label">
                                        Recibís
                                    </div>
                                    <div
                                        className="data colorFont"
                                        style={{
                                            textTransform: 'capitalize',
                                            textAlign: 'end',
                                            fontWeight: 'bold',
                                            fontSize: '25px',
                                        }}
                                    > {' '}
                                        <span
                                            style={{
                                                color: transactionType === 'ask' && '#85BB65',
                                                fontWeight: 'bold',
                                                fontSize: '25px'
                                            }}>
                                            {getCurrencyLabel(getCurrencyPair(originWallet.currency))}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div
                                        style={{
                                            flexBasis: '60%',
                                            fontSize: '25px',
                                        }}
                                        className="data-label">
                                        Cantidad
                                    </div>
                                    <div
                                        className="data colorFont"
                                        style={{
                                            textTransform: 'capitalize',
                                            fontWeight: 'bold',
                                            fontSize: '25px',
                                        }}
                                    >{transactionType === 'ask' ? <p
                                        style={{ color: transactionType === 'ask' && '#85BB65', fontWeight: 'bold', textAlign: 'end' }}
                                    >USDP {sanitizeAmount(amount / rate)}</p> : `$ ${sanitizeAmount((amount * rate))}`}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <div
                                        style={{
                                            flexBasis: '40%',
                                            fontSize: '25px'
                                        }}
                                        className="data-label">
                                        Pagás
                                    </div>
                                    <div
                                        className="data colorFont"
                                        style={{
                                            textTransform: 'capitalize',
                                            fontWeight: 'bold',
                                            fontSize: '25px'
                                        }}
                                        data-cy='business-name'
                                    >
                                        {transactionType === 'ask' ?
                                            <span>${sanitizeAmount((amount / rate) * rate)}</span>
                                            :
                                            <p
                                                style={{ color: transactionType === 'bid' && '#85BB65', fontWeight: 'bold' }}
                                            >USDP {sanitizeAmount((amount * rate) / rate)}
                                            </p>
                                        }

                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ textAlign: 'center', marginBottom: '3em' }}>
                                <SimpleButton
                                    onClick={handlePayment}
                                    backgroundColor='#85BB65'
                                >
                                    CONFIRMAR
                                </SimpleButton>
                            </Col>
                        </Row>

                    </Wrapper>
                </motion.div>
            </Container >

            <PaltaFooter />
            <PaltaModalSpinner show={loading} size="sm" />
        </>
    )
}

export default TransactionDetails