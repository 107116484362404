import React from "react";
import { Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
export const CreatePdfCashout = ({ data, escapeRegExp, commission }) => {
  
  return (
    <Document>
      <Page style={styles.body}>
        <Image style={[styles.img, styles.logoline]} src="./assets/images/detalle-r.png" />
        <View >
          <Text style={[styles.text, styles.sep]} >Fecha del retiro :
            {
              format(
                new Date(data.createdAt),
                ' dd-MMMM-yyy, HH:mm',
                { awareOfUnicodeTokens: true, locale: es },
              )
            }
          </Text>

          {data._userFromId !== undefined && (data._userFromId.role !== "commerce" || data._userFromId.role !== "cash" || data._userFromId.role !== "branch") ? (

            <Text style={styles.text} >Nombre y Apellido : {data.counterparty.name}</Text>

          ) : data._userFromId.role === "commerce" || data._userFromId.role === "cash" || data._userFromId.role === "branch" ? (
            <Text style={styles.text} >Nombre del Negocio : {data._userFromId.businessName}</Text>
          )
            : data._userFromId.role === "commerce" || data._userFromId.role === "cash" || data._userFromId.role === "branch" ?
              (<Text style={styles.text} >
                Nombre Fantasía : {escapeRegExp(`${data._userFromId.fantasyName}`)}
              </Text>) : null
          }
          <Text style={styles.text} >Monto del retiro :  ${data.amount.toFixed(2)}</Text>

          {data._walletFromId.currency === "fiscal_credit" ? (
            <Text style={styles.text}>Monedero: Crédito Fiscal</Text>
          ) : data._walletFromId.currency === "peso" ? (
            <Text style={styles.text}>Monedero: Pesos</Text>
          ) : data._walletFromId.currency === "peso_tourism" ? (
            <Text style={styles.text}>Monedero: Crédito Turismo</Text>
          ) : data._walletFromId.currency === "	peso_activa" ? (
            <Text style={styles.text}>Monedero: Crédito Activa</Text>
          ) : null}

          {data.type === "cashout" && data.counterparty !== undefined && data.counterparty.account_routing?.scheme !== undefined && data.counterparty.account_routing?.address !== undefined ? (
            <div className="modal-item-detail m-3" style={{ background: "#EDEDED", borderRadius: "30px" }}>
              {data.counterparty.account_routing?.scheme !== undefined ? (
                <Text style={styles.text}>CBU/CVU : {data.counterparty.account_routing?.address} </Text>
              ) : null}
            </div>
          ) : null}

          {data.status === "pending" ? (
            <Text style={styles.text}>Estado del retiro : {data.status.replace('pending', 'Pendiente')}</Text>
          ) :
            <Text style={styles.text}>Estado del retiro : {data.status.replace('approved', 'Aprobado')}</Text>
          }
          {commission !== undefined ? (
            <Text style={styles.text} >Comisión + impuesto:  {commission} </Text>
          ) : null}

          {data.feePercent !== undefined ? (
            <Text style={styles.text} >Porcentaje de la tarifa :  {data.feePercent} % </Text>
          ) : null}

          {data.xnetData !== undefined ? (
            <>
              <Text style={styles.hr}></Text>
              <Text style={styles.texta}>Pago ATM - xnet</Text>

              <Text style={styles.text}>Monto : {data.xnetData.details.amount.toFixed(2)}</Text>


              <Text style={styles.text}>Concept.Descripción: {data.xnetData.details.concept_description}</Text>


              <Text style={styles.text}>Concept.Id : {data.xnetData.details.concept_id}</Text>


              <Text style={styles.text}>External TransactionId : {data.xnetData.external_transaction_id}</Text>


              <Text style={styles.text}>Payer Email: {data.xnetData.payer.email}</Text>


              <Text style={styles.text}>Payer Número : {data.xnetData.payer.identification.number}</Text>


              <Text style={styles.text}>Payer Tipo : {data.xnetData.payer.identification.type}</Text>


              <Text style={styles.text}>Payer Nombre :  {data.xnetData.payer.name}</Text>

            </>
          ) : null}
          {data.transactionCode !== undefined ? (
            <Text style={styles.text} >N° operación :{data.transactionCode}</Text>
          ) : null}


        </View>


      </Page>
    </Document>
  )
}
const styles = StyleSheet.create({

  logo: {
    width: 70,
    height: 70,
  },
  logoline: {
    width: "105%",
    height: 140,
  },
  body: {
    paddingBottom: 65,
    paddingHorizontal: 35,
    padding: 15,

  },
  contentTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 10
  },
  title: {
    fontSize: 35,
    color: "#212121",
  },
  text: {
    marginTop: 30,
    width: "100%",
    backgroundColor: "#f0f0f0",
    paddingHorizontal: 20,
    paddingVertical: 10,
    color: "#212121",

  },
  texta: {
    marginTop: 15,
    width: "100%",
    paddingHorizontal: 20,
    paddingVertical: 10,
    color: "green",
    textAlign: 'center',
    fontSize: 20,
    fontWeight: "bold"
  },
  textb: {
    marginTop: 30,
    width: "100%",
    paddingHorizontal: 20,
    paddingVertical: 10,
    color: "green",
    textAlign: 'center',
    fontSize: 35,
    fontWeight: "bold"
  },
  hr: {
    border: "1px solid green",
    height: 2,
    width: "100%",
    textAlign: 'center',
    marginTop: 15,
  },
  img: {

    position: "absolute",
    top: 0,
    left: 0,
  },
  sep: {
    marginTop: 140,
  }

})