import React, { useState, useEffect } from 'react';
import { Wrapper } from '@components/grid';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import PaltaBreadcrumbs from '../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import PaltaModalSpinner from '../../components/PaltaModalSpinner';
import { Link, useLocation } from 'react-router-dom';
import { GridWallet } from '../../components/grid/index'
import styled from 'styled-components';
import * as ROUTES from '../../constants/routes'
// import './ServicePayments.css';
import './servicePaymentWallets.css';
import { Col, Row, Container } from 'react-bootstrap';
import { useHistory } from 'react-router';
import * as API from '../../constants/api-staging';
import { pagarLote } from '../../core/api-hooks/captar-pagos/pagarLote';
import { fireSwal } from '../../components/utils/swalUtils';
import ModalFailure from '../../components/ModalFailure/ModalFailure';
import { SeeFailure } from '../../components/SeeFailure/SeeFailure';

export const sanitizeAmount = amount => {
    return amount.toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}


const PaymentCashout = () => {
    const location = useLocation();
    const prevData = location.state;
    const activaFee = Number(API.CAPTAR_FEE_ACTIVA);
    const paymentAmount = Number(prevData.payBody.factura[0].importe);
    const paymentAmountWithFee = ((paymentAmount / 100) * activaFee) + paymentAmount
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const selectedCurrency = prevData.wallet.currency;
    const businessName = prevData.businessName;
    const businessNameNoPrefix = businessName.split(' ');
    const [viewModalError, setViewModalError] = useState(false);

    businessNameNoPrefix.shift();
    const handlePayButton = async () => {
        const payBody = { ...prevData.payBody, currency: selectedCurrency };

        try {
            setDisabledSubmit(true);
            setLoading(true);
            const response = await pagarLote(payBody);
            setLoading(false);
            
            if (response?.data?.data?.comprobante) { 
                const responseBody = { 
                    data: {
                        comprobante: response.data.data.comprobante,
                        monto: response.data.data.monto,
                        transaction: response.data.data.transaction
                    },
                    message: response.data.message
                }; 
                history.push({
                    pathname: ROUTES.SERVICE_PAYMENTS_SUCCESS,
                    state: {
                        responseBody, payBody: {...payBody, businessNameNoPrefix}
                    }
                })
            } else {
                setLoading(false);
                fireSwal('no se pudo completar el pago');
                // history.push(ROUTES.DASHBOARD);
            }
        } catch (error) {
            setLoading(false);
            
            setViewModalError(true);
        }

    }

    useEffect(() => {
        history.goForward(ROUTES.SERVICE_PAYMENTS_SUCCESS);
    }, []);

    return <div>
        <>
            <PaltaNavBar className="light" />
            <PaltaBreadcrumbs
                name="Seleccionar forma de pago"
                link={ROUTES.PAYMENTS_SELECT}
                variant="dark"
            />
            <section style={{ padding: '0.5rem 0px' }}></section>
            <Wrapper>
                <section style={{ padding: '1rem 0px' }}>
                    <Container>
                        <Row className="mb-3">
                            <Col>
                                <div className="data-label">Monedero</div>
                                <div
                                    className="data colorFont"
                                    data-cy='selected-wallet'
                                > {prevData.wallet.currency === 'peso' ? 'Peso' : 'Crédito Activa'}</div>
                            </Col>
                            <Col>
                                <div className="data-label"></div>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <div className="data-label">Saldo Disponible</div>
                                <div
                                    className="data colorFont"
                                    data-cy='available-funds'
                                > ${sanitizeAmount(Number(prevData.wallet.amount))}</div>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <div className="data-label">Monto Factura</div>
                                <div
                                    className="data colorFont"
                                    data-cy='service-amount'
                                > ${sanitizeAmount(Number(prevData.payBody.factura[0].importe))}</div>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <div className="data-label">Empresa</div>
                                <div className="data colorFont">{businessNameNoPrefix.join(' ')}</div>
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col>
                                <div className="data-label">Comision:</div>
                                <div
                                    className="data colorFont"
                                    data-cy='comission'
                                >{prevData.wallet.currency === 'peso' ?
                                    'Sin comisión' : `Comisión 1% : $${sanitizeAmount(((paymentAmount / 100) * activaFee))}`
                                    }</div>
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col>
                                <button
                                    className={disabledSubmit ? 'button-disabled' : 'button'}
                                    onClick={handlePayButton}
                                    disabled={disabledSubmit}
                                    data-cy='payment-button'
                                    style={{ fontSize: '0.85rem' }}
                                >
                                    Pagar ${prevData.wallet.currency === 'peso' ? sanitizeAmount(paymentAmount) : sanitizeAmount(paymentAmountWithFee)}
                                </button>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Wrapper>
            {viewModalError && (
            <ModalFailure
            show={true}
            onHide={() => {
                setViewModalError(false)
                history.push(ROUTES.DASHBOARD);
                }
                }>
            <div>
              <SeeFailure
              />
            </div>
          </ModalFailure>
        )}
            <PaltaFooter />
            <PaltaModalSpinner show={loading} size="sm" />
        </>

    </div>;
};

export default PaymentCashout;
