import axios from "axios";
import { BASE_URL } from "../../constants/api-staging";


export default async function getAllUnreadNotification({ userId }) {
    return axios.get(`${BASE_URL}/me/notification?userId=${userId}`, {
        headers: {
            Authorization: localStorage.getItem("token")
        }
    });
}