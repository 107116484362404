import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import PaltaBreadcrumbs from '../../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import { Wrapper } from '../../../components/wrapper';
import * as ROUTES from '../../../constants/routes';

export const ValidateCbuCvu = () => {
  const history = useHistory();

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <PaltaBreadcrumbs
        name="Validar los datos del CBU / CVU"
        link={ROUTES.CASH_OUT_CBU_CVU}
        variant="dark"
      />
      <section style={{ padding: '3rem 0px' }}>
        <Container>
          <Row>
            <Col>
              <h5>Validar datos del CBU /CVU</h5>
            </Col>
          </Row>
        </Container>
        <Wrapper>
          Acá se van a mostrar los datos del CBU / CVU para que el usuario
          valide si son correctos.
        </Wrapper>
        <Container>
          <Row>
            <Col md={{ span: 8, offset: 3 }}>
              <Button
                variant="primary"
                size="lg"
                className=" active btn-block btn-fullwidthe"
                onClick={() => history.push(ROUTES.CASH_OUT_AMOUNT)}
              >
                Validar datos
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};
