import React from 'react';
import styled from 'styled-components';
import { useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';

import { shopId } from '../../../store';
import * as ROUTES from '../../../constants/routes';

const ItemWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacers.s};
  margin-bottom: ${({ theme }) => theme.spacers.xs};
  border-radius: ${({ theme }) => theme.spacers.s};
  display: flex;
  align-items: center;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
`;

const ImageWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  overflow: hidden;
  background-color: rgba(54, 40, 221, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentWrapper = styled.div``;

const BusinessNameWrapper = styled.div`
  font-size: ${({ theme }) => theme.text.fontSize.m};
  margin-left: ${({ theme }) => theme.spacers.s};
  color:#3A4D9F
`;
export const ShopItem = ({ shop,escapeRegExp }) => {
  const { img, name, lastname,fantasyName, role } = shop;
  const setShopId = useSetRecoilState(shopId);
  const history = useHistory();

  const content = (
    <>
      {img ? (
        <ImageWrapper>
          <img src={img} alt={name || 'placeholder'} width={48} />
        </ImageWrapper>
      ) : (
        <ImageWrapper>
          {role === 'admin' && (
            <i className="material-icons shop-item-icon">
              manage_accounts
            </i>
          )}
          {role === 'user' && (
            <i className="material-icons shop-item-icon">
              account_circle
            </i>
          )}
          {role === 'commerce' && (
            <i className="material-icons shop-item-icon">
              storefront
            </i>
          )}

        </ImageWrapper>
      )}
      <ContentWrapper>
        <BusinessNameWrapper>
          {role === "user" ?
            `${name} ${lastname}`
            :
            escapeRegExp(`${fantasyName}`)
          }
        </BusinessNameWrapper>
      </ContentWrapper>
    </>
  );

  return (
    <ItemWrapper
      onClick={() => {
        history.push(ROUTES.PAYMENTS_SELECT_WALLET, {shopId : shop._id});
      }}
    >
      {content}
    </ItemWrapper>
  );
};
