import React, { useCallback, useEffect, useState } from 'react';
import ReactExport from 'react-export-excel';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { Grid, Col } from '@components/grid';
import { useGetWalletFilter } from '../../core/api-hooks/use-wallet/get';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
export const ButtonExportExcel = ({
  data,
  page,
  indexPage,
  wallet,
  userData,
  tokenData,
  setLoading,
  filter,
}) => {
  const year = new Date().getFullYear();
  const [{ data: dataWallet }, getWalletFilter] = useGetWalletFilter();
  const [dataResume, setDataResume] = useState([]);
  const [dataResumeByTypeTransaction, setDataResumeByTypeTransaction] =
    useState([]);

  const fetchData = useCallback(() => {
    getWalletFilter(wallet, userData, tokenData, filter);
  }, [wallet, userData, tokenData, filter]);

  const transactionsName = {
    amountCashin: 'Ingresos',
    amountCashout: 'Egresos',
    amountTransactionReceived: 'Transacciones recibidas',
    amountTransactionSent: 'Transacciones enviadas',
  };

  const transactionsType = {
    commission: 'Comision',
    transaction: 'Transaccion',
    tax: 'Impuesto',
    cashout: 'Retiro',
    cashin: 'Ingreso',
    promo: 'Promoción',
    sircupa: 'Impuesto Sircupa',
    'REVERTIR TRANSACCION': 'Reversión',
  };

  const getFormateDate = (activity) => {
    return format(new Date(activity.createdAt), 'dd/MM/yyy', {
      awareOfUnicodeTokens: true,
      locale: es,
    });
  };

  const calculateAmounts = (activities) => {
    const amountCashin = activities
      .filter((a) => a.type === 'cashin')
      .reduce((prev, curr) => prev + curr.amount, 0);
    const amountCashout = activities
      .filter((a) => a.type === 'cashout')
      .reduce((prev, curr) => prev + curr.amount, 0);
    const amountTransactionReceived = activities
      .filter(
        (a) =>
          a.type !== 'cashout' &&
          a.type !== 'cashin' &&
          a.type !== 'comission' &&
          a.type !== 'tax' &&
          a._userToId?._id === userData._id,
      )
      .reduce((prev, curr) => prev + curr.amount, 0);
    const amountTransactionSent = activities
      .filter(
        (a) =>
          a.type !== 'cashout' &&
          a.type !== 'cashin' &&
          a.type !== 'comission' &&
          a.type !== 'tax' &&
          a._userFromId?._id === userData._id,
      )
      .reduce((prev, curr) => prev + curr.amount, 0);
    setDataResume([
      { amountCashin },
      { amountCashout },
      { amountTransactionReceived },
      { amountTransactionSent },
    ]);
  };

  const resumeByTransactionTypeAndDate = (activities) => {
    const dataResumeByTypeTransaction = [];
    let dateOfTransactions = activities.map((activity) =>
      getFormateDate(activity),
    );
    dateOfTransactions = [...new Set(dateOfTransactions)];

    dateOfTransactions.forEach((date) => {
      const transactionByType = activities
        .filter((activity) => getFormateDate(activity) === date)
        .reduce(
          (prev, curr) => {
            const { type, _userToId, _userFromId } = curr;
            if (type === 'cashin') {
              prev.amountCashin += curr.amount;
            } else if (type === 'cashout') {
              prev.amountCashout += curr.amount;
            } else if (
              type !== 'cashout' &&
              type !== 'cashin' &&
              type !== 'comission' &&
              type !== 'tax' &&
              _userToId?._id === userData._id
            ) {
              prev.amountTransactionReceived += curr.amount;
            } else if (
              type !== 'cashout' &&
              type !== 'cashin' &&
              type !== 'comission' &&
              type !== 'tax' &&
              _userFromId?._id === userData._id
            ) {
              prev.amountTransactionSent += curr.amount;
            }
            return prev;
          },
          {
            amountCashin: 0,
            amountCashout: 0,
            amountTransactionReceived: 0,
            amountTransactionSent: 0,
          },
        );
      dataResumeByTypeTransaction.push({
        date,
        ...transactionByType,
      });
    });

    setDataResumeByTypeTransaction(dataResumeByTypeTransaction);
  };
  useEffect(() => {
    if (page) {
      if (
        userData &&
        userData?.role !== 'viewer' &&
        userData?.wallets?.length !== 0
      ) {
        if (wallet) {
          fetchData();
        }
      }
    }
  }, [page, userData, wallet, fetchData]);

  useEffect(() => {
    if (dataWallet) {
      calculateAmounts(dataWallet?.activities);
      resumeByTransactionTypeAndDate(dataWallet?.activities);
      setLoading(false);
    }
  }, [dataWallet]);
  function escapeRegExp(string) {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(
      `<!doctype html><body>${string}`,
      'text/html',
    ).body.textContent;
    return decodedString;
  }

  return (
    <>
      {data && data.activities && data.activities.length !== 0 ? (
        <>
          <ExcelFile
            element={
              <button className="btn btnColor text-white float-right">
                Descargar
              </button>
            }
            filename="Resumen de Actividades"
          >
            <ExcelSheet data={dataWallet?.activities} name="Actividades">
              <ExcelColumn
                label="Fecha"
                value={(col) =>
                  format(new Date(col.createdAt), 'yyyy') === year.toString()
                    ? format(new Date(col.createdAt), 'dd/MM/yyy - HH:mm', {
                        awareOfUnicodeTokens: true,
                        locale: es,
                      })
                    : format(new Date(col.createdAt), 'dd/MM/yyy - HH:mm', {
                        awareOfUnicodeTokens: true,
                        locale: es,
                      })
                }
              />
              <ExcelColumn label="N° de operacion" value="transactionCode" />
              <ExcelColumn
                label="Operacion"
                value={(col) => transactionsType[col.type]}
              />
              <ExcelColumn
                label="Desde"
                value={(col) => {
                  if (
                    col._userFromId?.role === 'user' ||
                    col.type === 'promo'
                  ) {
                    return `${col._userFromId?.name} ${col._userFromId?.lastname}`;
                  }
                  if (
                    col._userFromId?.role === 'commerce' ||
                    col._userFromId?.role === 'branch' ||
                    col._userFromId?.role === 'cash'
                  ) {
                    return `${escapeRegExp(
                      `${col._userFromId?.fantasyName || ''} - ${
                        col._userFromId?.businessName || ''
                      }`,
                    )}`;
                  }
                  if (
                    col.type === 'cashin' &&
                    col.counterparty !== undefined &&
                    col._userFromId === undefined
                  ) {
                    return `${col.counterparty?.name}`;
                  }
                  return null;
                }}
              />
              <ExcelColumn
                label="Hacia"
                value={(col) => {
                  if (
                    col._userToId?.role === 'user' ||
                    col.type === 'promo'
                  ) {
                    return `${col._userToId?.name} ${col._userToId?.lastname}`;
                  }
                  if (
                    col._userToId?.role === 'commerce' ||
                    col._userToId?.role === 'branch' ||
                    col._userToId?.role === 'cash'
                  ) {
                    return `${escapeRegExp(
                      `${col._userToId?.fantasyName || ''} - ${
                        col._userToId?.businessName || ''
                      }`,
                    )}`;
                  }
                  if (
                    col.type === 'cashin' &&
                    col.counterparty !== undefined &&
                    col._userToId === undefined
                  ) {
                    return `${col.counterparty?.name}`;
                  }
                  return null;
                }}
              />
              <ExcelColumn label="Monto" value={(col) => col.amount} />
              <ExcelColumn label="Monedero" value="currencyLabel" />
              <ExcelColumn
                label="Origen"
                value={(col) =>
                  col.counterparty?.account_routing?.scheme !== undefined
                    ? `${col.counterparty?.account_routing?.scheme}`
                    : null
                }
              />
              <ExcelColumn
                label="CVU/CBU"
                value={(col) =>
                  col.counterparty?.account_routing?.address !== undefined
                    ? `${col.counterparty?.account_routing?.address}`
                    : null
                }
              />
              <ExcelColumn
                label="Banco"
                value={(col) =>
                  col.counterparty?.bank !== undefined
                    ? `${col.counterparty?.bank}`
                    : null
                }
              />
              <ExcelColumn
                label="N° Transacción de origen"
                value={(col) =>
                  col._transactionOriginId?.transactionCode !== undefined &&
                  col.type === 'transaction'
                    ? `${col._transactionOriginId?.transactionCode}`
                    : (col.originTransactionCode !== undefined &&
                        col.type === 'tax') ||
                      (col.originTransactionCode !== undefined &&
                        col.type === 'commission')
                    ? `${col.originTransactionCode}`
                    : null
                }
              />
              <ExcelColumn
                label="Nombre de la Caja"
                value={(col) =>
                  col.originName !== undefined ? `${col.originName}` : null
                }
              />
            </ExcelSheet>
            <ExcelSheet data={dataResume} name="Resumen de actividades">
              <ExcelColumn
                label="Desde"
                value={(col) =>
                  format(filter.from, 'dd/MM/yyy', {
                    awareOfUnicodeTokens: true,
                    locale: es,
                  })
                }
              />
              <ExcelColumn
                label="Hasta"
                value={(col) =>
                  format(filter.to, 'dd/MM/yyy', {
                    awareOfUnicodeTokens: true,
                    locale: es,
                  })
                }
              />
              <ExcelColumn
                label="Tipo"
                value={(col) => transactionsName[Object.keys(col)[0]]}
              />
              <ExcelColumn
                label="Monto"
                value={(col) => Object.values(col)[0]}
              />
            </ExcelSheet>
            <ExcelSheet
              data={dataResumeByTypeTransaction}
              name="Resumen de actividades por día"
            >
              <ExcelColumn label="Fecha" value={(col) => col.date} />
              <ExcelColumn label="Ingreso" value={(col) => col.amountCashin} />
              <ExcelColumn label="Egreso" value={(col) => col.amountCashout} />
              <ExcelColumn
                label="Transacciones Recibidas"
                value={(col) => col.amountTransactionReceived}
              />
              <ExcelColumn
                label="Transacciones Enviadas"
                value={(col) => col.amountTransactionSent}
              />
            </ExcelSheet>
          </ExcelFile>
        </>
      ) : null}
    </>
  );
};
