import React from "react";
import { Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
export const CreatePdfTax = ({ data }) => {
  const year = new Date().getFullYear();
  return (
    <Document>
      <Page style={styles.body}>
        <Image style={[styles.img, styles.logoline]} src="./assets/images/detalle-imp.png" />
        <View>
          <Text style={[styles.text, styles.sep]} >Fecha del impuesto :
            {format(new Date(data.createdAt), 'yyyy') ===
              year
              ? format(
                new Date(data.createdAt),
                ' dd-MMMM-yyy, HH:mm',
                { awareOfUnicodeTokens: true, locale: es },
              )
              : format(
                new Date(data.createdAt),
                ' dd-MMMM-yyy, HH:mm',
                { awareOfUnicodeTokens: true, locale: es },
              )}
          </Text>

          <Text style={styles.text} >Monto del impuesto :  ${data.amount.toFixed(2)}</Text>

          {data._walletFromId.currency === "fiscal_credit" ? (
            <Text style={styles.text}>Monedero: Crédito Fiscal</Text>
          ) : data._walletFromId.currency === "peso" ? (
            <Text style={styles.text}>Monedero: Pesos</Text>
          ) : data._walletFromId.currency === "peso_tourism" ? (
            <Text style={styles.text}>Monedero: Crédito Turismo</Text>
          ) : data._walletFromId.currency === "peso_activa" ? (
            <Text style={styles.text}>Monedero: Crédito Activa</Text>
          ) : null}

          {data._transactionOriginId !== undefined ? (
            <Text style={styles.text} >Monto Inicial :  ${data._transactionOriginId.amount.toFixed(2)}</Text>
          ) : data._cashinOriginId !== undefined ? (
            <Text style={styles.text} >Monto Inicial :  ${data._cashinOriginId.amount.toFixed(2)}</Text>

          ) : data._cashoutOriginId !== undefined ? (
            <Text style={styles.text} >Monto Inicial :  ${data._cashoutOriginId.amount.toFixed(2)}</Text>
          ) : null}
          {data.originTransactionCode !== undefined && data.type === "tax" ? (
         <Text style={styles.text} >N° transacción origen :  {data.originTransactionCode}</Text>
          ) : null}

        </View>
      </Page>
    </Document>
  )
}
const styles = StyleSheet.create({

  logo: {
    width: 70,
    height: 70,
  },
  logoline: {
    width: "105%",
    height: 140,
  },
  body: {
    paddingBottom: 65,
    paddingHorizontal: 35,
    padding: 15,

  },
  contentTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 10
  },
  title: {
    fontSize: 35,
    color: "#212121",
  },
  text: {
    marginTop: 30,
    width: "100%",
    backgroundColor: "#f0f0f0",
    paddingHorizontal: 20,
    paddingVertical: 10,
    color: "#212121",

  },
  texta: {
    marginTop: 15,
    width: "100%",
    paddingHorizontal: 20,
    paddingVertical: 10,
    color: "green",
    textAlign: 'center',
    fontSize: 20,
    fontWeight: "bold"
  },
  textb: {
    marginTop: 30,
    width: "100%",
    paddingHorizontal: 20,
    paddingVertical: 10,
    color: "green",
    textAlign: 'center',
    fontSize: 35,
    fontWeight: "bold"
  },
  hr: {
    border: "1px solid green",
    height: 2,
    width: "100%",
    textAlign: 'center',
    marginTop: 15,
  },
  img: {

    position: "absolute",
    top: 0,
    left: 0,
  },
  sep: {
    marginTop: 140,
  }

})
