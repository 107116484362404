import axios from '../../Axios/Axios';
import * as API from '../../../constants/api-staging';

export const postDollarAcceptsUpdate = async (acceptsBoolean) => {
  const token = localStorage.getItem('token').replace(/"/g, '');
  const response = await axios.post(
    `${API.BASE_URL}/wallet/dollarwallet`,
    {
      acceptsDollarWallets: acceptsBoolean,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    },
  );
  return response;
};

export const getRates = async () => {
  const token = localStorage.getItem('token').replace(/"/g, '');
  const response = await axios.get(`${API.BASE_URL}/currency/rate`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });
  return response;
};

export const getPaltaDollarWallets = async () => {
  const token = localStorage.getItem('token').replace(/"/g, '');
  const response = await axios.get(`${API.BASE_URL}/wallet/dollarwallet`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });
  return response;
};

export const makeTransaction = async (payBody) => {
  const token = localStorage.getItem('token').replace(/"/g, '');
  const response = await axios.post(`${API.BASE_URL}/transaction`, payBody, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });
  return response;
};
