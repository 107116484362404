import {useCallback } from 'react';
import Axios from '../../Axios/Axios';
import * as API from '../../../constants/api-staging';
import Swal from 'sweetalert2';
export const useGetSendCodeSms = () => {
  const getSendSms = useCallback(() => {
    Axios.get(`${API.BASE_URL}/register/confirmationCode/${localStorage.getItem("userID")}`,
    
      {
      
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        },
      })
      .then(response => {
        Swal.fire({
          icon: 'success',
          title: `Código de validación enviado, revise la casilla de mensajes de su dispositivo movil `,
          padding: "2px",
          showConfirmButton: false,
          width: "400px",
          heightAuto: "200px",
          timer: 1500,
          customClass: {
            title: "m-0 text-success",
            header: "p-0"
          }
        })
      })
      .catch(error => {
      });

  }, []);

  return [
    getSendSms,
  ];
};
