import axios from '../../Axios/Axios';
import * as API from '../../../constants/api-staging';

export const getBarcodeFromImage = async (body) => {
  const token = localStorage.getItem('token').replace(/"/g, '');
  const response = await axios.post(`${API.BASE_URL}/gvision`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });
  return response;
};
