import React, { useState, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import logoPalta from '../../assets/images/palta-logo-500.svg';
import './register.css';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { dataFormInitialPalta, dataPhoneRegis, userId } from '../../store';
import { useSendFormEmailPhone } from '../../core/api-hooks/use-send-form-email-phone/post';
import PaltaModalSpinner from '../../components/PaltaModalSpinner';
import registerFormHelper from '../../helpers/registerForm';

const AppContainer = styled.div`
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BoxContainer = styled.div`
  width: 340px;
  min-height: 624px;
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 0px 0px 4.7px rgba(15, 15, 15, 0.28);
  position: relative;
  overflow: hidden;
  padding: 0px 10px 0px 10px;
`;

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  width: 100%;
`

export const Register = () => {
  const [dataPalta, setDataPalta] = useRecoilState(dataFormInitialPalta);
  const dataPaltaValue = useRecoilValue(dataFormInitialPalta);
  const { register, handleSubmit, errors, formState, control } = useForm({
    mode: 'onChange',
  });
  const [loading, setLoading] = useState(false);
  const setDataPhone = useSetRecoilState(dataPhoneRegis);
  const dataPhone = useRecoilValue(dataPhoneRegis);
  const [{ data: dataEmailPhone }, postEmailPhone] = useSendFormEmailPhone();
  const setUserId = useSetRecoilState(userId);
  const [regexResults, setRegexResults] = useState([]);

  setUserId(dataEmailPhone?._id);
  const [phone, setPhone] = useState(
    dataPaltaValue?.phone !== undefined ? dataPaltaValue?.phone : '',
  );
  const handleOnChange = (value) => {
    setPhone(value);
    setDataPhone(value);
  };
  const onSubmit = useCallback(
    async (data) => {
      const sendData = {
        email: data.email,
        password: data.password,
        phone: phone,
        phoneCompany: data.company,
      };
      setDataPalta(sendData);
      postEmailPhone(sendData, setLoading);
    },
    [dataPhone],
  );

  const regexValidations = (str) => {
    let arrayRegexResults = [];
    arrayRegexResults.push(
      registerFormHelper.lowerCaseLetters(str),
      registerFormHelper.upperCaseLetters(str),
      registerFormHelper.specialCharacters(str),
      registerFormHelper.maxLength(str),
      registerFormHelper.minLength(str),
      registerFormHelper.inputRequired(str)
    );
    setRegexResults(arrayRegexResults);
    return arrayRegexResults.some((item) => item.error === true) ? false : true;
  };

  return (
    <>
      <AppContainer>
        <BoxContainer>
          <InnerContainer>
            <img
              src={logoPalta}
              width="120"
              height="50"
              className="d-inline-block align-top position-logo-palta-form-user "
              alt="Palta logo"
            />

            <p style={{ marginTop: '18px', fontSize: 20, color: '#3A4D9F' }}>
              Formulario de Registro
            </p>

            <form onSubmit={handleSubmit(onSubmit)} className="mt-3">
              <div className="input-container">
                <input
                  name="email"
                  placeholder="Tu e-mail"
                  ref={register({
                    required: 'Required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    },
                  })}
                  defaultValue={dataPaltaValue?.email}
                  className="input-class"
                  data-cy="input-email"
                />

                <label className="label-text h3" htmlFor="name">
                  Email
                </label>
              </div>
              {errors.email && errors.email.type === 'required' && (
                <p className="input-error">El campo email es requerido</p>
              )}
              {errors.email && errors.email.type === 'pattern' && (
                <p className="input-error">El email es invalido</p>
              )}

              <div className="input-container mt-4 ">
                <input
                  type="password"
                  name="password"
                  className="input-class"
                  placeholder="Tu contraseña"
                  autoComplete="new-password"
                  defaultValue={dataPaltaValue?.password}
                  ref={register({
                    validate: {
                      regexValidation: (value) => regexValidations(value),
                    }
                  })}
                />
                <label className="label-text" htmlFor="name">
                  Contraseña
                </label>
              </div>
              <div className='mb-3'>
              {errors.password &&
                errors.password.type === 'regexValidation' &&
                regexResults.length &&
                regexResults
                  .filter((item) => item.error)
                  .map((regexValidation, index) => {
                    return (
                        <p className="input-error" key={index}>{regexValidation.message}</p>
                    );
                  })}
              </div>    
              <div className="input-container pt-4 mb-3">
                <PhoneInput
                  autoComplete="off"
                  name="phoneInput"
                  control={control}
                  rules={{ required: true }}
                  withCountryCallingCode
                  international
                  defaultCountry="AR"
                  countryCallingCodeEditable={false}
                  value={dataPhone !== undefined ? dataPhone : '+549'}
                  onChange={(e) => handleOnChange(e)}
                />
                <label className="label-phone mt-2" htmlFor="name">
                  Teléfono
                </label>
              </div>
              <div className="input-container pt-1 mb-3">
                <select
                  className="form-control mt-4 mb-3"
                  name="company"
                  ref={register({ required: 'Required' })}
                >
                  <option value="Claro">Claro</option>
                  <option value="Movistar">Movistar</option>
                  <option value="Tuenti">Tuenti</option>
                  <option value="Personal">Personal</option>
                </select>
                <label className="label-phone mt-2" htmlFor="name">
                  Compañia
                </label>
                {errors.company && errors.company.type === 'required' && (
                  <p className="input-error">
                    El campo contraseña es requerido
                  </p>
                )}
              </div>

              <div className="input-container pt-1 mb-3">
                <p
                  className="text-center"
                  style={{ fontSize: 15, fontWeight: 'none !important' }}
                >
                  Al registrarse,usted esta aceptando los
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://palta.app/terminos-y-condiciones.html"
                  >
                    <p> Términos y condiciones de palta</p>
                  </a>
                </p>
              </div>
              <ButtonContainer className=" d-flex justify-content-center align-items-center mt-2 mb-2">
                <Button
                  type="submit"
                  size="md"
                  className=" active btn-block btn-fullwidthe btnColor"
                  disabled={!formState.isValid}
                  style={{
                    fontSize: 18,
                    borderRadius: 5,
                    fontFamily: 'inherit',
                    textTransform: 'none',
                  }}
                  data-cy="button-ingresar"
                >
                  Siguiente
                </Button>
              </ButtonContainer>
            </form>
            {loading && <PaltaModalSpinner show={loading} size="sm" />}
          </InnerContainer>
        </BoxContainer>
      </AppContainer>
    </>
  );
};
