import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { isMobile } from 'react-device-detect';
import { user, selectedWallet, token } from '@store';
import * as ROUTE from '@constants/routes';
import { BoxItem } from '@components/box-item';
import { useGetUser } from '@core/api-hooks/use-user/get';
import userHelper from '@helpers/user';
import './dashboard.css';
import { CashinButton } from '../../components/cashin-button/CashinButton';
import { commerceViewer } from '../../store/comercio-viewer';
import { Modal } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { SelectAffinityModal } from '../../components/affinity-select-modal/SelectAffinityModal';
import { Title } from '@components/typography';
import { useGetAfinityGroups } from '../../core/api-hooks/use-afinity-group/get';
import { useHistory } from 'react-router-dom';
import { vibrate } from '../../helpers/app';
import {
	Wrapper,
	Grid,
	Col,
	ContainerDasboard,
	GridSmall,
} from '@components/grid';
import logoDepositar from '../../assets/images/logo-deposito.png';
import logoEnviarPalta from '../../assets/images/logo-enviar-palta.png';
import logoPagoFactura from '../../assets/images/logo-factura.png';
import logoQrCode from '../../assets/images/logo-qr-icon.png';
import logoRecargaCelular from '../../assets/images/logo-phone-top-up.png';
import logoSearch from '../../assets/images/logo-search.png';
import logoTransferencia from '../../assets/images/logo-transferencia.png';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import PaltaModalSpinner from '../../components/PaltaModalSpinner';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import styled from 'styled-components';
import updateFBTokens from '../../services/user/updateFBTokens';
import useFirebaseToken from '../../hooks/useFirebaseToken';
import UserWallets from './UserWallets';

const ContainerImg = styled.div`
  position: ${(props) => (props.isMobile ? 'absolute' : '')};
  top: ${(props) => (props.isMobile ? '70%' : '')};
  left: ${(props) => (props.isMobile ? '55%' : '')};
  overflow: hidden;
  border-radius: 50%;
  width: ${(props) => (props.isMobile ? '35px' : '50px')};
  height: ${(props) => (props.isMobile ? '35px' : '50px')};
  max-width: 50px;
  max-height: 50px;
  background-color: white;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #5e9f72;
`;
const ContainerAffinityAndPalta = styled.div`
  margin: 5px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid #7884be;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: white;
`;
const BigImg = styled.img`
  width: ${(props) => (props.isPalta ? '80%' : '100%')};
  height: ${(props) => (props.isPalta ? '80%' : '100%')};
  object-fit: cover;
  border-radius: 50%;
`;
const ImgAffinity = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Dashboard = () => {
	const history = useHistory();
	const [show, setShow] = useState(false);
	const [selectAfinity, setSelectAfinity] = useState(undefined);
	const [userData, setUserData] = useRecoilState(user);
	const [loading, setLoading] = useState(true);
	const setSelectedWallet = useSetRecoilState(selectedWallet);
	const setCommerceViewer = useSetRecoilState(commerceViewer);
	const commerceView = useRecoilValue(commerceViewer);
	const [{ data: dataGetUser }, getUser] = useGetUser();
	const [{ data: dataAfinity }, getAfinityGroups] = useGetAfinityGroups();
	const tokenData = useRecoilValue(token);
	const [count, setCount] = useState(0);
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleOpenModal = () => {
		setShowModal(true);
	};
	const [showDollarModal, setShowDollarModal] = useState(false);

	const hideDollarModal = () => {
		setShowDollarModal(false);
	};

	const { token: fbToken, error: errorFB } = useFirebaseToken();

	useEffect(() => {
		if (window.localStorage.getItem('AfinityID')) {
			if (dataAfinity) {
				const afinity = dataAfinity?.find(
					(af) =>
						JSON.parse(window.localStorage.getItem('AfinityID')) === af._id,
				);
				setSelectAfinity(afinity);
			}
			setShow(false);
		} else if (dataAfinity && dataAfinity.length && dataAfinity.length > 1) {
			setShow(true);
		} else if (dataAfinity && dataAfinity.length && dataAfinity.length === 1) {
			setSelectAfinity(dataAfinity[0]);
			localStorage.setItem('AfinityID', JSON.stringify(dataAfinity[0]._id));
		}
	}, [window.localStorage.getItem('AfinityID'), dataAfinity]);

	const getMe = useCallback(() => {
		if (userData && userData.role === 'user') {
			getAfinityGroups(userData, tokenData, setLoading);
		}
		getUser(tokenData);
		setLoading(false);
	}, [getUser, tokenData]);

	useEffect(() => {
		if (userData && count === 0) {
			setCount(1);
			getMe();
			setShowDollarModal(userData?.acceptsDollarWallets === undefined);
		}
	}, [userData, count, getMe]);

	useEffect(() => {
		if (dataGetUser) {
			userHelper.setUser(
				JSON.stringify({
					...dataGetUser.user,
					wallets: dataGetUser.wallets,
				}),
			);
			setUserData({
				...dataGetUser.user,
				wallets: dataGetUser.wallets,
			});
		}
	}, [dataGetUser]);

	function escapeRegExp(string) {
		const parser = new DOMParser();
		const decodedString = parser.parseFromString(
			`<!doctype html><body>${string}`,
			'text/html',
		).body.textContent;
		return decodedString;
	}
	const seterView = (e) => {
		setCommerceViewer(JSON.parse(e.target.value));
	};
	const userWallets = userData?.wallets
		?.slice()
		.filter((wallet) => {
			return !wallet.currencyLabel.match(/usdp/gi);
		})
		.sort((a, b) => {
			if (a.currency > b.currency) {
				return -1;
			}
			if (a.currency < b.currency) {
				return 1;
			}
			return 0;
		});

	const userDolarWallets = userData?.wallets
		?.slice()
		.filter((wallet) => {
			return wallet.currencyLabel.match(/usdp/gi);
		})
		.sort((a, b) => {
			if (a.currency > b.currency) {
				return -1;
			}
			if (a.currency < b.currency) {
				return 1;
			}
			return 0;
		});

	const userDollarWalletsCommerceView = commerceView?.walletsCommerceView
		?.filter((wal) => {
			return wal.currency.includes('dolar');
		})
		.sort((a, b) => {
			if (a.currencyLabel > b.currencyLabel) {
				return -1;
			}
			if (a.currencyLabel < b.currencyLabel) {
				return 1;
			}
			return 0;
		});
	const userPesoWalletsCommerceView = commerceView?.walletsCommerceView
		?.filter((wal) => {
			return !wal.currency.includes('dolar');
		})
		.sort((a, b) => {
			if (a.currencyLabel > b.currencyLabel) {
				return -1;
			}
			if (a.currencyLabel < b.currencyLabel) {
				return 1;
			}
			return 0;
		});

	useEffect(() => {
		if (fbToken && userData) {
			if (
				!userData?.firebaseDevicesTokens ||
				!userData?.firebaseDevicesTokens.includes(fbToken)
			) {
				const currentTokens = userData?.firebaseDevicesTokens ?? [];


				const newTokens = {
					firebaseDevicesTokens: [...currentTokens, fbToken],
				};

				updateFBTokens(userData._id, newTokens)
					.then((res) => console.log('User tokens updated!', { res }))
					.catch((error) => console.log(`Error updating user tokens`));
			}
		}
	}, [fbToken, userData]);
	//TODO preguntar que hacer con esto
	return (
		<>
			<PaltaNavBar className="light" />
			<motion.div
				initial={{ opacity: 0, scale: 0 }}
				animate={{ opacity: 1, scale: 1 }}
				transition={{ type: 'spring', duration: 0.5, bounce: 0.3 }}
			>
				<ContainerDasboard>
					<Wrapper>
						<Grid>
							{isMobile ? (
								<Col large="6" desktop="8">
									<Title textTransform="capitalize">
										{(userData?.role !== undefined &&
											(userData?.role === 'user' ||
												userData?.role === 'viewer')) ||
											userData?.role === 'tax'
											? `¡Hola, ${userData?.name}!`
											: `¡Hola, ${escapeRegExp(`${userData?.fantasyName}`)}!`}
										<ContainerAffinityAndPalta className="float-right">
											<BigImg
												isPalta={
													selectAfinity?.modifiesAppUi === true &&
														localStorage.getItem('AfinityID') &&
														selectAfinity?._id ===
														JSON.parse(localStorage.getItem('AfinityID'))
														? false
														: true
												}
												className="img-fluid"
												src={
													selectAfinity?.modifiesAppUi === true &&
														localStorage.getItem('AfinityID') &&
														selectAfinity?._id ===
														JSON.parse(localStorage.getItem('AfinityID'))
														? selectAfinity?.logo[0].image
														: 'assets/images/palta-512.svg'
												}
												alt=""
												height={50}
												width={50}
											/>
											{selectAfinity && (
												<Fragment>
													<ContainerImg isMobile={true}>
														<ImgAffinity
															className=" img-fluid"
															src="assets/images/palta-512.svg"
															alt=""
														/>
													</ContainerImg>
												</Fragment>
											)}
										</ContainerAffinityAndPalta>
									</Title>
									{userData?.role === 'viewer' && (
										<>
											<h4>¿Qué Comercio deseas ver?</h4>
											<Col large="3" desktop="4" className="pb-3">
												<select
													className="form-select form-select-lg"
													style={{
														textTransform: 'capitalize',
														fontFamily: 'ProductSans',
													}}
													onChange={(e) => {
														seterView(e);
													}}
													defaultValue={'DEFAULT'}
												>
													<option value="DEFAULT" disabled>
														Seleccione un comercio
													</option>

													{userData?.commerceAssociateViewer?.map((c) => {
														return (
															<option
																style={{ textTransform: 'capitalize' }}
																key={c.fantasyName}
																value={JSON.stringify(c)}
															>
																{c.fantasyName}
															</option>
														);
													})}
												</select>
											</Col>
										</>
									)}
								</Col>
							) : (
								<Col large="12" desktop="12">
									<Title textTransform="capitalize">
										{dataAfinity !== undefined &&
											dataAfinity.map((data, i) => {
												return (
													<Fragment key={i}>
														{data?.modifiesAppUi === true &&
															data?._id ===
															JSON.parse(localStorage.getItem('AfinityID')) ? (
															<>
																{data.logo.map((imag, i) => {
																	return imag.imageType === 'logo' ? (
																		<ContainerImg className="float-right">
																			<ImgAffinity
																				key={i}
																				className="img-fluid"
																				src={imag.image}
																				alt=""
																			/>
																		</ContainerImg>
																	) : null;
																})}
															</>
														) : null}
													</Fragment>
												);
											})}
										{(userData?.role !== undefined &&
											(userData?.role === 'user' ||
												userData?.role === 'viewer')) ||
											userData?.role === 'tax'
											? `¡Hola, ${userData?.name}!`
											: `¡Hola, ${escapeRegExp(`${userData?.fantasyName}`)}!`}
									</Title>
									{userData?.role === 'viewer' && (
										<>
											<h4>¿Qué Comercio desea ver?</h4>
											<Col large="3" desktop="4" className="pb-3">
												<select
													className="form-select form-select-lg"
													style={{ fontFamily: 'ProductSans' }}
													onChange={(e) => {
														seterView(e);
													}}
													defaultValue={'DEFAULT'}
												>
													<option value="DEFAULT" disabled>
														Seleccione un comercio
													</option>
													{userData?.commerceAssociateViewer?.map((c, i) => {
														return (
															<option key={i} value={JSON.stringify(c)}>
																{c.fantasyName}
															</option>
														);
													})}
												</select>
											</Col>
										</>
									)}
								</Col>
							)}
						</Grid>

						<UserWallets
							userWallets={userWallets}
							userDolarWallets={userDolarWallets}
							setSelectedWallet={setSelectedWallet}
							isMobile={isMobile}
						/>
						{userPesoWalletsCommerceView?.length > 0 && (
							<UserWallets
								userWallets={userPesoWalletsCommerceView}
								userDolarWallets={userDollarWalletsCommerceView}
								setSelectedWallet={setSelectedWallet}
								isMobile={isMobile}
								isViewer={true}
							/>
						)}
						<Grid>
							{userData?.role === 'viewer' && commerceView !== undefined ? (
								userPesoWalletsCommerceView?.length !== 0 ? (
									userPesoWalletsCommerceView?.map((wallet, index) => {
										return <></>;
									})
								) : null
							) : commerceView === undefined &&
								userData?.role !== 'user' &&
								userData?.role !== 'commerce' &&
								userData?.role !== 'cash' &&
								userData?.role !== 'branch' ? (
								<Col large="6" desktop="6" tablet="4" mobile="6">
									<h4>Debe seleccionar un comercio a ver</h4>
								</Col>
							) : userData?.role === 'user' ? null : null}
						</Grid>
						{userData &&
							userData?.role !== 'branch' &&
							userData?.role !== 'cash' &&
							userData?.role !== 'viewer' ? (
							<>
								<GridSmall className="grid-small-dashboard">
									<div
										className="dashboard-shortcut__button"
										onClick={() => {
											vibrate(15);
											history.push(ROUTE.QR_READER);
										}}
									>
										<div className="dashboard-shortcut__img-container">
											<img
												style={{ maxWidth: '2.3em' }}
												src={logoQrCode}
												className="logo-icon"
											/>
										</div>
										<div className="dashboard-sortcut__text-container">
											Pagá con QR
										</div>
									</div>
									<div
										className="dashboard-shortcut__button"
										onClick={() => {
											vibrate(15);
											history.push(ROUTE.CASH_OUT);
										}}
									>
										<div className="dashboard-shortcut__img-container">
											<img src={logoTransferencia} className="logo-icon" />
										</div>
										<div className="dashboard-sortcut__text-container">
											Transferí Dinero
										</div>
									</div>
									<div
										className="dashboard-shortcut__button"
										onClick={() => {
											vibrate(15);
											handleOpenModal();
										}}
									>
										<div className="dashboard-shortcut__img-container">
											<img src={logoDepositar} className="logo-icon" />
										</div>
										<div className="dashboard-sortcut__text-container">
											Cargá tu Palta
										</div>
									</div>
									<div
										className="dashboard-shortcut__button"
										onClick={() => {
											vibrate(15);
											history.push(ROUTE.PAYMENTS);
										}}
									>
										<div className="dashboard-shortcut__img-container">
											<img
												style={{ maxWidth: '2.3em' }}
												src={logoEnviarPalta}
												className="logo-icon"
											/>
										</div>
										<div className="dashboard-sortcut__text-container">
											Enviá Dinero
										</div>
									</div>
									<div
										className="dashboard-shortcut__button"
										onClick={() => {
											vibrate(15);
											history.push(ROUTE.SERVICE_PAYMENTS_CHOOSE_MODE);
										}}
									>
										<div className="dashboard-shortcut__img-container">
											<img src={logoPagoFactura} className="logo-icon" />
										</div>
										<div className="dashboard-sortcut__text-container">
											Pagá Servicios
										</div>
									</div>
									<div
										className="dashboard-shortcut__button"
										onClick={() => {
											vibrate(15);
											history.push(ROUTE.SERVICE_PAYMENTS_PHONE);
										}}
									>
										<div className="dashboard-shortcut__img-container">
											<img src={logoRecargaCelular} className="logo-icon" />
										</div>
										<div className="dashboard-sortcut__text-container">
											Cargá tu Celu
										</div>
									</div>
									<div
										className="dashboard-shortcut__button"
										onClick={() => {
											vibrate(15);
											history.push(ROUTE.SEARCH_SHOPS);
										}}
									>
										<div className="dashboard-shortcut__img-container">
											<img src={logoSearch} className="logo-icon" />
										</div>
										<div className="dashboard-sortcut__text-container">
											Buscá Comercios
										</div>
									</div>
									<div
										className="dashboard-shortcut__button"
										onClick={() => {
											vibrate(15);
											history.push(ROUTE.NOTIFICATION_PANEL);
										}}
									>
										<div className="dashboard-shortcut__img-container" style={ { position: "relative" } }>
											<i className="material-icons" style={ { color: 'white' } }>
												notifications
												{
													(userData?.hasUnreadNotifications === true) ?
														(
															<span
																style={{ position: "absolute", left: "58%", top: "28%", }}
																className="badge p-1 bg-danger">
																<span className="d-none">New alerts</span>
															</span>)
														: undefined
												}
											</i>
										</div>
										<div className="dashboard-sortcut__text-container">
											Notificaciones
										</div>
									</div>
								</GridSmall>
							</>
						) : null}
					</Wrapper>

					{loading && <PaltaModalSpinner show={loading} size="sm" />}
				</ContainerDasboard>
			</motion.div>
			<PaltaFooter />
			<Modal show={showModal} onHide={handleClose} centered>
				<CashinButton userData={userData} />
			</Modal>
			<Modal show={show} onHide={() => setShow(false)} centered>
				<SelectAffinityModal
					setShow={setShow}
					userData={userData}
					dataAfinity={dataAfinity}
					setSelectAfinity={setSelectAfinity}
					selectAfinity={selectAfinity}
				/>
			</Modal>
		</>
	);
};

export default Dashboard;