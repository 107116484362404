import React, { Fragment } from 'react';

import { Grid, Col } from '@components/grid';
import { BoxLeft } from './BoxLeft';
import { BoxRight } from './BoxRight';
import { Spinner } from 'react-bootstrap';
export const Activities = ({
  data,
  setCashinSee,
  setItem,
  setCashoutSee,
  setTax,
  getCommission,
  userData,
  Group,
  P,
  tokenData,
  loading,
  escapeRegExp
}) => {
  return (
    <Grid>
      <Col large="12" desktop="12" mobileSmall="12">
        {
          loading ?
            <Spinner animation="grow" variant="primary" /> :
            data &&
            data.activities.length !== 0 &&
            data.activities.map((transaction, index) => {
              return transaction !== undefined && transaction.type !== 'commission' && transaction.type !== 'COMISION PAGO SERVICIO' ? (
                <Fragment key={index}>
                  <a
                    className="transaction-anchor"
                    href="/resume#"
                    onClick={() => {
                      if (
                        transaction.type === 'transaction' ||
                        transaction.type === 'promo' ||
                        (transaction.type === 'REVERTIR TRANSACCION' && transaction._userFromId.email &&  transaction._userFromId.email !== process.env.REACT_APP_EMAIL_USER_TAXES)
                      ) {
                        setItem(transaction);
                        getCommission(transaction._id, tokenData);
                      }
                      if (transaction.type === 'cashin') {
                        setCashinSee(transaction);
                      }
                      if (
                        transaction.type === 'cashout' ||
                        transaction.type === 'xnet'
                      ) {
                        setCashoutSee(transaction);
                      }
                      if (transaction.type === 'sircupa' || transaction.type === 'tax' || (transaction.type === 'REVERTIR TRANSACCION' && transaction._userFromId.email && transaction._userFromId.email === process.env.REACT_APP_EMAIL_USER_TAXES)) {
                        setTax(transaction);
                      }
                      if (transaction.type === 'PAGO SERVICIO'){
                        setItem(transaction);
                      }
                    }}
                  >
                    <div
                      className="transaction-items-wapper"
                      key={transaction._id}
                      style={{ position: 'relative' }}
                    >

                      {transaction.type !== undefined &&
                        transaction.type === 'promo' && (
                          <div className="ribbon-mobile">
                            <img
                              src="./assets/images/ribon.png"
                              width={70}
                              height={70}
                              alt=""
                            />
                          </div>
                        )}
                      <BoxLeft
                        userData={userData}
                        transaction={transaction}
                        Group={Group}
                        escapeRegExp={escapeRegExp}
                      />
                      <BoxRight
                        userData={userData}
                        transaction={transaction}
                        P={P}
                      />
                    </div>
                  </a>
                </Fragment>
              ) : null;

            })}
      </Col>
    </Grid>
  )
}