import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { isMobile } from 'react-device-detect';
import { user } from '../../store';
import PaltaBreadcrumbs from '../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import { Spacer } from '../../components/spacer';
import * as ROUTES from '../../constants/routes';
import './profile.css';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
const Profile = () => {
  const userData = useRecoilValue(user);
  function escapeRegExp(string) {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(`<!doctype html><body>${string}`, 'text/html').body.textContent;
    return decodedString
  }
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <PaltaNavBar/>
      <section style={{ marginBottom: '5em' }}>
        <PaltaBreadcrumbs
          name="Mi cuenta"
          link={ROUTES.ACCOUNT}
          linkName="Mi Cuenta"
          variant="dark"
        />
        <Container>
          <Row>
            <Col sm={12}>
              {isMobile ? (
                <h3 className="title" style={{ color: '#3A4D9F' }}>
                  Mis Datos
                </h3>
              ) : (
                <h2 className="title" style={{ color: '#3A4D9F' }}>
                  Mis Datos
                </h2>
              )}

            </Col>
          </Row>
        </Container>
        <Container>
          <div className="justify-content-md-center align-items-center">
            <Col sm={12} className="box-title">
              <h5>Tu Información</h5>
              <Spacer size="s" />
              <p
              >Nombre: {userData && (userData.role === "user" || userData.role === "viewer") ?
                userData?.name
                : escapeRegExp(`${userData?.fantasyName}`)}
              </p>
              {userData && (userData.role === "user" || userData.role === "viewer")? (
                <p>Apellido: {userData && userData.lastname}</p>
              ) : null}
              {userData?.alias_cvu && (
                <p>Alias: {userData && userData.alias_cvu}</p>
              )}
              <Spacer size="xs" />
              <h5>Contacto</h5>
              <Spacer size="s" />
              <p>Email: {userData && userData.email}</p>
            </Col>
          </div>
        </Container>
      </section>
        <PaltaFooter/>
    </div>
  );
};

export default Profile;
