import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import * as ROUTES from '@constants/routes';
import logoUser from '../../../assets/images/user.svg';
import logoCommerce from '../../../assets/images/commerce.svg';
import logoBeneMzaAct from '../../../assets/images/Mendoza.svg';
import logoPalta from '../../../assets/images/palta-logo-500.svg';
import { useHistory, useParams } from 'react-router-dom';
const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

`;

const BoxContainer = styled.div`
  width: 340px;
  min-height: 624px;
  display: flex;
  flex-direction: column;
  border-radius: 50px;

  box-shadow: 0px 0px 4.7px rgba(15, 15, 15, 0.28);
  position: relative;
  overflow: hidden;
  padding: 0px 10px 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;


const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MenuBeneMzaAct = () => {
  const history = useHistory();
  const { id } = useParams();
  
  return (
    <>
      <AppContainer>
        <BoxContainer>
          <InnerContainer>
            <img
              src={logoPalta}
              width="120"
              height="50"
              className="d-inline-block align-top position-logo-palta "
              alt="Palta logo"
            />

            <div className="card carduser" style={{ width: "18rem" }} onClick={() => history.push(`${ROUTES.MENU_BENE_MZA_ACT_USER}/${id}`)}>
              <img className="card-img-top" src={logoUser} alt="image-user" height={70} width={70} />
              <img className="card-img-bottom" src={logoBeneMzaAct} alt="image-commerce" height={50} width={70} />
              <div className="card-body">
                <p className="card-text text-center bold">Soy beneficiario de Mendoza activa como persona</p>
              </div>
            </div>
            <div className="card carduser" style={{ width: "18rem" }} onClick={() => history.push(`${ROUTES.MENU_BENE_MZA_ACT_COMMERCE_PAGE_1}/${id}`)}>
              <img className="card-img-top" src={logoCommerce} alt="image-commerce" height={70} width={70} />
              <img className="card-img-bottom" src={logoBeneMzaAct} alt="image-commerce" height={50} width={70} />
              <div className="card-body">
                <p className="card-text text-center bold">Soy beneficiario de Mendoza activa como empresa</p>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-2">
              <button
                type="button"
                className=" active btn btn-fullwidthe bg-danger mt-0 text-white"
                onClick={() => history.push(`${ROUTES.REGISTER_USER}/${id}`)}
                style={{ fontSize: 18, borderRadius: 5, fontFamily: "inherit", textTransform: "none" }}
              >
                Volver
              </button>
            </div>
          </InnerContainer>
        </BoxContainer>
      </AppContainer>

    </>
  );
};
