import React from "react";
import { Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
export const DownloadPdfQr = ({ data,escapeRegExp}) => {
    const canvas = document.getElementById("qr-gen");
    let pngUrl=""
    if(canvas){
         pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");

    }
        
    return (
        <Document>
            <Page style={styles.body}>
                <View >
                    <Text style={styles.text} >
                        {escapeRegExp(`${data?.fantasyName}`)}
                    </Text>
                    <Image style={styles.logo} source={{ uri: pngUrl }} />
                </View>
            </Page>
        </Document>
    )
}
const styles = StyleSheet.create({
    body: {
        paddingVertical: 200,
        textAlign: "center",
    },
    logo: {
        width: 150,
        height: 150,
        marginLeft: "auto",
        marginRight: "auto",
        display: "block",

    },
    text: {
        marginTop: 30,
        fontSize: 16,
        paddingHorizontal: 20,
        paddingVertical: 10,
        color: "#212121",
    },
})