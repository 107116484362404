
export const ACCOUNT = '/account';
export const CARD = '/card';
export const CASH_IN = '/account/cashin';
export const CASH_IN_CB = '/account/cashin/coinbase';
export const CASH_IN_MP = '/account/cashin/mercadopago';
export const CASH_OUT = '/account/cash-out';
export const CASH_OUT_AMOUNT = '/account/cash-out/amount';
export const CASH_OUT_CBU_CVU = '/account/cash-out/cbu-cvu';
export const CASH_OUT_CONFIRM = '/account/cash-out/confirm';
export const CASH_OUT_SUCCESS = '/account/cash-out/success';
export const CASH_OUT_VALIDATE_CBU_CVU = '/account/cash-out/validate-cbu-cvu';
export const COMMERCE_PAGE_1 = '/commerce/page-1';
export const COMMERCE_PAGE_2 = '/commerce/page-2';
export const DASHBOARD = '/dashboard';
export const DOLLAR_CONVERSION = '/dollar-conversion';
export const DOLLAR_TRANSACTION_DETAIL = '/dollar-transaction-detail';
export const DOLLAR_TRANSACTION_SUCCESS = '/dollar-transaction-success';
export const EXPENSES = '/expenses';
export const EXPORT_CSV = '/account/exportcsv';
export const ITEM_RECEIVED = '/item-received';
export const ITEM_VIEW = '/item-view';
export const LOANS_PAY = '/loans/pay';
export const LOGIN = '/login';
export const MASSIVE_QR ='/account/massive-qr'
export const MENU_BENE_MZA_ACT = '/bene-mza-act';
export const MENU_BENE_MZA_ACT_COMMERCE_PAGE_1 = '/bene-act/com-bene-1';
export const MENU_BENE_MZA_ACT_COMMERCE_PAGE_2 = '/bene-act/com-bene-2';
export const MENU_BENE_MZA_ACT_COMMERCE_PAGE_3 = '/bene-act/com-bene-3';
export const MENU_BENE_MZA_ACT_USER = '/bene-act/user-bene';
export const NOTIFICATION_PANEL = '/me/notification'
export const PALTA = '/palta';
export const PASSWORD_FORGET = '/password-forget';
export const PASSWORD_RESET = '/password-reset';
export const PAYMENTS = '/payments';
export const PAYMENTS_PAY = '/payments/pay';
export const PAYMENTS_SELECT = '/payments/select';
export const PAYMENTS_SELECT_AMOUNT = '/payments/amount';
export const PAYMENTS_SELECT_CONFIRM = '/payments/confirm';
export const PAYMENTS_SELECT_SUCCESS = '/payments/success';
export const PAYMENTS_SELECT_WALLET = '/payments/select-wallet';
export const PROFILE = '/account/profile';
export const QR_GENERATOR = '/account/QrGenerator';
export const QR_READER = '/qr-reader';
export const RECEIVED = '/received';
export const REGISTER = '/register';
export const REGISTER_FINISH = '/register/finish';
export const REGISTER_SUCCESS = '/register/success';
export const REGISTER_USER = `/user`;
export const RESUME = '/resume';
export const RESUMEMONTH = '/resume/:year/:month';
export const SEARCH_SHOPS = '/search-shops';
export const SERVICE_PAYMENTS_BARCODE = '/scan-barcode';
export const SERVICE_PAYMENTS_CASHOUT = '/service-payments-cashout';
export const SERVICE_PAYMENTS_CHOOSE_MODE = '/scan-input';
export const SERVICE_PAYMENTS_GENERIC = '/service-payments';
export const SERVICE_PAYMENTS_GENERIC_1 = '/service-payments-1';
export const SERVICE_PAYMENTS_GET_OPCION_1 = '/service-payments-3';
export const SERVICE_PAYMENTS_GET_OPCION_2 = '/service-payments-4';
export const SERVICE_PAYMENTS_GET_OPCION_3 = '/service-payments-5';
export const SERVICE_PAYMENTS_GET_OPCION_4 = '/service-payments-6';
export const SERVICE_PAYMENTS_GET_OPCION_5 = '/service-payments-7';
export const SERVICE_PAYMENTS_PHONE = '/service-phone';
export const SERVICE_PAYMENTS_SUCCESS = '/service-payments-success';
export const SERVICE_PAYMENTS_WALLETS = '/service-payments-wallets';
export const SIGNOUT = '/signout';
export const TAXES = '/taxes';
export const USER_BENE_MZA_ACT = '/user-bene-mza-act';
export const USER_REGISTER = '/user-user';
export const USER_REGISTER_VALID_IDENTITY = '/user-valid-identity';
export const USER_REGISTER_VALID_IDENTITY_COMMERCE = '/com-valid-identity';
export const USER_REGISTER_VALID_IDENTITY_COMMERCE_BENE_MZA_ACT = '/commerce-bene-identity';
export const USER_REGISTER_VALID_IDENTITY_USER_BENE_MZA_ACT = '/user-bene-identity';
export const VALID_PHONE = '/register/valid-phone';
export const WELCOME = '/';
