import React, { useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import logoPalta from '../../../assets/images/palta-logo-500.svg';
import * as API from '@constants/api-staging';
import * as ROUTES from '@constants/routes';
import userHelper from '@helpers/user';
import Swal from 'sweetalert2';
import '../../register-form/register.css';
import { useRecoilState, useRecoilValue } from 'recoil';
import { dataFormCommerce, globalAddress } from '../../../store';
import GeoSelect from '../../../components/geo-selected/GeoSelect';
import { geoloc } from "../../../components/utils/localidades-list";
const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

`;

const BoxContainer = styled.div`
  width: 340px;
  min-height: 624px;
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 0px 0px 4.7px rgba(15, 15, 15, 0.28);
  position: relative;
  overflow: hidden;
  padding: 0px 10px 0px 10px;
`;


const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

export const RegisterCommercePage1 = () => {
  const { id } = useParams();
  const [datacommerceForm, setDatacommerceForm] = useRecoilState(dataFormCommerce);
  const datacommerce = useRecoilValue(dataFormCommerce);
  const [stateglobalAddress, setGlobalAddress] = useRecoilState(globalAddress);
  const dataAddress = useRecoilValue(globalAddress);
  const { register, handleSubmit, errors, formState, watch } = useForm({
    mode: 'onChange',
  });
  const [loading, setLoading] = useState(false);
  const [dataDirec, setDataDirec] = useState({
    globalAddress: {
      country: dataAddress?.globalAddress?.country !== undefined ? dataAddress?.globalAddress?.country : "argentina",
      province: dataAddress?.globalAddress?.province !== undefined ? dataAddress?.globalAddress?.province : "mendoza",
      city: dataAddress?.globalAddress?.city !== undefined ? dataAddress?.globalAddress?.city : "capital",
    }
  })
  const history = useHistory();
  const handleDateglobalAddress = (province) => {
    const value = province.toLowerCase();
    setDataDirec({
      ...dataDirec,
      globalAddress: {
        ...dataDirec.globalAddress,
        "province": value,
        'city': geoloc.argentina[value][0],
      },
    })

  };
  const handleDateglobalAddressCity = (city) => {
    const value = city.toLowerCase();
    setDataDirec({
      ...dataDirec,
      globalAddress: {
        ...dataDirec.globalAddress,
        "city": value,
      },
    })
  };
  const onSubmit = useCallback(async (data) => {
    setDatacommerceForm(data);
    setGlobalAddress(dataDirec);
    history.push(`${ROUTES.COMMERCE_PAGE_2}/${id}`);
  }, [datacommerceForm, stateglobalAddress, dataDirec]);
  return (
    <>
      <AppContainer>
        <BoxContainer>
          <InnerContainer>
            <img
              src={logoPalta}
              width="120"
              height="50"
              className="d-inline-block align-top position-logo-palta-form-user "
              alt="Palta logo"
            />
            <form
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="input-container">
                <div className="row d-flex justify-content-center mt-2">
                  <div className="col-12 mr-4 ml-4  col-sm-12">
                    <h5 className="text-center">
                      A continuación ingrese los datos de su comercio.
                    </h5>
                    <div className="form-group  text-center pb-2">

                      <br />
                      <>
                        <div className="input-container mt-2 mb-3 pl-3 pr-3">
                          <input
                            name="cuit"
                            className="input-class"
                            type="text"
                            defaultValue={datacommerce?.cuit}
                            ref={register({
                              required: 'Required',
                              pattern: {
                                value: /^-?[0-9]\d*\.?\d*$/i,
                              },
                              maxLength: 11,
                            })}
                            data-cy="cuit"
                          />
                          <label className="label-text pl-3 pr-3" htmlFor="name" >Cuit</label>
                        </div>

                        {errors.cuit && errors.cuit.type === 'required' && (
                          <p className="input-error">El campo de cuit es requerido</p>
                        )}
                        {(errors.cuit && errors.cuit.type === 'pattern') && (
                          <p className="input-error">
                            El campo debe tener solo números
                          </p>
                        )}
                        {errors.cuit && errors.cuit.type === 'maxLength' && (
                          <p className="input-error">
                            El campo debe tener hasta 11 caracteres
                          </p>
                        )}
                      </>
                      <div className="input-container mt-4 mb-3 pl-3 pr-3">
                        <input
                          name="bussinesName"
                          className="input-class"
                          type="text"
                          ref={register({ required: 'Required' })}
                          data-cy="bussinesName"
                          defaultValue={datacommerce?.bussinesName}
                        />
                        <label className="label-text pl-3 pr-3" htmlFor="name" >Razón Social</label>
                      </div>

                      {errors.bussinesName && errors.bussinesName.type === 'required' && (
                        <p className="input-error">El campo razón social es requerido</p>
                      )}
                      <div className="input-container mt-4 mb-3 pl-3 pr-3">
                        <input
                          name="fantasyName"
                          className="input-class"
                          type="text"
                          ref={register({ required: 'Required' })}
                          data-cy="fantasyName"
                          defaultValue={datacommerce?.fantasyName}
                        />
                        <label className="label-text pl-3 pr-3" htmlFor="name" >Nombre de Fantasía</label>
                      </div>

                      {errors.fantasyName && errors.fantasyName.type === 'required' && (
                        <p className="input-error">El campo razón social es requerido</p>
                      )}
                      <div className="input-container mt-4 mb-3 pl-3 pr-3">
                        <input
                          name="address"
                          className="input-class"
                          defaultValue={datacommerce?.address}
                          type="text"
                          ref={register({ required: 'Required' })}
                          data-cy="address"
                        />
                        <label className="label-text pl-3 pr-3" htmlFor="name" >Domicilio</label>
                      </div>

                      {errors.address && errors.address.type === 'required' && (
                        <p className="input-error">El campo de domicilio es requerido</p>
                      )}

                      <div className="input-container mt-2 pt-3 mb-4 pl-3 pr-3">
                        <GeoSelect
                          defValue={`${dataDirec.globalAddress.province}`}
                          handleDateglobalAddress={handleDateglobalAddress}
                          geoloc={Object.keys(geoloc[dataDirec.globalAddress.country.toLowerCase()])}
                        />
                        <label className="label-phone pl-3 pr-3" htmlFor="name" >Provincia</label>
                      </div>
                      <div className="input-container mt-2 pt-3 mb-4 pl-3 pr-3">
                        <GeoSelect
                          defValue={`${dataDirec.globalAddress.city}`}
                          handleDateglobalAddress={handleDateglobalAddressCity}
                          geoloc={Object.values(geoloc[dataDirec.globalAddress.country.toLowerCase()][dataDirec.globalAddress.province.toLowerCase()])}
                        />
                        <label className="label-phone pl-3 pr-3" htmlFor="name" >Departamento</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="position-button-align-register-initial d-flex justify-content-center align-items-center">

                <Button
                  type="submit"
                  size="md"
                  className=" active btn-block btn-fullwidthe bg-danger mt-0"
                  onClick={() => history.push(`${ROUTES.REGISTER_USER}/${id}`)}
                  style={{ fontSize: 18, borderRadius: 5, fontFamily: "inherit", textTransform: "none" }}
                  data-cy="button-ingresar"
                >
                  Volver
                </Button>
                <Button
                  type="submit"
                  size="md"
                  className="active btn-block btn-fullwidthe btnColor mt-0 ml-2"
                  disabled={!formState.isValid}
                  style={{ fontSize: 18, borderRadius: 5, fontFamily: "inherit", textTransform: "none" }}
                  data-cy="button-ingresar"
                >
                  Siguiente
                </Button>
              </div>
            </form>
          </InnerContainer>

        </BoxContainer>
      </AppContainer>

    </>
  );
};

