export const fontSize = {
  xxxxl: '40px',
  xxxl: '36px',
  xxl: '28px',
  xl: '22px',
  l: '18px',
  m: '16px',
  s: '14px',
  xs: '12px',
  xxs: '10px',
};

export const fontWeight = {
  light: '300',
  regular: '400',
  medium: '500',
  bold: '600',
  black: '800',
};
