import { useState, useCallback } from 'react';
import Swal from 'sweetalert2';
import * as API from '../../../constants/api-staging';
import '../../../components/Cvu/Cvu.css'
import { fetcher } from '../../Fetch/Fetch';
export const useGetCvu = () => {
    const [data, setData] = useState(undefined);
    const getCvu = useCallback((id, token) => {
        Swal.fire({
            title: 'Generación de CVU',
            showCancelButton: true,
            confirmButtonText: 'Generar cvu',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: "#3A4D9F",
            cancelButtonColor:"#DB1507",
            showLoaderOnConfirm: true,
            backdrop: true,
            width: '350px',
            customClass: {
              title: 'colorTextTitle',
              confirmButton: "btn rounded-pill",
              cancelButton: "btn rounded-pill",
              popup: "swal2-popup"
            },
            preConfirm: async () => {
              return await fetcher(`${API.BASE_URL}/bind/user/${id}/cvu`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: token,
                    },
                  })
                  .then((res) => {
                    if (!res.ok) {
                      if (res.status !== 200) {
                        Swal.showValidationMessage(
                          `Fallo al generar CVU: Ha ocurrido un error al generar en CVU, intente nuevamente  mas tarde`
                        )
                      }
                    } else { 
                    return res.json();
                  }
                  })
                .then(({ data }) => {
                  setData(data)
                })
                .catch((error) => {
              
                    Swal.showValidationMessage(
                      `Fallo al generar CVU: Ha ocurrido un error al generar en CVU, intente nuevamente mas tarde`
                    )  
             
                });
                
      
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((response) => {
            if (response.isConfirmed) {
              Swal.fire({
                title: `CVU generado con éxito`,
                confirmButtonColor: "#03A4D9F",
                confirmButtonText: 'Aceptar',
                width: '400px',
                customClass: {
                  title: 'colorTextTitle',
                  confirmButton: "btn rounded-pill",
                  popup: "swal2-popup"
                },
              })
            }
          })
  }, []);
  
  return [
    {
      data,
    },
    getCvu,
  ];
  };