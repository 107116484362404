import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
// import './PaltaListMenu.css';

export default class PaltaListMenu extends Component {
  render() {
      let { children } = this.props;
    return (
        <Container>
            <Row>
                <ul className="list-menu">
                    { children }
                </ul>
            </Row>
        </Container>
    )
  }
}
