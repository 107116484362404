import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import './acountBox.css';
import * as ROUTES from '@constants/routes';
import LogIn from '../../views/LogIn';
import { Button } from 'react-bootstrap';
import { motion, AnimatePresence } from "framer-motion";

const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BoxContainer = styled.div`
  width: 340px;
  min-height: 624px;
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 0px 0px 4.7px rgba(15, 15, 15, 0.28);
  position: relative;
  overflow: hidden;
`;

const TopContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 1.8em;
  position: absolute;
  z-index: 110;
`;

const BackDrop = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 550px;
  top: -340px;
  left: -70px;
  background-color: #5e9f72 !important;
  z-index: 100;
  // background-image: 'url(assets/images/fondo-palta-corto.sv)';
  // background-size: '100px';
  border-radius: '0 0 75% 75%/0 0 125% 55%';
`;

const HeaderContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  justify-content: center;
`;

const HeaderText = styled.div`
  font-weight: 600;
  color: #fff;
  z-index: 10;
  margin: 0;
  font-size: 30px;
  line-height: 1.24;
  margin-top: 360px;
  margin-left: 19px;
`;
const HeaderText2 = styled.div`
  font-weight: 600;
  color: #fff;
  z-index: 10;
  margin: 0;
  font-size: 22px;
  line-height: 1.24;
  margin-top: 10px;
  margin-left: 19px;
`;
const HeaderText3 = styled.div`
  font-weight: 600;
  color: #fff;
  z-index: 10;
  margin: 0;
  font-size: 30px;
  line-height: 1.24;
  margin-top: 180px;
  margin-left: 19px;
`;

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

const backdropVariants = {
  collapsed: {
    width: '550px',
    height: '-1px',
    borderRadius: '0 0 75% 75%/0 0 25% 25%',
    zIndex: '1',
  },
  expanded: {
    width: '550px',
    height: '1400px',
    borderRadius: '0 0 75% 75%/0 0 125% 55%',
    borderBottomRadius: '0 0 75% 75%/0 0 125% 55%',
    zIndex: '1',
  },
};

const expandingTransition = {
  type: 'spring',
  duration: 2.3,
  stiffness: 12,
};

export function AccountBox() {
  const [isExpanded, setExpanded] = useState(false);

  const playExpandingAnimation = useCallback(async () => {
    setExpanded(true);
    setTimeout(() => {
      if (!isExpanded) {
        setExpanded(true);
      }
    }, expandingTransition.duration * 1000 - 1500);
  }, []);

  return (
    <AnimatePresence
      initial={false}
      exitBeforeEnter={true}
      onExitComplete={() => null}
    >
      <motion.div
        initial={{ scale: 1 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ type: 'spring', duration: .4, bounce: 0.3 }}
        exit={{scale: 0.1}}
      >
        <AppContainer>
          <BoxContainer>
            <BackDrop
              variants={backdropVariants}
              transition={expandingTransition}
              initial={false}
              animate={isExpanded ? 'collapsed' : 'expanded'}
            />
            {!isExpanded ? (
              <TopContainer>
                <HeaderContainer>
                  <HeaderText>
                    <img
                      src="assets/images/fondo-palta-corto.svg"
                      height={100}
                      width={240}
                      alt="logoPalta"
                    />
                  </HeaderText>
                  <HeaderText3 onClick={() =>{ 
                    playExpandingAnimation();
                    if (typeof Notification !== "undefined" && Notification.permission !== "granted") {
                      Notification.requestPermission()
                      .catch(error => console.error(error));
                    }
                    }}>
                    <button
                      className="btn btn-lg text-white"
                      style={{ background: '#C0C4E2', fontSize: 20 }}
                    >
                      Ingresa a palta
                    </button>
                  </HeaderText3>
                  <HeaderText2>
                    <Button
                      onClick={() => {
                        window.open(`${ROUTES.PALTA}`);
                        window.close();
                      }}
                      alt=""
                      style={{
                        color: '#C0C4E2',
                        fontSize: 20,
                        background: 'transparent',
                        border: '0px',
                      }}
                    >
                      Registrate
                    </Button>
                  </HeaderText2>
                </HeaderContainer>
              </TopContainer>
            ) : null}

            <InnerContainer>
              <LogIn />
            </InnerContainer>
          </BoxContainer>
        </AppContainer>
      </motion.div>
    </AnimatePresence>
  );
}
