import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './PaltaInfoContainer.css';

export default class PaltaInfoContainer extends Component {
  render() {
    let { title, subtitle, amount, tagIcon, tagText, tagVariant, loan, payLoan } = this.props;
    return (
      <Container>
        {title ?
          <Row>
            <Col sm={12}>
              <h2 className="title">{title}</h2>
            </Col>
          </Row>
          : ''}
        <Row className="align-items-center">
          <Col className="d-flex flex-column align-items-center resume">
            {tagText ?
              <span className={`tag ${tagVariant ? `tag-${tagVariant}` : ''}`}>
                <div className="icon"><i className="material-icons">{tagIcon}</i></div>
                <span>{tagText}</span>
              </span>
              : ''
            }
            {subtitle ? <span className="subtitle">{subtitle}</span> : ''}
            {amount ? <span className="amount">{amount}</span> : ''}
          </Col>
        </Row>
        {
          loan
            ?
            loan.paid
              ?
              <Row className="align-items-center">
                <Col className="d-flex flex-column align-items-center">
                  <span className={'paid-loan-button'}>
                    <span className='paid-loan-button-text'>Pagado</span>
                  </span>
                </Col>
              </Row>
              :
              loan.isLoaded && loan._id && loan.canSetAsPayed
                ?
                <Row className="align-items-center">
                  <Col className="d-flex flex-column align-items-center">
                    <span className={'paid-loan-button not-paid'} onClick={payLoan}>
                      <span className='paid-loan-button-text'>Marcar como pagado</span>
                    </span>
                  </Col>
                </Row>
                : null
            : null
        }
      </Container>
    )
  }
}
