import React, { useState, useRef} from 'react';
import { Modal } from 'react-bootstrap';
export const CashinButton = ({ userData }) => {
  const [copySuccess, setCopySuccess] = useState('');
  const [copySuccessone, setCopySuccessone] = useState('');
  const textAreaRef = useRef(null);
  const aliasRef = useRef(null);
  function copyToClipboardCvu(e) {
    textAreaRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    setCopySuccess('👍');
  };

  function copyToClipboardAlias(e) {
    aliasRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    setCopySuccessone('👍');
  };
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title className="text-center colorFont font-weight-bold">Consultar Cvu y Alias</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pl-0 pr-0" >
        <label className="colorFont ">Cvu</label>
        <div className="input-group">
          <input type="text" className="form-control" value={userData.cvu?.toUpperCase()} readOnly ref={textAreaRef} />
          <span className="input-group-btn">
            {
              document.queryCommandSupported('copy') &&
              <>
                <button className=" btn btn-outline" onClick={copyToClipboardCvu}>
                  <i className="material-icons colorFont">file_copy</i>
                </button>
                {copySuccess}
              </>
            }
          </span>
        </div>

        <label htmlFor="alias" className="colorFont">Alias</label>
        <div className="input-group">
          <input type="text"
            name="alias"
            id="alias"
            required
            className="form-control"
            value={userData.alias?.toUpperCase()}
            readOnly
            ref={aliasRef}

          />
          <span className="input-group-btn">
            {
              document.queryCommandSupported('copy') &&
              <div>
                <button className=" btn btn-outline" onClick={copyToClipboardAlias}>
                  <span className="nav-icon ">
                    <i className="material-icons colorFont">file_copy</i>
                  </span>

                </button>
                {copySuccessone}
              </div>
            }
          </span>
        </div>
        <div className="row mx-0 justify-content-center mt-3">
          <div className="col-12 col-sm-12  p-0">
            <p className="alert alert-success m-0">
              Para ingresar dinero en tu Palta, copiá tu CVU o ALIAS haciendo click en COPIAR y usalo para transferir desde tu cuenta bancaria.
            </p>
          </div>
        </div>
      </Modal.Body>
    </>
  )


}