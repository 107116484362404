import React, { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import { shopId, token } from '../../store';
import * as ROUTES from '../../constants/routes';
import PaltaRowList from '../../components/PaltaRowList/PaltaRowList';
import { useGetTransactionLast } from '@core/api-hooks/use-transaction-last';
import { useGetAfinityGroups } from '../../core/api-hooks/use-afinity-group/get';
import './payments.css';
import { Fragment } from 'react';
import PaltaModalSpinner from '../../components/PaltaModalSpinner';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { user } from '../../store';

const ImgContainer = styled.div`
  margin-right: 10px;
  position: relative;
  background-color: #ffffff;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #7884be;
  border-radius: 50%;
`;
const LittleImgContainer = styled.div`
  position: absolute;
  top: 60%;
  left: 65%;
  background-color: #ffffff;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border: 3px solid #7884be;
  border-radius: 50%;
`;
const Img = styled.img`
  border-radius: 50%;
  object-fit: cover;
  width: ${(props) => (props.isPalta ? '80%' : '100%')};
  height: ${(props) => (props.isPalta ? '80%' : '100%')};
`;
const RowListItemPayment = ({ shop }) => {
  const history = useHistory();
  function escapeRegExp(string) {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(`<!doctype html><body>${string}`, 'text/html').body.textContent;
    return decodedString
  }
  return (
    <>
      {shop.role !== "tax" ? (

        <div
          className="payment-shop-item"
          onClick={() => {
            history.push(ROUTES.PAYMENTS_SELECT_WALLET,{shopId : shop._id});
          }}
        >
          {shop.role === "user" ? (
            <div className="shop-item-container">
              <i className="material-icons shop-item-icon"> account_circle</i>
            </div>

          ) : (
            <div className="shop-item-container">
              <i className="material-icons shop-item-icon">storefront</i>
            </div>
          )}
          <div className="payment-shop-item-text">
            {shop.role === "user" ?
              `${shop.name}`
              : shop.role === "commerce" || shop.role === "branch" || shop.role === "cash" ?
                escapeRegExp(`${shop.name}`) : null}
          </div>
        </div>

      ) : null}
    </>
  );
};

const Payments = () => {
  const [loading, setLoading] = useState(true);
  const tokenData = useRecoilValue(token);
  const [{ data, error }, getTranscationsLast] = useGetTransactionLast();
  const [transactions, setTransactions] = useState(undefined);
  const [dataUser, setUserData] = useRecoilState(user);
  const [{ data: affinityGroups }, getAffinityGroups] = useGetAfinityGroups();
  const [affinitySelected, setAffintySelected] = useState(undefined);

  useEffect(() => {
    if (window.localStorage.getItem('AfinityID') && dataUser) {
      getAffinityGroups(dataUser, tokenData, setLoading);
    }
  }, [dataUser, tokenData]);
  useEffect(() => {
    if (affinityGroups?.length && window.localStorage.getItem('AfinityID')) {
      const affinity = affinityGroups.find(
        (af) => af._id === JSON.parse(window.localStorage.getItem('AfinityID')),
      );
      setAffintySelected(affinity);
    }
  }, [affinityGroups]);
  useEffect(() => {
    if (tokenData) {
      getTranscationsLast(tokenData);
    }
  }, [tokenData, getTranscationsLast]);

  useEffect(() => {
    if (data) {
      setTransactions(data);
      setLoading(false);
    }
  }, [data]);

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <PaltaNavBar />
      <motion.div
        initial={{ x: '100%', opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ type: 'spring', duration: .4, bounce: 0.2 }}
      >
        <Container>
          {isMobile ? (
            <>
              <Row className="d-flex justify-content-around align-items-center mt-3">
                <div className="col-8 col-sm-8 col-md-8 p-0">
                  <h3 className="title ml-3 d-flex  align-items-center m-0" style={{ color: '#3A4D9F' }}>
                    Enviar Dinero
                  </h3>
                </div>
                <ImgContainer>
                        <Img
                          isPalta={affinitySelected ? false : true}
                          src={
                            affinitySelected
                              ? affinitySelected.logo[0].image
                              : 'assets/images/palta-512.svg'
                          }
                          alt=""
                          height={60}
                          width={60}
                        />
                        {affinitySelected ? (
                          <LittleImgContainer>
                            <img
                              src="assets/images/palta-512.svg"
                              alt=""
                              height={20}
                              width={20}
                            />
                          </LittleImgContainer>
                        ) : null}
                    </ImgContainer>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <div className="col-8 col-sm-8 col-md-8">
                  <h2 className="title ml-2" style={{ color: '#3A4D9F', marginTop: "38px" }}>
                    Enviar Dinero
                  </h2>
                </div>

              </Row>
            </>
          )}
        </Container>
        <PaltaRowList
          mainItemLink={ROUTES.PAYMENTS_SELECT}
          variant="h-payments-group-pay"
          urlIcon="assets/images/send.svg"
          addNewIcon={true}
          title="Enviar"
        >
          {transactions?.users
            .filter(shop => {
              const usersPaltaDollar = ['620d0b3748738f6628b65a96', '62012d2e4f0247097efb305e']
              return !usersPaltaDollar.includes(shop._id)
            })
            .map((shop, index) => {
              return (
                shop.role !== "admin"
                && (
                  <Fragment key={index}>
                    <RowListItemPayment key={index} shop={shop} />
                  </Fragment>
                )
              )
            })}
        </PaltaRowList>
        {loading && (
          <PaltaModalSpinner show={loading} size="sm" />
        )}
      </motion.div>
      <PaltaFooter />
    </div >
  );
};

export default Payments;
