import { isMessagingSupported, messaging } from "../core/Firebase";


export default function useFirebaseMessagingHandler() {
    if (isMessagingSupported && ("serviceWorker" in navigator)) {

        messaging.onMessage((payload) => {

            const { title, body } = payload.notification;

            try {
                navigator.serviceWorker.getRegistration("./firebase-cloud-messaging-push-scope")
                    .then(registration => registration.showNotification(title, { 
                        body, 
                        icon: `${process.env.PUBLIC_URL}/assets/icons/android/android-launchericon-72-72.png` 
                     }))
                    .catch(console.error);

            } catch (e) {
                console.error(e);
            }

        });

    }
}