import axios from '../../Axios/Axios';
import * as API from '../../../constants/api-staging';

export const buscarEmpresaCP = async (businessName) => {
  
  try {
    const token = localStorage.getItem('token').replace(/"/g, '');
    const response = await axios.get(
      `${API.BASE_URL}/captarpagos/getBuscarEmpresa/${businessName}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};
