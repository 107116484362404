import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { sanitizeAmount, trunc } from '../../views/service-payments/utils';
const ItemWrapper = styled.div`
  
  @media ${({ theme }) => theme.breakPoint.mobileSmall} {

  }
`;

const Title = styled.div`
  text-align: center;
  color: white; 
  font-weidht: bold;
`;
const Amount = styled.div`
color: white; 
text-align: center;
`;

const Container = styled.div`
font-size: 1.2em
@media (max-width: 380px) {
  font-size: 0.94em;
}
`;

const Link = styled.div`

`;

export const BoxItem = ({ text, amount, link, onHandleAction, dolar }) => {
  return (
    <NavLink to={link ? link : '#'} onClick={onHandleAction}>
      <Container>
        <Title
          style={{
            backgroundColor: dolar ? '#004c05' : '#202b5a',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            padding: '6px 2px',
            visibility: text === 'hide' && 'hidden',
            textTransform: 'capitalize'
          }}
        >
          {text}
        </Title>
        <ItemWrapper
          style={{
            backgroundColor: dolar ? '#85bb65' : '#3a4d9f',
            borderBottomLeftRadius: dolar ? '0px' : '20px',
            borderBottomRightRadius: dolar ? '20px' : '0px',
            padding: '8px',
            visibility: text === 'hide' && 'hidden'
          }}
        >
          <Amount
            style={{
              fontSize: '1.4em',
              height: '3em',
              paddingTop: '0.6em'
            }}
          >
            {
              dolar ?
                `$ ${sanitizeAmount(trunc(Number(amount.split(' ')[1])))}`
                :
                `$ ${sanitizeAmount(Number(amount.split(' ')[1]))}`
            }
          </Amount>
        </ItemWrapper>
      </Container>
    </NavLink>

  );
};