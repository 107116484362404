import { useCallback, useState } from 'react';
import * as API from '../../../constants/api-staging';
import axios from '../../Axios/Axios';
import { token } from '../../../store';
import { useRecoilValue } from 'recoil';

export const useGetPromoList = () => {
  const [data, setData] = useState([]);
	const tokenData = useRecoilValue(token);
  const getPromoList = useCallback((setLoading) => {
		setLoading(true);
    axios.get(`${API.BASE_URL}/promo/list?active=true`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: tokenData
        },
      })
      .then(response => {
        setData(response.data.data);
      })
      .catch(error => {
      })
			.finally(() => setLoading(false));

  }, []);

  return [
    {
      data,
    },
    getPromoList,
  ];
};
