import React from 'react';
import { Container } from '@components/grid';
export const PaginationActivities = ({
  data,
  page,
  indexPage,
  backOne,
  pageDecrementButton,
  pageIncrementButton,
  minPageNumberList,
  onClickIndex,
  forwardOne,
  maxPageLimit,
  maxPageNumberList
}) => {


  return (
    <>
      {data && data.activities && data.activities.length !== 0 ? (
        <Container className="mb-5">
          <ul className="pageNumbers">
            {page === indexPage[0] ? (
              <li
                style={{
                  backgroundColor: '#AEB8CF',
                }}
              >
                <button
                  onClick={backOne}
                  disabled={page === indexPage[0] ? true : false}
                >
                  <i
                    style={{
                      color: "white "
                    }}
                    className="material-icons mt-2">arrow_back</i>
                </button>
              </li>
            ) : (
              <li
                style={{
                  backgroundColor: '#3A4D9F',
                }}
              >
                <button
                  onClick={backOne}
                  disabled={page === indexPage[0] ? true : false}
                >
                  <i
                    style={{
                      color: "white "
                    }}
                    className="material-icons mt-2">arrow_back</i>
                </button>
              </li>
            )}

            {pageDecrementButton}

            {indexPage.map((idx) => {
              if (
                idx < maxPageNumberList + 1 &&
                idx > minPageNumberList
              ) {
                return (
                  <li
                    key={idx}
                    id={idx}
                    className={page === idx ? 'active' : null}
                    onClick={onClickIndex(idx)}
                  >
                    <button>
                      {idx}
                    </button>
                  </li>
                );
              } else {
                return null;
              }
            })}
            {pageIncrementButton}
            {!(page < maxPageLimit) ? (

              <li
                style={{
                  backgroundColor: '#AEB8CF',
                }}
              >
                <button
                  onClick={forwardOne}
                  disabled={!(page < maxPageLimit)}
                >
                  <i
                    style={{
                      color: "white "
                    }}
                    className="material-icons mt-2">
                    arrow_forward
                  </i>
                </button>
              </li>
            ) : (
              <li
                style={{
                  backgroundColor: '#3A4D9F',
                }}
              >
                <button
                  onClick={forwardOne}
                  disabled={!(page < maxPageLimit)}
                >
                  <i
                    style={{
                      color: "white "
                    }}
                    className="material-icons mt-2">
                    arrow_forward
                  </i>
                </button>
              </li>
            )}
          </ul>
        </Container>
      ) : null}
    </>
  )
}