import React from 'react';
import Datepicker from 'react-datepicker';
import { Container } from '@components/grid';
import { isMobile } from 'react-device-detect';
import Swal from 'sweetalert2';

export const SearchActivities = ({setFilter, filter }) => {
  const handleDate = (input) => {
    
    return (value) => {
      if(input === 'to'){
        if(value > filter.from){
          setFilter(Object.assign({}, filter, { [input]: value }));
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Fecha no valida',
            padding: '2px',
            showConfirmButton: false,
            width: '300px',
            heightAuto: '200px',
            timer: 1500,
            customClass: {
              title: 'm-0 text-danger',
              header: 'p-0',
            },
          })
        }
      }else{ 
        if(value < filter.to){
        setFilter(Object.assign({}, filter, { [input]: value }));
         }else{
          Swal.fire({
            icon: 'error',
            title: 'Fecha no valida',
            padding: '2px',
            showConfirmButton: false,
            width: '300px',
            heightAuto: '200px',
            timer: 1500,
            customClass: {
              title: 'm-0 text-danger',
              header: 'p-0',
            },
          })
         }
        }
    };
  };
  return (
    <>
      {isMobile ? (
        <Container>
          <div className="row">
            <label className=" ml-2 bold">
              <h5>Filtrar por fecha:</h5>
            </label>
            <div className="col-3 col-sm-3  ml-2 p-0">
              <Datepicker
                name='from'
                className="lenghtlabel"
                selected={filter.from}
                onChange={handleDate('from')}
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <div className="col-3 col-sm-3  ml-2 p-0">
              <Datepicker
                name='to'
                className="lenghtlabel"
                selected={filter.to}
                onChange={handleDate('to')}
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
        </Container>
      ) : (
        <Container>
          <div className="row">
            <label className=" ml-2 bold">
              <h5>Filtrar por fecha:</h5>
            </label>
            <div className="col-6 col-sm-2 col-lg-2  ml-2 p-0">
              <Datepicker
                className="lenghtlabel"
                selected={filter.from}
                onChange={handleDate('from')}
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <div className="col-6 col-sm-2 col-lg-2  ml-2 p-0">
              <Datepicker
                className="lenghtlabel"
                selected={filter.to}
                onChange={handleDate('to')}
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
        </Container>
      )}
    </>
  )



}