import { useState, useCallback } from 'react';
import Axios from '../../Axios/Axios';
import * as API from '../../../constants/api-staging';
import { useHistory } from 'react-router';
import * as ROUTES from '@constants/routes';
import { id } from 'date-fns/locale';

export const useSendDataRegister = () => {
  const history = useHistory();
  const putSendDataRegister = useCallback((senData, id) => {
    Axios.put(`${API.BASE_URL}/register/updateRegister/${id}`,
      senData,  
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        },
      })
      .then(response => {
        history.push(`${ROUTES.USER_REGISTER_VALID_IDENTITY}/${id}`);
      })
      .catch(error => {


      });

  }, []);

  return [
    putSendDataRegister,
  ];
};
export const useSendDataRegisterCommerce = () => {
  const history = useHistory();
  const putSendDataRegisterCommerce = useCallback((senData, id) => {
   
    Axios.put(`${API.BASE_URL}/register/updateRegister/${id}`,
      senData,  
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        },
      })
      .then(response => {
        history.push(`${ROUTES.USER_REGISTER_VALID_IDENTITY_COMMERCE}/${id}`);
      })
      .catch(error => {
      });

  }, []);

  return [
    putSendDataRegisterCommerce,
  ];
};

export const useSendDataRegisterCommerceBeneMzaAct = () => {
  const history = useHistory();
  const putSendDataRegisterCommerceBeneMzaAct = useCallback((senData, id) => {
    Axios.put(`${API.BASE_URL}/register/updateRegister/${id}`,
      senData,  
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        },
      })
      .then(response => {
        history.push(`${ROUTES.USER_REGISTER_VALID_IDENTITY_COMMERCE_BENE_MZA_ACT}/${id}`);
      })
      .catch(error => {


      });

  }, []);

  return [
    putSendDataRegisterCommerceBeneMzaAct,
  ];
};

export const useSendDataRegisterUserBeneMzaAct = () => {
  const history = useHistory();
  const putSendDataRegisterUserBeneMzaAct = useCallback((senData, id) => {
   
    Axios.put(`${API.BASE_URL}/register/updateRegister/${id}`,
      senData,  
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        },
      })
      .then(response => {
        history.push(`${ROUTES.USER_REGISTER_VALID_IDENTITY_USER_BENE_MZA_ACT}/${id}`);
      })
      .catch(error => {
      });

  }, []);

  return [
    putSendDataRegisterUserBeneMzaAct,
  ];
};

