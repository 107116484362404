import React from 'react'
import { Form } from 'react-bootstrap'
import styled from 'styled-components';

export const AllOptions = styled.div`
  color: #3a4d9f;
  font-weight: bold;
 flex-direction: wrap
  
`;

export const Option = styled.div`
display: flex;
border: 1px solid #3a4d9f;
border-radius: 12px;
padding: 10px;
margin: 10px 20px;
`;
const OptionDisabled = styled(Option)`
border: 1px solid #dddddd;
color : #ababab;
cursor : not-allowed;
`;

const OpcionTable = ({ opciones, handleSelect, selectValue, idProduct }) => {

    const paintTable = (options) => {

        const dividers = opciones[0].split(';').length;
        const columns = Array(dividers).fill('');
        const amountToNumber = (option) =>{
            let amount = option.split(';')[1];
            let amountNumber = Number(`${amount.slice(0, amount.length - 2) + "." + amount.slice(amount.length - 2)}`)
            return amountNumber;
        }
        return (
            <AllOptions>
               
                {
                    options.map((option, i) => {
                        
                        
                        return (
                            idProduct !== '58150001'  ?
                            <Option>
                                <div
                                    style={{
                                        width: '85%',
                                        paddingLeft: '1.3rem'
                                    }}
                                >
                                    {
                                        [...option.split(';'), null].map((segment, j) => {

                                            if (j === 1 && dividers !== 1) {
                                                return (
                                                    <div>
                                                        {
                                                            !(segment.split('').filter(char => char !== '0').join('')).length ?
                                                                'Monto abierto'
                                                                : `$ ${segment.slice(0, segment.length - 2) + "," + segment.slice(segment.length - 2)}`
                                                        }
                                                    </div>
                                                )
                                            } else if (j === 0 && dividers !== 1) {
                                                return (
                                                    <div>
                                                        {
                                                            !(segment.split('').filter(char => char !== '0').join('')).length ?
                                                                '----' : segment
                                                        }
                                                    </div>
                                                )
                                            }
                                            return (
                                                <div>{segment}</div>
                                            )
                                        })
                                    }
                                </div>
                                <div
                                    onChange={handleSelect}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <input
                                        style={{
                                            margin: '0 10px'
                                        }}
                                        type={'radio'}
                                        name={'option'}
                                        id={`my check`}
                                        value={options[i]}
                                        checked={options[i] === selectValue}
                                    />
                                </div>
                            </Option>
                                   :  amountToNumber(option) < 20000 ? <Option>
                                   <div
                                       style={{
                                           width: '85%',
                                           paddingLeft: '1.3rem'
                                       }}
                                   >
                                       {
                                           [...option.split(';'), null].map((segment, j) => {
   
                                               if (j === 1 && dividers !== 1) {
                                                   return (
                                                       <div>
                                                           {
                                                               !(segment.split('').filter(char => char !== '0').join('')).length ?
                                                                   'Monto abierto'
                                                                   : `$ ${segment.slice(0, segment.length - 2) + "," + segment.slice(segment.length - 2)}`
                                                           }
                                                       </div>
                                                   )
                                               } else if (j === 0 && dividers !== 1) {
                                                   return (
                                                       <div>
                                                           {
                                                               !(segment.split('').filter(char => char !== '0').join('')).length ?
                                                                   '----' : segment
                                                           }
                                                       </div>
                                                   )
                                               }
                                               return (
                                                   <div>{segment}</div>
                                               )
                                           })
                                       }
                                   </div>
                                   <div
                                       onChange={handleSelect}
                                       style={{
                                           display: 'flex',
                                           alignItems: 'center'
                                       }}
                                   >
                                       <input
                                           style={{
                                               margin: '0 10px'
                                           }}
                                           type={'radio'}
                                           name={'option'}
                                           id={`my check`}
                                           value={options[i]}
                                           checked={options[i] === selectValue}
                                       />
                                   </div>
                               </Option> : <OptionDisabled>
                                   <div
                                       style={{
                                           width: '85%',
                                           paddingLeft: '1.3rem'
                                       }}
                                   >
                                       {
                                           [...option.split(';'), null].map((segment, j) => {
   
                                               if (j === 1 && dividers !== 1) {
                                                   return (
                                                       <div>
                                                           {
                                                               !(segment.split('').filter(char => char !== '0').join('')).length ?
                                                                   'Monto abierto'
                                                                   : `$ ${segment.slice(0, segment.length - 2) + "," + segment.slice(segment.length - 2)}`
                                                           }
                                                       </div>
                                                   )
                                               } else if (j === 0 && dividers !== 1) {
                                                   return (
                                                       <div>
                                                           {
                                                               !(segment.split('').filter(char => char !== '0').join('')).length ?
                                                                   '----' : segment
                                                           }
                                                       </div>
                                                   )
                                               }
                                               return (
                                                   <div>{segment}</div>
                                               )
                                           })
                                       }
                                   </div>
                                   <div
                                       style={{
                                           display: 'flex',
                                           alignItems: 'center'
                                       }}
                                   >
                                       <input
                                           style={{
                                               margin: '0 10px',
                                           }}
                                           type={'radio'}
                                           name={'option'}
                                           id={`my check`}
                                           value={undefined}
                                           disabled={true}
                                       />
                                   </div>
                               </OptionDisabled>
                        )
                    })
                }
            </AllOptions >
        )
    }
    return (<>
        <Form>{paintTable(opciones)}</Form>
   </>)
}

export default OpcionTable