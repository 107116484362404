import React, { useState, useEffect } from 'react';
import { Container, Wrapper } from '@components/grid';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import { useLocation } from 'react-router-dom';
import './ServicePayments.css';
import { getEmpresa } from '../../core/api-hooks/captar-pagos/getEmpresa';
import PaltaBreadcrumbs from '../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import { useHistory } from 'react-router';
import * as ROUTES from '../../constants/routes';
import { fireSwal } from '../../components/utils/swalUtils';
import './ServicePayments.css';
import PaltaModalSpinner from '../../components/PaltaModalSpinner';
import { useGetOpcion } from '../../core/api-hooks/captar-pagos/useGetOpcion';
import { Col, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';

const ServicePayments = () => {
  const location = useLocation();
  const business = location.state;
  const history = useHistory();
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [responseEmpresa, setResponseEmpresa] = useState(undefined);
  const [productId, setProductId] = useState(undefined);
  const [requiereDatosAdicionales, setRequiereDatosAdicionales] =
    useState(undefined);

  const [postBody, setPostBody] = useState({
    tipo: 'sinFactura',
    descripcion: business.descripcion,
    codeProducto: business.idProducto,
    ordinal: 1,
    parametros: [],
  });

  const handleInput = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setPostBody((previous) => {
      return {
        ...previous,
        parametros: [value],
      };
    });
  };

  useEffect(() => {
    if (!responseEmpresa) {
      getEmpresa(business.idProducto)
        .then((res) => {
          setResponseEmpresa(res);
          setRequiereDatosAdicionales(
            res?.data?.factura?.[0]?.opcion?.[0]
              ?.requiereDatosAdicionales?.[0] === 'true',
          );
          setProductId(res.data.factura[0].opcion[0].idProducto[0]);
          setLoading(false);
        })
        .catch((err) => {
          Swal.fire('Error al buscar la empresa', '', 'error');
          setLoading(false);
        });
    }
  }, []);

  const handleSubmitButton = async () => {
    setLoading(true);
    try {
      const response = await useGetOpcion(postBody);
      setLoading(false);
      if (response?.data?.data?.code === 200) {
        history.push({
          pathname: ROUTES.SERVICE_PAYMENTS_GET_OPCION_1,
          state: {
            response: response.data,
            businessName: business.descripcion,
            postBody,
          },
        });
      } else {
        fireSwal(
          `Hubo un error al buscar al cliente, por favor verifique si el ID es Válido`,
        );
      }
    } catch (error) {
      setLoading(false);
      fireSwal(
        `Hubo un error al buscar al cliente, por favor verifique si el ID es Válido`,
      );
    }
  };
  const codeClaro = process.env.REACT_APP_CAPTAR_PAGOS_CLARO_ID;

  return (
    <>
      <PaltaNavBar className="light" />
      <PaltaBreadcrumbs
        name="Seleccionar forma de pago"
        link={ROUTES.PAYMENTS_SELECT}
        variant="dark"
      />
      <Container>
        <Wrapper>
          <Row>
            <Col>
              <h4>Datos requeridos</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              {requiereDatosAdicionales && (
                <>
                  <Row>
                    <Col>
                      <div className="data-label">Empresa</div>
                      <div
                        className="data colorFont"
                        data-cy="business-description"
                      >
                        {business.descripcion}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="data-label">Dato requerido:</div>
                      <div className="data colorFont">
                        {productId === codeClaro
                          ? 'Ingrese el Nro de Cuenta (reemplazando el primer 2 con un 0)'
                          : responseEmpresa.data.factura[0].opcion[0]
                              .descripcion[0]}
                      </div>
                      <input
                        type="text"
                        className="input-empresa form-control"
                        value={inputValue}
                        onChange={handleInput}
                        data-cy="required-data"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {inputValue.length > 0 && ( // paymentInputValue.length !== 0
                        <button
                          className="button"
                          onClick={handleSubmitButton}
                          data-cy="continue-button"
                        >
                          Continuar
                        </button>
                      )}
                    </Col>
                  </Row>

                  <div></div>
                </>
              )}
            </Col>
          </Row>
        </Wrapper>
      </Container>
      <PaltaFooter />
      <PaltaModalSpinner show={loading} size="sm" />
    </>
  );
};

export default ServicePayments;
