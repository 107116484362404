import { useState, useCallback } from 'react';
import Axios from '../../Axios/Axios';
import * as API from '../../../constants/api-staging';
import Swal from 'sweetalert2';
export const usePostCreateIdentity = (id) => {
  // const [data, setData] = useState(undefined);
  const postCreateIdentity = useCallback(() => {
    Axios.post(`${API.BASE_URL}/register/createIdentity`, {
      flowId: `${process.env.REACT_APP_FLOW_ID}`,
      metadata: { "Client": id }
    })
      .then(response => {
        localStorage.setItem("identity", response.data.data.identity);
        // setData(response.data.data.identity);
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: `${error.response.data.message}`,
          padding: "2px",
          showConfirmButton: false,
          width: "300px",
          heightAuto: "200px",
          timer: 1500,
          customClass: {
            title: "m-0 text-danger",
            header: "p-0"
          }
        })

      });

  }, []);

  return [
    // {
    //   data
    // },
    postCreateIdentity,
  ];
};