import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap';

export default class PaltaRowList extends Component {
  render() {
    let { title, variant, urlIcon, addNewIcon, children, titleIcon, mainItemLink } = this.props;
    return (
      <Container className={`h-payments-group ${variant || ''}`} style={{ marginTop: "2rem" }}>
        <Row className="row-payments-title">
          <Col sm={12} className="box-payments-title">
            <h3 className="payments-title-pay">{title}</h3>
          </Col>
        </Row>
        <Row>
          <div className="box-main-item-pay">
            <Link to={mainItemLink}>
              <div className="main-item-pay">
                <div className="icon" style={{ backgroundImage: `url(${urlIcon})` }}>
                  {addNewIcon ? <img className="add-new" alt="add new icon" src="assets/images/payments-icon-mini-add.svg" /> : ''}
                </div>
                <div className="d-flex align-items-center justify-content-center info">
                  <span>{titleIcon || 'Buscar'}</span>
                </div>
              </div>
            </Link>
            <div className="divider"></div>
          </div>
          <div className="row-payments-items">
            {children}
          </div>
        </Row>
      </Container>
    )
  }
}
