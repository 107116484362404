import React, { useEffect, useState } from 'react';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import PaltaModalSpinner from '../../components/PaltaModalSpinner';
import { Container, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { BASE_URL } from '../../constants/api-staging';
import { motion } from 'framer-motion';
import Swal from 'sweetalert2';
import axios from '../../core/Axios/Axios';
import Shops from './Shops';
import { PaginationShops } from './pagination-shops/PaginationShops';
import PaltaBreadcrumbs from '../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import * as ROUTES from '../../constants/routes';
import { useGetPromoList } from '../../core/api-hooks/use-promo-list/get';

export const SearchShops = () => {
  const [inputs, setInputs] = useState({
    rubro: '',
    localidad: '',
    nombre: '',
    currency: '',
    promoId: '',
    percentage: '',
		percent: '',
		day: ''
  });
  const [shops, setShops] = useState(undefined);
  const [rubros, setRubros] = useState(undefined);
  const [page, setPage] = useState(1);
  const pageNumberList = 5;
  const [maxPageNumberList, setMaxPageNumberList] = useState(5);
  const [minPageNumberList, setMinPageNumberList] = useState(0);
  const [maxPageLimit, setMaxPageLimit] = useState(0);
  const [indexPage, setIndexPage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkedMaxUses, setCheckedMaxUses] = useState(false);
  const [checkedLimitPromo, setCheckedLimitPromo] = useState(false);
  const [{ data: promoList }, getPromoList] = useGetPromoList();
  // const [skip, setSkip] = useState((page - 1) * 15);
  let limit = 15;
  let skip = (page - 1) * limit;
  // let skipFirstPage = 0;

  useEffect(() => {
    getPromoList(setLoading);
  }, []);

  const sessionExpiredModal = () => {
    Swal.fire({
      icon: 'error',
      title: 'Sesion Expirada',
      padding: '2px',
      showConfirmButton: false,
      width: '300px',
      heightAuto: '200px',
      timer: 1500,
      customClass: {
        title: 'm-0 text-danger',
        header: 'p-0',
      },
    }).then(function () {
      window.location.href = '/';
    });
  };

  const back = () => {
    if (page > 1) {
      setPage(() => page - 1);
      if (page - 5 < maxPageNumberList && page - 5 <= maxPageLimit) {
        setPage(page - 5);
        setMaxPageNumberList(maxPageNumberList + pageNumberList);
        setMinPageNumberList(minPageNumberList + pageNumberList);
      }
      if (page - 1 < maxPageNumberList) {
        setMaxPageNumberList(maxPageNumberList - pageNumberList);
        setMinPageNumberList(minPageNumberList - pageNumberList);
      }
    }
  };

  const backOne = () => {
    if (page > 1) {
      setPage(() => page - 1);
      if ((page - 1) % pageNumberList === 0) {
        setMaxPageNumberList(maxPageNumberList - pageNumberList);
        setMinPageNumberList(minPageNumberList - pageNumberList);
      }
    }
  };

  const onClickIndex = (idx) => {
    return () => {
      setPage(idx);
    };
  };

  const forwardOne = () => {
    setPage(page + 1);
    if (page + 1 > maxPageNumberList) {
      setMaxPageNumberList(maxPageNumberList + pageNumberList);
      setMinPageNumberList(minPageNumberList + pageNumberList);
    }
  };

  const forward = () => {
    setPage(page + 1);
    if (page + 5 > maxPageNumberList && page + 5 <= maxPageLimit) {
      setPage(page + 5);
      setMaxPageNumberList(maxPageNumberList + pageNumberList);
      setMinPageNumberList(minPageNumberList + pageNumberList);
    }
    if (page + 1 > maxPageNumberList) {
      setMaxPageNumberList(maxPageNumberList + pageNumberList);
      setMinPageNumberList(minPageNumberList + pageNumberList);
    }
  };

  let pageIncrementButton = null;
  if (indexPage.length > maxPageNumberList) {
    pageIncrementButton = (
      <li onClick={forward}>
        <button>&hellip;</button>
      </li>
    );
  }

  let pageDecrementButton = null;
  if (minPageNumberList >= 1) {
    pageDecrementButton = (
      <li onClick={back}>
        <button>&hellip;</button>
      </li>
    );
  }

  const getRubros = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/heading/`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: window.localStorage.getItem('token'),
        },
      });
      const nameRubros = response.data.data.headings.map((r) => r.name);
      setRubros(nameRubros);
    } catch (e) {
      if (e.response.status === 401) {
        sessionExpiredModal();
      } else {
        Swal.fire('Error al obtener los rubros ', '', 'error');
      }
    }
  };

  let arrayIndexes = [];

  useEffect(() => {
    if (shops && shops.data && shops.data.length) {
      const count = shops.pagination.count;
      const indexCount = Math.ceil(count / limit);
      setMaxPageLimit(indexCount);
      for (let i = 0; i < indexCount; i++) {
        arrayIndexes.push(i + 1);
      }
      setIndexPage(arrayIndexes);
    } else {
    }
  }, [shops, setIndexPage]);

  useEffect(async () => {
    await getRubros();
  }, []);

  useEffect(async () => {
    try {
      setLoading(true);
      await fetchData(skip);
      // setSkip((page - 1) * limit, async (skip) => await fetchData(skip))
      setLoading(false);
    } catch (e) {
      setLoading(false);
      if (e.response.status === 401) {
        sessionExpiredModal();
      } else {
        Swal.fire('No se pudo encontrar algun comercio');
      }
    }
  }, [page]);

  const handleChangeInputs = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleChangeCheck = (setter, e) => {
    setter((val) => !val);
  };

  const fetchData = async (skip = 0) => {
		const haveMaxUses = checkedMaxUses ? "true" : "";
		const haveLimitPromo = checkedLimitPromo ? "true" : "";
    const response = await axios.get(
      `${BASE_URL}/user/business-query
				?rubro=${inputs.rubro.toLowerCase()}
				&localidad=${inputs.localidad.toLowerCase()}
				&nombre=${inputs.nombre.toLowerCase()}
				&currency=${inputs.currency.toLowerCase()}
				&skip=${skip}
				&limit=${limit}
				&promoId=${inputs.promoId}
				&haveLimitPromo=${haveLimitPromo}
				&percent=${inputs.percentage}
				&haveMaxUses=${haveMaxUses}
				&day=${inputs.day}
				`,
      {
        headers: {
          Authorization: window.localStorage.getItem('token'),
        },
      },
    );
    setShops(response.data.data);
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setMinPageNumberList(0);
      setMaxPageNumberList(5);
      setMaxPageLimit(0);
      setPage(1);
      await fetchData();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      if (e.response.status === 401) {
        sessionExpiredModal();
      } else {
        Swal.fire('No se pudo encontrar algun comercio');
      }
    }
  };

  const percentageList = ['5', '10', '15', '20', '25', '30', '50'];
  const promoDays = [
    { value: 'monday', name: 'Lunes' },
    { value: 'tuesday', name: 'Martes' },
    { value: 'wednesday', name: 'Miércoles' },
    { value: 'thursday', name: 'Jueves' },
    { value: 'friday', name: 'Viernes' },
    { value: 'saturday', name: 'Sábado' },
    { value: 'sunday', name: 'Domingo' },
  ];

  return (
    <>
      <PaltaNavBar className="light" />
      <PaltaBreadcrumbs
        className="p-0"
        name="Buscador de comercios"
        link={ROUTES.ACCOUNT}
        variant="dark"
      />
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ type: 'spring', duration: 0.5, bounce: 0.3 }}
      >
        <Container>
          {loading && <PaltaModalSpinner show={loading} size="sm" />}
          {isMobile ? (
            <>
              <Row className="d-flex justify-content-center alig-item-center">
                <div className="col-8 col-sm-8 col-md-8 p-0">
                  <h3
                    className="title ml-3 d-flex  align-items-center"
                    style={{ color: '#3A4D9F', marginTop: '45px' }}
                  >
                    Buscar Comercios
                  </h3>
                </div>
                <div
                  className="col-4 col-sm-4 col-md-4 p-0"
                  style={{ height: '80px' }}
                >
                  <img
                    className="float-right img-fluid mr-3 pb-5"
                    src="assets/images/palta-512.svg"
                    alt=""
                    style={{ marginTop: '33px' }}
                    height={50}
                    width={50}
                  />
                </div>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <div className="col-8 col-sm-8 col-md-8">
                  <h2
                    className="title ml-2"
                    style={{ color: '#3A4D9F', marginTop: '38px' }}
                  >
                    Buscar Comercios
                  </h2>
                </div>
              </Row>
            </>
          )}

          <form onSubmit={(e) => handleSubmitForm(e)}>
            <div class="input-group input-group-lg mt-4 mb-3">
              <input
                type="text"
                class="form-control display-4"
                placeholder="Nombre comercio"
                id="nombreFantasy"
                name="nombre"
                onChange={(e) => handleChangeInputs(e)}
              />
            </div>
            <select
              className="form-control  mt-4 mb-3"
              name="rubro"
              onChange={(e) => handleChangeInputs(e)}
            >
              <option value="">Selecciona Rubro</option>
              {rubros &&
                rubros.length &&
                rubros.map((r) => {
                  return (
                    <option value={r} key={r}>
                      {r}
                    </option>
                  );
                })}
            </select>
            <select
              className="form-control mt-4 mb-3"
              id="city"
              name="localidad"
              onChange={(e) => handleChangeInputs(e)}
            >
              <option value="">Seleccionar localidad</option>
              <option value="capital">Capital</option>
              <option value="general alvear">General Alvear</option>
              <option value="godoy cruz">Godoy Cruz</option>
              <option value="guaymallén">Guaymallén</option>
              <option value="junín">Junín</option>
              <option value="la paz">La Paz</option>
              <option value="las heras">Las Heras</option>
              <option value="lavalle">Lavalle</option>
              <option value="luján de cuyo">Luján de Cuyo</option>
              <option value="maipú">Maipú</option>
              <option value="malargüe">Malargüe</option>
              <option value="rivadavia">Rivadavia</option>
              <option value="san carlos">San Carlos</option>
              <option value="san martín">San Martín</option>
              <option value="san rafael">San Rafael</option>
              <option value="santa rosa">Santa Rosa</option>
              <option value="tunuyán">Tunuyán</option>
              <option value="tupungato">Tupungato</option>
            </select>
            <select
              className="form-control mt-4 mb-3"
              id="currency"
              name="currency"
              onChange={(e) => handleChangeInputs(e)}
            >
              <option value="">Seleccionar monedero</option>
              <option value="peso">Pesos</option>
              <option value="fiscal_credit">Crédito Fiscal</option>
              <option value="peso_tourism">Crédito Turismo</option>
              <option value="peso_activa">Crédito Activa</option>
            </select>
            <select
              className="form-control mt-4 mb-3"
              id="promoId"
              name="promoId"
              onChange={(e) => handleChangeInputs(e)}
            >
              <option value="">Seleccionar Promoción</option>
              {promoList.length > 0 &&
                promoList.map((promo) => {
                  return <option value={promo._id}>{promo.label}</option>;
                })}
            </select>
            <select
              className="form-control mt-4 mb-3"
              id="percentage"
              name="percentage"
              onChange={(e) => handleChangeInputs(e)}
            >
              <option value="">Seleccionar Porcentaje</option>
              {percentageList.map((percentage) => {
                return <option value={percentage}>{`${percentage}%`}</option>;
              })}
            </select>
            <select
              className="form-control mt-4 mb-3"
              id="day"
              name="day"
              onChange={(e) => handleChangeInputs(e)}
            >
							<option value="">Seleccionar Día</option>
							{promoDays.map((dayObject) => {
								return <option value={dayObject.value}>{dayObject.name}</option>
							})}
						</select>
            <div>
							<label>
								Tiene Limite de Usos
								<input
									type="checkbox"
									value={checkedMaxUses}
									name="haveMaxUses"
									style={{ marginLeft: "10px" }}
									onChange={(e) => handleChangeCheck(setCheckedMaxUses, e)}
								/>
							</label>
            </div>
            <div>
							<label>
								Tiene Limite de Dinero
								<input
									type="checkbox"
									value={checkedLimitPromo}
									name="haveLimitPromo"
									style={{ marginLeft: "10px" }}
									onChange={(e) => handleChangeCheck(setCheckedLimitPromo, e)}
								/>
							</label>
            </div>
            <button
              class="btn btn-success btn-block mt-4 mb-3 pt-2 pb-2"
              id="search-commerce"
            >
              Buscar
            </button>
          </form>
          {shops && shops.data && shops.data.length !== 0 ? (
            <>
              <Shops shops={shops.data} />
              <PaginationShops
                data={shops.data}
                page={page}
                indexPage={indexPage}
                backOne={backOne}
                pageDecrementButton={pageDecrementButton}
                pageIncrementButton={pageIncrementButton}
                minPageNumberList={minPageNumberList}
                onClickIndex={onClickIndex}
                forwardOne={forwardOne}
                maxPageLimit={maxPageLimit}
                maxPageNumberList={maxPageNumberList}
              />
            </>
          ) : (
            <p
              className="text-center pt-3 pb-3 no-commerces-found"
              style={{
                color: '#3A4D9F',
                fontSize: 15,
                fontWeight: 'bold',
                backgroundColor: '#CCE5FF',
              }}
            >
              No se encuentran comercios
            </p>
          )}
        </Container>
      </motion.div>
      <PaltaFooter />
    </>
  );
};
