import { atom } from 'recoil';

export const wording = atom({
  key: 'wording',
  default: {
    screens: {
      activity: {
        title: 'Actividad',
        walletsDescription: {
          peso_tourism:
            'Solo disponible para usar en comercios relacionados con el Turismo.',
          peso_activa:
            'Usalos en todos los comercios adheridos a Mendoza Activa sin restricciones.',
          fiscal_credit:
            'La vas a poder usar para comprar en comercios que se adhieran y tambien para pagar tus impuestos provinciales.',
          peso:
            'Usalos en cualquier comercio adherido o mandalo a tu CBU',
          dolar: 'Es una moneda digital estable, convertida desde tu monedero Pesos, con paridad en USDT.',
          dolar_tourism: 'Es una moneda digital estable, convertida desde tu monedero Crédito Turismo, con paridad en USDT.',
          dolar_activa: 'Es una moneda digital estable, convertida desde tu monedero Crédito Activa, con paridad en USDT.'
            
        },
        sections: {
          lastActivities: {
            title: 'últimos movimientos',
            fields: {
              search: {
                placeholder: 'Buscar consumo o recibo',
              },
            },
            empty: 'Hoy no hubo movimientos en el monedero de ',
          },
        },
      },
    },
  },
});
