import React, { useCallback } from 'react';
import { Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { auth } from '../../core/Firebase';
import { Spacer } from '../../components/spacer';
import { H2, Body1 } from '../../components/typography';
import { fireSwal } from '../../components/utils/swalUtils';
import Swal from 'sweetalert2';

const Modal = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundLight};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background};
  ${({ theme }) =>
    theme.isMobile
      ? `
    width: 100%;
    height: 100%;
    justify-content: space-between;
  `
      : `

      padding: ${theme.spacers.m};
      border-radius: ${theme.spacers.m};
      box-shadow: 0px 8px 16px rgba(0,0,0,0.1);
    `}
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  ${({ theme }) =>
    theme.isMobile
      ? `padding: 8px;`
      : `
    position: absolute;
    top: 16px;
    right: 16px;
  `}
`;

const ModalBody = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacers.m};
`;

const ModalFooter = styled.div`
  width: 100%;
  padding: 0px ${({ theme }) => theme.spacers.m} 36px;
`;

const IconButton = styled.div`
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ForgotPassword = ({ setShowModal, setLoading }) => {
  const { register, handleSubmit, errors, formState } = useForm({
    mode: 'onChange',
  });

  const onSubmit = useCallback(async (data) => {
    setLoading(true);
    auth
      .sendPasswordResetEmail(data.email)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Email enviado correctamente',
          padding: '2px',
          showConfirmButton: false,
          width: '300px',
          heightAuto: '200px',
          timer: 1500,
          customClass: {
            title: 'm-0 text-success',
            header: 'p-0',
          },
        })
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.code === 'auth/user-not-found') {
          fireSwal('El email no corresponde a ningun usuario', 2500);
        } else {
          fireSwal('Tuvimos un problema intentelo mas tarde', 2500);
        }
      });
  }, []);

  return (
    <Modal>
      <ModalCard>
        <ModalHeader>
          <IconButton onClick={() => setShowModal(false)}>
            <i className="material-icons" style={{ fontSize: 36 }}>
              close
            </i>
          </IconButton>
        </ModalHeader>
        <ModalBody>
          <H2>Recuperar contraseña</H2>
          <Spacer />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="form-group-login">
              <input
                name="email"
                placeholder="Tu email"
                ref={register({
                  required: 'Required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  },
                })}
                className="form-control"
              />
            </Form.Group>
            {errors.email && errors.email.type === 'required' && (
              <p className="input-error">El campo email es requerido</p>
            )}
            {errors.email && errors.email.type === 'pattern' && (
              <p className="input-error">El email es invalido</p>
            )}
          </form>
          <Spacer />
          <div className="row mx-0 justify-content-center mt-3">
            <div className="col-12 col-sm-12  p-0">
              <p className="alert alert-info m-0">
                <Body1 className="text-center">Recibirás un email para poder recuperar tu contraseña</Body1>
              </p>
            </div>
          </div>

        </ModalBody>
        <ModalFooter>
          <Spacer />
          <Button
            variant="primary"
            size="lg"
            className=" active btn-block btn-fullwidthe"
            disabled={!formState.isValid}
            onClick={handleSubmit(onSubmit)}
          >
            Enviar
          </Button>
        </ModalFooter>
      </ModalCard>
    </Modal>
  );
};
