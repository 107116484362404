import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import impuesto from '../../assets/images/detalle_impuesto.svg';
class ModalTax extends Component {
  render() {
    let { children, footer } = this.props;
    return (
      <Modal {...this.props} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className="p-0" closeButton>
          <img className="img-fluid"
            style={{ borderRadius: "14px" }}
            height={400} src={impuesto} alt="imagenTransaction" />
        </Modal.Header>
        {children &&
          <Modal.Body className="p-0">
            {children}
          </Modal.Body>
        }
        {footer &&
          <Modal.Footer>

            {footer}
          </Modal.Footer>
        }

      </Modal>
    );
  }
}

export default ModalTax;