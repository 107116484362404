import axios from '../../Axios/Axios';
import { fireSwal } from '../../../components/utils/swalUtils';
import * as API from '../../../constants/api-staging';

export const pagarLote = async (body) => {
  console.log(body);
  const token = localStorage.getItem('token').replace(/"/g, '');
  const response = await axios.post(
    `${API.BASE_URL}/captarpagos/pagarLote`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    },
  );
  return response;
};
