import React from 'react';
import anuncioExito from '../../assets/images/anuncio-cash-exito.svg'
import { Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import { getCurrencyLabel, sanitizeAmount } from '../service-payments/utils';
import { useHistory } from 'react-router';
import { motion } from 'framer-motion'

const DollarSuccess = () => {
    const location = useLocation();
    const history = useHistory();
    const { amountPayed, amountReceived, destinationCurrency, originCurrency, transactionCode } = location.state;

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const today = new Date();
    const formatedDate = today.toLocaleDateString("es-AR", options)
    const time = today.toString().split(' ');

    return (
        <div>
            <PaltaNavBar />
            <section>
                <motion.div
                    initial={{ x: '100%', opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ type: 'spring', duration: .4, bounce: 0.2 }}
                >
                    <Container className="contenedor">

                        <h2 style={{ color: "#79B279", marginTop: '0.5em' }}>

                            {destinationCurrency === "peso" ? (
                                `Recibís ${sanitizeAmount(Number(amountReceived))} Pesos`
                            ) : destinationCurrency === "dolar" ? (
                                `Recibís ${sanitizeAmount(Number(amountReceived))} USDP`
                            ) : destinationCurrency === "peso_activa" ? (
                                `Recibís ${sanitizeAmount(Number(amountReceived))} Crédito Activa`
                            ) : destinationCurrency === "dolar_activa" ? (
                                `Recibís ${sanitizeAmount(Number(amountReceived))} USDP Activa`
                            ) : destinationCurrency === "peso_tourism" ? (
                                `Recibís ${sanitizeAmount(Number(amountReceived))} Crédito Turismo`
                            ) : destinationCurrency === "dolar_tourism" ? (
                                `Recibís ${sanitizeAmount(Number(amountReceived))} USDP Turismo`
                            ) : null
                            }
                        </h2>

                        <div className="d-flex justify-content-center align-items-center h1">
                            <img src={anuncioExito} alt="imagen" height={300} />
                        </div>
                    </Container>
                    <Container style={{ backgroundColor: '#D4EDDA', textAlign: 'center' }}>
                        <div>
                            <h5 className="success-subheading">
                                Solicitud de Intercambio de monedas realizada
                            </h5>
                        </div>
                        <div>
                            <hr style={{ border: 0, borderTop: "3px solid rgba(0,0,0,.1)", borderColor: "#155724" }} />
                            <div className='pb-3'>
                                <p className='success-body'>
                                    {`N° operación: ${transactionCode}`}
                                </p>
                                <p className='success-body'>
                                    {`Pagaste: ${sanitizeAmount(amountPayed)} ${getCurrencyLabel(originCurrency)}`}
                                </p>
                                {true !== undefined && (
                                    <p className='success-body'>
                                        {`Creada el: ${formatedDate} a las ${time[4]}`}
                                    </p>
                                )}
                            </div>
                        </div>
                    </Container>
                </motion.div>
            </section>
            <PaltaFooter />
        </div>
    )
}

export default DollarSuccess;