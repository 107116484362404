import Swal from 'sweetalert2';

export const fireSwal = (title, timer = 1500, icon = 'error') => {
    Swal.fire({
        icon,
        title,
        padding: "2px",
        showConfirmButton: false,
        width: "300px",
        heightAuto: "200px",
        timer: 1500,
        customClass: {
          title: "m-0 text-danger",
          header: "p-0"
        }
      })
}