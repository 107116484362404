import React, { useState, useCallback, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import logoPalta from '../../../../assets/images/palta-logo-500.svg';
import * as API from '@constants/api-staging';
import * as ROUTES from '@constants/routes';
import userHelper from '@helpers/user';
import Swal from 'sweetalert2';
import '../../../register-form/register.css';
import { useRecoilState, useRecoilValue } from 'recoil';
import { dataCommerceBeneFinalProcess, dataCommerceFormBene,  dataFormCommerceFinishBene,globalAddressBene } from '../../../../store';
import { useSendDataRegisterCommerceBeneMzaAct } from '../../../../core/api-hooks/use-send-data-register/put';
import { usePostCreateIdentity } from '../../../../core/api-hooks/use-create-identity/post';

const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

`;

const BoxContainer = styled.div`
  width: 340px;
  min-height: 624px;
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 0px 0px 4.7px rgba(15, 15, 15, 0.28);
  position: relative;
  overflow: hidden;
  padding: 0px 10px 0px 10px;
`;


const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

export const CommerceBeneMzaActPage3 = () => {
  const { id } = useParams();
  const datacommerceForm = useRecoilValue(dataCommerceFormBene);
  const datacommerceFormFinish = useRecoilValue(dataFormCommerceFinishBene);
  const datagloballAddress = useRecoilValue(globalAddressBene);
  const [datacommerceFinalProcess, setDatacommerceFinalProcess] = useRecoilState(dataCommerceBeneFinalProcess);
  const datacommerce = useRecoilValue(dataCommerceBeneFinalProcess);
  const [putSendDataRegisterCommerceBeneMzaAct] = useSendDataRegisterCommerceBeneMzaAct();
  const { register, handleSubmit, errors, formState, watch } = useForm({
    mode: 'onChange',
  });
  const commerceData = useRef({});
  commerceData.current = watch("commerceData", "");
  const history = useHistory();
  const [postCreateIdentity] = usePostCreateIdentity();
  let dataDefaultMyPymeCert = datacommerce?.myPymeCert?.hasCert !== undefined ? datacommerce?.myPymeCert?.hasCert : false;
  let dataDefaultObligatedSubject = datacommerce?.obligatedSubject !== undefined ? datacommerce?.obligatedSubject : false;
  const [state] = useState({ data: { checked: dataDefaultObligatedSubject } });
  const [stateMyPymeCert] = useState({ data: { checked: dataDefaultMyPymeCert } });
  const onSubmit = useCallback(async (data) => {
    let clientRiskPep = datacommerceFormFinish.pep === true && "alto";
    let clientRiskData = datacommerceFormFinish.impositiveCondition === "Responsable inscripto" ? "medio"
      : datacommerceFormFinish.impositiveCondition === "Monotributista" ? "medio"
        : datacommerceFormFinish.impositiveCondition === "Exento Iva" ? "bajo"
          : datacommerceFormFinish.impositiveCondition === "Consumidor final" ? "bajo"
            : null;
    const senData = {
      role: "user",
      fantasyName: datacommerceForm.fantasyName,
      businessName: datacommerceForm.bussinesName,
      cuit: datacommerceForm.cuit,
      rubro: datacommerceFormFinish.rubro,
      address: datacommerceForm.address,
      impositiveCondition: datacommerceFormFinish.impositiveCondition,
      mzaActivaTicketNumber: data.numTicket,
      isMzaActivaBeneficiary: true,
      pep: datacommerceFormFinish.pep === "true" ? true : false,
      holderOrProxy: datacommerceFormFinish.commerceData,
      globalAddress: {
        city: datagloballAddress.globalAddress.city,
        country: datagloballAddress.globalAddress.country,
        province: datagloballAddress.globalAddress.province,
      },
      myPymeCert: {
        hasCert: data.certifyPyme === "true" ? true : false,
      },
      isTaxReduced: false,
      isTaxReached: false,
      obligatedSubject: data.sujetoObligado === "true" ? true : false,
      clientRisk: clientRiskPep !== false ? clientRiskPep : clientRiskData,
    }
    setDatacommerceFinalProcess(senData)
    postCreateIdentity(id);
    putSendDataRegisterCommerceBeneMzaAct(senData, id);
  }, [datacommerceFinalProcess]);
  if (datacommerceForm === undefined) {
    history.push(`${ROUTES.MENU_BENE_MZA_ACT_COMMERCE_PAGE_1}/${id}`)
  }
  return (
    <>
      <AppContainer>
        <BoxContainer>
          <InnerContainer>
            <img
              src={logoPalta}
              width="120"
              height="50"
              className="d-inline-block align-top position-logo-palta-form-user "
              alt="Palta logo"
            />
            <form
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="input-container">
                <div className="row d-flex justify-content-center mt-2">
                  <div className="col-12 mr-4 ml-4 mb-3 col-sm-12">
                    <div className="form-group  text-center mb-0">
                      <br />
                      <label className="text-center h2 mb-0 bold" style={{ fontSize: 18, width: "auto", height: "2rem" }} htmlFor="name" >
                        <a
                          target="_blank"
                          style={{ textDecoration: "underline" }}
                          rel="noopener noreferrer"
                          href="https://www.argentina.gob.ar/uif/sujetos-obligados/listado"
                        >
                          <p>¿Sos sujeto obligado?</p>
                        </a>
                      </label>
                      <br />
                      <label style={{ width: "auto", height: "1rem" }}>
                        <input
                          name="sujetoObligado"
                          className="mr-2"
                          ref={register({ required: 'Required' })}
                          value={true}
                          defaultChecked={state.data.checked === true}
                          type="radio"
                        />
                        Si
                      </label>
                      <label style={{ width: "auto", height: "1rem" }} className="ml-2">
                        <input
                          className="mr-2"
                          name="sujetoObligado"
                          ref={register({ required: 'Required' })}
                          value={false}
                          defaultChecked={state.data.checked === false}
                          type="radio"
                        />
                        No
                      </label>
                      {(errors.sujetoObligado && errors.sujetoObligado.type === 'required') && (
                        <p className="input-error mb-0">* El campo es requerido</p>
                      )}
                      <br />
                      <label className="text-center mt-2 h2 mb-0 bold" style={{ fontSize: 18, width: "auto", height: "2rem" }} htmlFor="name" >
                        ¿Tengo certificado mi Pyme?
                      </label>
                      <br />
                      <label style={{ width: "auto", height: "1rem" }}>
                        <input
                          name="certifyPyme"
                          className="mr-2"
                          ref={register({ required: 'Required' })}
                          value={true}
                          defaultChecked={stateMyPymeCert.data.checked === true}
                          type="radio"
                        />
                        Si
                      </label>
                      <label style={{ width: "auto", height: "1rem" }} className="ml-2">
                        <input
                          className="mr-2"
                          name="certifyPyme"
                          ref={register({ required: 'Required' })}
                          value={false}
                          defaultChecked={stateMyPymeCert.data.checked === false}
                          type="radio"
                        />
                        No
                      </label>
                      {(errors.certifyPyme && errors.certifyPyme.type === 'required') && (
                        <p className="input-error mb-0">* El campo es requerido</p>
                      )}

                      <div className="input-container mt-5 mb-3 pl-3 pr-3">
                        <input
                          name="numTicket"
                          className="input-class"
                          type="text"
                          ref={register({
                            pattern: {
                              value: /^[0-9\b]+$/,
                            },
                            required: 'Required'
                          })}
                          data-cy="numTicket"
                          defaultValue={datacommerce?.mzaActivaTicketNumber}
                        />
                        <label className="label-text pl-3 pr-3" htmlFor="name" >Número de ticket Mendoza activa</label>
                      </div>

                      {errors.numTicket && errors.numTicket.type === 'required' && (
                        <p className="input-error">El campo es requerido</p>
                      )}
                      {errors.numTicket && errors.numTicket.type === 'pattern' && (
                        <p className="input-error">el campo solo debe tener números</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>


              <div className="position-button-align-bene-mza-act-commerce-finish d-flex justify-content-center align-items-center mt-2">

                <Button
                  type="submit"
                  size="md"
                  className=" active btn-block btn-fullwidthe bg-danger mt-0"
                  onClick={() => history.push(`${ROUTES.MENU_BENE_MZA_ACT_COMMERCE_PAGE_2}/${id}`)}
                  style={{ fontSize: 18, borderRadius: 5, fontFamily: "inherit", textTransform: "none" }}
                  data-cy="button-ingresar"
                >
                  Volver
                </Button>
                <Button
                  type="submit"
                  size="md"
                  className="active btn-block btn-fullwidthe btnColor mt-0 ml-2"
                  disabled={!formState.isValid}
                  style={{ fontSize: 18, borderRadius: 5, fontFamily: "inherit", textTransform: "none" }}
                  data-cy="button-ingresar"
                >
                  Siguiente
                </Button>
              </div>

            </form>
          </InnerContainer>

        </BoxContainer>
      </AppContainer>

    </>
  );
};

