import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { user, token } from '../../store';
import '../Cvu/Cvu.css'
import { useGetCvu } from '../../core/api-hooks/use-cvu/get';
import Swal from 'sweetalert2';
import * as API from '../../constants/api-staging';
import { fetcher } from '../../core/Fetch/Fetch';

export const Cvu = ({ cvu, setCvu }) => {

  const [{ data }, getCvu] = useGetCvu();
  const tokenData = useRecoilValue(token);
  const userData = useRecoilValue(user);
  let ali = "";

  if (cvu?.shortAlias !== undefined || cvu?.shortAlias === "") {
    ali = cvu?.shortAlias
  }
  let alias = ali;

  useEffect(() => {
    if (userData) {
      if ((cvu && cvu.cvu === undefined) || (cvu?.cvu === "")) {
        getCvu(userData._id, tokenData);
      }
    }
  }, [userData, getCvu]);

  const setUserDataAlias = (inputName) => {

    return e => {
      let value = e.target.value;
      if (e.target.type === 'checkbox') {
        value = e.target.checked;
      }
      setCvu({ ...cvu, [inputName]: value });

    }
  }
  const setUserData = inputName => {
    return e => {
      let value = e.target.value;
      if (e.target.type === 'checkbox') {
        value = e.target.checked;
      }
      setCvu({ ...cvu, [inputName]: value });

    }
  }
  const putAlias = () => {
    Swal.fire({
      title: 'Generación de Alias',
      showCancelButton: true,
      confirmButtonText: 'Generar alias',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: "#3A4D9F",
      cancelButtonColor: "#DB1507",
      width: '350px',
      customClass: {
        title: 'colorTextTitle',
        confirmButton: "btn rounded-pill",
        cancelButton: "btn rounded-pill",
        popup: "swal2-popup"
      },

      showLoaderOnConfirm: true,

      preConfirm: async () => {

        return await fetcher(`${API.BASE_URL}/bind/user/${userData._id}/alias`, {
          method: 'PUT',
          body: JSON.stringify({ alias }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: tokenData,
          },
        })
          .then((res) => {
            if (!res.ok) {
              if (res.status !== 200) {
                Swal.showValidationMessage(
                  `Fallo al generar alias: el campo de alias es obligatorio`
                )
              }
            } else {
              return res.json();
            }
          })
          .then(({ data }) => {
          })
          .catch((error) => {
            if (alias === "") {
              Swal.showValidationMessage(
                `Fallo al generar alias: El campo de alias es obligatorio`
              )
            } else {
              Swal.showValidationMessage(
                `Fallo al generar alias: El Alias de CVU no puede modificarse más de una vez, debe esperar 24 hs para volver a hacerlo`
              )
            }

          });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((response) => {
      if (response.isConfirmed) {
        Swal.fire({
          title: `Alias generado con éxito`,
          confirmButtonColor: "#3A4D9F",
          confirmButtonText: 'Aceptar',
          width: '400px',
          customClass: {
            title: 'colorTextTitle',
            confirmButton: "btn rounded-pill",
            popup: "swal2-popup"
          },
        })
      }
    })
  }
  let cvus = "";

  if (data?.cvu !== undefined) {
    cvus = data?.cvu;
  } else {
    cvus = cvu?.cvu
  }
  return (
    <>
      <div className="form-group">
        <label style={{ color: '#3A4D9F' }}>Cvu: </label>
        <input type="text" className="form-control" value={cvus} onChange={setUserData('cvu')} readOnly />
      </div>
      <div className="form-group">
        <label style={{ color: '#3A4D9F' }} htmlFor="alias">Alias: </label>
        <div className="d-inline-flex ml-2">
          <input type="text"
            name="alias"
            id="alias"
            required
            className="form-control"
            value={alias?.toUpperCase()}
            onChange={setUserDataAlias('shortAlias')}
          />
          <label className="p-2 m-0" style={{ color: '#3A4D9F' }}>.PALTA</label>
        </div>
        {cvus !== undefined ? (
          <button type="post" className="btn btn-lg btnColor text-white mt-3 mb-3  float-right" onClick={putAlias}>Asignar Alias</button>
        ) : null}
      </div>

    </>
  )


}