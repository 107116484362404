import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import { CreatePdfCashout } from '../create-pdf-cash/CreatePdfCashout';
import { useRecoilValue } from 'recoil';
import { token } from '../../store';
import axios from '../../core/Axios/Axios';
import * as API from '../../constants/api-staging';

export const SeeCashout = ({ cashoutSee, escapeRegExp }) => {
  const year = new Date().getFullYear();
  const tokenData = useRecoilValue(token);
  const [amountCommission, setAmountCommission] = useState(undefined);

  const getCommissions = async () => {
    const response = await axios.get(
      `${API.BASE_URL}/transaction/commissions/cashout/${cashoutSee._id}`,
      {
        headers: {
          Authorization: window.localStorage.getItem('token'),
        },
      },
    );
    const commissions = response.data?.data
    if (commissions.length) {
      const amountCommission = commissions.reduce(
        (prev, curr) => (prev = prev + curr.amount),
      0);
      setAmountCommission(amountCommission);
    } else setAmountCommission(0);
  };

  useEffect(() => {
    if (cashoutSee) {
      getCommissions();
    }
  }, [cashoutSee]);

  const generatePdfDocument = async (cashoutSee) => {
    const blob = await pdf(
      <CreatePdfCashout
        title="My PDF"
        commission={amountCommission}
        data={cashoutSee}
        escapeRegExp={escapeRegExp}
      />,
    ).toBlob();
    saveAs(blob, 'Retiro.pdf');
  };
  return (
    <>
      <div
        className="modal-item-detail mb-2"
        style={{ background: '#EDEDED', borderRadius: '30px' }}
      >
        <p className="mt-2 ml-2">Monto del retiro: </p>
        <p className="mt-2 mr-2">${cashoutSee.amount.toFixed(2)}</p>
      </div>
      <div
        className="modal-item-detail mb-2"
        style={{ background: '#EDEDED', borderRadius: '30px' }}
      >
        <p className="mt-2 ml-2">Fecha del retiro: </p>
        <p className="mt-2 mr-2">
          {format(new Date(cashoutSee.createdAt), 'yyyy') === year.toString()
            ? format(new Date(cashoutSee.createdAt), 'dd-MMMM-yyy, HH:mm', {
                awareOfUnicodeTokens: true,
                locale: es,
              })
            : format(new Date(cashoutSee.createdAt), 'dd-MMMM-yyy', {
                awareOfUnicodeTokens: true,
                locale: es,
              })}
        </p>
      </div>
      {cashoutSee._walletFromId.currency === 'fiscal_credit' ? (
        <div
          className="modal-item-detail mb-2"
          style={{ background: '#EDEDED', borderRadius: '30px' }}
        >
          <p className="mt-2 ml-2">Monedero: </p>
          <p className="mt-2 mr-2">Crédito Fiscal</p>
        </div>
      ) : cashoutSee._walletFromId.currency === 'peso' ? (
        <div
          className="modal-item-detail mb-2"
          style={{ background: '#EDEDED', borderRadius: '30px' }}
        >
          <p className="mt-2 ml-2">Monedero: </p>
          <p className="mt-2 mr-2">Pesos</p>
        </div>
      ) : cashoutSee._walletFromId.currency === 'peso_tourism' ? (
        <div
          className="modal-item-detail mb-2"
          style={{ background: '#EDEDED', borderRadius: '30px' }}
        >
          <p className="mt-2 ml-2">Monedero: </p>
          <p className="mt-2 mr-2">Crédito Turismo</p>
        </div>
      ) : cashoutSee._walletFromId.currency === '	peso_activa' ? (
        <div
          className="modal-item-detail mb-2"
          style={{ background: '#EDEDED', borderRadius: '30px' }}
        >
          <p className="mt-2 ml-2">Monedero: </p>
          <p className="mt-2 mr-2">Crédito Activa</p>
        </div>
      ) : null}

      <div
        className="modal-item-detail mb-2"
        style={{ background: '#EDEDED', borderRadius: '30px' }}
      >
        <p className="mt-2 ml-2">Estado del retiro: </p>
        {cashoutSee.status === 'pending' ? (
          <p className="mt-2 mr-2">
            {cashoutSee.status.replace('pending', 'Pendiente')}
          </p>
        ) : cashoutSee.status === 'approved' ? (
          <p className="mt-2 mr-2">
            {cashoutSee.status.replace('approved', 'Aprobado')}
          </p>
        ) : (
          <p className="mt-2 mr-2">
            {cashoutSee.status.replace('rejected', 'Rechazado')}
          </p>
        )}
      </div>

      {cashoutSee.counterparty && cashoutSee.counterparty.name && (
        <div
          className="modal-item-detail mb-2"
          style={{ background: '#EDEDED', borderRadius: '30px' }}
        >
          <p className="mt-2 ml-2">Destinatario: </p>
          <p className="mt-2 mr-2">
            {cashoutSee.counterparty.name.toLowerCase()}
          </p>
        </div>
      )}

      {amountCommission !== undefined ? (
        <div
          className="modal-item-detail mb-2"
          style={{ background: '#EDEDED', borderRadius: '30px' }}
        >
          <p className="mt-2 ml-2">Comisión + impuesto: </p>
          <p className="mt-2 mr-2">{amountCommission}</p>
        </div>
      ) : null}
      {cashoutSee.feePercent !== undefined ? (
        <div
          className="modal-item-detail mb-2"
          style={{ background: '#EDEDED', borderRadius: '30px' }}
        >
          <p className="mt-2 ml-2">Porcentaje de Tarifa: </p>
          <p className="mt-2 mr-2">{cashoutSee.feePercent}%</p>
        </div>
      ) : null}

      {cashoutSee.xnetData !== undefined ? (
        <>
          <hr color="black" size={1} width="100%" />
          <div className="form-row justify-content-center align-items-center font-weight-bold">
            <div className="text-black font-weight-bold h5">
              <label className="text-center text-black">Pago ATM - xnet</label>
            </div>
          </div>
          <div
            className="modal-item-detail mb-2"
            style={{ background: '#EDEDED', borderRadius: '30px' }}
          >
            <p className="mt-2 ml-2">Monto: </p>
            <p className="mt-2 mr-2">${cashoutSee.xnetData.details.amount}</p>
          </div>
          <div
            className="modal-item-detail mb-2"
            style={{ background: '#EDEDED', borderRadius: '30px' }}
          >
            <p className="mt-2 ml-2">Descripción: </p>
            <p className="mt-2 mr-2">
              {cashoutSee.xnetData.details.concept_description}
            </p>
          </div>
          <div
            className="modal-item-detail mb-2"
            style={{ background: '#EDEDED', borderRadius: '30px' }}
          >
            <p className="mt-2 ml-2">Id: </p>
            <p className="mt-2 mr-2">
              {cashoutSee.xnetData.details.concept_id}
            </p>
          </div>
          <div
            className="modal-item-detail mb-2"
            style={{ background: '#EDEDED', borderRadius: '30px' }}
          >
            <p className="mt-2 ml-2">Ext.Id: </p>
            <p className="mt-2 mr-2">
              {cashoutSee.xnetData.external_transaction_id}
            </p>
          </div>
          <div
            className="modal-item-detail mb-2"
            style={{ background: '#EDEDED', borderRadius: '30px' }}
          >
            <p className="mt-2 ml-2">Payer Email: </p>
            <p className="mt-2 mr-2">{cashoutSee.xnetData.payer.email}</p>
          </div>
          <div
            className="modal-item-detail mb-2"
            style={{ background: '#EDEDED', borderRadius: '30px' }}
          >
            <p className="mt-2 ml-2">Payer Número: </p>
            <p className="mt-2 mr-2">
              {cashoutSee.xnetData.payer.identification.number}
            </p>
          </div>
          <div
            className="modal-item-detail mb-2"
            style={{ background: '#EDEDED', borderRadius: '30px' }}
          >
            <p className="mt-2 ml-2">Payer Tipo: </p>
            <p className="mt-2 mr-2">
              {cashoutSee.xnetData.payer.identification.type}
            </p>
          </div>
          <div
            className="modal-item-detail mb-2"
            style={{ background: '#EDEDED', borderRadius: '30px' }}
          >
            <p className="mt-2 ml-2">Payer Nombre: </p>
            <p className="mt-2 mr-2">{cashoutSee.xnetData.payer.name}</p>
          </div>
        </>
      ) : null}
      {cashoutSee.type === 'cashout' &&
      cashoutSee.counterparty !== undefined &&
      cashoutSee.counterparty.account_routing?.scheme !== undefined &&
      cashoutSee.counterparty.account_routing?.address !== undefined ? (
        <div
          className="modal-item-detail mb-2"
          style={{ background: '#EDEDED', borderRadius: '30px' }}
        >
          <p className="mt-2 ml-2">CBU/CVU:</p>
          <p className="mt-2 mr-2">
            {cashoutSee.counterparty.account_routing?.address}
          </p>
        </div>
      ) : null}
      {cashoutSee.transactionCode !== undefined ? (
        <div
          className="modal-item-detail mb-2"
          style={{ background: '#EDEDED', borderRadius: '30px' }}
        >
          <p className="mt-2 ml-2">N° operación: </p>
          <p className="mt-2 mr-2">{cashoutSee.transactionCode}</p>
        </div>
      ) : null}
      <button
        className="btn float-right text-white rounded-pill mb-3 btnColor"
        onClick={() => generatePdfDocument(cashoutSee)}
      >
        <span className="icon-download ">
          <i className="material-icons">file_download</i>
        </span>
        Descargar PDF
      </button>
    </>
  );
};
