import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { postDollarAcceptsUpdate } from '../../core/api-hooks/dollar-utils/paltaDollars';

export const DollarModal = ({ setShowDollarModal, title }) => {

    const userData = JSON.parse(localStorage.getItem('user'));

    const handleBasesCondiciones = async (e) => {
        const userAccepts = e.target.getAttribute('accepts') === 'true';
        const response = await postDollarAcceptsUpdate(userAccepts);
        setShowDollarModal(false);
        localStorage.setItem('user', JSON.stringify({...userData, acceptsDollarWallets: userAccepts}));
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title className="text-center colorFont font-weight-bold"></Modal.Title>
            </Modal.Header>
            <Modal.Body className="pl-0 pr-0" style={{ textAlign: 'justify' }} >

                {
                    userData.acceptsDollarWallets === undefined ?
                        <>
                            <div style={{ color: 'black' }}>
                                <p>¿Te gustaría poder resguardar de la inflación tu saldo, incluido Crédito Activa y Crédito Turismo?</p>
                                <p>Ahora te ofrecemos esta posibilidad, vas a poder convertir tus saldos y seguir operando con normalidad.</p>
                                <p>Leer <a href='https://palta.app/terminos-y-condiciones.html' target="_blank">Términos y Condiciones</a></p>
                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around'
                            }}>
                                <button
                                    accepts={'true'}
                                    style={{ flexBasis: '3em' }}
                                    className='btn btn-primary'
                                    onClick={handleBasesCondiciones}
                                >
                                    Leí y aceptos las condiciones</button>
                                <button
                                    accepts={'false'}
                                    style={{ flexBasis: '3em' }}
                                    className='btn btn-danger'
                                    onClick={handleBasesCondiciones}
                                >
                                    No acepto las condiciones</button>
                            </div>
                        </> :

                        userData.acceptsDollarWallets ?
                            <>
                                <div style={{ color: 'black' }}>
                                    <h4>Tus fondos están dolarizados</h4>
                                    <p>De momento tus fondos convertidos a Dólar Palta están resguardados de la inflación.</p>
                                    <p>Si eliminás tus monederos Dólar Palta, los fondos serán convertidos automáticamente a su moneda de orígen, y acreditados en los mismos.</p>
                                    <p>Leer <a href='https://palta.app/terminos-y-condiciones.html' target="_blank">Términos y Condiciones</a></p>
                                </div>

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-around'
                                }}>
                                    <button
                                        accepts={'false'}
                                        style={{ flexBasis: '3em' }}
                                        className='btn btn-danger'
                                        onClick={handleBasesCondiciones}
                                    >
                                        Dejar de dolarizar</button>
                                </div>
                            </>
                            :
                            <>
                                <div style={{ color: 'black' }}>
                                    <h4>Tus fondos <strong>no</strong> están dolarizados</h4>
                                    <p>Si querés protegerlos contra la inflación y poder convertir tus saldos en Palta dólar, te invitamos a leer y aceptar los <a href='https://palta.app/terminos-y-condiciones.html' target="_blank">Términos y Condiciones</a>.</p>
                                    <p>Si hacés click en el botón a continuación, estas de acuerdo con los mismos.</p>
                                </div>

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-around'
                                }}>
                                    <button
                                        accepts={'true'}
                                        style={{ flexBasis: '3em' }}
                                        className='btn btn-primary'
                                        onClick={handleBasesCondiciones}
                                    >
                                        Leí y aceptos las condiciones</button>
                                </div>
                            </>

                }



            </Modal.Body>
        </>
    )


}