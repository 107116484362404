import React from 'react';
import './Shops.css';
import logoEnviarPalta from '../../assets/images/logo-enviar-palta.png';
import * as ROUTES from '../../constants/routes';
import { useHistory } from 'react-router-dom';

const Shops = ({ shops }) => {
  const history = useHistory();
  return shops.map((shop) => {
    return (
      <div
        key={shop._id}
        className="shops-container"
        style={{ position: 'relative' }}
      >
        <div className="shop-item-container">
          <i className="material-icons shop-item-icon">storefront</i>
        </div>
        <div className="shops-information transform-title">
          <h5 className="title">
            {shop.fantasyName
              ? shop.fantasyName
              : `${shop.name}  ${shop.lastname}`}
          </h5>
          {shop.address && (
            <p className="subtitle">Dirección: {shop.address}</p>
          )}
          {shop.globalAddress && shop.globalAddress.city && (
            <p className="subtitle">Localidad: {shop.globalAddress.city}</p>
          )}
          {shop.rubro && <p className="subtitle">Rubro: {shop.rubro}</p>}
        </div>
        <div
          className="icon-payments-container"
          onClick={() => {
            history.push(ROUTES.PAYMENTS_SELECT_WALLET, { shopId: shop._id });
          }}
        >
          <i className="material-icons">send</i>
          <span className='payment-text' >Enviá Dinero</span>
       
        </div>
      </div>
    );
  });
};

export default Shops;
