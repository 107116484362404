import { palette } from './palette';

export const colors = {
  black: palette.black,
  white: palette.white,
  primary: palette.violetGlow,
  secondary: palette.dazzlingBlue,
  text: palette.chimneySweep,
  background: palette.white,
  backgroundLight: palette.drWhite,
  backgroundFooter: palette.ghostWhite,
  danger: palette.redMull,
  link: palette.doverStraits,
  success: palette.vitalize,
  disabled: palette.galleryWhite,
};
