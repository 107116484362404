import React from "react";

export const ModalDesiredCurrencyButton = ({ currency, setDesiredInputCurrency, close }) => {
	return (
		<button
			className="btn p-3"
			style={{
				width: '8rem',
				backgroundColor: currency === 'USDP' ? '#85bb65' : '#3a4d9f',
				color: 'white',
			}}
			onClick={() => {
				setDesiredInputCurrency(currency);
				close();
			}}
		>
			{currency}
		</button>
	)
}