import React, { Component } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { CSVDownload } from 'react-csv';
import { toast } from 'react-toastify';
import fetchApi from '../../core/FetchApi';
import PaltaBreadcrumbs from '../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import * as API from '../../constants/api-staging';
import * as ROUTES from '../../constants/routes';

class index extends Component {
  state = {
    dateFrom: '',
    dateTo: '',
    csvData: null,
  };

  handleSubmit = (e, done) => {
    let context = this.context;
    e.preventDefault();
    fetchApi(
      'GET',
      context,
      `${API.TRANSACTIONS}?date_from=${this.state.dateFrom}&date_to=${this.state.dateTo}&per_page=1000`,
    )
      .then((data) =>
        data.map((t) => ({
          id: t.id,
          created_at: t.created_at,
          from_nickname: t.from_nickname,
          to_nickname: t.to_nickname,
          description: t.description,
          amount: t.amount,
          currency: t.currency,
        })),
      )
      .then((data) => {
        this.handleCsvData(data);
      })
      .catch((err) => {
        // if any error happen, use balance data storage in context
        toast.error(`Error al obtener las transacciones: ${err}`);
        console.error('catched Error: ', err);
      });
  };

  handleInputChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handleCsvData = (data) => {
    this.setState({
      csvData: data,
    });
  };

  render() {
    let headers = [
      { label: 'Id', key: 'id' },
      { label: 'Fecha', key: 'created_at' },
      { label: 'Emisor', key: 'from_nickname' },
      { label: 'Receptor', key: 'to_nickname' },
      { label: 'Descripcion', key: 'description' },
      { label: 'Monto', key: 'amount' },
      { label: 'Moneda', key: 'currency' },
    ];
    let { csvData, dateFrom, dateTo } = this.state;
    let isInvalid = !dateFrom || !dateTo;
    return (
      <div style={{ backgroundColor: '#fff' }}>
        <PaltaBreadcrumbs
          name="Exportar Transacciones"
          link={ROUTES.ACCOUNT}
          linkName="Mi Cuenta"
          variant="dark"
        />
        <section style={{ marginBottom: '4em' }}>
          <Container>
            <Row>
              <Col sm={12}>
                <h2 className="title" style={{ color: '#3628DD' }}>
                  Exportar Transacciones
                </h2>
              </Col>
            </Row>
          </Container>
          <Container>
            <div className="justify-content-md-center align-items-center">
              <Form style={{ maxWidth: '28rem', margin: '0 auto' }}>
                <Form.Group className="form-group-icon" controlId="dateFrom">
                  <Form.Text className="text-muted">Desde</Form.Text>
                  <i className="material-icons icon">event</i>
                  <Form.Control
                    type="date"
                    name="dateFrom"
                    placeholder="Desde"
                    value={dateFrom}
                    onChange={this.handleInputChange}
                  />
                </Form.Group>

                <Form.Group className="form-group-icon" controlId="dateTo">
                  <Form.Text className="text-muted">Hasta</Form.Text>
                  <i className="material-icons icon">event</i>
                  <Form.Control
                    type="date"
                    name="dateTo"
                    placeholder="Hasta"
                    value={dateTo}
                    onChange={this.handleInputChange}
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  disabled={isInvalid}
                  size="lg"
                  className="btn-block btn-fullwidthe"
                  type="submit"
                  onClick={this.handleSubmit}
                >
                  Descargar
                </Button>
                {csvData && (
                  <CSVDownload
                    data={csvData}
                    headers={headers}
                    filename={`exportCSV_from_${dateFrom}_to_${dateTo}.csv`}
                    target="_blank"
                  />
                )}
              </Form>
            </div>
          </Container>
        </section>
      </div>
    );
  }
}

export default index;
