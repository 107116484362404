import { useState, useCallback } from 'react';
import Swal from 'sweetalert2';
import * as API from '../../../constants/api-staging';
import { fetcher } from '../../Fetch/Fetch';

export const useGetAliasBind = () => {
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(false);
  const getAliasBind = useCallback((alias, token, setLoading, setShow) => {
    setLoading(true)
    let aliasMayus = alias.toUpperCase()
    fetcher(
      `${API.BASE_URL}/cashout/account/alias/${aliasMayus}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    )
      .then((res) => {

        if (!res.ok) {
          if (res.status === 401) {
            Swal.fire({
              icon: 'error',
              title: 'Sesion Expirada',
              padding: '2px',
              showConfirmButton: false,
              width: '300px',
              heightAuto: '200px',
              timer: 1500,
              customClass: {
                title: 'm-0 text-danger',
                header: 'p-0',
              },
            }).then(function () {
              window.location.href = '/';
            });
          }
        } else {
          return res.json();
        }
      })
      .then(({ data }) => {
        setData(data);
        setLoading(false)
        setShow(false)
      })
      .catch((error) => {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: `El Alias no es correcto`,
          padding: '2px',
          showConfirmButton: false,
          width: '300px',
          heightAuto: '200px',
          timer: 1500,
          customClass: {
            title: 'm-0 text-danger',
            header: 'p-0',
          },
        });
        setError(true);
      });
  }, []);

  return [
    {
      data,
      error,
    },
    getAliasBind,
  ];
};
