import axios, { Axios } from "axios";
import { BASE_URL } from "../../constants/api-staging";


export default function updateFBTokens(id, tokens) {


axios.patch("" , {},{})

  return axios.patch(`${BASE_URL}/user/${id}/editFBTokens`, tokens, {
    headers :{
      Authorization: window.localStorage.getItem("token")
    }
  }); 
};