import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import enviarDinero from '../../assets/images/logo-enviar.svg';
export default class index extends Component {
  render() {
    let {
      link,
      imgsrc,
      icon,
      title,
      subtitle,
      variant,
      className,
      classNameImage,
    } = this.props;
    return (
      <>
        <li
          className={`${className ? className : ''} ${variant === 'main' ? 'main-item' : 'item'
            }`}
          {...this.props}
          style={{
            borderBottom: "2px dashed rgb(116, 130, 187)"

          }}
        >
          <Link to={link ? link : '#'} >
            {imgsrc ? (
              <img
                className={`${classNameImage ? classNameImage : ''
                  } main-user-img`}
                src={imgsrc}
                alt="images"
                style={{
                  height: title === 'Dolarizar Fondos' && '25px'
                }}
              />
            ) : icon === "attach_money" ? (

              <div className="box-icon">
                <img
                  src={enviarDinero}
                  width="40"
                  height="40"

                  alt="Enviar dinero"
                />
              </div>
            ) :
              <div className="box-icon">
                <i className="material-icons" style={{ fontSize: 40 }}>{icon}</i>
              </div>
            }
            <div className={variant === 'main' ? 'main-box-info' : 'box-info'}       >
              <div className="text">
                <span className="title"><h5>{title}</h5></span>
                <span className="subtitle"><h5>{subtitle}</h5></span>
              </div>
              {title !== "Cerrar Sesión" ? (
                <div className="action">
                  <i className="material-icons">keyboard_arrow_right</i>
                </div>
              ) : null}
            </div>

          </Link>

        </li>

        
      </>
    );
  }
}
