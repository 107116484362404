import Swal from "sweetalert2";

export const fetcher = async (...args) => {
  try {
    const [resource, config] = args;
    const response = await fetch(resource, config);
    const data = await response.clone().json();
    if (data.tokenRefresh) {
      window.localStorage.setItem('token', data.tokenRefresh);
    }
    if (response.status === 401){
      Swal.fire({title:'Sesion Expirada', icon:'error', timer:4000})
      .then(() => window.location.href = '/')
    }
    return response;
  } catch (error) {
    return error;
  }
};
