import React, { Component } from 'react';
import './PaltaListDetailsItem.css';

export default class PaltaListDetailsItem extends Component {
  render() {
      let { title, subtitle, message, arrow } = this.props;
    return (
        <li className="item-quota">
            <div className="box-quota">
                <div className="text">
                    <span className="title">{ title }</span>
                    { subtitle ? 
                    <span className="quota-selection">{ subtitle }</span>
                    : <p>{ message }</p>
                    }
                </div>
                { arrow ? 
                <div className="action">
                    <i className="material-icons">keyboard_arrow_right</i>
                </div>
                : ''
                }
            </div>
        </li>
    )
  }
}
