import '@firebase/messaging';
import { useEffect, useState } from 'react';
import { isMessagingSupported, messaging } from '../core/Firebase';

export default function useFirebaseToken() {

    const [isLoading, setIsLoading] = useState(false);
    const [token, setToken] = useState(null);
    const [error, setError] = useState(undefined);

    useEffect(() => {

        setError(null);
        setIsLoading(true);

        if (typeof Notification !== 'undefined' && isMessagingSupported) {


            if (Notification.permission === 'granted') {



                messaging
                    .getToken({ vapidKey: process.env.REACT_APP_VAPIDKEY })
                    .then(token => {
                        setToken(token);
                    }).catch(error => {
                        console.log({ error });
                        setError(`Ocurrio un error al obtener el token: ${error.message}`);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })




            } else {
                setError("El usuario no permitio las notificaciones");
                setIsLoading(false);
            }


        } else {
            setIsLoading(false);
            setError(`Notification API or Firebase Messaging APIs not available!`);
        }



    }, []);



    return { token, error, isLoading };
}

