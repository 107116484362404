import axios from "axios";
import Swal from "sweetalert2";

/* axios.interceptors.request.use((request) =>{
  //haces algo antes de que se envie la request
   return request;
 })*/

axios.interceptors.response.use(
  response => {
    if(response.data?.tokenRefresh){
      window.localStorage.setItem('token', response.data.tokenRefresh);
    }
    return response;
  },
  error => {
    if (error.response.status === 401) {
      Swal.fire({title:'Sesion Expirada', icon:'error', timer:4000})
       .then(() => window.location.href = '/')
    }
    return error
  });

export default axios;