export const palette = {
  // color names are based on https://colornamer.netlify.app/
  black: '#000000',
  direWolf: '#272727',
  carbon: '#333333',
  knightsArmor: '#5C5C5C',
  whiteBlue: '#CDD4DB',
  galleryWhite: '#EBEBE4',
  ghostWhite: '#f9f9fe',
  drWhite: '#FAFAFA',
  white: '#ffffff',
  chimneySweep: '#283238',
  violetGlow: '#3A4D9F',
  dazzlingBlue: '#3a4d9f',
  doverStraits: '#3366BB',
  superRoseRed: '#C60D2B',
  amour: '#EE5350',
  redMull: '#FF8888',
  vitalize: '#28AC42',
};
