import React from 'react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

export const BoxLeft = ({ userData, transaction, Group, escapeRegExp }) => {
  const year = new Date().getFullYear();
  const stylePending = {
    color: "grey",
    padding: "4px",
  };
 
  const styleRejected = {
    color: "#dc3545",
    padding: "4px",
  }
  return (

    <div
      className="transaction-item-left"
      style={{
        display: 'flex',
      }}
    >
      <div className="shop-item-container">
        {transaction.type !== 'PAGO SERVICIO' && transaction.type !== 'REVERTIR TRANSACCION' ? (<>
        {transaction._userToId !== undefined ? (
          <>
            {transaction.type !== undefined &&
              transaction.type === 'promo' &&
              transaction._userToId?.role !==
              'admin' && (
                <img
                  src="./assets/images/etiqueta-de-precio.svg"
                  width={150}
                  height={35}
                  alt="promocion"
                />
              )}
            {userData._id ===
              transaction._userFromId?._id
              ? transaction._userToId?.role ===
              'admin' &&
              transaction.type ===
              'transaction' && (
                <i className="material-icons shop-item-icon">
                  manage_accounts
                </i>
              )
              : userData._id ===
                transaction._userToId?._id
                ? transaction._userFromId?.role ===
                'admin' &&
                transaction.type ===
                'transaction' && (
                  <i className="material-icons shop-item-icon">
                    manage_accounts
                  </i>
                )
                : null}

            {userData._id ===
              transaction._userToId?._id ? (
              transaction._userFromId?.role ===
                'user' &&
                transaction.type === 'tax' ? (
                <i className="material-icons shop-item-icon">
                  account_circle
                </i>
              ) : transaction._userFromId?.role === 'commerce' && transaction.type === 'tax' ? (
                <i className="material-icons shop-item-icon">
                  storefront
                </i>
              ) : null
            ) : userData._id ===
              transaction._userFromId?._id ? (
              transaction._userToId?.role ===
              'user' &&
              (transaction.type === 'tax' || transaction.type === 'sircupa') && (
                <i className="material-icons shop-item-icon">
                  receipt
                </i>
              )
            ) : null}

            {userData._id ===
              transaction._userFromId?._id ? (
              transaction._userToId?.role ===
                'user' &&
                transaction.type === 'transaction' ? (
                <i className="material-icons shop-item-icon">
                  account_circle
                </i>
              ) : (transaction._userToId?.role === 'commerce' || transaction._userToId?.role === 'branch' || transaction._userToId?.role === 'cash') && transaction.type === 'transaction' ? (
                <i className="material-icons shop-item-icon">
                  storefront
                </i>
              ) : null
            ) : userData._id ===
              transaction._userToId?._id ? (
              transaction._userFromId?.role ===
                'user' &&
                transaction.type === 'transaction' ? (
                <i className="material-icons shop-item-icon">
                  account_circle
                </i>
              ) : (transaction._userFromId?.role === 'commerce' || transaction._userFromId?.role === 'branch' || transaction._userFromId?.role === 'cash') &&
                transaction.type ===
                'transaction' ? (
                <i className="material-icons shop-item-icon">
                  storefront
                </i>
              ) : null
            ) : null}
            {(transaction._userToId?.role ===
              'user' ||
              transaction._userToId?.role ===
              'commerce') &&
              transaction.type === 'cashin' && (
                <img
                  src="./assets/images/Iconos color-03.png"
                  width={50}
                  height={50}
                  alt=""
                />
              )}
          </>
        ) : (
          <img
            src="./assets/images/Iconos color-04.png"
            width={50}
            height={50}
            alt=""
          />
        )}
        </>) : transaction.type === 'REVERTIR TRANSACCION' ? (<>
          <i class="material-icons shop-item-icon">
          currency_exchange
          </i>
          </>) :
          <i className="material-icons shop-item-icon">
            receipt
          </i>
        } 
      </div>
      <Group>
        {transaction.type !== 'cashout' && transaction.type !== 'PAGO SERVICIO'? (<>{transaction.type === 'transaction' ||
          transaction.type === 'promo' ||
          transaction.type === 'tax' ||
					transaction.type === 'sircupa' ||
          transaction.type === 'REVERTIR TRANSACCION' ? (
          userData._id !==
            transaction._userFromId._id ? (
            <>
              <p className="transaction-item-title">
                {transaction._userFromId?.role ===
                  'user'
                  ? `${transaction._userFromId?.name.toLowerCase()} ${transaction._userFromId?.lastname.toLowerCase()}`
                  : (transaction._userFromId?.role === 'commerce' || transaction._userFromId?.role === 'branch' || transaction._userFromId?.role === 'cash')
                    ? escapeRegExp(`${transaction._userFromId?.fantasyName}`)
                    : `${transaction._userFromId?.name.toLowerCase()} ${transaction._userFromId?.lastname.toLowerCase()}`}
                    {transaction.type === 'REVERTIR TRANSACCION' && <span className={stylePending}> (Reversion)</span>}
              </p>
            </>
          ) : (
            <>
              <p className="transaction-item-title">
                {transaction._userToId?.role ===
                  'user'
                  ? `${transaction._userToId?.name.toLowerCase()} ${transaction._userToId?.lastname.toLowerCase()}`
                  : transaction._userToId?.role === 'commerce' || transaction._userToId?.role === 'branch' || transaction._userToId?.role === 'cash'
                    ? escapeRegExp(`${transaction._userToId?.fantasyName}`).toLowerCase()
                    : transaction.type === "cashin" ? null :
                      `${transaction._userToId?.name.toLowerCase()} ${transaction._userToId?.lastname.toLowerCase()}`}
                       {transaction.type === 'REVERTIR TRANSACCION' && <span className={stylePending}> (Reversion)</span>}
              </p>
            </>
          )
        ) : transaction._userFromId &&
          transaction.type === 'transaction' &&
          transaction._userFromId.role === 'user' &&
          userData._id !==
          transaction._userFromId._id ? (
          <>
            <p className="transaction-item-title">
              {transaction._userToId?.role ===
                'user'
                ? `${transaction._userFromId?.name.toLowerCase()} ${transaction._userFromId?.lastname.toLowerCase()}`
                : transaction._userFromId?.role === 'commerce' && transaction._userFromId?.role === 'branch' && transaction._userFromId?.role === 'cash'
                  ? escapeRegExp(`${transaction._userFromId?.fantasyName}`).toLowerCase()
                  : transaction.type === "cashin" ? null :
                    `${transaction._userFromId?.name.toLowerCase()} ${transaction._userFromId?.lastname.toLowerCase()}`}
                     {transaction.type === 'REVERTIR TRANSACCION' && <span className={stylePending}> (Reversion)</span>}
            </p>
          </>
        ) : transaction._userFromId &&
          transaction.type === 'promo' ? (
          <>
            <p className="transaction-item-title">
              {transaction._userFromId?.role ===
                'user'
                ? `${transaction._userFromId?.name.toLowerCase()} ${transaction._userFromId?.lastname.toLowerCase()}`
                : transaction._userFromId?.role === 'commerce' || transaction._userFromId?.role === 'branch' || transaction._userFromId?.role === 'cash'
                  ? escapeRegExp(`${transaction._userFromId?.fantasyName}`).toLowerCase()
                  : `${transaction._userFromId?.name.toLowerCase()} ${transaction._userFromId?.lastname.toLowerCase()}`}
                   {transaction.type === 'REVERTIR TRANSACCION' && <span className={stylePending}> (Reversion)</span>}
            </p>
          </>
        ) : transaction._userToId !== undefined &&
          transaction._userToId?.role !==
          'commerce' ? (
          transaction._userToId?.name ===
            'Perc. Imp. Débitos y Créditos' ? (
            <>
              <p className="transaction-item-title">
                Perc. Imp. Débitos y<br />
                Créditos RN 253
              </p>
            </>
          ) : null
        ) : transaction._userToId?.role ===
          'commerce' ? (
          <>
            <p className="transaction-item-title">
              {escapeRegExp(`${transaction._userToId?.fantasyName}`).toLowerCase()}
              {transaction.type === 'REVERTIR TRANSACCION' && <span className={stylePending}> (Reversion)</span>}
            </p>
          </>
        ) : transaction._userFromId !== undefined &&
          transaction._userFromId?.role !==
          'commerce' && transaction.type !== "cashin" ? (
          <p className="transaction-item-title">
            {`${transaction._userFromId?.name.toLowerCase()} ${transaction._userFromId?.lastname.toLowerCase()}`}
            {transaction.type === 'REVERTIR TRANSACCION' && <span className={stylePending}> (Reversion)</span>}
          </p>
        ) : transaction._userFromId?.role ===
          'commerce' ? (
          <>
            <p className="transaction-item-title">
              {escapeRegExp(`${transaction._userFromId?.fantasyName}`).toLowerCase()}
              {transaction.type === 'REVERTIR TRANSACCION' && <span className={stylePending}> (Reversion)</span>}
            </p>
          </>
        ) : null}
        {transaction.type === "cashin" && transaction.counterparty?.name !== undefined ? (
          <p className="transaction-item-title">
            {`${transaction.counterparty?.name.toLowerCase()}`}
            {transaction.type === 'REVERTIR TRANSACCION' && <span className={stylePending}> (Reversion)</span>}
          </p>
        ) : transaction.type === "cashin" && transaction._userToId.role === "user" ? (
          <p className="transaction-item-title">
            {`${transaction._userToId?.name.toLowerCase()} ${transaction._userToId?.lastname.toLowerCase()}`}
            {transaction.type === 'REVERTIR TRANSACCION' && <span className={stylePending}> (Reversion)</span>}
          </p>
        ) : null
        }</>):(<>
          {transaction.type === 'cashout' && <p className="transaction-item-title">
            {transaction.counterparty?.name?.trim().toLowerCase()}
            {transaction.status === 'pending' && <span style={stylePending}>(Pendiente)</span>}
            {transaction.status === 'rejected' && <span style={styleRejected}>(Rechazado)</span>}
          </p>}
          {transaction.type === 'PAGO SERVICIO'&& 
          <p className="transaction-item-title">
            Pago Servicio
          </p>}
          
          
        </>)}
        {}

        <p className="transaction-item-date">
          {format(
            new Date(transaction.createdAt),
            'yyyy',
          ) === year.toString()
            ? format(
              new Date(transaction.createdAt),
              'dd/MM/yyy - HH:mm',
              {
                awareOfUnicodeTokens: true,
                locale: es,
              },
            )
            : format(
              new Date(transaction.createdAt),
              'dd/MM/yyy - HH:mm',
              {
                awareOfUnicodeTokens: true,
                locale: es,
              },
            )}
        </p>
      </Group>
    </div>
  )
}