import React from 'react';
import styled from 'styled-components';
import PaltaModalSpinner from '../PaltaModalSpinner';
import logo from '../../assets/images/palta-512.svg';

const WalletGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  width: 100%;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;
const SingleWallet = styled.div`
  background-color: ${props => props.bg};
  color: ${props => props.color};
  width: 90%;
  padding: .57rem;
  display: flex;
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;

  @media (max-width: 768px) {
    
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;

  @media ${({ theme }) => theme.breakPoint.mobileSmall} {
    align-items: flex-start;
    flex-direction: column;
    
  }
`;
const TextWrapper = styled.div`
  padding: ${({ theme }) => theme.spacers.s};
  border-radius: ${({ theme }) => theme.spacers.m};
  display: inline-block;
  width: 100%
`;
export const Wallets = ({
  setPage,
  userData,
  setLoading,
  loading,
  setWallet,
  wallet,
  setMaxPageNumberList,
  setMinPageNumberList,
  commerceView
}) => {
  const userWallets = userData?.wallets?.slice().sort((a, b) => {

    if (a.currencyLabel > b.currencyLabel) {
      return -1;
    }
    if (a.currencyLabel < b.currencyLabel) {
      return 1;
    }
    return 0;
  })
  const userPesoWallets = userData?.wallets?.filter(wal => !wal.currency.includes('dolar')).slice().sort((a, b) => {

    if (a.currencyLabel > b.currencyLabel) {
      return -1;
    }
    if (a.currencyLabel < b.currencyLabel) {
      return 1;
    }
    return 0;
  })
  const userDollarWallets = userData?.wallets?.filter(wal => wal.currency.includes('dolar')).slice().sort((a, b) => {

    if (a.currencyLabel > b.currencyLabel) {
      return -1;
    }
    if (a.currencyLabel < b.currencyLabel) {
      return 1;
    }
    return 0;
  })
  let useWalletsCommerceView = userData?.walletsCommerceView?.slice().sort((a, b) => {

    if (a.currencyLabel > b.currencyLabel) {
      return -1;
    }
    if (a.currencyLabel < b.currencyLabel) {
      return 1;
    }
    return 0;
  })
  let useWalletsCommerceViewer = commerceView?.walletsCommerceView?.slice().sort((a, b) => {

    if (a.currencyLabel > b.currencyLabel) {
      return -1;
    }
    if (a.currencyLabel < b.currencyLabel) {
      return 1;
    }
    return 0;
  })
  const mapWallets = mapUserWallets => {
    return mapUserWallets?.map((singleWallet, index) => {
      const isDollar = singleWallet.currency.includes('dolar')
      if (wallet === singleWallet.currency) {
        return (
          <SingleWallet
            key={index}
            bg={isDollar ? '#5a8a3d' : '#505baf'}
            color='white'
          >
            <strong>{singleWallet.currencyLabel}</strong>
          </SingleWallet>
        );
      } else {
        return (
          <SingleWallet
            key={index}
            bg={isDollar ? '#82b95f' : '#c0c4e2'}
            style={{textAlign: 'center'}}
            color={isDollar && 'white'}
            onClick={() => {
              setLoading(true);
              setMaxPageNumberList(5);
              setMinPageNumberList(0);
              setPage(1);
              setWallet(singleWallet.currency);
            }}
          >
            {singleWallet.currencyLabel}
          </SingleWallet>
        );
      }
    })
  }
  return (
    <ItemWrapper>
      <TextWrapper>
        {userData && userWallets?.length !== 0 && userData.role !== "viewer" &&
          <WalletGrid>
            {mapWallets(userPesoWallets)}
            {mapWallets(userDollarWallets)}
          </WalletGrid>

        }

        {commerceView !== undefined && userData && userData !== undefined && useWalletsCommerceView?.length !== 0 ? (
          useWalletsCommerceView?.length !== 0
            ? useWalletsCommerceViewer?.map((singleWallet, index) => {
              if (wallet === singleWallet.currency) {
                return (
                  <div className="row" key={index}>
                    {singleWallet._currencyId.currencyLabel === "Pesos" ? (
                      <div
                        key={singleWallet.currency}
                        className="wallet-item wallet-item-active"
                        style={{
                          marginRight: 40
                        }}
                      >
                        <p>{singleWallet._currencyId.currencyLabel}  ${singleWallet.amount.toFixed(2)}</p>
                      </div>
                    ) : (
                      <div className="wallet-item wallet-item-active">
                        <p>{singleWallet._currencyId.currencyLabel}  ${singleWallet.amount.toFixed(2)}</p>
                      </div>
                    )}
                  </div>
                );
              } else {
                return (
                  <div className="row" key={index}>
                    <div className="wallet-item"
                      key={singleWallet.currency}
                      onClick={() => {
                        setLoading(true);
                        setMaxPageNumberList(5);
                        setMinPageNumberList(0);
                        setPage(1);
                        setWallet(singleWallet.currency);
                      }}
                    >
                      {singleWallet._currencyId.currencyLabel}
                    </div>
                    <div className="col-2 p-0">
                      <button className="btn btn-outline mt-3"
                        onClick={() => {
                          setLoading(true);
                          setMaxPageNumberList(5);
                          setMinPageNumberList(0);
                          setPage(1);
                          setWallet(singleWallet.currency);

                        }}>
                        <i className="material-icons" style={{ fontSize: 50, color: "#C0C4E2" }}>visibility</i></button>
                    </div>
                  </div>
                );
              }
            }
            )
            : null

        ) : commerceView === undefined && userData?.role !== "user" && userData?.role !== "commerce" && userData?.role !== "cash" ? (
          <h4>Debe seleccionar un comercio a ver</h4>
        ) : userData?.role === "user" ? null : null}
      </TextWrapper>
      {loading && (
        <PaltaModalSpinner show={loading} size="sm">
          <h3 className="subtitle">
            <img
              src={logo}
              width="120"
              height="50"
              className="d-inline-block align-top img-rotate float-left"
              alt="Palta logo"
            />
            <p className="float-right mt--1 mb-0" style={{ fontSize: 30, marginRight: 40 }}> Cargando...</p>
          </h3>
        </PaltaModalSpinner>
      )}

    </ItemWrapper>

  )
}
