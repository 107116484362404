import { useCallback, useState } from 'react';
// import Axios from 'axios';
import * as API from '../../../constants/api-staging';
import axios from '../../Axios/Axios';

export const useGetAfinityGroups = () => {
  const [data, setData] = useState(undefined);
  const getAfinityGroups = useCallback((userData,tokenData, setLoading) => {
    axios.get(`${API.BASE_URL}/user/${userData._id}/affinity`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: tokenData
        },
      })
      .then(response => {
        setData(response.data.data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      });

  }, []);

  return [
    {
      data,
    },
    getAfinityGroups,
  ];
};
