import React from "react";
import { ModalDesiredCurrencyButton } from './ModalDesiredCurrencyButton';

export const ModalDesiredCurrencyButtonContainer = ({ setDesiredInputCurrency, close }) => {
	return (
		<div
			style={{ display: 'flex', justifyContent: 'space-around' }}
		>
			<ModalDesiredCurrencyButton
				currency={'USDP'}
				setDesiredInputCurrency={setDesiredInputCurrency}
				close={close}
			/>
			<ModalDesiredCurrencyButton
				currency={'Peso'}
				setDesiredInputCurrency={setDesiredInputCurrency}
				close={close}
			/>
		</div>
	)
}