import { useState, useCallback } from 'react';
import Axios from '../../Axios/Axios';
import * as API from '../../../constants/api-staging';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router';
import * as ROUTES from '@constants/routes';

export const useSendCodeSms = () => {
  const [data, setData] = useState(undefined);
  const history = useHistory();
  const postSendSms = useCallback((code) => {
    Axios.post(
      `${API.BASE_URL}/register/confirmationCode/${localStorage.getItem(
        'userID',
      )}`,
      {
        confirmationCode: code,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      },
    )
      .then((response) => {
        setData(response.data.data);
        history.push(ROUTES.REGISTER_SUCCESS);
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: `${error.response.data.message}`,
          padding: '2px',
          showConfirmButton: false,
          width: '300px',
          heightAuto: '200px',
          timer: 1500,
          customClass: {
            title: 'm-0 text-danger',
            header: 'p-0',
          },
        });
      });
  }, []);

  return [
    {
      data,
    },
    postSendSms,
  ];
};
