import styled from 'styled-components';

export const Title = styled.div`
  font-size: ${({ theme }) => theme.text.fontSize.xxxl};
  font-weight: ${({ theme }) => theme.text.fontWeight.medium};
  color: ${({ theme }) => theme.colors.secondary};
  font-family: 'ProductSansBold';
  ${({ textTransform }) =>
    textTransform ? `text-transform: ${textTransform};` : null};
    margin-bottom: 15px;
`;
