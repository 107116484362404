import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Container, Row } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import * as ROUTES from '../../constants/routes';
import PaltaListMenu from '../../components/PaltaListMenu/PaltaListMenu';
import PaltaListMenuItem from '../../components/PaltaListMenuItem/PaltaListMenuItem';
import './account.css';
import { ChangePassword } from '../../components/modal-password/ChangePassword';
import { commerceViewer } from '../../store/comercio-viewer';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import PaltaModalSpinner from '../../components/PaltaModalSpinner';
import { user, token } from '../../store';
import { useGetAfinityGroups } from '../../core/api-hooks/use-afinity-group/get';
import { DollarModal } from './DollarModal';
import { motion } from 'framer-motion';
import PaltaBreadcrumbs from '../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import styled from 'styled-components';
import { webAuthn } from '../../core/WebAuthn/WebAuthn';
const Header = styled.div`
  width: 100%;
  height: 160px;
  background-color: #304085;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ImgContainer = styled.div`
  position: relative;
  background-color: #ffffff;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #7884be;
  border-radius: 50%;
`;
const LittleImgContainer = styled.div`
  position: absolute;
  top: 60%;
  left: 65%;
  background-color: #ffffff;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 3px solid #7884be;
  border-radius: 50%;
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
`;
const Title = styled.span`
  color: #ffffff;
  font-size: 20px;
`;
const Affinity = styled.span`
  color: #7884be;
`;
const Img = styled.img`
  border-radius: 50%;
  object-fit: cover;
  width: ${(props) => (props.isPalta ? '80%' : '100%')};
  height: ${(props) => (props.isPalta ? '80%' : '100%')};
`;

const Account = () => {
  const [{ data: dataAfinity }, getAfinityGroups] = useGetAfinityGroups();
  const tokenData = useRecoilValue(token);
  const userData = useRecoilValue(user);
  const [afinitySelected, setAfinitySelected] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [webAuthnIsAvailable, setWebAuthnIsAvailable] = useState(false);
  useEffect(() =>{
    //PREGUNTAR SI ESTA DISPONIBLE LAS CREDENCIALES
    const isAvailable = webAuthn.isAvailable();
    setWebAuthnIsAvailable(isAvailable);
  },[])
  useEffect(() => {
    if (userData !== undefined && userData.role === 'user') {
      getAfinityGroups(userData, tokenData, setLoading);
    }else{
      setLoading(false);
    }
  }, [getAfinityGroups, tokenData]);
  useEffect(() => {
    if (dataAfinity?.length) {
      getAffinityUser(dataAfinity);
    }
  }, [dataAfinity]);
  const commerceView = useRecoilValue(commerceViewer);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleOpenModal = () => {
    setShow(true);
  };
  const [showDollarModal, setShowDollarModal] = useState(false);

  const getAffinityUser = (afinityGroups) => {
    const affinitySelected = afinityGroups.filter(
      (elem) => window.localStorage.getItem('AfinityID') && elem._id === JSON.parse(window.localStorage.getItem('AfinityID')),
    );
    affinitySelected?.length ? setAfinitySelected(affinitySelected[0]) : null;
  };
  const openDollarModal = () => {
    setShowDollarModal(true);
  };
  const hideDollarModal = () => {
    setShowDollarModal(false);
  };
  return (
    <div>
      <PaltaNavBar />
      <PaltaBreadcrumbs
        name="Mi cuenta"
        link={ROUTES.DASHBOARD}
        variant="dark"
      />
      <motion.div
        initial={{ y: '30%', scale: 0.4 }}
        animate={{ y: 0, scale: 1 }}
        transition={{ type: 'spring', duration: 0.4 }}
      >
        <section style={{ paddingBottom: '5em', color: '#3A4D9F' }}>
          <Header>
            <div className="d-flex m-3 align-items-center container">
              <ImgContainer>
                <Img
                  isPalta={afinitySelected ? false : true}
                  src={
                    afinitySelected
                      ? afinitySelected.logo[0].image
                      : 'assets/images/palta-512.svg'
                  }
                  alt=""
                  height={60}
                  width={60}
                />
                {afinitySelected ? (
                  <LittleImgContainer>
                    <img
                      src="assets/images/palta-512.svg"
                      alt=""
                      height={20}
                      width={20}
                    />
                  </LittleImgContainer>
                ) : null}
              </ImgContainer>
              <TextContainer>
                <Title>{userData.name + ' ' + userData.lastname}</Title>
                <Affinity>
                  {afinitySelected ? afinitySelected.name : null}
                </Affinity>
              </TextContainer>
            </div>
          </Header>
          {/* <div
                    className="col-6 col-sm-6 col-md-6 p-0 "
                    style={{ height: '100px' }}
                  >
                    <img
                      className="float-right img-fluid mr-3 pb-5"
                      src="assets/images/palta-512.svg"
                      alt=""
                      style={{ marginTop: '33px' }}
                      height={50}
                      width={50}
                    />
                  </div> */}
          <PaltaListMenu>
            <PaltaListMenuItem
              link={ROUTES.PROFILE}
              title="Mis Datos"
              icon="person"
            />
            {userData &&
            userData?.role !== 'branch' &&
            userData?.role !== 'cash' &&
            userData?.role !== 'viewer' ? (
              <PaltaListMenuItem
                link={ROUTES.CASH_OUT}
                title="Retirar Dinero"
                icon="attach_money"
              />
            ) : null}
            {userData?.role === 'commerce' ||
            userData?.role === 'user' ||
            userData?.role === 'cash' ||
            commerceView?.role === 'commerce' ||
            commerceView?.role === 'cash' ? (
              <PaltaListMenuItem
                link={ROUTES.QR_GENERATOR}
                title="Código QR"
                icon="qr_code_2"
              />
            ) : null}
            {userData.isMassiveQR && (
              <PaltaListMenuItem
                link={ROUTES.MASSIVE_QR}
                title="Alta QR Masivo"
                icon="qr_code_2"
              />
            )}
            {webAuthnIsAvailable &&
               <PaltaListMenuItem
                title="Datos biométricos"
                icon="fingerprint"
                onClick={() => webAuthn.registerCredential(userData?._id)}
              />
            }
            {userData?.role !== 'viewer' ? (
              <PaltaListMenuItem
                onClick={() =>
                  window.open('https://palta.app/terminos-y-condiciones.html')
                }
                title="Terminos y Condiciones"
                icon="insert_drive_file"
              />
            ) : null}
            <li
              className="item"
              style={{
                borderBottom: '2px dashed rgb(116, 130, 187)',
                cursor: 'pointer',
              }}
              onClick={() => {
                handleOpenModal();
              }}
            >
              <div className="box-icon pt-2">
                <i className="material-icons" style={{ fontSize: 40 }}>
                  vpn_key
                </i>
              </div>
              <div className="box-info">
                <div className="text">
                  <span className="title">
                    <h5>Cambio de Contraseña</h5>
                  </span>
                </div>
              </div>
            </li>
            {/* <PaltaListMenuItem
            onClick={() => {setShowDollarModal(true)}}
            title="Dolarizar Fondos"
            icon="local_atm_icon"
          /> */}
            <PaltaListMenuItem
              link={ROUTES.SIGNOUT}
              title="Cerrar Sesión"
              icon="exit_to_app"
            />
          </PaltaListMenu>
          <Container className="pt-4">
            <Row className="d-flex justify-content-center align-items-center">
              <img
                src={afinitySelected ? afinitySelected.logo[1].image : "assets/images/dibujo.svg"}
                className="img-fluid"
                alt="imagenFondo"
              />
            </Row>
          </Container>
        </section>
        {loading && (
              <PaltaModalSpinner show={loading} size="sm" />
            )}
      </motion.div>
      <PaltaFooter />
      <Modal show={show} centered>
        <ChangePassword />
      </Modal>
      <Modal show={showDollarModal} onHide={hideDollarModal} centered>
        <DollarModal
          title="Dolarizar Fondos"
          setShowDollarModal={setShowDollarModal}
        />
      </Modal>
    </div>
  );
};

export default Account;