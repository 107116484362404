import { useState, useCallback } from 'react';
import Axios from '../../Axios/Axios';
import * as API from '../../../constants/api-staging';
import Swal from 'sweetalert2';
import { useValidateEmail } from '../use-validate-email/get';

export const useSendFormEmailPhone = () => {
  const [getRiskCore] = useValidateEmail();
  const [data, setData] = useState(undefined);
  const postEmailPhone = useCallback((body, setLoading) => {
    setLoading(true)
    Axios.post(`${API.BASE_URL}/register`, {
      email: body.email,
      password: body.password,
      phone: body.phone,
      phoneCompany: body.phoneCompany
    }, {
      headers: {
        Authorization: window.localStorage.getItem('token')
      }
    })
      .then(response => {
        setLoading(true)
        setData(response.data.data);
        localStorage.setItem("userID", response.data.data._id);
        setTimeout(() => {  
          getRiskCore(setLoading);
        }, 30);
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: `${error.response.data.message}`,
          padding: "2px",
          showConfirmButton: false,
          width: "300px",
          heightAuto: "200px",
          timer: 1500,
          customClass: {
            title: "m-0 text-danger",
            header: "p-0"
          }
        })
        setLoading(false)
        if (error.response.data.message === "Sesión expirada") {
          Swal.fire({
            icon: 'error',
            title: `${error.response.data.message}`,
            padding: "2px",
            showConfirmButton: false,
            width: "300px",
            heightAuto: "200px",
            timer: 1500,
            customClass: {
              title: "m-0 text-danger",
              header: "p-0"
            }
          }).then(function () {
            window.location.href = "/";
          });
        }
      });

  }, []);

  return [
    {
      data,
    },
    postEmailPhone,
  ];
};
