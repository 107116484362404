import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Form, Row, Col, Spinner, Button } from 'react-bootstrap';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import { cashOutCbuCvu, token } from '../../../store';
import PaltaBreadcrumbs from '../../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import { Wrapper } from '../../../components/wrapper';
import * as ROUTES from '../../../constants/routes';
import { validarCBU } from "./validacionCbu"
import './cbu.css';
import { useGetCbuBind } from '../../../core/api-hooks/use-cbu-bind/get';
import { useGetAliasBind } from '../../../core/api-hooks/use-alias-bind/get';
import PaltaModalSpinner from '@components/PaltaModalSpinner';
export const CbuCvu = () => {
  const [loading, setLoading] = useState(false);
  const [activeRadio, setRadioActive] = useState(true);
  const [show, setShow] = useState(true);
  const tokenData = useRecoilValue(token);
  const [{ data }, getCbuBind] = useGetCbuBind();
  const [{ data: dataAlias }, getAliasBind] = useGetAliasBind();
  // const activeRadio = true;
  const [validTextError, setValidTextError] = useState();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
  });
  const alias = {
    required: 'El campo es requerido',
    pattern: 'Solo Admite letras (con excepción de la ñ), números,(punto) y/o (guion medio)',
    minLength: 'El campo debe tener mínimo 6 caracteres',
    maxLength: 'El campo debe tener máximo 20 caracteres',
  };
  const setCashOutCbuCvu = useSetRecoilState(cashOutCbuCvu);

  const onSubmit = useCallback(
    (data) => {
      setLoading(false);
      setCashOutCbuCvu(data);
      if (data.cbucvu) {
        valid(data.cbucvu);
      } else {
        validAliasBind(data.alias);
      }
    },
    [history],
  );

  const validAliasBind = (alias) => {
    getAliasBind(alias, tokenData, setLoading, setShow)
  }

  const valid = (nroCbu) => {
    if (nroCbu.length === 0) {
      setValidTextError("");
    } else if (nroCbu > 0 && nroCbu.length < 22) {
      setValidTextError("Resta ingresar " + (22 - nroCbu.length) + " dígitos")
    } else if (validarCBU(nroCbu)) {
      setValidTextError("CBU válido");
      setTimeout(function () {
        validCbuBind(nroCbu)
      }, 500);

    } else {
      setValidTextError("CBU Inválido");
    }
  }
  const validCbuBind = (nroCbu) => {

    getCbuBind(nroCbu, tokenData, setLoading, setShow)

  }
  const nextButton = () => {
    history.push({
      pathname: `${ROUTES.CASH_OUT_AMOUNT}`,
      state: { data: data !== undefined ? data : dataAlias }
    });
  }
  // eslint-disable-next-line no-extend-native
  String.prototype.capitalize = function () {
    return this.replace(/(^|\s)([a-z])/g, function (m, p1, p2) { return p1 + p2.toUpperCase(); });
  };
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <PaltaNavBar/>
      <PaltaBreadcrumbs
        name="Ingrese un CBU/CVU/ALIAS"
        link={ROUTES.CASH_OUT}
        variant="dark"
      />
      <section style={{ padding: '3rem 0px' }}>
        <Container>
          <Row>
            <Col>
              <h5>CBU/CVU/ALIAS</h5>
            </Col>
          </Row>
        </Container>

        <Container>
          {(show && data === undefined) || (show) ? (
            <Row>
              <Col md={{ span: 8, offset: 3 }}>
                <Wrapper>
                  <div className="form-check  ">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="option"
                      id="optionYes"
                      value={false}
                      checked={activeRadio}
                      disabled={activeRadio ? true : false}
                      onChange={() => setRadioActive(true)}
                    />
                    <label className="form-check-label" htmlFor="option">
                      Transferir a un CBU / CVU
                    </label>
                  </div>
                  <div className="form-check ">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="option"
                      id="optionNo"
                      value={false}
                      checked={!activeRadio}
                      disabled={!activeRadio ? true : false}
                      onChange={() => setRadioActive(false)}
                    />
                    <label className="form-check-label" htmlFor="option">
                      Transferir a un ALIAS
                    </label>
                  </div>
                </Wrapper>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {activeRadio === true && (
                    <>
                      <Form.Group className="form-group-login">
                        <input
                          id="cbucvu"
                          name="cbucvu"
                          type="number"
                          placeholder="CBU / CVU"
                          ref={register({
                            required: activeRadio ? true : false,
                            pattern: {
                              value: /^-?[0-9]\d*\.?\d*$/i,
                            },
                            minLength: 22,
                            maxLength: 22,
                            validate: () => setValidTextError("")
                          })}
                          className="form-control"
                          autoComplete="off"
                          style={{ display: activeRadio ? 'block' : 'none' }}
                        />

                        <>
                          {activeRadio === true && (
                            <>
                              {((errors.cbucvu && errors.cbucvu.type === 'required') || (validTextError === "CBU Inválido")) && (
                                <>
                                  <p className="input-error">El campo es requerido</p>
                                  <p className="input-error">{validTextError}</p>
                                </>
                              )}
                              {(errors.cbucvu && errors.cbucvu.type === 'pattern') && (
                                <p className="input-error">
                                  El campo debe tener solo números
                                </p>
                              )}
                              {errors.cbucvu && errors.cbucvu.type === 'minLength' && (
                                <p className="input-error">
                                  El campo debe tener mínimo 22 caracteres
                                </p>
                              )}
                              {errors.cbucvu && errors.cbucvu.type === 'maxLength' && (
                                <p className="input-error">
                                  El campo debe tener máximo 22 caracteres
                                </p>
                              )}
                              {validTextError === "CBU válido" ? (
                                <p className="input-success">{validTextError}</p>
                              ) : null}

                            </>
                          )}
                        </>
                      </Form.Group>
                      {(show && data === undefined) || (show) ? (
                        (errors.cbucvu) || (validTextError === "CBU Inválido") ? (
                          <Button
                            type="submit"
                            size="lg"
                            className=" active btn-block btn-fullwidthe bg-danger mt-3 "
                            disabled={false}
                          >
                            Validar
                          </Button>
                        ) : (!errors.cbucvu) || (validTextError === "CBU válido") ? (
                          <Button
                            type="submit"
                            size="lg"
                            className=" active btn-block btn-fullwidthe btnColor  mt-3"
                            disabled={false}
                          >
                            Validar
                          </Button>
                        ) : null

                      ) : null}
                    </>
                  )}
                  {activeRadio === false && (
                    <>
                      <Form.Group className="form-group-login">
                        <input
                          id="alias"
                          name="alias"
                          placeholder="ALIAS"
                          ref={register({
                            required: !activeRadio ? true : false,
                            pattern: {
                              value: (/^[A-Z0-9.-]{6,20}$/i),
                            },
                            minLength: 6,
                            maxLength: 20,
                          })}
                          className="form-control input-alias"
                          autoComplete="off"
                          style={{ display: !activeRadio ? 'block' : 'none' }}
                        />
                        <>
                          {errors.alias && (
                            <p className="input-error">{alias[errors.alias.type]}</p>
                          )}
                        </>

                      </Form.Group>
                      {(show && dataAlias === undefined) || (show) ? (
                        (errors.alias) ? (
                          <Button
                            type="submit"
                            size="lg"
                            className=" active btn-block btn-fullwidthe bg-danger  mt-3"
                            disabled={false}
                          >
                            Validar
                          </Button>
                        ) : (!errors.alias) ? (
                          <Button
                            type="submit"
                            size="lg"
                            className=" active btn-block btn-fullwidthe btnColor  mt-3"
                            disabled={false}
                          >
                            Validar
                          </Button>
                        ) : null
                      ) : null}
                    </>
                  )}
                </form>
              </Col>
            </Row>
          ) : null}
          {!show && data !== undefined && dataAlias === undefined ? (
            <Row className="d-flex justify-content-center align-items-center">
              <Col md={{ span: 8, offset: 3 }}>
                <h3>Datos destinatario</h3>
                <p>{`Nombre : ${data?.owners[0].display_name.capitalize()}`} </p>
                <p>
                  {`${data?.owners[0].id_type} : ${data?.owners[0].id}`}
                </p>
                <p>
                  {`CBU/CVU : ${data?.account_routing.address}`}
                </p>
                <p>
                  {`ALIAS : ${data?.label}`}
                </p>
                <p>
                  {`Entidad : ${data?.bank_routing.address}`}
                </p>


              </Col>

            </Row>
          ) : null}
          {!show && dataAlias !== undefined ? (
            <Row className="d-flex justify-content-center align-items-center">
              <Col md={{ span: 8, offset: 3 }}>
                <h3>Datos destinatario</h3>
                <p>{`Nombre : ${dataAlias?.owners[0].display_name.capitalize()}`} </p>
                <p>
                  {`${dataAlias?.owners[0].id_type} : ${dataAlias?.owners[0].id}`}
                </p>
                <p>
                  {`CBU/CVU : ${dataAlias?.account_routing.address}`}
                </p>
                <p>
                  {`ALIAS : ${dataAlias?.label}`}
                </p>
                <p>
                  {`Entidad : ${dataAlias?.bank_routing.address}`}
                </p>

              </Col>

            </Row>
          ) : null}

          <Row className="d-flex justify-content-center align-items-center">
            <Col>
              <Wrapper className="d-flex justify-content-center align-items-center">
                {!show && (data !== undefined || dataAlias !== undefined) ? (
                  <>
                    <button
                      type="submit"
                      className="btn  btn-lg button-cancel mr-2 "
                      onClick={() => {
                        setShow(true);
                      }}
                    >
                      Volver
                    </button>
                    <button
                      type="submit"
                      className=" btnColor btn btn-primary btn-lg"
                      onClick={() => {
                        nextButton();
                      }}
                    >
                      Continuar
                    </button>
                  </>
                ) : null}

              </Wrapper>
            </Col>
          </Row>
        </Container>
      </section>
      {loading && (
        <PaltaModalSpinner show={loading} size="sm"/>
      )}
      <PaltaFooter/>
    </div>
  );
};
