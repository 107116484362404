import React, { useState } from 'react';
import styled from 'styled-components';
import { BoxItem } from '../../components/box-item/box-item';
import * as ROUTES from '@constants/routes';
import imgExchange from '../../assets/images/exchange.png';
import { useHistory } from 'react-router';
import { user, selectedWallet, token } from '@store';
import { useSetRecoilState } from 'recoil';

const StandardWallets = styled.div`
  margin-top: 25px;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(4, 1fr);
  cursor: pointer;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const WalletContainer = styled.div`

  @media (max-width: 768px) {

  }
`;

const WalletChild = styled.div`
  flex-basis: 45%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {

  }
`;

const WalletColumn = styled.div`
  flex-basis: 43%;
  margin: 7px 0;
  align-items: center;

  @media (max-width: 768px) {

  }
`;

const ExchangeColumn = styled.div`
  flex-basis: 10%;
  max-width: 40px;

  @media (max-width: 768px) {

  }
`;

const ExchangeChild = styled.div`
  flex-basis: 15%;

  @media (max-width: 768px) {

  }
`;

const BackgroundImage = styled.div`
background: rgb(58,77,144);
background: -moz-linear-gradient(90deg, rgba(58,77,144,1) 0%, rgba(93,128,124,1) 48%, rgba(133,187,101,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(58,77,144,1) 0%, rgba(93,128,124,1) 48%, rgba(133,187,101,1) 100%);
background: linear-gradient(90deg, rgba(58,77,144,1) 0%, rgba(93,128,124,1) 48%, rgba(133,187,101,1) 100%);
filter: pGradientType=1;
border-radius: 12px;
cursor: pointer;


  @media (max-width: 768px) {

  }
`;

const ExchangeImage = styled.img`
  max-width: ${props => props.maxWidth};
  padding: 5px;
  border: 1px solid black;
  border-radius: 12px;
  filter: invert(100%) sepia(97%) saturate(0%) hue-rotate(358deg) brightness(103%) contrast(103%);
  @media (max-width: 768px) {

  }
`;

const Container = styled.div`
  width: ${props => props.width};

  @media (max-width: 768px) {

  }
`;

const MixWalletGrid = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr
`;

const UserWallets = ({ userWallets = [], userDolarWallets = [], isMobile, isViewer }) => {
    const setSelectedWallet = useSetRecoilState(selectedWallet);
    const history = useHistory();
    const handleExchangeButton = (currency) => {
        if (currency) {
            history.push({
                pathname: ROUTES.DOLLAR_CONVERSION,
                state: { currency }
            })
        }
    }

    return (
        <>
            {
                userDolarWallets.length === 0 ?
                    <>
                        <StandardWallets>
                            {
                                userWallets.map((wallet, index) => {
                                    return <BoxItem
                                        key={`${wallet.currency}-${index} `}
                                        isMobile={isMobile}
                                        text={wallet.currencyLabel}
                                        amount={`$ ${wallet.amount}`}
                                        link={ROUTES.RESUME}
                                        onHandleAction={() => setSelectedWallet(wallet.currency)}
                                    />
                                })
                            }
                        </StandardWallets>
                    </>
                    :
                    <>
                        <WalletContainer>
                            {
                                userWallets.map((wallet, i) => {
                                    return <div key={wallet.currencyLabel}>
                                        <WalletChild
                                        >
                                            <WalletColumn>
                                                <BoxItem
                                                    isMobile={isMobile}
                                                    text={isViewer ? wallet._currencyId.currencyLabel : wallet.currencyLabel}
                                                    amount={`$ ${wallet.amount}`}
                                                    link={ROUTES.RESUME}
                                                    onHandleAction={() => setSelectedWallet(wallet.currency)}
                                                />
                                            </WalletColumn>
                                            {
                                                userDolarWallets[i] ? (
                                                    <>
                                                        <ExchangeColumn
                                                        style={{
                                                            visibility: isViewer && 'hidden'
                                                        }}
                                                        >
                                                            <BackgroundImage
                                                                onClick={() => {
                                                                    const currency = userDolarWallets[i].currency;
                                                                    const currencyLabel = userDolarWallets[i].currencyLabel;
                                                                    handleExchangeButton({ currency, currencyLabel });

                                                                }}
                                                            >
                                                                <ExchangeImage
                                                                    src={imgExchange}
                                                                    maxWidth='100%'
                                                                />
                                                            </BackgroundImage>
                                                        </ExchangeColumn>
                                                        <WalletColumn>
                                                            <BoxItem
                                                                isMobile={isMobile}
                                                                dolar={true}
                                                                key={userDolarWallets[i].currencyLabel}
                                                                text={isViewer ? userDolarWallets[i]._currencyId.currencyLabel : userDolarWallets[i].currencyLabel}
                                                                amount={`$ ${userDolarWallets[i].amount}`}
                                                                link={ROUTES.RESUME}
                                                                onHandleAction={() => setSelectedWallet(userDolarWallets[i].currency)}
                                                            />
                                                        </WalletColumn>
                                                    </>
                                                ) :
                                                    <div style={{ visibility: 'hidden' }}>
                                                        <ExchangeColumn>

                                                        </ExchangeColumn>
                                                        <WalletColumn>
                                                            <BoxItem
                                                                isMobile={isMobile}
                                                                key={wallet.currencyLabel}
                                                                text={wallet.currencyLabel}
                                                                amount={`$ ${wallet.amount}`}
                                                                link={ROUTES.RESUME}
                                                                onHandleAction={() => setSelectedWallet(wallet.currency)}
                                                            />
                                                        </WalletColumn>
                                                    </div>
                                            }
                                        </WalletChild>
                                    </div>
                                })
                            }
                        </WalletContainer>
                    </>
            }
        </>
    )
}

export default UserWallets