import { useState, useCallback } from 'react';
import Axios from '../../Axios/Axios';
import * as API from '../../../constants/api-staging';
import { useHistory } from 'react-router';
export const useSendEmail = () => {
  const postSendEmail = useCallback((code) => {
    Axios.post(`${API.BASE_URL}/user/registervalidatemail `,
      {
        id: localStorage.getItem("userID"),
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
      })
      .catch(error => {
      });

  }, []);

  return [
    postSendEmail,
  ];
};
