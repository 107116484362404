import { useState, useCallback } from 'react';
import Axios from '../../Axios/Axios';
import * as API from '../../../constants/api-staging';
import Swal from 'sweetalert2';
import * as ROUTES from '@constants/routes';
import { useHistory } from 'react-router-dom';
export const useValidateEmail = () => {
  // const [data, setData] = useState(undefined);
  const history = useHistory();
  const getRiskCore = useCallback((setLoading) => {
    setLoading(true)
    Axios.get(`${API.BASE_URL}/register/mail/risk/${localStorage.getItem("userID")}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => {
        setLoading(true)
        // setData(response.data.data.riskScore);
        if (response.data.data.riskScore <= 95) {
          history.push(ROUTES.VALID_PHONE);
        }
        if (response.data.data.riskScore > 95) {
          Swal.fire({
            icon: 'error',
            title: "Su mail no ha podido ser validado,verifique los datos ingresados",
            padding: "2px",
            showConfirmButton: false,
            width: "300px",
            heightAuto: "200px",
            timer: 1500,
            customClass: {
              title: "m-0 text-danger",
              header: "p-0"
            }
          })
        }

      })
      .catch(error => {
        setLoading(false);
      });

  }, []);

  return [
    // {
    //   data,
    // },
    getRiskCore,
  ];
};
