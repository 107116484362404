import React from "react";

function capitalizar(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}

const GeoSelect = ({ defValue,geoloc,handleDateglobalAddress}) => {
  return (
      <select
        className="form-control"
        value={defValue.toLowerCase()}
        onChange={(e) => {
          const province = e.target.value.toLowerCase();
          handleDateglobalAddress(province)}}
      >
        {geoloc.map((geoElement) => {
          return <option key={geoElement} value={geoElement}>{capitalizar(geoElement)}</option>;
        })}
      </select>
  );
};

export default GeoSelect;