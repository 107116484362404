import React, { useEffect, useState } from 'react';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import PaltaBreadcrumbs from '../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import PaltaModalSpinner from '../../components/PaltaModalSpinner';
import * as ROUTES from '../../constants/routes';
import { Col, Container, Row } from 'react-bootstrap';
import { Wrapper } from '../../components/grid';
import './ServicePayments.css'
import { useHistory } from 'react-router';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { getBarra } from '../../core/api-hooks/captar-pagos/getBarra';
import { getBarcodeFromImage } from '../../core/api-hooks/captar-pagos/gvision';
import { fireSwal } from '../../components/utils/swalUtils';
import { getNumbers } from './utils'

const BarcodeScanner = () => {
    const [loading, setLoading] = useState(false);
    const [facturaInfo, setFacturaInfo] = useState(null);
    const [barcode, setBarcode] = useState('');
    const userData = JSON.parse(localStorage.getItem('user'));
    const history = useHistory();
    const [manualInput, setManualInput] = useState(false);

    const getCorrectNumber = async (array) => {
        try {
            const fact = await getBarra(array[0]);
            return {
                bar: array[0],
                factura: fact.data
            };
        } catch (error) {
            try {
                const fact = await getBarra(array[1]);
                return {
                    bar: array[1],
                    factura: fact.data
                };
            } catch (error) {
                try {
                    const fact = await getBarra(array[0] + array[1]);
                    return {
                        bar: array[0] + array[1],
                        factura: fact.data
                    };
                } catch (error) {
                    try {
                        const fact = await getBarra(array[1] + array[0]);
                        return {
                            bar: array[1] + array[0],
                            factura: fact.data
                        };
                    } catch (error) {
                        throw error;
                    }
                }
            }
        }
    }

    const handleScanner = async (photo64) => {
        const fileTypeIdIndex = 22
        const plain64Data = photo64.substring(fileTypeIdIndex);
        setLoading(true);
        try {
            const analizeImage = await getBarcodeFromImage({ factura: plain64Data });
            const numbersFactura = getNumbers(analizeImage.data[0]);
            const { bar, factura } = await getCorrectNumber(numbersFactura);
            setBarcode(bar);
            setLoading(false);
            setFacturaInfo(factura);
        } catch (error) {
            setLoading(false);
            fireSwal('no se encontró una factura válida');
            history.push(ROUTES.SERVICE_PAYMENTS_CHOOSE_MODE)
        }
    }

    const handleInputSwitch = () => {
        setManualInput(!manualInput);
        setBarcode('');
    }

    const handleAvanzar = async () => {
        try {
            setLoading(true);
            const barra = await getBarra(barcode);
            setFacturaInfo(barra.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            fireSwal('no se encontró una factura válida');
        }
        
    }

    const handleInput = (e) => {
        setBarcode(e.target.value);

    }
    useEffect(() => {
        if (facturaInfo) {
            if (facturaInfo?.code === 200) {
                const datosFactura = facturaInfo?.factura[0];
                if (datosFactura?.idTransaccion?.[0] && barcode) {
                    const postPayBody = {
                        factura: [{
                            tipo: "barra",
                            idTransaccion: datosFactura?.idTransaccion?.[0],
                            importeVariable: datosFactura?.importeVariable?.[0],
                            descripcion: datosFactura?.descripcion[0],
                            importe: datosFactura?.importe?.[0],
                            codeProducto: barcode,
                            idTrxComercio: userData.cuit,
                            tipoPago: "E",
                            idPago: userData.cuit
                        }],
                        currency: undefined
                    }
                    history.push({
                        pathname: ROUTES.SERVICE_PAYMENTS_WALLETS,
                        state: { datosTransaccion: postPayBody, businessName: datosFactura?.descripcion[0] }
                    })
                } else {
                    setFacturaInfo(null)
                    fireSwal('no se encontró una factura válida');
                }
            } else {
                setFacturaInfo(null);
            }
            if (facturaInfo?.code !== 200) {
                setFacturaInfo(null)
                fireSwal('no se encontró una factura válida');
            }
        }
    }, [facturaInfo]);

    return (
        <>
            <PaltaNavBar className="light" />
            <PaltaBreadcrumbs
                name="Inicio"
                link={ROUTES.SERVICE_PAYMENTS_CHOOSE_MODE}
                variant="dark"
            />
            <Container>
                <Wrapper>
                    {
                        manualInput ?
                            <>
                                <Row>
                                    <Col>
                                        <h4
                                            data-cy="heading"
                                            className='mt-3'
                                        >Ingresar código manualmente</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ display: 'flex', flexDirection: 'column', }}>
                                        <div
                                            className='camera-container'
                                        >
                                            <input
                                                style={{ border: '1px solid #5e9f72', width: '100%' }}
                                                className='form-control mb-3'
                                                type="text"
                                                inputMode="decimal"
                                                step="any"
                                                onChange={handleInput}
                                                value={barcode}
                                            ></input>
                                        </div>
                                        <div
                                            className='camera-container'
                                            style={{
                                                // width: '15em',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <button
                                                className='button-payment-input button-a'
                                                style={{
                                                    height: '4em',
                                                    width: '11em',
                                                    fontFamily: "'ProductSans', sans-serif",
                                                    fontWeight: 'bold',
                                                    fontSize: '0.85rem'
                                                }}
                                                onClick={handleAvanzar}
                                            >
                                                Continuar

                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div
                                            className="alert alert-secondary input-payment-message"
                                            role="alert">
                                            Ingresar el código numérico debajo de las barras, sin espacios.
                                        </div>
                                    </Col>
                                </Row>
                            </>
                            :
                            <>
                                <Row>
                                    <Col>
                                        <h4
                                            data-cy="heading"
                                            className='mt-3'
                                        >Escanear código de barras</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div
                                            className='camera-container'
                                        >
                                            <Camera
                                                onTakePhoto={handleScanner}
                                                isImageMirror={false}
                                                idealFacingMode='environment'
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div
                                            className="alert alert-secondary input-payment-message"
                                            role="alert">
                                            Fijar el código de barras y su número inferior lo más cerca y nítido posible en la imágen.
                                            Luego presionar el botón.
                                        </div>
                                    </Col>
                                </Row>
                            </>
                    }
                    <Row>
                        <Col>
                            <button
                                className='button-payment-input button-a'
                                style={{
                                    height: '5em',
                                    marginBottom: '5em',
                                    fontFamily: "'ProductSans', sans-serif",
                                    fontWeight: 'bold',
                                    fontSize: '0.85rem'
                                }}
                                onClick={handleInputSwitch}
                            >
                                <div>{manualInput ? 'Escanear Código' : 'Ingresar manualmente'}</div>
                            </button>
                        </Col>
                    </Row>
                </Wrapper>
            </Container>
            <PaltaFooter />
            <PaltaModalSpinner show={loading} size="sm" />
        </>
    )
};

export default BarcodeScanner;
