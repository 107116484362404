import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import QRCode from 'qrcode.react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { user } from '../../store';
import PaltaBreadcrumbs from '../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import * as ROUTES from '../../constants/routes';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import { DownloadPdfQr } from './DownloadQR';
import { commerceViewer } from '../../store/comercio-viewer';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
const QrWrapper = styled.div`
  padding: 24px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const QrGenerator = () => {
  const commerceView = useRecoilValue(commerceViewer);
  const userData = useRecoilValue(user);
  function escapeRegExp(string) {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(`<!doctype html><body>${string}`, 'text/html').body.textContent;
    return decodedString
  }
  const generatePdfDocument = async (data) => {
    const blob = await pdf((
      <DownloadPdfQr
        title='My PDF'
        data={data}
        escapeRegExp={escapeRegExp}
      />
    )).toBlob();
    saveAs(blob, `${escapeRegExp(`${data.fantasyName}`)}.pdf`);
  };
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <PaltaNavBar/>
      <PaltaBreadcrumbs name="Mi Cuenta" link={ROUTES.ACCOUNT} variant="dark"/>
      <section style={{ padding: '3rem 0px' }}>
        <Container>
          {commerceView !== undefined ? (
            <Row className="justify-content-center align-items-center d-flex">
              <Col>
                <h5 className="text-center" style={{ color: "#3A4D9F" }}>{`Codigo QR de ${commerceView?.fantasyName}`}</h5>
              </Col>
            </Row>
          ) : null}
          {(userData?.role === "user" || userData?.role === "commerce" || userData?.role === "cash") && (
            <Row className="justify-content-center align-items-center d-flex">
              <Col>
                <h5 className="text-center" style={{ color: "#3A4D9F" }}>Mi QR</h5>
              </Col>
            </Row>
          )}
          {isMobile ? (
            <>
              <Row>
                <Col>
                  {userData && userData?.role !== "viewer" ? (
                    (userData?.role === "commerce" || userData?.role === "cash") ? (

                      <QrWrapper>
                        <QRCode
                          id="qr-gen"
                          title={escapeRegExp(`${userData.fantasyName}`)}
                          value={`https://palta.app/api/qr-reader?name=${escapeRegExp(`${userData.fantasyName}`)}&id=${userData?._id}`}
                          size={180}
                          fgColor="#3A4D9F"
                        />
                      </QrWrapper>
                    ) : userData.role === "user" ? (
                      <QrWrapper>
                        <QRCode
                          title={userData?.name}
                          value={`https://palta.app/api/qr-reader?name=${userData?.name}&id=${userData?._id}`}
                          size={180}
                          fgColor="#3A4D9F"
                        />
                      </QrWrapper>
                    ) : null
                  ) : null}
                  {(commerceView && commerceView?.role === "commerce") && (userData?.role === "viewer") ? (
                    <QrWrapper>
                      <QRCode
                        id="qr-gen"
                        title={commerceView?.fantasyName}
                        value={`https://palta.app/api/qr-reader?name=${commerceView?.fantasyName}&id=${commerceView?._id}`}
                        size={180}
                        fgColor="#3A4D9F"
                      />
                    </QrWrapper>
                  ) : commerceView === undefined && userData?.role !== "user" && userData?.role !== "commerce" && userData?.role !== "cash" ? (
                    <h4>Debe seleccionar un comercio a ver</h4>
                  ) : userData?.role === "user" ? null : null}

                </Col>
              </Row>
              {(userData?.role === "commerce" || userData?.role === "cash") ? (
                <div className="d-flex justify-content-center align-items-center">
                  <button className="btn float-right text-white rounded-pill mb-3 btnColor" onClick={() => generatePdfDocument(userData)}>
                    <span className="icon-download ">
                      <i className="material-icons">file_download</i>
                    </span>
                    Descargar PDF
                  </button>
                </div>
              ) : userData?.role === "viewer" && commerceView !== undefined ? (
                <div className="d-flex justify-content-center align-items-center">
                  <button className="btn float-right text-white rounded-pill mb-3 btnColor" onClick={() => generatePdfDocument(commerceView)}>
                    <span className="icon-download ">
                      <i className="material-icons">file_download</i>
                    </span>
                    Descargar PDF
                  </button>
                </div>
              ) : null}

            </>
          ) : (
            <>
              <Row>
                <Col>
                  {userData && userData?.role !== "viewer" ? (
                    (userData?.role === "commerce" || userData?.role === "cash") ? (

                      <QrWrapper>
                        <QRCode
                          id="qr-gen"
                          title={escapeRegExp(`${userData?.fantasyName}`)}
                          value={`https://palta.app/api/qr-reader?name=${escapeRegExp(`${userData?.fantasyName}`)}&id=${userData?._id}`}
                          size={180}
                          fgColor="#3A4D9F"
                        />
                      </QrWrapper>
                    ) : userData?.role === "user" ? (
                      <QrWrapper>
                        <QRCode
                          title={userData?.name}
                          value={`https://palta.app/api/qr-reader?name=${userData?.name}&id=${userData?._id}`}
                          size={180}
                          fgColor="#3A4D9F"
                        />
                      </QrWrapper>
                    ) : null
                  ) : null}
                  {commerceView && (commerceView?.role === "commerce" || commerceView?.role === "cash") && (userData?.role === "viewer") ? (
                    <QrWrapper>
                      <QRCode
                        id="qr-gen"
                        title={commerceView?.fantasyName}
                        value={`https://palta.app/api/qr-reader?name=${commerceView?.fantasyName}&id=${commerceView?._id}`}
                        size={180}
                        fgColor="#3A4D9F"
                      />
                    </QrWrapper>
                  ) : commerceView === undefined && userData?.role !== "user" && userData?.role !== "commerce" && userData?.role !== "cash" ? (
                    <h4>Debe seleccionar un comercio a ver</h4>
                  ) : userData?.role === "user" ? null : null}

                </Col>
              </Row>
              {(userData?.role === "commerce" || userData?.role === "cash") ? (
                <div className="d-flex justify-content-center align-items-center">
                  <button className="btn float-right text-white rounded-pill mb-3 btnColor" onClick={() => generatePdfDocument(userData)}>
                    <span className="icon-download ">
                      <i className="material-icons">file_download</i>
                    </span>
                    Descargar PDF
                  </button>
                </div>
              ) : userData?.role === "viewer" && commerceView !== undefined ? (
                <div className="d-flex justify-content-center align-items-center">
                  <button className="btn float-right text-white rounded-pill mb-3 btnColor" onClick={() => generatePdfDocument(commerceView)}>
                    <span className="icon-download ">
                      <i className="material-icons">file_download</i>
                    </span>
                    Descargar PDF
                  </button>
                </div>
              ) : null}

            </>
          )}
        </Container>
      </section>
      <PaltaFooter/>
    </div>
  );
};

export default QrGenerator;
