import React from 'react';
import { ModalDesiredCurrencyPickerContainer } from './ModalDesiredCurrencyPickerContainer';
import { motion, AnimatePresence } from 'framer-motion';
import FramerModal from '../framer/FramerModal';

export const ModalDesiredCurrency = ({ modalOpen, setDesiredInputCurrency, close }) => {
	return (
		<AnimatePresence
			// Disable any initial animations on children that
			// are present when the component is first rendered
			initial={false}
			// Only render one component at a time.
			// The exiting component will finish its exit
			// animation before entering component is rendered
			exitBeforeEnter={true}
			// Fires when all exiting nodes have completed animating out
			onExitComplete={() => null}
		>
			{modalOpen && (
				<FramerModal key="modal">
					<motion.div
						variants={{
							hidden: {
								y: '-100vh',
								opacity: 0,
							},
							visible: {
								y: '0',
								opacity: 1,
								transition: {
									duration: 0.1,
									type: 'spring',
									damping: 35,
									stiffness: 600,
								},
							},
							exit: {
								y: '100vh',
								opacity: 0,
							},
						}}
						initial="hidden"
						animate="visible"
						exit="exit"
						onClick={(e) => e.stopPropagation()}
					>
						<ModalDesiredCurrencyPickerContainer close={close} setDesiredInputCurrency={setDesiredInputCurrency}/>
					</motion.div>
				</FramerModal>
			)}
		</AnimatePresence>
	)
}