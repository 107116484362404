import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

class PaltaModalCvu extends Component {
  render() {
    let { title, children, footer } = this.props;
    return (
      <Modal {...this.props} aria-labelledby="contained-modal-title-vcenter" centered >
        {title &&
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#3A4D9F', fontWeight: "bold" }}>
              {title}
            </Modal.Title>
          </Modal.Header>
        }
        {children &&
          <Modal.Body>
            {children}
          </Modal.Body>
        }
        {footer &&
          <Modal.Footer>
            {footer}
          </Modal.Footer>
        }

      </Modal>
    );
  }
}

export default PaltaModalCvu;