import { useState, useCallback } from 'react';
import Swal from 'sweetalert2';
import { format } from 'date-fns';

import * as API from '../../../constants/api-staging';
import { fetcher } from '../../Fetch/Fetch';

export const useGetWallet = () => {
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(false);
  const [indexPage, setIndexPage] = useState([]);

  const getWallet = useCallback((wallet, user, token, filter, limit, skip) => {
    let walletId = "";
    if (user?.wallets !== undefined) {
      walletId = user?.wallets?.find(
        (walletSearch) => walletSearch.currency === wallet,
      )._id;

    } else {
      walletId = user?.walletsCommerceView.find(
        (walletSearch) => walletSearch.currency === wallet,
      )._id;
    }
    let from = format(filter.from, 'dd/MM/yyyy');
    let to = format(filter.to, 'dd/MM/yyyy');
    let params =
      '&skip=' + skip + '&limit=' + limit + '&from=' + from + '&to=' + to;
    let _id = user?.role !== "viewer" ? user?._id : user?.commerceAssociateViewer?._id;
    fetcher(
      `${API.BASE_URL}/user/${_id}/activities?_walletId=${walletId}` +
      params,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    )
      .then((res) => {

        if (!res.ok) {
          if (res.status === 401) {
            Swal.fire({
              icon: 'error',
              title: 'Sesion Expirada',
              padding: '2px',
              showConfirmButton: false,
              width: '300px',
              heightAuto: '200px',
              timer: 1500,
              customClass: {
                title: 'm-0 text-danger',
                header: 'p-0',
              },
            }).then(function () {
              window.location.href = '/';
            });
          }
        } else {
          return res.json();
        }
      })
      .then(({ data }) => {
        setData(data);
      })
      .catch((error) => {
        if (from > to) {
          Swal.fire({
            icon: 'error',
            title: 'La fecha de inicio es mayor que la de finalización',
            padding: '2px',
            showConfirmButton: false,
            width: '300px',
            heightAuto: '200px',
            timer: 1500,
            customClass: {
              title: 'm-0 text-danger',
              header: 'p-0',
            },
          });
        }
        setError(true);
      });
  }, []);

  return [
    {
      data,
      error,
      indexPage,
      setIndexPage,
    },
    getWallet,
  ];
};


export const useGetWalletFilter = () => {
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(false);

  const getWalletFilter = useCallback((wallet, user, token, filter) => {
    let walletId = "";
    if (user?.wallets !== undefined) {
      walletId = user?.wallets?.find(
        (walletSearch) => walletSearch.currency === wallet,
      )._id;

    } else {
      walletId = user?.walletsCommerceView.find(
        (walletSearch) => walletSearch.currency === wallet,
      )._id;
    }
    let from = format(filter.from, 'dd/MM/yyyy');
    let to = format(filter.to, 'dd/MM/yyyy');
    let params = '&from=' + from + '&to=' + to;
    let _id = user?.role !== "viewer" ? user?._id : user?.commerceAssociateViewer?._id;
    fetcher(
      `${API.BASE_URL}/user/${_id}/activities?_walletId=${walletId}` + params,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    )
      .then((res) => {
        if (!res.ok) {
          if (res.status === 401) {
            Swal.fire({
              icon: 'error',
              title: 'Sesion Expirada',
              padding: '2px',
              showConfirmButton: false,
              width: '300px',
              heightAuto: '200px',
              timer: 1500,
              customClass: {
                title: 'm-0 text-danger',
                header: 'p-0',
              },
            }).then(function () {
              window.location.href = '/';
            });
          }
        } else {
          return res.json();
        }
      })
      .then(({ data }) => {
        setData(data);
      })
      .catch((error) => {
        setError(true);
      });
  }, []);

  return [
    {
      data,
      error,
    },
    getWalletFilter,
  ];
};