import React from "react";
import { Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { Fragment } from "react";
export const CreatePdf = ({ data, commission, escapeRegExp, userData, lote, ticket, cupon }) => {
  const {
    name,
    lastname,
    role,
    fantasyName, 
     } = data._userFromId;
   const { payService } = data;  

  const {
    name: nameReceptor,
    lastname: lastnameReceptor,
    role: roleReceptor,
    fantasyName: fantasyNameReceptor } = data._userToId;
  const renderSwitchTransmitter = (role) => {
    switch (role) {
      case 'user':
      case 'admin':
        return <Text style={styles.text} >Cuenta Origen : {name}  {lastname}</Text>;
      default:
        return <Text style={styles.text} >Cuenta Origen : {escapeRegExp(`${fantasyName}`)}</Text>;
    }
  }
  const renderSwitchReceiver = (roleReceptor) => {
    switch (roleReceptor) {
      case 'commerce':
      case 'cash':
      case 'branch':
        if(data.type === 'PAGO SERVICIO'){
          return <Text style={styles.text} >Destinatario : {payService.description}</Text>;
        }
        return <Text style={styles.text} >Cuenta Destino : {escapeRegExp(`${fantasyNameReceptor}`)}</Text>;
      default:
        if(data.type === 'PAGO SERVICIO'){
          return <Text style={styles.text} >Destinatario : {payService.description}</Text>;
        }
        return <Text style={styles.text} >Cuenta Destino : {nameReceptor}  {lastnameReceptor}</Text>;
        

    }
  }
  const year = new Date().getFullYear();
  return (
    <Document>
      <Page style={styles.body}>
        <Image style={[styles.img, styles.logoline]} src="./assets/images/detalle_t.png" />
        <View>
          <Text style={[styles.text, styles.sep]} >Fecha de la Transacción :
            {format(new Date(data.createdAt), 'yyyy') ===
              year
              ? format(
                new Date(data.createdAt),
                ' dd-MMMM-yyy, HH:mm',
                { awareOfUnicodeTokens: true, locale: es },
              )
              : format(
                new Date(data.createdAt),
                ' dd-MMMM-yyy, HH:mm',
                { awareOfUnicodeTokens: true, locale: es },
              )}
          </Text>
          {renderSwitchTransmitter(role)}
          {renderSwitchReceiver(roleReceptor)}
          <Text style={styles.text} >Monto de la Transacción :  ${data.amount.toFixed(2)}</Text>
          
          <Text style={styles.text} >Monedero :  {data.currencyLabel}</Text>
          {userData._id !== data._userFromId._id && commission !== undefined ? (
            commission.commission === true ? (
              <Fragment>
                <Text style={styles.text}>Comisión {commission.commissionPercentage} %  :    $ {commission.amount.toFixed(2)} </Text>
              </Fragment>
            ) : null
          ) : null}
          {data.transactionCode !== undefined ? (
            <Text style={styles.text} >N° operación :{data.transactionCode}</Text>
          ) : null}
          {data.type === 'PAGO SERVICIO' && <>
          <Text style={styles.text} >Lote :  {lote}</Text>
          <Text style={styles.text} >Ticket :  {ticket}</Text>
          <Text style={styles.text} >Cupon :  {cupon}</Text>
          </>}
          {data._transactionOriginId?.transactionCode !== undefined ? (
            <Text style={styles.text} >N° Transacción de origen :{data._transactionOriginId?.transactionCode}</Text>
          ) : null}
        </View>

      </Page>
    </Document>
  )
}
const styles = StyleSheet.create({
  logo: {
    width: 70,
    height: 70,
  },
  logoline: {
    width: "105%",
    height: 140,
  },
  body: {
    paddingBottom: 65,
    paddingHorizontal: 35,
    padding: 15,

  },
  contentTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    aligndatas: 'center',
    paddingBottom: 10
  },
  title: {
    fontSize: 35,
    color: "#212121",
  },
  text: {
    marginTop: 30,
    width: "100%",
    backgroundColor: "#f0f0f0",
    paddingHorizontal: 20,
    paddingVertical: 10,
    color: "#212121",

  },
  texta: {
    marginTop: 15,
    width: "100%",
    paddingHorizontal: 20,
    paddingVertical: 10,
    color: "green",
    textAlign: 'center',
    fontSize: 20,
    fontWeight: "bold"
  },
  textb: {
    marginTop: 30,
    width: "100%",
    paddingHorizontal: 20,
    paddingVertical: 10,
    color: "green",
    textAlign: 'center',
    fontSize: 35,
    fontWeight: "bold"
  },
  hr: {
    border: "1px solid green",
    height: 2,
    width: "100%",
    textAlign: 'center',
    marginTop: 15,
  },
  img: {

    position: "absolute",
    top: 0,
    left: 0,
  },
  sep: {
    marginTop: 140,
  }

})