import React, { useState, useEffect, useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { user, selectedWallet, token } from '../../store';
import { useGetWallet } from '../../core/api-hooks/use-wallet/get';
import './resume.css';
import { SeeCashout } from '../../components/SeeCashout/SeeCashout';
import ModalCash from '../../components/ModalCash/ModalCash';
import ModalCashin from '../../components/ModalCashin/ModalCashin';
import { SeeCashin } from '../../components/SeeCashin/SeeCashin';
import { SeeTransaction } from '../../components/SeeTransaction/SeeTransaction';
import { Wallets } from '../../components/Wallets/Wallets';
import ModalTransaccion from '../../components/ModalTransaccion/ModalTransaccion';
import ModalTax from '../../components/ModalTax/ModalTax';
import { SeeTax } from '../../components/SeeTax/SeeTax';
import { isMobile } from 'react-device-detect';
import 'react-datepicker/dist/react-datepicker.css';
import { Container, Wrapper, Grid, Col } from '@components/grid';
import { Title } from '@components/typography';
import { useGetCommission } from '../../core/api-hooks/use-commission/get';
import { SearchActivities } from './search-activities/SearchActivities';
import { PaginationActivities } from './pagination-activities/PaginationActivities';
import { Activities } from './get-activities/Activities';
import { ButtonExportExcel } from './ButtonExportExcel';
import { useGetUser } from '@core/api-hooks/use-user/get';
import userHelper from '@helpers/user';
import { commerceViewer } from '../../store/comercio-viewer';
import PaltaNavBar from '@components/PaltaNavBar/PaltaNavBar';
import PaltaFooter from '@components/PaltaFooter/PaltaFooter';
import { motion } from 'framer-motion';
import { sanitizeAmount } from '../service-payments/utils';
import { useGetAfinityGroups } from '../../core/api-hooks/use-afinity-group/get';

const P = styled.div`
  color: ${({ theme, cashin }) =>
    cashin ? theme.colors.success : theme.colors.black};
`;

const Group = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const ImgContainer = styled.div`
  margin-right: 10px;
  position: relative;
  background-color: #ffffff;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #7884be;
  border-radius: 50%;
`;
const LittleImgContainer = styled.div`
  position: absolute;
  top: 60%;
  left: 65%;
  background-color: #ffffff;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border: 3px solid #7884be;
  border-radius: 50%;
`;
const Img = styled.img`
  border-radius: 50%;
  object-fit: cover;
  width: ${(props) => (props.isPalta ? '80%' : '100%')};
  height: ${(props) => (props.isPalta ? '80%' : '100%')};
`;

const Resume = ({ wording }) => {
  const { walletsDescription, sections } = wording;
  const [dataUser, setUserData] = useRecoilState(user);
  const [loading, setLoading] = useState(true);
  const [wallet, setWallet] = useRecoilState(selectedWallet);
  const [{ data, indexPage, setIndexPage }, getWallet] = useGetWallet();
  const [{ data: dataCommission }, getCommission] = useGetCommission();
  const [{ data: affinityGroups }, getAffinityGroups] = useGetAfinityGroups();
  const userData = useRecoilValue(user);
  const tokenData = useRecoilValue(token);
  const [affinitySelected, setAffintySelected] = useState(undefined);
  const [item, setItem] = useState({});
  const [cashoutSee, setCashoutSee] = useState({});
  const [cashinSee, setCashinSee] = useState({});
  const [tax, setTax] = useState({});
  const day = new Date();
  const toDay = new Date();
  day.setMonth(new Date().getMonth() - 1);
  toDay.setDate(new Date().getDate() + 1);
  const [filter, setFilter] = useState({
    from: day,
    to: toDay,
  });
  const [page, setPage] = useState(1);
  const pageNumberList = 5;
  const [maxPageNumberList, setMaxPageNumberList] = useState(5);
  const [minPageNumberList, setMinPageNumberList] = useState(0);
  const [maxPageLimit, setMaxPageLimit] = useState(0);
  const commerceView = useRecoilValue(commerceViewer);
  let limit = 15;
  let skip = (page - 1) * limit;
  const [{ data: dataGetUser }, getUser] = useGetUser();
  const [count, setCount] = useState(0);

  const fetchData = useCallback(() => {
    if (userData && userData.role !== 'viewer') {
      getWallet(wallet, userData, tokenData, filter, limit, skip, setPage);
    } else {
      getWallet(wallet, commerceView, tokenData, filter, limit, skip, setPage);
    }
  }, [wallet, userData, commerceView, tokenData, filter, limit, skip]);

  useEffect(() => {
    if (window.localStorage.getItem('AfinityID')) {
      getAffinityGroups(dataUser, tokenData, setLoading);
    }
  }, [dataUser, tokenData]);
  useEffect(() => {
    if (affinityGroups?.length && window.localStorage.getItem('AfinityID')) {
      const affinity = affinityGroups.find(
        (af) => af._id === JSON.parse(window.localStorage.getItem('AfinityID')),
      );
      setAffintySelected(affinity);
    }
  }, [affinityGroups]);
  const getMe = useCallback(() => {
    getUser(tokenData);
  }, [getUser, tokenData]);

  useEffect(() => {
    if (dataUser && count === 0) {
      setCount(1);
      getMe();
    }
  }, [dataUser, count, getMe]);

  useEffect(() => {
    if (dataGetUser) {
      userHelper.setUser(
        JSON.stringify({
          ...dataGetUser.user,
          wallets: dataGetUser.wallets,
        }),
      );
      setLoading(true);
      setUserData({
        ...dataGetUser.user,
        wallets: dataGetUser.wallets,
      });
    }
  }, [dataGetUser]);

  useEffect(() => {
    if (page) {
      if (
        userData &&
        userData?.role !== 'viewer' &&
        userData?.wallets?.length !== 0
      ) {
        if (wallet) {
          setLoading(true);
          fetchData();
        }
      } else if (
        userData &&
        userData?.role === 'viewer' &&
        commerceView?.walletsCommerceView?.length !== 0
      ) {
        if (wallet) {
          setLoading(true);
          fetchData();
        }
      }
    }
  }, [page, userData, wallet, fetchData]);

  let arrayIndexes = [];

  useEffect(() => {
    if (data) {
      const pagination = data.pagination;
      const indexCount = Math.ceil(pagination.count / limit);
      setMaxPageLimit(indexCount);
      for (let i = 0; i < indexCount; i++) {
        arrayIndexes.push(i + 1);
      }
      setIndexPage(arrayIndexes);
    } else {
      setLoading(false);
      setLoading(true);
    }
    setLoading(false);
  }, [data, setIndexPage, userData]);

  const onClickIndex = (idx) => {
    return () => {
      setPage(idx);
    };
  };

  const back = () => {
    if (page > 1) {
      setPage(() => page - 1);
      if (page - 5 < maxPageNumberList && page - 5 <= maxPageLimit) {
        setPage(page - 5);
        setMaxPageNumberList(maxPageNumberList + pageNumberList);
        setMinPageNumberList(minPageNumberList + pageNumberList);
      }
      if (page - 1 < maxPageNumberList) {
        setMaxPageNumberList(maxPageNumberList - pageNumberList);
        setMinPageNumberList(minPageNumberList - pageNumberList);
      }
    }
  };
  const backOne = () => {
    if (page > 1) {
      setPage(() => page - 1);
      if ((page - 1) % pageNumberList === 0) {
        setMaxPageNumberList(maxPageNumberList - pageNumberList);
        setMinPageNumberList(minPageNumberList - pageNumberList);
      }
    }
  };

  const forwardOne = () => {
    setPage(page + 1);
    if (page + 1 > maxPageNumberList) {
      setMaxPageNumberList(maxPageNumberList + pageNumberList);
      setMinPageNumberList(minPageNumberList + pageNumberList);
    }
  };
  const forward = () => {
    setPage(page + 1);
    if (page + 5 > maxPageNumberList && page + 5 <= maxPageLimit) {
      setPage(page + 5);
      setMaxPageNumberList(maxPageNumberList + pageNumberList);
      setMinPageNumberList(minPageNumberList + pageNumberList);
    }
    if (page + 1 > maxPageNumberList) {
      setMaxPageNumberList(maxPageNumberList + pageNumberList);
      setMinPageNumberList(minPageNumberList + pageNumberList);
    }
  };

  let pageIncrementButton = null;

  if (indexPage.length > maxPageNumberList) {
    pageIncrementButton = (
      <li onClick={forward}>
        <button>&hellip;</button>
      </li>
    );
  }

  let pageDecrementButton = null;
  if (minPageNumberList >= 1) {
    pageDecrementButton = (
      <li onClick={back}>
        <button>&hellip;</button>
      </li>
    );
  }

  function escapeRegExp(string) {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(
      `<!doctype html><body>${string}`,
      'text/html',
    ).body.textContent;
    return decodedString;
  }

  let userWallets = userData?.wallets.slice().sort((a, b) => {
    if (a.currencyLabel > b.currencyLabel) {
      return -1;
    }
    if (a.currencyLabel < b.currencyLabel) {
      return 1;
    }
    return 0;
  });

  const showSelectedWallet = wallet
    ? userData.wallets.find((wal) => wal.currency === wallet)
    : undefined;

  return (
    <>
      <PaltaNavBar className="light" />
      <motion.div
        initial={{ x: '100%', opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ type: 'spring', duration: 0.4, bounce: 0.2 }}
      >
        <Container>
          <Wrapper>
            <Grid>
              {isMobile ? (
                commerceView !== undefined ? (
                  <Col large="6" desktop="8">
                    <Title textTransform="capitalize">
                      Actividad
                      <img
                        className="float-right img-fluid mr-3 "
                        src="assets/images/palta-512.svg"
                        alt=""
                        height={50}
                        width={50}
                      />
                    </Title>
                    <h4> Estas viendo {`${commerceView?.fantasyName}`}</h4>
                  </Col>
                ) : (
                  <Col large="6" desktop="8">
                    <Title textTransform="capitalize" className='d-flex justify-content-between'>
                      Actividad
                      <ImgContainer>
                        <Img
                          isPalta={affinitySelected ? false : true}
                          src={
                            affinitySelected
                              ? affinitySelected.logo[0].image
                              : 'assets/images/palta-512.svg'
                          }
                          alt=""
                          height={60}
                          width={60}
                        />
                        {affinitySelected ? (
                          <LittleImgContainer>
                            <img
                              src="assets/images/palta-512.svg"
                              alt=""
                              height={20}
                              width={20}
                            />
                          </LittleImgContainer>
                        ) : null}
                      </ImgContainer>
                    </Title>
                  </Col>
                )
              ) : commerceView !== undefined ? (
                <Col large="12" desktop="12">
                  <Title textTransform="capitalize">Actividad</Title>
                  <h4> Estas viendo {`${commerceView?.fantasyName}`}</h4>
                </Col>
              ) : (
                <Col large="6" desktop="8">
                  <Title textTransform="capitalize">Actividad</Title>
                </Col>
              )}
            </Grid>

            <Wallets
              userData={userData?.role !== 'viewer' ? userData : commerceView}
              loading={loading}
              setLoading={setLoading}
              setWallet={setWallet}
              setMaxPageNumberList={setMaxPageNumberList}
              setMinPageNumberList={setMinPageNumberList}
              wallet={wallet}
              setPage={setPage}
              commerceView={commerceView}
            />

            {wallet !== undefined && userWallets.length !== 0 ? (
              <Grid>
                <Col large="12" desktop="12">
                  <div className="alert alert-info m-0">
                    <Wrapper
                      className="text-center p-0"
                      style={{
                        color: '#3A4D9F',
                        fontSize: 15,
                        fontWeight: 'bold',
                      }}
                    >
                      {walletsDescription[wallet]}
                    </Wrapper>
                  </div>
                </Col>
              </Grid>
            ) : null}

            {showSelectedWallet && (
              <>
                <h3 className="mt-4" style={{ textTransform: 'capitalize' }}>
                  Saldo {showSelectedWallet.currencyLabel}
                </h3>
                <div
                  className="alert alert-primary mt-3"
                  style={{
                    fontSize: '2rem',
                    textAlign: 'center',
                  }}
                >
                  {wallet.includes('dolar') ? 'USDP' : '$'}{' '}
                  <strong>{sanitizeAmount(showSelectedWallet.amount)}</strong>
                </div>
              </>
            )}

            <SearchActivities setFilter={setFilter} filter={filter} />
            {data && data.activities && data.activities.length === 0 ? (
              <Grid>
                <Col large="12" desktop="12" className="box-title">
                  <h3>
                    {sections.lastActivities.empty}
                    {userData &&
                      userData.wallets.map((singleWallet) => {
                        if (singleWallet.currency === wallet) {
                          return singleWallet.currencyLabel;
                        }
                        return null;
                      })}
                  </h3>
                </Col>
              </Grid>
            ) : (
              <>
                <Grid>
                  <Col large="8" desktop="8" className="box-title">
                    {wallet !== undefined && userWallets.length !== 0 ? (
                      <h3>{sections.lastActivities.title}</h3>
                    ) : wallet !== undefined &&
                      commerceView?.commerceAssociateViewer?.walletsCommerceView
                        ?.length !== 0 ? (
                      <h3>{sections.lastActivities.title}</h3>
                    ) : (
                      <h3>Seleccione un monedero a consultar</h3>
                    )}
                  </Col>
                </Grid>
                <ButtonExportExcel
                  data={data}
                  page={page}
                  indexPage={indexPage}
                  wallet={wallet}
                  userData={
                    userData?.role !== 'viewer' ? userData : commerceView
                  }
                  tokenData={tokenData}
                  setLoading={setLoading}
                  filter={filter}
                />
                <h6 className="mt-2">
                  {`Página ${page} de ${indexPage.length}`}
                </h6>
                <Activities
                  data={data}
                  setCashinSee={setCashinSee}
                  setItem={setItem}
                  setCashoutSee={setCashoutSee}
                  setTax={setTax}
                  getCommission={getCommission}
                  userData={
                    userData?.role !== 'viewer' ? userData : commerceView
                  }
                  Group={Group}
                  P={P}
                  tokenData={tokenData}
                  loading={loading}
                  escapeRegExp={escapeRegExp}
                />
                <PaginationActivities
                  data={data}
                  page={page}
                  indexPage={indexPage}
                  backOne={backOne}
                  pageDecrementButton={pageDecrementButton}
                  pageIncrementButton={pageIncrementButton}
                  minPageNumberList={minPageNumberList}
                  onClickIndex={onClickIndex}
                  forwardOne={forwardOne}
                  maxPageLimit={maxPageLimit}
                  maxPageNumberList={maxPageNumberList}
                />
              </>
            )}
          </Wrapper>
        </Container>
        {item._id && (
          <ModalTransaccion
            show={true}
            item={item}
            commission={dataCommission}
            onHide={() => setItem({})}
          >
            <div>
              <SeeTransaction
                item={item}
                commission={dataCommission}
                escapeRegExp={escapeRegExp}
                userData={userData}
              />
            </div>
          </ModalTransaccion>
        )}
        {cashoutSee._id && (
          <ModalCash show={true} onHide={() => setCashoutSee({})}>
            <div>
              <SeeCashout cashoutSee={cashoutSee} escapeRegExp={escapeRegExp} />
            </div>
          </ModalCash>
        )}
        {cashinSee._id && (
          <ModalCashin show={true} onHide={() => setCashinSee({})}>
            <div>
              <SeeCashin cashinSee={cashinSee} escapeRegExp={escapeRegExp} />
            </div>
          </ModalCashin>
        )}
        {tax._id && (
          <ModalTax show={true} onHide={() => setTax({})}>
            <div>
              <SeeTax tax={tax} />
            </div>
          </ModalTax>
        )}
      </motion.div>
      <PaltaFooter />
    </>
  );
};

export default Resume;

