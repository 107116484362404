import Swal from 'sweetalert2';

export const makeSwalError = (title) => {
	Swal.fire({
		icon: 'error',
		title: title,
		padding: '2px',
		showConfirmButton: false,
		width: '300px',
		heightAuto: '200px',
		timer: 1500,
		customClass: {
			title: 'm-0 text-danger',
			header: 'p-0',
		},
	}).then(() => {
		window.location.href = "/dashboard";
	});
}