import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { setTimeout } from 'core-js';

import fetchApiMig from '../../core/FetchApiMig';
import PaltaList from '../../components/PaltaList/PaltaList';
import PaltaBreadcrumbs from '../../components/PaltaBreadcrumbs/PaltaBreadcrumbs';
import PaltaListUser from '../../components/PaltaListUser';
import * as ROUTES from '../../constants/routes';
import * as API from '../../constants/api-staging';

class Step1 extends React.Component {
  render() {
    const {
      name,
      users,
      handleChange,
      handleUserSelected,
      currentStep,
    } = this.props;

    if (currentStep !== 1) {
      return null;
    }

    return (
      <div>
        <Form.Group className="form-group-icon" controlId="name">
          <i className="material-icons icon">search</i>
          <Form.Control
            type="text"
            name="name"
            autoComplete="off"
            placeholder="Nombre, DNI o Mail"
            value={name}
            onChange={handleChange}
          />
        </Form.Group>
        <PaltaList
          style={{
            paddingBottom: '4rem',
            paddingLeft: '0px',
            paddingRight: '0px',
          }}
        >
          {users &&
            users.map((user) => (
              <PaltaListUser
                key={user.id}
                onClick={() => handleUserSelected(user)}
                name={user.nickname}
                url=""
                username={user.email}
                imgSrc={user.image}
              />
            ))}
        </PaltaList>
      </div>
    );
  }
}

class Step2 extends React.Component {
  render() {
    const {
      amount,
      handleChange,
      _prev,
      _next,
      currentStep,
      context,
    } = this.props;
    const isValid = amount.match(/^[1-9]\d*(\.[0-9]{1,2})?$/);

    if (currentStep !== 2) {
      return null;
    }

    return (
      <div>
        <Form.Group
          className="align-items-center d-flex form-group resume flex-row"
          controlId="amount"
        >
          <h1
            style={{
              fontSize: '5rem',
              color: 'rgb(55, 40, 222)',
              width: '5rem',
              margin: '0',
            }}
          >
            $
          </h1>
          <Form.Control
            min="0.01"
            step="2"
            type="number"
            inputmode="decimal"
            pattern="\d*"
            name="amount"
            max={context.balance.remaining_credit}
            className="payment-amount"
            autoComplete="off"
            placeholder="0"
            value={amount}
            onChange={handleChange}
          />
        </Form.Group>
        <div className="text-center" style={{ marginTop: '1rem' }}>
          <Button variant="secundary" size="lg" onClick={_prev}>
            {' '}
            Volver{' '}
          </Button>
          <Button
            style={{ marginLeft: '1em' }}
            variant="primary"
            size="lg"
            disabled={!isValid}
            onClick={_next}
          >
            {' '}
            Siguiente{' '}
          </Button>
        </div>
      </div>
    );
  }
}

class Step3 extends React.Component {
  render() {
    const {
      description,
      userToPay,
      handleChange,
      _prev,
      _next,
      currentStep,
    } = this.props;

    if (currentStep !== 3) {
      return null;
    }

    return (
      <div>
        <div className="resume">
          <div className="box-quota">
            <div className="text">
              <span className="title">Descripcion</span>
              <p>{description || `Pago a ${userToPay.nickname}`}</p>
            </div>
          </div>
        </div>
        <Form.Group className="form-group-icon" controlId="description">
          <i className="material-icons icon">message</i>
          <Form.Control
            type="text"
            name="description"
            autoComplete="off"
            placeholder="Descripción del préstamo"
            value={description}
            onChange={handleChange}
          />
        </Form.Group>
        <div className="text-center" style={{ marginTop: '1rem' }}>
          <Button variant="secundary" size="lg" onClick={_prev}>
            {' '}
            Volver{' '}
          </Button>
          <Button
            style={{ marginLeft: '1em' }}
            variant="primary"
            size="lg"
            onClick={_next}
          >
            {' '}
            Siguiente{' '}
          </Button>
        </div>
      </div>
    );
  }
}

class Step4 extends React.Component {
  render() {
    const {
      isLoading,
      userToPay,
      amount,
      description,
      currentStep,
      onClick,
    } = this.props;

    if (currentStep !== 4) {
      return null;
    }

    return (
      <div>
        <div className="justify-content-md-center align-items-center">
          <Col
            className="justify-content-md-center text-center align-items-center"
            style={{ padding: '2rem 0' }}
          >
            <h1 style={{ fontSize: '5rem' }}>$ {amount}</h1>
          </Col>
          <ul className="list-item-details list-palta">
            <li className="main-item">
              <img
                className=" main-user-img"
                src={userToPay.image}
                alt="images"
              />
              <div className="main-box-info">
                <div className="text">
                  <span className="title">{userToPay.nickname}</span>
                  <span className="subtitle">{userToPay.email}</span>
                </div>
                <div className="action">
                  <i className="material-icons">keyboard_arrow_right</i>
                </div>
              </div>
            </li>
            <li className="item-quota">
              <div className="box-quota">
                <div className="text">
                  <span className="title">Descripcion</span>
                  <p>{description || `Pago a ${userToPay.nickname}`}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div style={{ marginTop: '1.7rem' }}>
          <Button
            variant="primary"
            size="lg"
            disabled={isLoading}
            className="btn-block btn-fullwidthe"
            onClick={onClick}
          >
            {' '}
            {isLoading || 'Confirmar'}{' '}
          </Button>
        </div>
      </div>
    );
  }
}

class index extends Component {
  state = {
    currentStep: 1,
    amount: '',
    description: '',
    userToPay: {},
    name: '',
    users: [],
    isLoading: false,
    // isLoan: false
  };

  UNSAFE_componentWillMount() {
    const { id } = this.props.computedMatch.params;
    if (id) {
      this.setState({ currentStep: 2 });
      return fetchApiMig('GET', `${API.USERS}/${id}`)
        .then((data) => {
          this.setState({ userToPay: data });
        })
        .catch((err) => {
          toast.error(`Error al cargar usuario: ${err}`);
          console.error('catched Error: ', err);
        });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleChangeSearchUser = (event) => {
    event.preventDefault();

    const { name, value } = event.target;
    this.setState({ [name]: value });
    const reText = new RegExp('[a-z]', 'ig');
    const reEmail = new RegExp('[@]', 'ig');
    if (reEmail.test(value)) {
      let nameLowerCase = value.toLowerCase();
      this.setState({ [name]: nameLowerCase });
      if (nameLowerCase.length > 2) {
        return fetchApiMig(
          'GET',
          `${API.USERS}?by_email=${nameLowerCase}&per_page=30`,
        )
          .then((data) => {
            this.setState({ users: data });
          })
          .catch((err) => {
            toast.error(`Error al cargar usuarios: ${err}`);
            console.error('catched Error: ', err);
          });
      }
    } else if (reText.test(value)) {
      let nameUppercase = value
        .toLowerCase()
        .replace(/\b[a-z]/g, (letter) => letter.toUpperCase());
      this.setState({ [name]: nameUppercase });
      if (nameUppercase.length > 2) {
        return fetchApiMig(
          'GET',
          `${API.USERS}?by_name=${nameUppercase}&per_page=30`,
        )
          .then((data) => {
            this.setState({ users: data });
          })
          .catch((err) => {
            toast.error(`Error al cargar usuarios: ${err}`);
            console.error('catched Error: ', err);
          });
      }
    } else {
      if (value.length > 2) {
        let dniValue = Number(value);
        return fetchApiMig('GET', `${API.USERS}?by_dni=${dniValue}&per_page=30`)
          .then((data) => {
            this.setState({ users: data });
          })
          .catch((err) => {
            toast.error(`Error al cargar usuarios: ${err}`);
            console.error('catched Error: ', err);
          });
      }
    }
  };
  handleUserSelected = (user) => {
    this.setState({ userToPay: user });
    this._next();
  };

  _next = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep < 4 && currentStep + 1;
    this.setState({
      currentStep: currentStep,
    });
  };

  _prev = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
    });
  };

  hanldeSubmitTransfer = (e) => {
    e.preventDefault();
    const context = this.context;

    this.setState({ isLoading: 'Procesando' });
    const { description, userToPay, amount } = this.state;

    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 5000);

    const descriptionToSend = `${
      description || `Pago a ${userToPay.nickname}`
    }`;
    const amountToSend = Number(amount).toFixed(2);

    let data = {
      description: descriptionToSend,
      _to: userToPay._id,
      amount: amountToSend,
      currency: 'ARS',
      isLoan: true,
    };

    fetchApiMig('POST', `${API.TRANSACTIONS}`, data)
      .then((data) => {
        toast.success(` 🤙 Pago Exitoso `, {
          hideProgressBar: false,
          autoClose: 2000,
        });
        context.handleItemView(data);
        setTimeout(() => {
          this.props.history.push(ROUTES.ITEM_VIEW + '/' + data._id);
        }, 2000);
      })
      .catch((err) => {
        toast.error(`Error al intentar transferir: ${err}`);
      });
  };

  render() {
    let context = this.context;
    let {
      users,
      name,
      amount,
      description,
      userToPay,
      currentStep,
      isLoading,
    } = this.state;
    return (
      <div style={{ backgroundColor: '#fff' }}>
        <PaltaBreadcrumbs
          name="Prestar"
          link={ROUTES.PAYMENTS}
          linkName="Transferencias"
          variant="dark"
        />
        <section style={{ marginBottom: '4em' }}>
          <Container>
            <Row>
              <Col sm={12}>
                <h2 className="title" style={{ color: '#3628DD' }}>
                  Prestar
                </h2>
              </Col>
            </Row>
          </Container>
          <Container>
            <div className="justify-content-md-center align-items-center">
              <Form style={{ maxWidth: '28rem', margin: '0 auto' }}>
                <React.Fragment>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <Step1
                      users={users}
                      currentStep={currentStep}
                      handleUserSelected={this.handleUserSelected}
                      handleChange={this.handleChangeSearchUser}
                      name={name}
                      _next={this._next}
                    />
                    <Step2
                      currentStep={currentStep}
                      handleChange={this.handleChange}
                      amount={amount}
                      context={context}
                      _prev={this._prev}
                      _next={this._next}
                    />
                    <Step3
                      userToPay={userToPay}
                      currentStep={currentStep}
                      handleChange={this.handleChange}
                      description={description}
                      _prev={this._prev}
                      _next={this._next}
                    />
                    <Step4
                      isLoading={isLoading}
                      amount={amount}
                      userToPay={userToPay}
                      description={description}
                      currentStep={currentStep}
                      onClick={this.hanldeSubmitTransfer}
                    />
                  </form>
                </React.Fragment>
              </Form>
            </div>
          </Container>
        </section>
      </div>
    );
  }
}

export default withRouter(index);
