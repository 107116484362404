import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import * as ROUTES from '@constants/routes';
import { Button } from 'react-bootstrap';
import logoPalta from '../../../assets/images/palta-logo-500.svg';
import refresh from '../../../assets/images/refresh.svg';
import { useHistory } from 'react-router-dom';
import { dataPhoneRegis} from '../../../store';
import { useRecoilValue } from 'recoil';
import { useTimer } from 'react-timer-hook';
import { useSendCodeSms } from '../../../core/api-hooks/use-send-code-sms/post';
import { useGetSendCodeSms } from '../../../core/api-hooks/use-resend-sms-code-cel/get';
import { useSendEmail } from '../../../core/api-hooks/use-send-email/post';
import ReactCodeInput from 'react-code-input';
const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BoxContainer = styled.div`
  width: 340px;
  min-height: 624px;
  display: flex;
  flex-direction: column;
  border-radius: 50px;

  box-shadow: 0px 0px 4.7px rgba(15, 15, 15, 0.28);
  position: relative;
  overflow: hidden;
  padding: 0px 10px 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ValidPhone = () => {
  const [pinCode, setPinCode] = useState('');
  const handlePinChange = (pinCode) => {
    setPinCode(pinCode);
  };
  const [{ data: dataSMSCode }, postSendSms] = useSendCodeSms();
  const [expire, setExpire] = useState('');
  const time = new Date();
  let expiryTimestamp = time.setSeconds(time.getSeconds() + 90);
  const { seconds, minutes } = useTimer({
    expiryTimestamp,
    onExpire: () => setExpire('onExpire called'),
  });
  const {handleSubmit} = useForm({
    mode: 'onChange',
  });
  const dataPhone = useRecoilValue(dataPhoneRegis);
  const history = useHistory();
  const [putSendDataRegister] = useGetSendCodeSms();
  const [postSendEmail] = useSendEmail();
  const onSubmit = useCallback(
    async (dataSMSCode) => {
      postSendSms(pinCode);
    },
    [pinCode],
  );

  if (dataSMSCode !== undefined) {
    postSendEmail();
  }
  const resendSmsCodeToCell0Phone = () => {
    putSendDataRegister();
  };

  return (
    <>
      <AppContainer>
        <BoxContainer>
          <InnerContainer>
            <img
              src={logoPalta}
              width="120"
              height="50"
              className="d-inline-block align-top position-logo "
              alt="Palta logo"
            />
            <h5>Validación de teléfono</h5>
            <h5 className="text-center">
              {dataPhone !== undefined
                ? `Ingrese el código enviado a ${dataPhone}`
                : `Ingrese el código enviado a `}
            </h5>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="phone-field">
                <ReactCodeInput
                  placeholder="0"
                  inputStyle={{
                    height: 57,
                    width: 45,
                    fontFamily: 'monospace',
                    borderRadius: 6,
                    border: '1px solid #3A4D9F',
                    boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 10px 0px',
                    margin: 4,
                    paddingLeft: 12,
                    fontSize: 32,
                    boxSizing: 'border-box',
                    color: '#3A4D9F',
                    backgroundColor: 'white',
                  }}
                  id="pinCode"
                  type="number"
                  fields={4}
                  onChange={handlePinChange}
                  value={pinCode}
                  min="1"
                  pattern="^[0-9]+"
                  inputMode="numeric"
                />
              </div>
              <div className="position-button-phone d-flex justify-content-center align-items-center mt-4">
                <Button
                  type="submit"
                  className="active btn-md btn-block btn-fullwidthe bg-danger mt-4 text-white"
                  onClick={() => history.push(ROUTES.REGISTER)}
                  style={{
                    fontSize: 18,
                    borderRadius: 5,
                    fontFamily: 'inherit',
                    textTransform: 'none',
                  }}
                >
                  Volver
                </Button>
                <Button
                  type="submit"
                  className="ml-2 active btn-md btn-block btn-fullwidthe btnColor mt-4 text-white"
                  disabled={pinCode.length < 4}
                  style={{
                    fontSize: 18,
                    borderRadius: 5,
                    fontFamily: 'inherit',
                    textTransform: 'none',
                  }}
                >
                  Continuar
                </Button>
              </div>
            </form>
            {expire === '' ? (
              <div className="position-sms d-flex justify-content-center align-items-center mt-4">
                <p
                  className="text-center"
                  style={{ fontSize: 13, color: '#5D5D5D' }}
                >
                  ¿No recibiste ningún código? Reenviar en{' '}
                  <span>{minutes}</span>:<span>{seconds}</span>
                </p>
              </div>
            ) : (
              <div className="position-sms-button d-flex justify-content-center align-items-center">
                <Button
                  type="submit"
                  className=" active btn btn-fullwidthe btnColor mt-4"
                  style={{
                    fontSize: 18,
                    borderRadius: 5,
                    fontFamily: 'inherit',
                    textTransform: 'none',
                  }}
                  onClick={resendSmsCodeToCell0Phone}
                >
                  <img
                    src={refresh}
                    width="30"
                    height="20"
                    className="d-inline-block align-center mr-1 mb-1"
                    alt="Palta logo"
                  />
                  Reenviar
                </Button>
              </div>
            )}
          </InnerContainer>
        </BoxContainer>
      </AppContainer>
    </>
  );
};
